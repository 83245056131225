import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Badge, Button, Col, Row, Table as BootstrapTable } from 'react-bootstrap';
import { API } from 'aws-amplify';
import * as Sentry from '@sentry/react';

function Table(props) {
    const { teams, canManageTeams, disabled, removeTeam } = props;
    const [ saving, setSaving ] = useState(false);
    const [ error, setError ] = useState();
    const unread = props.unread || [];
    const history = useHistory();

    const reactivateTeam = async (team) => {
        setError(null);
        setSaving(true);

        try {
            await API.put('AuthenticatedAPI', `/teams/${team.id}`, { body: { ...team, disabled: false } });
            setSaving(false);
            removeTeam(team);
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setSaving(false);
                        setError(error.response.data.message);
                        break;
                    case 401:
                        history.push('/', {
                            message: 'Your session has expired. Please log back in to continue.',
                            redirect: history.location
                        });
                        break;
                    case 403:
                    case 404:
                        history.push('/recipient/contacts/teams');
                        break;
                    default:
                        setSaving(false);
                        setError( `We encountered an unexpected issue while updating this Team. The service returned error code ${error.response.status}`);
                        Sentry.captureException(error);
                        break;
                }
            } else {
                setSaving(false);
                setError(`We encountered an unexpected issue while updating this Team. The service returned error message ${error.message}`);
                Sentry.captureException(error);
            }
        }
    };

    return (
        <>
            <Row className="smaller justify-content-end my-3">
                <Col xs="4" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-comment fa-fw"></i> Chat with Team
                </Col>
                {Boolean(canManageTeams) && (
                    <Col xs="4" lg="auto" className="text-gray-400 font-weight-light">
                        <i className="fas fa-edit fa-fw"></i> Edit Team
                    </Col>
                )}
                {disabled && (
                    <Col xs="4" lg="auto" className="text-gray-400 font-weight-light">
                        <i className="fas fa-trash-restore fa-fw"></i> Reactivate Team
                    </Col>
                )}
            </Row>

            {error && (
                <Alert variant="orange-200">{error}</Alert>
            )}

            <BootstrapTable responsive striped bordered hover className="bg-white">
                <thead className="bg-gray-200 text-white small font-weight-normal">
                    <tr>
                        <th>Team</th>
                        <th width={canManageTeams ? 122 : 71}></th>
                    </tr>
                </thead>
                <tbody>
                    {teams === null ? (
                        <tr key="loading">
                            <td colSpan="2" className="text-center">
                                <i className="fas fa-spinner fa-fw fa-lg fa-spin" />
                            </td>
                        </tr>
                    ) : teams.length === 0 ? (
                        <tr key="none">
                            <td colSpan="2" className="text-center">
                                No results found
                            </td>
                        </tr>
                    ) : teams.map(team => {
                        let unreadRecipient = unread.find(x => x.id === `team_${team.id}`);
                        let unreadCount = unreadRecipient ? unreadRecipient.count : null;

                        return (
                            <tr key={team.id}>
                                <td>
                                    {team.name}
                                </td>
                                <td className="text-right">
                                    {Boolean(team.chatEnabled) && Boolean(team.assignedTo) && (
                                        <span className="position-relative">
                                            <Button as={Link} variant="blue-400" href="/recipient/chat" to={{ pathname: '/recipient/chat', state: { team } }} className="text-gray-700"><i className="fas fa-comment fa-fw"></i></Button>
                                            <Badge variant="orange-200" className="position-absolute text-gray-700" style={{ right: -5, top: -15 }}>{unreadCount}</Badge>
                                         </span>
                                    )}

                                    {Boolean(canManageTeams) && (
                                        <>
                                            {disabled ? (
                                                <Button variant="orange-200" className="text-gray-700 ml-1" onClick={() => reactivateTeam(team)} disabled={saving}><i className="fas fa-trash-restore fa-fw" /></Button>
                                            ) : (
                                                <Button as={Link} variant="blue-300" className="text-gray-700 ml-1" href={`/recipient/contacts/teams/${team.id}`} to={`/recipient/contacts/teams/${team.id}`} title="Edit Team"><i className="fas fa-edit fa-fw"></i></Button>
                                            )}
                                        </>
                                    )}
                                </td>
                            </tr>
                        )}
                    )}
                </tbody>
            </BootstrapTable>
        </>
    );
}

export default Table;
