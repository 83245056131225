import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Table from '../Webhooks/Table';
import Pagination from '../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Webhooks(props) {
    const [ webhookState, setWebhookState ] = useState({ webhooks: null, total: 0, error: '' });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 25;
    const history = useHistory();
    const id = props.partner.id;

    useEffect(() => {
        const getWebhooksByPartner = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/partners/${id}/webhooks`, { response: true, queryStringParameters: { pageSize, pageNumber } });
                setWebhookState({ webhooks: response.data, total: response.headers['x-total-count'], error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setWebhookState({ webhooks: null, total: 0, error: error.response.data.message });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Webhooks for this Partner. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/admin/partners');
                            break;
                        default:
                            setWebhookState({ webhooks: null, total: 0, error: `We encountered an unexpected issue while retrieving Webhooks for this Partner. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setWebhookState({ webhooks: null, total: 0, error: `We encountered an unexpected issue while retrieving Webhooks for this Partner. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setWebhookState({ webhooks: null, total: 0, error: '' });
        getWebhooksByPartner();
    }, [ id, pageNumber, history ]);

    return (
        <>
            <hr/>

            {webhookState.error !== '' && (
                <Alert variant="orange-200" className="small">{webhookState.error}</Alert>
            )}

            <Table webhooks={webhookState.webhooks} />

            {webhookState.total > 0 && (
                <Pagination total={webhookState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
            )}
        </>
    );
}

export default Webhooks;
