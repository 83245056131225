import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { default as BootstrapCard } from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Filters from './Filters';
import Card from './Card';
import Pagination from '../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Responded(props) {
    const { branchId } = props;

    const [ responsesState, setResponsesState ] = useState({ responses: null, total: 0, error: '', loading: false });
    const [ filters, setFilters ] = useState({ sendingUserId: null, createdAfter: '', createdBefore: '' });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 30;
    const history = useHistory();

    useEffect(() => {
        const getResponsesByBranch = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branchId}/responses`, { response: true, queryStringParameters: { ...filters, pageSize, pageNumber, expand: 'notification,sending-user' } });

                setResponsesState({ responses: response.data, total: parseInt(response.headers['x-total-count']), error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setResponsesState({ responses: null, total: 0, loading: false, error: error.response.data.message });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Responses for this Branch.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setResponsesState({ responses: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Responses for this Branch. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setResponsesState({ responses: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Responses for this Branch. The service returned error code ${error.response.status}` });
                    Sentry.captureException(error);
                }
            }
        };

        setResponsesState({ responses: null, total: 0, loading: true, error: '' });
        getResponsesByBranch();
    }, [ history, branchId, filters, pageNumber ]);

    return (
        <>
            <Helmet>
                <title>Employee | Pending Responses</title>
            </Helmet>

            <Filters branchId={branchId} onSearch={setFilters} showSentBy={true} />

            {responsesState.loading ? (
                <h1 className="mt-3"><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>
            ) : responsesState.error !== '' ? (
                <Alert variant="orange-200" className="small">{responsesState.error}</Alert>
            ) : responsesState.total === 0 ? (
                <h3 className="text-center my-5">No results.</h3>
            ) : (
                <div className="bg-gray-400 mt-2 px-1 rounded">
                    <Form.Row>
                        {responsesState.responses.map(response => {
                            let hyperlink;
                            const { notification } = response;

                            if (notification.responses.length > 0 && response.response === notification.responses[0].text) {
                                hyperlink = notification.responses[0].hyperlink;
                            } else if (notification.responses.length > 1 && response.response === notification.responses[1].text) {
                                hyperlink = notification.responses[1].hyperlink;
                            }

                            return (
                                <Col key={notification.id} xs="12" sm="6" md="12" lg="6" xl="4" className="my-1">
                                    <Card notification={notification} footer={response.response && (
                                        <BootstrapCard.Footer>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="small font-weight-medium">Responded <span className="text-blue-400">&ldquo;{response.response}&rdquo;</span></div>

                                                {hyperlink && (
                                                    <Button variant="blue-300" size="sm" className="text-gray-700 font-weight-medium small" href={hyperlink} target="_blank" rel="noreferrer noopener">
                                                        <i className="fas fa-link fa-fw fa-sm mr-1" />
                                                        Review Link
                                                    </Button>
                                                )}
                                            </div>
                                        </BootstrapCard.Footer>
                                    )} />
                                </Col>
                            );
                        })}
                    </Form.Row>
                </div>
            )}

            <hr />

            {responsesState.total > 0 && (
                <Pagination total={responsesState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
            )}
        </>
    );
}

export default Responded;
