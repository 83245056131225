import React from 'react';
import { default as BootstrapPagination } from 'react-bootstrap/Pagination';

function Pagination(props) {
    if (props.pageSize === 0) {
        return null;
    }

    return (
        <>
            <div className="small text-gray-400 font-weight-semibold text-right">
                Total: {props.total}
            </div>

            <BootstrapPagination size="sm" className="justify-content-end flex-wrap">
                {Array.from(Array(Math.ceil(props.total/props.pageSize)).keys()).map(page =>
                    <BootstrapPagination.Item key={page} active={page === props.pageNumber} onClick={evt => props.onChange(page)}>{page + 1}</BootstrapPagination.Item>
                )}
            </BootstrapPagination>
        </>
    );
}

export default Pagination;
