import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Auth, API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

// TODO - Expand `completeNewPassword` errors
function Login(props) {
    const history = useHistory()
    const { search } = useLocation()
    const query = new URLSearchParams(search)
    const token = query.get('token')

    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState(token || '');
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ formState, setFormState ] = useState({ user: null, error: null, loading: false });

    const message = props.location.state ? props.location.state.message || null : null;
    const redirect = props.location.state ? props.location.state.redirect || null : null;

    const navigateToApp = useCallback((user) => {
        let groups = user.signInUserSession.idToken.payload['cognito:groups'];
        let group = groups && groups.length > 0 ? groups[0] : null;

        switch (group) {
            case 'admin':
                history.push('/admin');
                break;
            case 'partner':
                history.push('/partner');
                break;
            case 'individual':
                API.patch('AuthenticatedAPI', `/recipients/${user.username}`, { body: { } }).then(data => {
                    history.push('/recipient');
                }).catch(error => {
                    if (error.response) {
                        console.log(error.response)
                        switch (error.response.status) {
                            case 400:
                                setFormState({ user: null, error: error.response.data.message, loading: false });
                                break;
                            case 401:
                                history.push('/', {
                                    message: 'Your session has expired. Please log back in to continue.',
                                    redirect: history.location
                                });
                                break;
                            case 403:
                                history.push('/', {
                                    message: 'You are not authorized to update this Recipient.',
                                    redirect: history.location
                                });
                                break;
                            case 404:
                                setFormState({ user: null, error: `We could not find your account. Please contact support for assistance.`, loading: false });
                                break;
                            default:
                                setFormState({ user: null, error: `We encountered an unexpected issue while updating this Recipient. The service returned error code ${error.response.status}`, loading: false });
                                Sentry.captureException(error);
                                break;
                        }
                    } else {
                        setFormState({ user: null, error: `We encountered an unexpected issue while updating this Recipient. The service returned error message ${error.message}`, loading: false });
                        Sentry.captureException(error);
                    }
                });
                break;
            default:
                setFormState({ user: null, error: <>Uhoh! It looks like your account is not setup properly. Please contact <a href="https://app.notifyd.com/support" target="_blank" rel="noopener noreferrer">support</a> for assistance.</>, loading: false });
                Sentry.captureMessage("User detected without a group.");
                break;
        }
    }, [ history ]);

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(user => {
            navigateToApp(user);
        }).catch(() => {
            // Do nothing.
        });
    }, [ navigateToApp ]);

    const loginFormSubmit = async (evt) => {
        evt.preventDefault();
        setFormState({ user: null, error: null, loading: true });

        try {
            let user = await Auth.signIn(email.trim(), password);

            if (user.signInUserSession) {
                if (redirect) {
                    history.push(redirect);
                } else {
                    navigateToApp(user);
                }
            } else {
                setFormState({ ...formState, user, loading: false });
            }
        } catch (error) {
            let formError;

            switch (error.code) {
                case 'NotAuthorizedException':
                    switch(error.message) {
                        case 'Password attempts exceeded':
                            formError = 'Password attempts exceeded, please try again in a couple hours.';
                            break;
                        case 'Temporary password has expired and must be reset by an administrator.':
                            formError = 'Your temporary credentials have expired. Please reach out to your employer and ask for a Notifyd password reset.';
                            break;
                        default:
                            if (token) {
                                formError = 'Incorrect email or activation link. Your email is case sensitive or you may have received a more recent activation link.';
                            } else {
                                formError = 'Incorrect email or password. Your email and password are case sensitive.';
                            }
                            break;
                    }
                    break;
                case 'UserNotFoundException':
                    if (token) {
                        formError = 'Incorrect email. Your email is case sensitive.';
                    } else {
                        formError = 'Incorrect email or password. Your email and password are case sensitive.';
                    }
                    break;
                case 'InvalidParameterException':
                    formError = 'Invalid email. Please check that your email is formatted correctly.';
                    break;
                case 'PasswordResetRequiredException':
                    history.push('/confirmation', { email: email.trim() });
                    return;
                case 'UserNotConfirmedException':
                    history.push('/verification', { email: email.trim() });
                    return;
                default:
                    formError = `We encountered an unexpected issue while logging in. The service returned error code ${error.code}`;
                    Sentry.captureException(error);
                    break;
            }

            setFormState({ ...formState, error: formError, loading: false });
        }
    }

    const setPasswordFormSubmit = async (evt) => {
        evt.preventDefault();
        setFormState({ ...formState, error: null, loading: true });

        if (newPassword !== confirmPassword) {
            setFormState({ ...formState, error: 'Your passwords do not match.', loading: false });
            return
        }

        try {
            let user = await Auth.completeNewPassword(formState.user, newPassword);
            navigateToApp(user);
        } catch (error) {
            setFormState({ ...formState, error: error.message, loading: false });
            Sentry.captureException(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Login</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <div className="bg-gray-700 py-5">
                <Container>
                    {!formState.user ? (
                        <>
                            {token ? (
                                <>
                                    <div className="text-center">
                                        <h1 className="display-4">Activate Your Account</h1>
                                        <p className="lead">Sign in with your email below to get started.</p>
                                    </div>

                                    <hr />

                                    <Form onSubmit={loginFormSubmit}>
                                        <Row className="justify-content-center">
                                            <Col xs="12" md="6" lg="5" xl="4">
                                                <fieldset>
                                                    <Form.Group controlId="email">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="email" required value={email} onChange={e => setEmail(e.target.value)} />
                                                    </Form.Group>
                                                </fieldset>

                                                {formState.error !== null && (
                                                    <Alert variant="orange-200" className="small">{formState.error}</Alert>
                                                )}

                                                <Button type="submit" variant="orange-300" className="text-white" disabled={formState.loading}>Login</Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                    <p className="text-center small mt-4">By logging in you, you agree to our <a href="https://notifyd.com/terms/user#terms">User Terms of Service</a>.</p>
                                </>
                            ) : (
                                <>
                                    <div className="text-center">
                                        <h1 className="display-4">Welcome Back</h1>
                                        <p className="lead">Sign in with your credentials below.</p>
                                    </div>

                                    <hr />

                                    <Form onSubmit={loginFormSubmit}>
                                        <Row className="justify-content-center">
                                            <Col xs="12" md="6" lg="5" xl="4">
                                                {message !== null && (
                                                    <Alert variant="green-500" className="small">{message}</Alert>
                                                )}

                                                <fieldset>
                                                    <Form.Group controlId="email">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="email" required value={email} onChange={e => setEmail(e.target.value)} />
                                                    </Form.Group>

                                                    <Form.Group controlId="password">
                                                        <Form.Label>Password</Form.Label>
                                                        <Form.Control type="password" required minLength="8" pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$" value={password} onChange={e => setPassword(e.target.value)} />
                                                        <Form.Text muted>Must be at least 8 characters long and contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.</Form.Text>
                                                    </Form.Group>
                                                </fieldset>

                                                {formState.error !== null && (
                                                    <Alert variant="orange-200" className="small">{formState.error}</Alert>
                                                )}

                                                <Button type="submit" variant="orange-300" className="text-white" disabled={formState.loading}>Login</Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                    <p className="text-center small mt-4">By logging in you, you agree to our <a href="https://notifyd.com/terms/user#terms">User Terms of Service</a>.</p>

                                    <hr />

                                    <p className="text-center">
                                        <Link href="/reset-password" to="/reset-password">Forgot your password?</Link>
                                    </p>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="text-center">
                                <h1 className="display-4">Set a new password!</h1>
                                <p className="lead">For security reasons you are required to reset your password the first time you login.</p>
                            </div>

                            <hr />

                            <Form onSubmit={setPasswordFormSubmit}>
                                <Row className="justify-content-center">
                                    <Col xs="12" md="6" lg="5" xl="4">
                                        <fieldset>
                                            <Form.Group controlId="password">
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control type="password" required minLength="8" pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                                <Form.Text muted>Must be at least 8 characters long and contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.</Form.Text>
                                            </Form.Group>

                                            <Form.Group controlId="confirmPassword">
                                                <Form.Label>Confirm New Password</Form.Label>
                                                <Form.Control type="password" required minLength="8" pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                                            </Form.Group>
                                        </fieldset>

                                        {formState.error !== null && (
                                            <Alert variant="orange-200" className="small">{formState.error}</Alert>
                                        )}

                                        <Button type="submit" variant="orange-300" className="text-white" disabled={formState.loading}>Set Password</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )}
                </Container>
            </div>
        </>
    );
}

export default Login;
