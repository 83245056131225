import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from './ListGroupItem';
import { Dropdown } from 'react-bootstrap';

const VIEW_COOKIE_KEY = 'notificationView';

function List(props) {
    const { notifications, unread, setNotification, setFilters } = props;
    const history = useHistory();
    const v = document.cookie.match(`(^|;) ?${VIEW_COOKIE_KEY}=([^;]*)(;|$)`);
    const initialViewIndex = (v && v[2] ? parseInt(v[2]) : 0);

    const VIEW_OPTIONS = [{
        text: 'My Recent Notifications',
        filters: {}
    }, {
        text: 'My Unread Notifications',
        filters: {
            ids: unread ? unread.map(x => x.id.replace('notification_', '')).join(',') : ''
        }
    }];

    const [ viewIndex, setViewIndex ] = useState(initialViewIndex)
    const view = VIEW_OPTIONS[viewIndex]

    useEffect(() => {
        setFilters(view.filters)
    }, [])

    const handleViewChange = (viewOption, index) => {
        if (viewOption.text !== view.text) {
            let d = new Date();
            d.setTime(d.getTime() + 24*60*60*1000*30); // 30 days
            document.cookie = `${VIEW_COOKIE_KEY}=${index};path=/;expires=${d.toGMTString()}`;

            setViewIndex(index)
            setFilters(viewOption.filters)
        }
    }

    let unreadCount = unread ? unread.length : 0;

    return (
        <>
            <div className="bg-blue-300 text-white text-center small font-weight-medium py-2">
                <i className="fas fa-envelope fa-fw"/>
                
                <Dropdown className="d-inline">
                    <Dropdown.Toggle variant="link" className="text-white px-1 py-0 border-0 small">{view.text}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {VIEW_OPTIONS.map((viewOption, index) =>
                            <Dropdown.Item key={index} className="small" onClick={() => handleViewChange(viewOption, index)}>{viewOption.text}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
                
                <Badge variant="orange-200" className="text-gray-700">{unreadCount > 0 && unreadCount}</Badge>
            </div>

            <ListGroup variant="flush">
                <ListGroup.Item>
                    <button onClick={() => history.push('/recipient/notifications')} className="d-flex w-100 text-gray-100 p-0 bg-transparent border-0 text-left">
                        <div className="position-relative">
                            <span className="fa-2x fa-layers fa-fw">
                                <i className="fas fa-circle text-green-500"></i>
                                <i className={`fa-inverse fas fa-envelope`} data-fa-transform="shrink-8"></i>
                            </span>
                        </div>

                        <div className="flex-grow-1 ml-2">
                            <div className="d-lg-flex justify-content-between">
                                <div>
                                    <span className="small font-weight-semibold text-blue-300">Let's get started!</span>
                                </div>
                            </div>

                            <p className="mb-0 small">Click here to start a notification!</p>
                        </div>
                    </button>
                </ListGroup.Item>

                {notifications === null ? (
                    <ListGroup variant="flush">
                        <ListGroup.Item key="loading" className="text-center">
                            <i className="fas fa-spinner fa-fw fa-spin fa-2x text-blue-400" />
                        </ListGroup.Item>
                    </ListGroup>
                ) : notifications.map(notification => {
                    let unreadNotification = unread && unread.find(x => x.id.replace('notification_', '') === notification.id.toString());

                    return (
                        <ListGroupItem key={notification.id} notification={notification} unreadCount={unreadNotification && unreadNotification.count} setNotification={setNotification} />
                    );
                })}
            </ListGroup>
        </>
    );
}

export default List;
