module.exports = {
    publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    plans: [{
        id: process.env.REACT_APP_STRIPE_ANNUAL_PLAN_ID,
        nickname: 'Yearly',
        amount: 540000,
        interval: 'year',
        metadata: {
            message: 'Pay for the whole year and save 10% on your annual cost!'
        }
    }, {
        id: process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID,
        nickname: 'Monthly',
        amount: 50000,
        interval: 'month',
        metadata: {
            message: 'Pay as you go with no longterm commitment!'
        }
    }]
};
