import React from 'react';
import { Link, Switch, Route, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Badge from 'react-bootstrap/Badge';
import New from './New';
import Responded from './Responded';
import Sent from './Sent';
import Details from './Details';
import Form from './Form';

function App(props) {
    const { branch, unread } = props;

    const location = useLocation();

    const sentUnread = unread ? unread.reduce((accumulator, item) => accumulator + item.count, 0) : 0;

    return (
        <>
            <div className="p-4">
                <h1 className="text-truncate">Notifications</h1>

                <Nav variant="tabs small" activeKey={location.pathname}>
                    <Nav.Item className="d-none d-sm-inline">
                        <Nav.Link as={Link} to="/recipient/notifications/new" href="/recipient/notifications/new">
                            <i className="fas fa-envelope fa-fw" /> New
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="d-none d-sm-inline">
                        <Nav.Link as={Link} to="/recipient/notifications/responded" href="/recipient/notifications/responded">
                            <i className="fas fa-envelope-open fa-fw" /> Responded
                        </Nav.Link>
                    </Nav.Item>
                    {Boolean(branch.officeGroup) && (
                        <Nav.Item className="d-none d-sm-inline mr-auto">
                            <Nav.Link as={Link} to="/recipient/notifications/sent" href="/recipient/notifications/sent">
                                <i className="fas fa-paper-plane fa-fw" /> Sent
                                {sentUnread !== 0 && (
                                    <Badge variant="orange-200" className="ml-1">{sentUnread}</Badge>
                                )}
                            </Nav.Link>
                        </Nav.Item>
                    )}

                    <NavDropdown title={<>Viewing {sentUnread !== 0 && (<Badge variant="orange-200">{sentUnread}</Badge>)}</>} className="d-sm-none mr-auto">
                        <NavDropdown.Item as={Link} to="/recipient/notifications/new" href="/recipient/contact/new" className="small">
                            <i className="fas fa-envelope fa-fw" /> New
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/recipient/notifications/responded" href="/recipient/contact/responded" className="small">
                            <i className="fas fa-envelope-open fa-fw" /> Responded
                        </NavDropdown.Item>
                        {Boolean(branch.officeGroup) && (
                            <NavDropdown.Item as={Link} to="/recipient/notifications/sent" href="/recipient/contact/sent" className="small">
                                <i className="fas fa-paper-plane fa-fw" /> Sent
                                {sentUnread !== 0 && (
                                   <Badge variant="orange-200" className="ml-1">{sentUnread}</Badge>
                                )}
                            </NavDropdown.Item>
                        )}
                    </NavDropdown>

                    {Boolean(branch.officeGroup) && (
                        <Nav.Item>
                            <Nav.Link as={Link} to="/recipient/notifications" href="/recipient/notifications" className="action">
                                <i className="fas fa-plus fa-fw" /> Notification
                            </Nav.Link>
                        </Nav.Item>
                    )}
                </Nav>

                <Switch>
                    <Route path="/recipient/notifications/new" exact>
                        <New branchId={branch.id} />
                    </Route>
                    <Route path="/recipient/notifications/responded" exact>
                        <Responded branchId={branch.id} />
                    </Route>
                    <Route path="/recipient/notifications" exact>
                        <Form branch={branch} />
                    </Route>
                    <Route path="/recipient/notifications/sent" exact>
                        <Sent branchId={branch.id} canViewAllBranchNotifications={branch.canViewAllBranchNotifications} unread={unread} />
                    </Route>
                    <Route path="/recipient/notifications/responses/:id" exact render={props =>
                        <Details notificationId={props.match.params.id} />
                    } />
                </Switch>
            </div>
        </>
    );
}

export default App;
