import React, { useState } from 'react';
import Linkify from 'react-linkify';
import { Button, Card as BootstrapCard, ListGroup } from 'react-bootstrap';
import { default as ChatButton } from '../Chat/Button'
import { default as CallButton } from '../Call/Button'
import getFileIcon from '../FileIcons';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Card(props) {
    const { notification, footer } = props;

    const [ downloading, setDownloading ] = useState(false);

    const createdOnDate = new Date(notification.createdOn);
    const timeDifferenceInSeconds = (new Date().getTime() - createdOnDate.getTime()) / 1000;

    let fileExt = notification.fileId ? notification.fileId.split('.')[1] : undefined;

    const getFile = async (notification) => {
        setDownloading(true);

        try {
            let file = await API.get('AuthenticatedAPI', `/branches/${notification.branchId}/notifications/${notification.id}/files/${notification.fileId}`);
            setDownloading(false);
            window.open(file.uri, '_blank');
        } catch (error) {
            Sentry.captureException(error);
            alert(error);
        }
    };

    return (
        <BootstrapCard {...props}>
            <BootstrapCard.Header className="font-weight-medium d-flex justify-content-between">
                {notification.title}

                <span>
                    {Boolean(notification.critical) && (
                        <i className="fas fa-exclamation-triangle fa-fw text-orange-200" />
                    )}

                    {Boolean(notification.assigned) && (
                        <i className="fas fa-tag fa-fw text-blue-300"></i>
                    )}
                </span>
            </BootstrapCard.Header>

            <BootstrapCard.Body>
                <BootstrapCard.Text className="small" style={{ whiteSpace: 'pre-line' }}>
                    <Linkify componentDecorator={(href, text, key) => (
                        <a href={href} key={key} target="_blank" rel="noopener noreferrer">{text}</a>
                    )}>{notification.message}</Linkify>
                </BootstrapCard.Text>

                {notification.fileId && (
                    <Button variant="link" className="small p-0" onClick={() => getFile(notification)} disabled={downloading}><i className={`fas fa-fw ${getFileIcon(fileExt)}`} /> View Attachment</Button>
                )}
            </BootstrapCard.Body>

            <ListGroup variant="flush" className="border-bottom-0">
                <ListGroup.Item className="py-1 d-flex justify-content-between align-items-center">
                    <span className="py-1 smaller font-weight-medium text-gray-300">
                        Sent

                        {timeDifferenceInSeconds < 60 ? (
                            <> {Math.floor(timeDifferenceInSeconds)} seconds ago</>
                        ) : timeDifferenceInSeconds / 60 < 60 ? (
                            <> {Math.floor(timeDifferenceInSeconds / 60)} minutes ago</>
                        ) : timeDifferenceInSeconds / 60 / 60 < 24 ? (
                            <> {Math.floor(timeDifferenceInSeconds / 60 / 60)} hours ago</>
                        ) : (
                            <> {createdOnDate.toLocaleString()}</>
                        )}

                        {notification['sending-user'] && notification['sending-user'] && (
                            <>
                                <br/>
                                By <span className="text-orange-300">{notification['sending-user'].name}</span>
                            </>
                        )}
                    </span>

                    {notification['sending-user'] && (
                        <div>
                            <ChatButton recipient={notification['sending-user']} />
                            <CallButton recipient={notification['sending-user']} />
                        </div>
                    )}
                </ListGroup.Item>
            </ListGroup>

            {footer}
        </BootstrapCard>
    );
}

export default Card;
