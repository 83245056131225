import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { default as BootstrapForm } from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Form(props) {
    const [ name, setName ] = useState(props.partner.name);
    const [ formState, setFormState ] = useState({ message: '', error: '', loading: false });
    const history = useHistory();

    const formSubmit = evt => {
        evt.preventDefault();

        const putPartner = async () => {
            try {
                await API.put('AuthenticatedAPI', `/partners/${props.partner.id}`, { body: { name } });

                setFormState({ message: 'Successfully updated Partner.', error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ message: '', error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to edit this Partner. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/admin/partners');
                            break;
                        default:
                            setFormState({ message: '', error: `We encountered an unexpected issue while retrieving Partners. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ message: '', error: `We encountered an unexpected issue while retrieving Partners. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        setFormState({ message: '', error: '', loading: true })
        putPartner();
    };

    return (
        <BootstrapForm onSubmit={formSubmit}>
            <div className="bg-white rounded border border-gray-500 p-3 my-3">
                <Row>
                    <Col xs="12" sm="6" lg="4">
                        <fieldset>
                            <legend>Partner Details</legend>

                            <BootstrapForm.Group controlId="name">
                                <BootstrapForm.Label>Name</BootstrapForm.Label>
                                <BootstrapForm.Control value={name} required onChange={e => setName(e.target.value)} />
                            </BootstrapForm.Group>
                        </fieldset>
                    </Col>
                </Row>
            </div>

            {formState.message !== '' && (
                <Alert variant="green-500" className="small">{formState.message}</Alert>
            )}

            {formState.error !== '' && (
                <Alert variant="orange-200" className="small">{formState.error}</Alert>
            )}

            <Button type="submit" variant="green-500" className="text-white" disabled={formState.loading}>Save</Button>
        </BootstrapForm>
    );
}

export default Form;
