import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import GettingStarted from './GettingStarted';
import { default as RecentNotifications } from '../Notifications/Recent/App';
import { default as RecentChats } from '../Chat/Recent';
import Picker from '../Contacts/Picker';
import Monitor from '../Chat/Monitor';
import { Auth, API } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import { dashboardCols, col, fixedWidthCol, manageCol, dismiss } from './App.module.scss';

const CHAT_MONITOR_REFRESH_PERIOD = 15000;
const CHAT_MONITOR_LIMIT = 10;

function App(props) {
    const { branch, unread } = props;

    const [ hideGettingStarted, setHideGettingStarted ] = useState(false);
    const [ hideRecentNotifications, setHideRecentNotifications ] = useState(false);
    const [ hideRecentChats, setHideRecentChats ] = useState(false);
    const [ showAddColumns, setShowAddColumns ] = useState(false);
    const [ recipients, setRecipients ] = useState(branch.canViewAllChats ? [{ id: Auth.user.username }] : []);
    const [ messages, setMessages ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState();

    const monitorCookieKey = `monitors_${Auth.user.username}_${branch.id}`;
    const recipientIds = recipients.map(recipient => recipient.id).filter(x => x !== Auth.user.username).sort();
    const recipientIdsString = recipientIds.join(',');
    const history = useHistory();

    useEffect(() => {
        const getRecipientsByBranch = async (recipientIds) => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branch.id}/recipients`, { queryStringParameters: { ids: recipientIds.join(','), expand: 'branch-recipient' } });
                setRecipients(response);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Recipients.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving Recipients for this Branch. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving Recipients for this Branch. The service returned error message ${error.message}`);
                    Sentry.captureException(error);
                }
            }
        };

        let v = document.cookie.match(`(^|;) ?${monitorCookieKey}=([^;]*)(;|$)`);
        let recipientIds = (v && v[2] ? v[2].split(',') : []).concat(Auth.user.username);

        getRecipientsByBranch(recipientIds);
    }, [ history, branch.id, monitorCookieKey ]);

    useEffect(() => {
        const getRecentMessageSummaryByBranch = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branch.id}/recent-message-summary`, { queryStringParameters : { recipientIds: recipientIdsString, expand: 'target' } });

                setLoading(false);
                setMessages(response);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            setLoading(false);
                            setError(error.response.data.message);
                            break;
                        default:
                            setLoading(false);
                            setError(`We encountered an unexpected issue while retrieving recently sent messages. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setLoading(false);
                    setError(`We encountered an unexpected issue while retrieving recently sent messages. The service returned error message ${error.message}`);
                    Sentry.captureException(error);
                }
            }
        };

        if (recipientIdsString.length > 0) {
            setLoading(true);
            setError(null);
            getRecentMessageSummaryByBranch();

            let d = new Date();
            d.setTime(d.getTime() + 24*60*60*1000*30); // 30 days
            document.cookie = `${monitorCookieKey}=${recipientIdsString};path=/;expires=${d.toGMTString()}`;
        } else {
            document.cookie = `${monitorCookieKey}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        }

        let interval = setInterval(() => {
            if (recipientIdsString.length > 0) {
                setLoading(true);
                setError(null);
                getRecentMessageSummaryByBranch();
            }
        }, CHAT_MONITOR_REFRESH_PERIOD);

        return () => clearInterval(interval);
    }, [ history, branch.id, monitorCookieKey, recipientIdsString ]);

    const selectRecipient = (recipient, checked) => {
        if (recipient.id === Auth.user.username) {
            setHideRecentChats(!checked);
        }

        if (checked) {
            if (recipients.length >= CHAT_MONITOR_LIMIT) {
                alert(`You can only add ${CHAT_MONITOR_LIMIT} Chat Monitors.`);
                return;
            }

            setRecipients(recipients.concat(recipient));
        } else {
            setRecipients(recipients.filter(x => x.id !== recipient.id));
        }
    };

    return (
        <>
            <Helmet>
                <title>Employee | App</title>
            </Helmet>

            <div className={`${dashboardCols} d-flex flex-row no-gutters overflow-auto h-100 mh-100v`}>
                {!hideGettingStarted && (
                    <Col xs="auto" className={fixedWidthCol}>
                        <button className={`position-absolute bg-transparent border-0 text-white ${dismiss}`} onClick={() => setHideGettingStarted(true)}><i className="fas fa-times fa-fw" /></button>
                        <GettingStarted branch={branch} />
                    </Col>
                )}
                {!hideRecentNotifications && (
                    <Col className={col}>
                        <button className={`position-absolute bg-transparent border-0 text-white ${dismiss}`} onClick={() => setHideRecentNotifications(true)}><i className="fas fa-times fa-fw" /></button>
                        <RecentNotifications branchId={branch.id} unread={unread && unread.notifications} />
                    </Col>
                )}
                {!hideRecentChats && (
                    <Col className={col}>
                        <button className={`position-absolute bg-transparent border-0 text-white ${dismiss}`} onClick={() => setHideRecentChats(true)}><i className="fas fa-times fa-fw" /></button>
                        <RecentChats branchId={branch.id} unread={unread && unread.recipients} />
                    </Col>
                )}
                {Boolean(branch.canViewAllChats) && (
                    <>
                        {recipients.filter(recipient => recipient.id !== Auth.user.username).map(recipient =>
                            <Col key={recipient.id} className={col}>
                                <Monitor branchId={branch.id} recipient={recipient} messages={messages === null ? null : messages.filter(message => recipient.id === message.recipientId)} loading={loading} error={error} onClose={() => selectRecipient(recipient, false)} />
                            </Col>
                        )}

                        <Col xs="auto" className={`${manageCol} overflow-hidden`}>
                            <Button variant="blue-300" className="text-gray-700 font-weight-semibold" onClick={() => setShowAddColumns(true)}><i className="fas fa-plus-circle fa-fw" /> Add Column</Button>
                            <Modal show={showAddColumns} onHide={() => setShowAddColumns(false)}>
                                <Modal.Header className="bg-blue-100 text-gray-700">
                                    <Modal.Title className="p font-weight-medium">Stay Informed on Daily Activity</Modal.Title>

                                    <button className="close text-gray-700" onClick={() => setShowAddColumns(false)}><i className="fas fa-times fa-sm" /></button>
                                </Modal.Header>

                                <Modal.Body>
                                    <p className="small">You can have up to <strong className="text-orange-200">{CHAT_MONITOR_LIMIT}</strong> Chat Monitors. If you would like additional Chat Monitors, please contact Support at <a className="font-weight-medium" href="https://app.notifyd.com/support" target="_blank" rel="noopener noreferrer">Contact Support</a>.</p>

                                    <hr />

                                    <Picker branch={branch} showRecipients={true} selectedRecipients={recipients} onSelectRecipient={selectRecipient} pageSize={25} allowSelectPage={false}  />

                                    <Button variant="green-500" size="sm" className="text-gray-700" onClick={() => setShowAddColumns(false)}>Done</Button>
                                </Modal.Body>
                            </Modal>
                        </Col>
                    </>
                )}
            </div>
        </>
    );
}

export default App;
