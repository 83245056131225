import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { API } from 'aws-amplify';
import RecipientSummary from './../../Branches/RecipientSummary';
import NotificationSummary from './../../Branches/NotificationSummary';
import ChatSummary from './../../Branches/ChatSummary';
import * as Sentry from "@sentry/react";

function Reports(props) {
    const [ recipientSummary, setRecipientSummary ] = useState({ summary: null, loading: true, error: '' });
    const [ downloading, setDownloading ] = useState(false);
    const [ notificationSummary, setNotificationSummary ] = useState({ summary: null, loading: true, error: '' });
    const [ chatSummary, setChatSummary ] = useState({ summary: null, loading: true, error: '' });
    const history = useHistory();
    const branchId = props.branch.id;

    useEffect(() => {
        const getRecipientSummary = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branchId}/recipient-summary`);
                setRecipientSummary({ summary: response, loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setRecipientSummary({ summary: null, loading: false, error: error.response.data.message });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access reports for this Branch. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/admin/branches');
                            break;
                        default:
                            setRecipientSummary({ summary: null, loading: false, error: `We encountered an unexpected issue while retrieving the Employee Report. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setRecipientSummary({ summary: null, loading: false, error: `We encountered an unexpected issue while retrieving the Employee Report. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        const getNotificationSummary = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branchId}/notification-summary`);
                setNotificationSummary({ summary: response, loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setNotificationSummary({ summary: null, loading: false, error: error.response.data.message });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access reports for this Branch. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/admin/branches');
                            break;
                        default:
                            setNotificationSummary({ summary: null, loading: false, error: `We encountered an unexpected issue while retrieving the Notification Report. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setNotificationSummary({ summary: null, loading: false, error: `We encountered an unexpected issue while retrieving the Notification Report. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        const getChatSummary = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branchId}/chat-summary`);
                setChatSummary({ summary: response, loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setChatSummary({ summary: null, loading: false, error: error.response.data.message });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access reports for this Branch. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/admin/branches');
                            break;
                        default:
                            setChatSummary({ summary: null, loading: false, error: `We encountered an unexpected issue while retrieving the Chat Report. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setChatSummary({ summary: null, loading: false, error: `We encountered an unexpected issue while retrieving the Chat Report. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        getRecipientSummary();
        getNotificationSummary();
        getChatSummary();
    }, [ branchId, history ]);

    const downloadNotLoggedIn = evt => {
        const getNotLoggedIn = async () => {
            try {
                let data = await API.get('AuthenticatedAPI', `/branches/${props.branch.id}/recipients`, { headers: { 'Accept': 'text/csv' }, queryStringParameters: { hasLoggedIn: false, pageSize: (recipientSummary.summary.numberOfEmployees - recipientSummary.summary.numberOfEmployeesLoggedIn) }});

                let element = document.createElement('a');
                element.setAttribute('href', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(data));
                element.setAttribute('download', 'download.csv');
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);

                setDownloading(false);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setDownloading(false);
                            alert(error.response.data.message);
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access reports for this Branch. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setDownloading(false);
                            alert(`We encountered an unexpected issue while retrieving employees who have not logged in. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setDownloading(false);
                    alert(`We encountered an unexpected issue while retrieving employees who have not logged in. The service returned error message ${error.message}`);
                    Sentry.captureException(error);
                }
            }
        };

        setDownloading(true);
        getNotLoggedIn();
    };

    return (
        <>
            <hr />

            <Row>
                <Col xs="12" sm="6" xl="4" className="mb-3">
                    <Card className="h-100">
                        <Card.Body>
                            <Card.Title>Employee Onboarding</Card.Title>

                            {recipientSummary.loading ? (
                                <div className="text-center py-5">
                                    <i className="fas fa-spinner fa-fw fa-spin fa-3x" />
                                </div>
                            ) : recipientSummary.error ? (
                                <Alert variant="orange-200" className="small">{recipientSummary.error}</Alert>
                            ) : (
                                <RecipientSummary {...recipientSummary.summary} />
                            )}
                        </Card.Body>

                        <Card.Footer>
                            <Button variant="blue-300" size="sm" block className="text-gray-700" onClick={downloadNotLoggedIn} disabled={recipientSummary.loading || downloading}>
                                <i className="fas fa-file-csv fa-fw"></i> Not Logged In
                            </Button>
                        </Card.Footer>
                    </Card>
                </Col>

                <Col xs="12" sm="6" xl="4" className="mb-3">
                    <Card className="h-100">
                        <Card.Body>
                            <Card.Title>Notifications Usage</Card.Title>

                            {notificationSummary.loading ? (
                                <div className="text-center py-5">
                                    <i className="fas fa-spinner fa-fw fa-spin fa-3x" />
                                </div>
                            ) : notificationSummary.error ? (
                                <Alert variant="orange-200" className="small">{notificationSummary.error}</Alert>
                            ) : (
                                <NotificationSummary {...notificationSummary.summary} />
                            )}
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs="12" sm="6" xl="4" className="mb-3">
                    <Card className="h-100">
                        <Card.Body>
                            <Card.Title>Chat Usage</Card.Title>
                            <Card.Subtitle className="text-muted font-weight-regular">Are you using Chat to cutdown on miscommunications and missed calls?</Card.Subtitle>

                            {chatSummary.loading ? (
                                <div className="text-center py-5">
                                    <i className="fas fa-spinner fa-fw fa-spin fa-3x" />
                                </div>
                            ) : chatSummary.error ? (
                                <Alert variant="orange-200" className="small">{chatSummary.error}</Alert>
                            ) : (
                                <ChatSummary {...chatSummary.summary} />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default Reports;
