import React, { useState, useEffect } from 'react';
import { Switch, Route, Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from './Form';
import Recipients from './Recipients';
import Reports from './Reports';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Details(props) {
    const [ branch, setBranch ] = useState(null);
    const [ error, setError ] = useState('');

    const history = useHistory();
    const id = props.match.params.id;

    useEffect(() => {
        const getBranch = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${id}`, { queryStringParameters : { expand: 'subscription' }});

                setBranch(response);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setError(error.response.data.message);
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Branch. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/admin/branches');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving this Branch. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving this Branch. The service returned error message ${error.message}`);
                    Sentry.captureException(error);
                }
            }
        };

        setError('');
        getBranch();
    }, [ id, history ]);

    if (branch === null) {
        return (
            <div className="p-4">
                {error === '' ? (
                    <h1><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>
                ) : (
                    <Alert variant="orange-200" className="small">{error}</Alert>
                )}
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Admin | Manage Branch</title>
            </Helmet>

            <div className="p-4">
                <h1>{branch.name}</h1>
                <span>{branch.id}</span>

                {error !== '' && (
                    <Alert variant="orange-200" className="small">{error}</Alert>
                )}

                <hr />

                <Row>
                    <Col xs="12" sm="6" lg="auto">
                        <Button as={Link} variant="blue-300" size="sm" block to={props.match.url} href={props.match.url} className="text-white mb-2 mb-lg-0">
                            <i className="fas fa-pencil fa-fw"></i> Edit Branch
                        </Button>
                    </Col>
                    <Col xs="12" sm="6" lg="auto">
                        <Button as={Link} variant="blue-300" size="sm" block to={`/admin/companies/${branch.companyId}`} href={`/admin/companies/${branch.companyId}`} className="text-white mb-2 mb-lg-0">
                            <i className="fas fa-building fa-fw"></i> View Company
                        </Button>
                    </Col>
                    <Col xs="12" sm="6" lg="auto">
                        <Button as={Link} variant="blue-300" size="sm" block to={`${props.match.url}/recipients`} href={`${props.match.url}/recipients`} className="text-white mb-2 mb-lg-0">
                            <i className="fas fa-users fa-fw"></i> View Recipients
                        </Button>
                    </Col>
                    <Col xs="12" sm="6" lg="auto">
                        <Button as={Link} variant="blue-300" size="sm" block to={`/admin/partners/${branch.partnerId}`} href={`/admin/partners/${branch.partnerId}`} className="text-white mb-2 mb-lg-0">
                            <i className="fas fa-handshake fa-fw"></i> View Partner
                        </Button>
                    </Col>
                    <Col xs="12" sm="6" lg="auto">
                        <Button as={Link} variant="blue-300" size="sm" block to={`${props.match.url}/reports`} href={`${props.match.url}/reports`} className="text-white mb-2 mb-lg-0">
                            <i className="fas fa-chart-line fa-fw"></i> View Reports
                        </Button>
                    </Col>
                    <Col xs="12" sm="6" lg="auto">
                        <a className="btn btn-blue-300 btn-sm btn-block text-white mb-2 mb-lg-0" href={`https://dashboard.stripe.com/subscriptions/${branch.stripeSubscriptionId}`} target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-cc-stripe fa-fw"></i> View Billing
                        </a>
                    </Col>
                </Row>

                <Switch>
                    <Route path={`${props.match.url}`} exact>
                        <Form branch={branch} />
                    </Route>
                    <Route path={`${props.match.url}/recipients`} exact>
                        <Recipients branch={branch} />
                    </Route>
                    <Route path={`${props.match.url}/reports`} exact>
                        <Reports branch={branch} />
                    </Route>
                </Switch>
            </div>
        </>
    );
}

export default Details;
