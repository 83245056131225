import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { default as BootstrapForm } from 'react-bootstrap/Form'
import { API } from 'aws-amplify'
import * as Sentry from "@sentry/react"

const CustomPhoneInput = React.forwardRef((props, ref) => <BootstrapForm.Control ref={ref} {...props} />)

function Form(props) {
    const contactPhoneParts = props.recipient.contactPhone?.split(',') ?? []

    const [ name, setName ] = useState(props.recipient.name || '')
    const [ email, setEmail ] = useState(props.recipient.email || '')
    const [ phone, setPhone ] = useState(props.recipient.phone || '')
    const [ contactPhone, setContactPhone ] = useState(contactPhoneParts[0] || '')
    const [ contactPhoneExt, setContactPhoneExt ] = useState(contactPhoneParts[1] || '')
    const [ hidePhoneNumber, setHidePhoneNumber ] = useState(props.recipient.hidePhoneNumber)
    const [ jobTitle, setJobTitle ] = useState(props.recipient.jobTitle || '')
    const [ zipCode, setZipCode ] = useState(props.recipient.zipCode || '')
    const [ currentPassword, setCurrentPassword ] = useState('')
    const [ newPassword, setNewPassword ] = useState('')
    const [ formState, setFormState ] = useState({ message: '', error: '', saving: false })

    const history = useHistory()

    const formSubmit = evt => {
        evt.preventDefault();

        const putRecipient = async () => {
            try {
                const fullContactPhone = contactPhone + (contactPhoneExt.length > 0 ? ',' : '') + contactPhoneExt

                await API.put('AuthenticatedAccessAPI', `/recipients/${props.recipient.id}`, { body: { name, email, phone, contactPhone : fullContactPhone, jobTitle, zipCode, hidePhoneNumber, currentPassword, password: newPassword }});

                setFormState({ message: 'Successfully updated Recipient.', error: '', saving: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ message: '', error: error.response.data.message, saving: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to edit this Recipient.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/recipient');
                            break;
                        default:
                            setFormState({ message: '', error: `We encountered an unexpected issue while updating this Branch. The service returned error code ${error.response.status}`, saving: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ message: '', error: `We encountered an unexpected issue while updating this Branch. The service returned error message ${error.message}`, saving: false });
                    Sentry.captureException(error);
                }
            }
        };

        setFormState({ ...formState, message: '', error: '', saving: true })
        putRecipient();
    };


    return (
        <BootstrapForm onSubmit={formSubmit}>
            <div className="bg-white rounded border border-gray-500 p-3 my-3">
                <Row>
                    <Col xs="12" sm="6" xl="4">
                        <fieldset>
                            <legend>Contact Details</legend>

                            <BootstrapForm.Group controlId="name">
                                <BootstrapForm.Label>Name</BootstrapForm.Label>
                                <BootstrapForm.Control value={name} onChange={e => setName(e.target.value)} required />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group controlId="email">
                                <BootstrapForm.Label>Email</BootstrapForm.Label>
                                <BootstrapForm.Control type="email" value={email} onChange={e => setEmail(e.target.value)} required />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group controlId="phone">
                                <BootstrapForm.Label>Mobile Phone #</BootstrapForm.Label>
                                <PhoneInput defaultCountry="US" limitMaxLength={true} placeholder="Enter phone number" value={phone || ''} onChange={setPhone} inputComponent={CustomPhoneInput} />
                                <BootstrapForm.Text className="text-muted">Used for password resets and text message notices, and is not shared with others.</BootstrapForm.Text>
                            </BootstrapForm.Group>

                            <BootstrapForm.Group>
                                <Row>
                                    <Col xs={12} lg={8}>
                                        <BootstrapForm.Label htmlFor="contactPhone">Contact Phone #</BootstrapForm.Label>
                                        <PhoneInput id="contactPhone" defaultCountry="US" limitMaxLength={true} placeholder="Enter phone number" value={contactPhone} onChange={val => setContactPhone(val === undefined ? '' : val)} inputComponent={CustomPhoneInput} />
                                    </Col>
                                    <Col xs={5} lg={4}>
                                        <BootstrapForm.Label htmlFor="contactPhoneExt">Ext.</BootstrapForm.Label>
                                        <BootstrapForm.Control id="contactPhoneExt" pattern="[0-9]+" maxLength={5} value={contactPhoneExt} onChange={e => setContactPhoneExt(e.target.value)} />
                                    </Col>
                                </Row>
                                <BootstrapForm.Text className="text-muted">Displayed to authorized users so they can call directly.</BootstrapForm.Text>
                            </BootstrapForm.Group>

                            <BootstrapForm.Group controlId="hidePhoneNumber">
                                <BootstrapForm.Label>Hide phone number when calling</BootstrapForm.Label>
                                <BootstrapForm.Check type="switch" label="Yes" checked={hidePhoneNumber} onChange={() => setHidePhoneNumber(!hidePhoneNumber)} />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group controlId="allowedPushNotifications">
                                <BootstrapForm.Label>Ready to receive messages and notifications in the app</BootstrapForm.Label>
                                <BootstrapForm.Check type="switch" label="Yes" checked={props.recipient.allowedPushNotifications} disabled />
                                {!props.recipient.allowedPushNotifications && (
                                    <BootstrapForm.Text className="text-muted">In order to receive mobile alerts, download the Notifyd mobile app and grant Notifyd permission to send you Push Notifications.</BootstrapForm.Text>
                                )}
                            </BootstrapForm.Group>
                        </fieldset>
                    </Col>

                   <Col xs="12" sm="6" xl="8">
                        <Row>
                            <Col xs="12" xl="6">
                                <fieldset>
                                    <legend>Employment Details</legend>

                                    <BootstrapForm.Group controlId="jobTitle">
                                        <BootstrapForm.Label>Job Title</BootstrapForm.Label>
                                        <BootstrapForm.Control value={jobTitle || ''} onChange={e => setJobTitle(e.target.value)} />
                                    </BootstrapForm.Group>

                                    <BootstrapForm.Group controlId="zipCode">
                                        <BootstrapForm.Label>ZIP Code</BootstrapForm.Label>
                                        <BootstrapForm.Control pattern="^[0-9]{5}$" value={zipCode} onChange={e => setZipCode(e.target.value)} />
                                        <BootstrapForm.Text className="text-muted">Used to send shift offers and emergency preparedness alerts in the area.</BootstrapForm.Text>
                                        <BootstrapForm.Text className="text-muted font-weight-semibold">Format: 75013</BootstrapForm.Text>
                                    </BootstrapForm.Group>
                                </fieldset>
                            </Col>

                            <Col xs="12" xl="6">
                                <fieldset>
                                    <legend>Change Password</legend>

                                    <BootstrapForm.Group controlId="currentPassword">
                                        <BootstrapForm.Label>Current Password</BootstrapForm.Label>
                                        <BootstrapForm.Control type="password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />
                                    </BootstrapForm.Group>

                                    <BootstrapForm.Group controlId="newPassword">
                                        <BootstrapForm.Label>New Password</BootstrapForm.Label>
                                        <BootstrapForm.Control type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} minLength="8" pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" />
                                        <BootstrapForm.Text className="text-muted">Must be at least 8 characters long and contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.</BootstrapForm.Text>
                                    </BootstrapForm.Group>
                                </fieldset>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            {formState.message !== '' && (
                <Alert variant="green-500" className="small">{formState.message}</Alert>
            )}

            {formState.error !== '' && (
                <Alert variant="orange-200" className="small">{formState.error}</Alert>
            )}

            <Button type="submit" variant="green-500" block className="text-white" disabled={formState.saving}>Save</Button>
        </BootstrapForm>
    );
}

export default Form;
