import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { default as BootstrapForm } from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Picker from '../Picker';
import { Auth, API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Form(props) {
    const { branch, onNewTeam } = props;

    const [ formState, setFormState ] = useState({ message: '', error: '', loading: false });
    const [ name, setName ] = useState(props.team.name || '');
    const [ chatEnabled, setChatEnabled ] = useState(props.team.chatEnabled || false);
    const [ recipients, setRecipients ] = useState(props.team.recipients || []);

    const history = useHistory();
    const id = props.team.id || null;

    if (id === Auth.user.username) {
        history.push('/recipient/contacts/teams');
    }

    useEffect(() => {
        setName(props.team.name || '');
        setChatEnabled(props.team.chatEnabled || '');
        setRecipients(props.team.recipients || []);
    }, [
        id,
        props.team.name,
        props.team.chatEnabled,
        props.team.recipients
    ]);

    const selectRecipient = (recipient, checked) => {
        if (checked) {
            if (Array.isArray(recipient)) {
                let dedupedRecipients = recipient.filter(x => !recipients.some(y => x.id === y.id));
                setRecipients(recipients.concat(dedupedRecipients));
            } else if (!recipients.some(x => x.id === recipient.id)) {
                setRecipients(recipients.concat(recipient));
            }
        } else {
            if (Array.isArray(recipient)) {
                setRecipients(recipients.filter(x => !recipient.some(y => x.id === y.id)));
            } else {
                setRecipients(recipients.filter(x => x.id !== recipient.id));
            }
        }
    };

    const formSubmit = evt => {
        evt.preventDefault();

        const postTeamByBranch = async () => {
            try {
                const team = await API.post('AuthenticatedAPI', `/branches/${branch.id}/teams`, { body: { name, chatEnabled, recipientIds: recipients.map(x => x.id) } });
                setFormState({ message: 'Successfully created Team.', error: '', loading: false });
                onNewTeam(team);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ message: '', error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                        case 404:
                            history.push('/recipient/contacts/teams');
                            break;
                        default:
                            setFormState({ message: '', error: `We encountered an unexpected issue while creating a Team. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ message: '', error: `We encountered an unexpected issue while creating a Team. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }

        };

        const putTeam = async () => {
            try {
                await API.put('AuthenticatedAPI', `/teams/${props.team.id}`, { body: { name, chatEnabled, recipientIds: recipients.map(x => x.id) } })
                setFormState({ message: 'Successfully updated Team.', error: '', loading: false })
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ message: '', error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                        case 404:
                            history.push('/recipient/contacts/teams');
                            break;
                        default:
                            setFormState({ message: '', error: `We encountered an unexpected issue while updating this Team. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ message: '', error: `We encountered an unexpected issue while updating this Team. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        setFormState({ message: '', error: '', loading: true })

        if (id === null) {
            postTeamByBranch()
        } else {
            putTeam()
        }
    };

    return (
        <>
            <Row>
                <Col xs="12" sm="10" lg="8" xl="6">
                    <fieldset>
                        <legend>How should this team appear?</legend>

                        <BootstrapForm.Group controlId="name">
                            <BootstrapForm.Label>Department/Team Name</BootstrapForm.Label>
                            <BootstrapForm.Control value={name} required onChange={e => setName(e.target.value)} />
                        </BootstrapForm.Group>

                        <BootstrapForm.Check type="switch" id="chatEnabled">
                            <BootstrapForm.Check.Input checked={chatEnabled} onChange={e => setChatEnabled(e.target.checked)} />
                            <BootstrapForm.Check.Label>
                                Chat Enabled
                                <BootstrapForm.Text className="text-muted smaller">Enabling chat allows all team members to openly communicate with each other.</BootstrapForm.Text>
                            </BootstrapForm.Check.Label>
                        </BootstrapForm.Check>
                    </fieldset>
                </Col>
            </Row>

            <fieldset className="mt-3">
                <legend>Who works with this department/team?</legend>

                {recipients.length > 0 && (
                    <>
                        <h6>Currently Selected</h6>

                        <BootstrapForm.Group className="overflow-auto border-top border-bottom border-gray-600 pt-1" style={{ maxHeight: 200 }}>
                            {recipients.sort((recipient1, recipient2) => recipient1.name.toLowerCase() < recipient2.name.toLowerCase() ? -1 : 1).map(recipient =>
                                <Button key={recipient.id} size="sm" variant="gray-600" className="smaller mr-1 mb-1" onClick={() => selectRecipient(recipient, false)}>
                                    {recipient.name} <i className="fas fa-times fa-lg text-orange-200" />
                                </Button>
                            )}
                        </BootstrapForm.Group>
                    </>
                )}

                <Picker branch={branch} showRecipients={true} selectedRecipients={recipients} onSelectRecipient={selectRecipient} showRecipientUpload={true} allowSelectPage={true} />
            </fieldset>

            {formState.message !== '' && (
                <Alert variant="green-500" className="small">{formState.message}</Alert>
            )}

            {formState.error !== '' && (
                <Alert variant="orange-200" className="small">{formState.error}</Alert>
            )}

            <Button variant="green-500" block className="text-white" onClick={formSubmit} disabled={formState.loading}>Save</Button>
        </>
    );
}

export default Form;
