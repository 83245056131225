import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import { Auth, API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function App(props) {
    const { branch } = props;
    const [ recipient, setRecipient ] = useState(null);
    const [ recipientLoading, setRecipientLoading ] = useState(true);
    const [ recipientError, setRecipientError ] = useState();
    const [ partner, setPartner ] = useState(null);
    const [ partnerLoading, setPartnerLoading ] = useState(true);
    const [ partnerError, setPartnerError ] = useState();

    const [ showInstallMobile, setShowInstallMobile ] = useState(false);
    const [ desktopNotificationsEnabled, setDesktopNotificationsEnabled ] = useState(null);
    const [ showIntegratePartner, setShowIntegratePartner ] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if ("Notification" in window) {
            switch (Notification.permission) {
                case 'granted':
                    setDesktopNotificationsEnabled(true);
                    break;
                case 'denied':
                    setDesktopNotificationsEnabled(false);
                    break;
                default:
                    setDesktopNotificationsEnabled(null);
                    break;
            }
        }
    }, []);

    useEffect(() => {
        const getRecipient = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/recipients/${Auth.user.username}`);

                setRecipient(response);
                setRecipientLoading(false);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setRecipientError(error.response.data.message);
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Recipient.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/recipient');
                            break;
                        default:
                            setRecipientError(`We encountered an unexpected issue while retrieving this Recipient. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setRecipientError(`We encountered an unexpected issue while retrieving this Recipient. The service returned error message ${error.message}`);
                    Sentry.captureException(error);
                }
            }
        };

        const getPartner = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/partners/${branch.partnerId}`);

                setPartner(response);
                setPartnerLoading(false);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setPartnerError(error.response.data.message);
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Recipient.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/recipient');
                            break;
                        default:
                            setPartnerError(`We encountered an unexpected issue while retrieving this Recipient. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setPartnerError(`We encountered an unexpected issue while retrieving this Recipient. The service returned error message ${error.message}`);
                    Sentry.captureException(error);
                }
            }
        };

        getRecipient();

        if (branch.canManageBranch) {
            getPartner();
        }
    }, [ history, branch.partnerId, branch.canManageBranch ]);

    const setDesktopAlerts = e => {
        const handlePermission = permission => {
            switch (permission) {
                case 'granted':
                    setDesktopNotificationsEnabled(true);
                    break;
                case 'denied':
                    setDesktopNotificationsEnabled(false);
                    break;
                default:
                    setDesktopNotificationsEnabled(null);
                    break;
            }
        };

        try {
            Notification.requestPermission.then(permission => handlePermission(permission));
        } catch (error) {
            Notification.requestPermission(permission => handlePermission(permission));
        }
    };

    return (
        <>
            <div className="bg-green-500 text-white text-center small font-weight-medium py-2 border-bottom border-green-500">
                <i className="fas fa-chalkboard-teacher fa-fw" /> Getting Started
            </div>

            <ListGroup variant="flush">
                {recipientLoading ? (
                    <ListGroup.Item key="loading" className="text-blue-400 text-center">
                        <i className="fas fa-spinner fa-fw fa-spin fa-2x" />
                    </ListGroup.Item>
                ) : recipientError ? (
                    <ListGroup.Item key="error" className="text-orange-200">{recipientError}</ListGroup.Item>
                ) : (
                    <>
                        <ListGroup.Item className="d-flex flex-row align-items-center">
                            <span className="fa-layers fa-fw mr-1" style={{ width: '15%' }}>
                                <i className={`${recipient.allowedPushNotifications ? 'fas text-green-500' : 'far text-gray-600'} fa-circle`} data-fa-transform="grow-16" />
                                <i className={`fas fa-fw ${recipient.allowedPushNotifications ? 'fa-check text-gray-700' : 'fa-mobile-alt text-gray-400'}`} />
                            </span>

                            <div style={{ width: '85%' }}>
                                <button className="small font-weight-semibold text-green-500 p-0 bg-transparent border-0" onClick={() => setShowInstallMobile(true)}>Install Mobile App</button>
                                <p className="smaller mb-0">Keep in touch with your coworkers while on the go!</p>
                            </div>
                        </ListGroup.Item>
                        <Modal show={showInstallMobile} onHide={() => setShowInstallMobile(false)}>
                            <Modal.Header className="bg-blue-100 text-gray-700">
                                <Modal.Title className="p font-weight-medium">Communicate from your phone securely</Modal.Title>

                                <button className="close text-gray-700" onClick={() => setShowInstallMobile(false)}><i className="fas fa-times fa-sm" /></button>
                            </Modal.Header>

                            <Modal.Body>
                                <Modal.Title>Why should I download the app?</Modal.Title>
                                <ul className="small">
                                    {branch.officeGroup ? (
                                        <>
                                            <li>Real-time HIPAA compliant chat across your team</li>
                                            <li>Retrieve message details and history quickly</li>
                                            <li>Manage sent messages and responses</li>
                                            <li>Fast document sharing/storage across files, videos, and pictures</li>
                                            <li>Get notified directly on your device when you have a new message</li>
                                        </>
                                    ) : (
                                        <>
                                            <li>Receive and respond to a shift offer in real time</li>
                                            <li>Keep track of conversations regarding a patient, shift, or other general information</li>
                                            <li>Have direct access to back office personnel without having to make a phone call</li>
                                            <li>Less interruptions to your day</li>
                                            <li>No more phone tag</li>
                                            <li>Faster access to information</li>
                                        </>
                                    )}
                                </ul>

                                <Modal.Title>How do I download the app?</Modal.Title>
                                <span className="small font-weight-semibold">If you are an iPhone user</span>

                                <ul className="small">
                                    <li>Click the &ldquo;App Store&rdquo; button below</li>
                                    <li>Or search for <span className="text-blue-400 font-weight-bold">Notifyd</span> in the App Store on your phone.</li>
                                </ul>

                                <span className="small font-weight-semibold">If you are an Android user</span>
                                <ul className="small">
                                    <li>Click the &ldquo;Google Play&rdquo; button below.</li>
                                    <li>Or search for <span className="text-blue-400 font-weight-bold">Notifyd</span> in the Google Play store on your phone.</li>
                                </ul>
                            </Modal.Body>

                            <Modal.Footer>
                                <a href="https://apps.apple.com/us/app/notifyd-device/id1281731159?mt=8" target="_blank" rel="noopener noreferrer" className="d-inline-block overflow-hidden" style={{ background: 'url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2018-01-09&kind=iossoftware&bubble=ios_apps) no-repeat', width: 135, height: 40 }}>&nbsp;</a>

                                <a href="https://play.google.com/store/apps/details?id=com.notifyd.device&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank" rel="noopener noreferrer">
                                    <img width="150" alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
                                </a>
                            </Modal.Footer>
                        </Modal>
                        {Boolean(branch.canManageEmployees) && (
                            <ListGroup.Item className="d-flex flex-row align-items-center">
                                <span className="fa-layers fa-fw mr-1" style={{ width: '15%' }}>
                                    <i className="far text-gray-600 fa-circle" data-fa-transform="grow-16" />
                                    <i className="fas fa-fw fa-user-tie text-gray-400" />
                                </span>

                                <div style={{ width: '85%' }}>
                                    <Link href="/recipient/contacts/recipients/new" to="/recipient/contacts/recipients/new" className="small font-weight-semibold text-green-500">Add an Employee</Link>
                                    <p className="smaller mb-0">Give another coworker access to Notifyd and start communicating securely!</p>
                                </div>
                            </ListGroup.Item>
                        )}
                        <ListGroup.Item className="d-flex flex-row align-items-center">
                            <span className="fa-layers fa-fw mr-1" style={{ width: '15%' }}>
                                <i className="far text-gray-600 fa-circle" data-fa-transform="grow-16" />
                                <i className="fas fa-fw fa-comment text-gray-400" />
                            </span>

                            <div style={{ width: '85%' }}>
                                <Link href="/recipient/contacts/recipients" to="/recipient/contacts/recipients" className="small font-weight-semibold text-green-500">Send a Chat</Link>
                                <p className="smaller mb-0">
                                    Send your <i className="fas fa-heart fa-fw text-orange-200" /> favorite emoji to your coworkers through a chat message!
                                    <br /><br />
                                    Just click the <i className="fas fa-comment fa-fw text-blue-400" /> icon!
                                </p>
                            </div>
                        </ListGroup.Item>
                        {"Notification" in window && (
                            <ListGroup.Item className="d-flex flex-row align-items-center">
                                <span key={`desktopNotificationsEnabled${desktopNotificationsEnabled === null ? '' : desktopNotificationsEnabled ? 'On' : 'Off'}`} className="fa-layers fa-fw mr-1" style={{ width: '15%' }}>
                                    <i className={`${desktopNotificationsEnabled === null ? 'far text-gray-600' : `fas ${desktopNotificationsEnabled ? 'text-green-500' : 'text-orange-200'}`} fa-circle`} data-fa-transform="grow-16" />
                                    <i className={`fas ${desktopNotificationsEnabled === null ? 'fa-bell text-gray-400' : desktopNotificationsEnabled ? 'fa-check text-gray-700' : 'fa-bell-slash text-gray-700'} fa-fw`} />
                                </span>

                                <div style={{ width: '85%' }}>
                                    <button onClick={setDesktopAlerts} className="p-0 bg-transparent border-0 small font-weight-semibold text-green-500">Setup Desktop Alerts</button>
                                    <p className="smaller mb-0">
                                        {desktopNotificationsEnabled !== false ? (
                                            <>Receive automatic alerts to your desktop when something requires your attention!</>
                                        ) : (
                                            <>
                                                You currently have chat alerts blocked. <a href="https://www.tidio.com/knowledge/applications/how-to-allow-notifications-in-your-browser/" target="_blank" rel="noopener noreferrer">Learn how to unblock notifications</a>.
                                            </>
                                        )}
                                    </p>
                                </div>
                            </ListGroup.Item>
                        )}
                        {Boolean(branch.officeGroup) && (
                            <>
                                <ListGroup.Item className="d-flex flex-row align-items-center">
                                    <span className="fa-layers fa-fw mr-1" style={{ width: '15%' }}>
                                        <i className="far text-gray-600 fa-circle" data-fa-transform="grow-16" />
                                        <i className="fas fa-fw fa-envelope text-gray-400" />
                                    </span>

                                    <div style={{ width: '85%' }}>
                                        <Link href="/recipient/notifications" to="/recipient/notifications" className="small font-weight-semibold text-green-500">Send a Notification</Link>
                                        <p className="smaller mb-0">
                                            Ask your coworkers some HIPAA trivia through a notification:
                                            <br /><br />
                                            &ldquo;Text messages are HIPAA compliant&rdquo;<br />
                                            &ldquo;Yes&rdquo; or &ldquo;No&rdquo;
                                        </p>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex flex-row align-items-center">
                                    <span className="fa-layers fa-fw mr-1" style={{ width: '15%' }}>
                                        <i className="far text-gray-600 fa-circle" data-fa-transform="grow-16" />
                                        <i className="fas fa-fw fa-reply text-gray-400" />
                                    </span>

                                    <div style={{ width: '85%' }}>
                                        <Link href="/recipient/notifications/sent" to="/recipient/notifications/sent" className="small font-weight-semibold text-green-500">Gather Responses</Link>
                                        <p className="smaller mb-0">See how well your coworkers scored against your HIPAA trivia questions by viewing their responses.</p>
                                    </div>
                                </ListGroup.Item>
                            </>
                        )}
                        {Boolean(branch.canManagePatients) && (
                            <ListGroup.Item className="d-flex flex-row align-items-center">
                                <span className="fa-layers fa-fw mr-1" style={{ width: '15%' }}>
                                    <i className="far text-gray-600 fa-circle" data-fa-transform="grow-16" />
                                    <i className="fas fa-fw fa-user-injured text-gray-400" />
                                </span>

                                <div style={{ width: '85%' }}>
                                    <Link href="/recipient/contacts/patients/new" to="/recipient/contacts/patients/new" className="small font-weight-semibold text-green-500">Build a Care Team</Link>
                                    <p className="smaller mb-0">Setup a team of employees who work with a patient so they can coordinate care for that patient.</p>
                                </div>
                            </ListGroup.Item>
                        )}
                        {Boolean(branch.canManageTeams) && (
                            <ListGroup.Item className="d-flex flex-row align-items-center">
                                <span className="fa-layers fa-fw mr-1" style={{ width: '15%' }}>
                                    <i className="far text-gray-600 fa-circle" data-fa-transform="grow-16" />
                                    <i className="fas fa-fw fa-users text-gray-400" />
                                </span>

                                <div style={{ width: '85%' }}>
                                    <Link href="/recipient/contacts/teams/new" to="/recipient/contacts/teams/new" className="small font-weight-semibold text-green-500">Build a Team</Link>
                                    <p className="smaller mb-0">Setup a team of employees who have the same job function so they can collaborate.</p>
                                </div>
                            </ListGroup.Item>
                        )}
                        <ListGroup.Item className="d-flex flex-row align-items-center">
                            <span className="fa-layers fa-fw mr-1" style={{ width: '15%' }}>
                                <i className={`${recipient.contactPhone && recipient.zipCode ? 'fas text-green-500' : 'far text-gray-600'} fa-circle`} data-fa-transform="grow-16" />
                                <i className={`fas fa-fw ${recipient.contactPhone && recipient.zipCode ? 'fa-check text-gray-700' : 'fa-user-cog text-gray-400'}`} />
                            </span>

                            <div style={{ width: '85%' }}>
                                <Link href="/recipient/profile" to="/recipient/profile" className="small font-weight-semibold text-green-500">Complete Your Profile</Link>
                                <p className="smaller mb-0">Make sure coworkers can get in touch wtih you and alert you of emergencies in your area.</p>
                            </div>
                        </ListGroup.Item>
                        {Boolean(branch.canManageBranch) && (
                            <>
                                {partnerLoading ? (
                                    <ListGroup.Item key="loading" className="text-blue-400 text-center">
                                        <i className="fas fa-spinner fa-fw fa-spin fa-2x" />
                                    </ListGroup.Item>
                                ) : partnerError ? (
                                    <ListGroup.Item key="error" className="text-orange-200">{partnerError}</ListGroup.Item>
                                ) : partner.name !== 'Notifyd' && (
                                    <>
                                        <ListGroup.Item className="d-flex flex-row align-items-center">
                                            <span className="fa-layers fa-fw mr-1" style={{ width: '15%' }}>
                                                <i className="far text-gray-600 fa-circle" data-fa-transform="grow-16" />
                                                <i className="fas fa-fw fa-handshake text-gray-400" />
                                            </span>

                                            <div style={{ width: '85%' }}>
                                                <button className="small font-weight-semibold text-left text-green-500 p-0 bg-transparent border-0" onClick={() => setShowIntegratePartner(true)}>Setup {partner.name} Integration</button>
                                                <p className="smaller mb-0">Integrate Notifyd with {partner.name} for additional functionality!</p>
                                            </div>
                                        </ListGroup.Item>
                                        <Modal show={showIntegratePartner} onHide={() => setShowIntegratePartner(false)}>
                                            <Modal.Header className="bg-blue-100 text-gray-700">
                                                <Modal.Title className="p font-weight-medium">Setting up {partner.name} integration</Modal.Title>

                                                <button className="close text-gray-700" onClick={() => setShowIntegratePartner(false)}><i className="fas fa-times fa-sm" /></button>
                                            </Modal.Header>

                                            <Modal.Body>
                                                {partner.name === 'Kantime' ? (
                                                    <>
                                                        <p className="small">We will submit your integration request to Kantime for you. It can take up to 2 business days to complete this request.</p>
                                                        <p className="small">Once this request is completed, you will be able to see <strong>&ldquo;Notifyd Settings&rdquo;</strong> under the <strong>&ldquo;Setup&rdquo;</strong> tab in Kantime.</p>
                                                        <p className="small mb-0">If you are unable to see this option after 2 business days, please reach out to <a href="https://app.notifyd.com/support" target="_blank" rel="noopener noreferrer">Notifyd Support</a>.</p>
                                                    </>
                                                ) : partner.name === 'ABS - Brightstar' ? (
                                                    <>
                                                        <p className="small">To get started, please submit an email to the ABS Help Desk at <a href="mailto:support@brightstarcare.com">support@brightstarcare.com</a> with the following information.</p>
                                                        <pre className="smaller bg-gray-700 border border-gray-600 p-1 rounded" style={{ whiteSpace: 'pre-wrap' }}>{`BrightStar Care of ${branch.name} has created a Notifyd subscription and would like to request the service be turned on in ABS. Please send Notifyd app invites to our caregivers.

Company ID: ${branch.companyId}
Branch ID:  ${branch.id}`}</pre>

                                                        <p className="small mb-0">You can keep track of where you are at in the integration process by referring to the <a href="https://notifyd-public-assets.s3.us-east-2.amazonaws.com/Notifyd+Workflow+Document+v2.pdf" target="_blank" rel="noopener noreferrer">&ldquo;Notifyd Integration Workflow Document&rdquo;</a> put together by Notifyd and ABS. You have currently completed Step 1.</p>
                                                    </>
                                                ) : (
                                                    <p>To get started, please <a href="https://app.notifyd.com/support" target="_blank" rel="noopener noreferrer">contact Notifyd Support</a></p>
                                                )}
                                            </Modal.Body>
                                        </Modal>
                                    </>
                                )}
                                <ListGroup.Item className="d-flex flex-row align-items-center">
                                    <span className="fa-layers fa-fw mr-1" style={{ width: '15%' }}>
                                        <i className={`${branch.archiveNotificationsAfter !== null ? 'fas text-green-500' : 'far text-gray-600'} fa-circle`} data-fa-transform="grow-16" />
                                        <i className={`fas fa-fw ${branch.archiveNotificationsAfter !== null ? 'fa-check text-gray-700': 'fa-archive text-gray-400'}`} />
                                    </span>

                                    <div style={{ width: '85%' }}>
                                        <Link href="/recipient/settings" to="/recipient/settings" className="small font-weight-semibold text-green-500">Set Archive Period</Link>
                                        <p className="smaller mb-0">Reduce the risk of HIPAA breaches by setting an archive period.</p>
                                    </div>
                                </ListGroup.Item>
                            </>
                        )}
                    </>
                )}
            </ListGroup>
        </>
    );
}

export default App;
