import React from 'react'
import { useHistory } from 'react-router-dom'
import { Badge, Button as BootstrapButton } from 'react-bootstrap';

function Button(props) {
  const { recipient, unread, size } = props
  let history = useHistory()

  const handleClick = () => {
    if (!recipient.doNotDisturb || window.confirm('This employee is currently in "Do not disturb" mode, and may not see your message right away. If you don\'t get a response, consider reaching out to another coworker.')) {
      history.push({ pathname: '/recipient/chat', state: { recipient } })
    }
  }

  return (
    <div className="d-inline-block position-relative">
      <BootstrapButton
        variant={recipient.hasLoggedIn ? 'blue-400' : 'gray-600'}
        size={size}
        className="text-gray-700"
        onClick={handleClick}
        disabled={!recipient.hasLoggedIn}
      >
        <i className="fas fa-comment fa-fw" />
      </BootstrapButton>

      {unread > 0 && (
        <Badge variant="orange-200" className="position-absolute text-gray-700" style={{ right: -5, top: -15 }}>{unread}</Badge>
      )}

      {Boolean(recipient.doNotDisturb) && (
        <Badge variant="gray-400" className="position-absolute text-gray-700 p-1" style={{ right: -5, bottom: -5, zIndex: 5 }}><i className="fa fa-bell-slash fa-fw fa-xs" /></Badge>
      )}
    </div>
  )
}

export default Button