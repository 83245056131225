import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from './Form';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Details(props) {
    const { branch } = props;
    const patientId = props.patientId === 'new' ? null : props.patientId;
    const [ profileState, setProfileState ] = useState({ patient: null, error: '', loading: true });

    const history = useHistory();

    const setPatient = (patient) => {
        setProfileState({ patient, error: '', loading: false });
        history.push(`/recipient/contacts/patients/${patient.id}`);
    };

    useEffect(() => {
        const getPatient = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/patients/${patientId}`, { queryStringParameters: { expand: 'patient-recipient' } });
                setProfileState({ patient: response, error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setProfileState({ patient: null, error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient/contacts/patients');
                            break;
                        case 404:
                            history.push('/recipient/contacts/patients');
                            break;
                        default:
                            setProfileState({ patient: null, error: `We encountered an unexpected issue while retrieving this Patient. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setProfileState({ patient: null, error: `We encountered an unexpected issue while retrieving this Patient. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        if (patientId !== null) {
            getPatient();
        } else {
            setProfileState({ patient: null, error: '', loading: false });
        }
    }, [ branch.id, patientId, history ]);

    const deletePatient = () => {
        const deletePatientBranchRelationship = async () => {
            try {
                await API.del('AuthenticatedAPI', `/branches/${branch.id}/patients/${patientId}`);

                history.push('/recipient/contacts/patients');
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setProfileState({ ...profileState, error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient/contacts/patients');
                            break;
                        case 404:
                            history.push('/recipient/contacts/patients');
                            break;
                        default:
                            setProfileState({ ...profileState, error: `We encountered an unexpected issue while deleting this Patient. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setProfileState({ ...profileState, error: `We encountered an unexpected issue while deleting this Patient. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        if (window.confirm(`Are you sure you want to remove ${profileState.patient.name} from ${branch.name}?`)) {
            setProfileState({ ...profileState, error: '', loading: true });
            deletePatientBranchRelationship();
        }
    };

    if (profileState.loading) {
        return (
            <h1 className="mt-3"><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>
        );
    }

    if (profileState.error) {
        return (
            <Alert variant="orange-200" className="small">{profileState.error}</Alert>
        );
    }

    return (
        <>
            <Helmet>
                <title>Employee | Manage Care Team</title>
            </Helmet>

            <div className="bg-white p-4 mb-3 border border-top-0 border-gray-600 rounded-bottom">
                {profileState.patient === null ? (
                    <Row className="align-items-center">
                        <Col xs="12" sm="3" lg="2" className="text-center">
                            <div className="bg-gray-300 rounded-circle d-inline-block p-3">
                                <i className="fas fa-3x fa-user-injured fa-fw text-gray-700"></i>
                            </div>

                            <hr className="d-sm-none" />
                        </Col>

                        <Col xs="12" sm="9" lg="10">
                            <h4>Fill out this form to create a team of employees that work with a specific patient.</h4>
                            <p className="small mb-0">Care teams allow employees to organize patient care activities and share information among all of the participants concerned with a patient's care to achieve safer and more effective care.</p>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col xs="12" sm="6" lg={true}>
                            <h4>{profileState.patient.name}</h4>
                        </Col>

                        <Col xs="12" sm="6" lg={"auto"}>
                            <Button variant="orange-200" size="sm" block className="text-white mt-2 mt-sm-0" onClick={deletePatient}><i className="fas fa-trash-alt"></i> Deactivate</Button>
                        </Col>
                    </Row>
                )}
            </div>

            <Form branch={branch} patient={profileState.patient || {}} onNewPatient={setPatient} />
        </>
    );
}

export default Details;
