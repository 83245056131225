import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import { Auth } from 'aws-amplify';

function ListGroupItem(props) {
    const { notification, unreadCount, setNotification } = props;

    let date, dateString, icon, bgColor;

    if (notification.critical) {
        date = new Date(notification.createdOn);
        icon = 'fa-exclamation-triangle';
        bgColor = 'orange-200';
    } else if (notification.sendingUserId === Auth.user.username || notification.assigned) {
        date = new Date(notification.lastRespondedOn || notification.createdOn);
        icon = notification.sendingUserId === Auth.user.username ? 'fa-paper-plane' : 'fa-tag';
        bgColor = 'blue-300';
    } else {
        date = new Date(notification.createdOn);
        icon = 'fa-envelope';
        bgColor = 'blue-300';
    }

    let timeDifferenceInSeconds = (new Date().getTime() - date.getTime()) / 1000;
    dateString = timeDifferenceInSeconds < 86400 ? date.toLocaleTimeString() : date.toLocaleDateString();

    return (
        <ListGroup.Item>
            <button className="d-flex w-100 p-0 bg-transparent border-0 text-left text-gray-100" onClick={() => setNotification(notification)}>
                <div className="position-relative">
                    <span className="fa-2x fa-layers fa-fw">
                        <i className={`fas fa-circle text-${bgColor}`}></i>
                        <i className={`fa-inverse fas ${icon}`} data-fa-transform="shrink-8"></i>
                    </span>
                    <Badge variant="orange-200" className="position-absolute text-gray-700" style={{ right: -5, top: -5 }}>{unreadCount}</Badge>

                    {notification.recipientCount > 0 && (
                        <div className="text-center smaller">{notification.responseCount} / {notification.recipientCount}</div>
                    )}
                </div>

                <div className="flex-grow-1 ml-2">
                    <div className="d-lg-flex justify-content-between">
                        <div>
                            <span className="small font-weight-semibold text-blue-300">{notification.title}</span>
                        </div>

                        <span className="d-none d-lg-inline smaller font-weight-light text-gray-400 text-right">{dateString}</span>
                    </div>

                    <p className="mb-0 small text-break" style={{ whiteSpace: 'pre-line' }}>{`${notification.message.substring(0, 70)}${notification.message.length > 70 ? '...' : ''}`}</p>

                    <span className="d-lg-none smaller font-weight-light text-gray-400">{dateString}</span>
                </div>
            </button>
        </ListGroup.Item>
    );
}

export default ListGroupItem;
