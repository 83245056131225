import React from 'react';

function RecipientSummary(props) {
    const { numberOfEmployees, numberOfEmployeesLoggedIn, numberOfEmployeesWithAlerts } = props;

    const percentageLoggedIn = numberOfEmployees > 0 ? numberOfEmployeesLoggedIn / numberOfEmployees : 0;
    const percentageWithAlerts = numberOfEmployees > 0 ? numberOfEmployeesWithAlerts / numberOfEmployees : 0;
    const loggedInClass = percentageLoggedIn < 0.4 ? 'bronze' : percentageLoggedIn < 0.6 ? 'silver' : percentageLoggedIn < 0.8 ? 'gold' : 'green-500';
    const withAlertsClass = percentageWithAlerts < 0.4 ? 'bronze' : percentageWithAlerts < 0.6 ? 'silver' : percentageWithAlerts < 0.8 ? 'gold' : 'green-500';

    return (
        <>
            <h4 className="text-center py-3 text-gray-400">
                {numberOfEmployees} Employees Invited<br />
                <small className="text-muted"><i className="far fa-infinity"></i> invites remaining</small>
            </h4>

            <hr />

            <div className="text-center text-truncate small font-weight-bold" style={{ height:30 }}>
                <div className={`h-100 py-1 rounded${percentageLoggedIn < 1 && '-left'} bg-striped-${loggedInClass} float-left`} style={{ width: `${100 * percentageLoggedIn}%` }}>{Math.round(100 * percentageLoggedIn)}%</div>
                <div className={`h-100 py-1 rounded${percentageLoggedIn > 0 && '-right'} border border-gray-600 bg-gray-700 float-right`} style={{ width: `${100 * (1 - percentageLoggedIn)}%` }}></div>
            </div>

            <small className="font-weight-medium"># of Logged In Users: <span className="font-weight-bold text-{{ loggedInClass }}">{numberOfEmployeesLoggedIn}</span></small>

            <hr />

            <div className="text-center text-truncate small font-weight-bold" style={{ height: 30 }}>
                <div className={`h-100 py-1 rounded${percentageWithAlerts < 1 && '-left'} bg-striped-${withAlertsClass} float-left`} style={{ width: `${100 * percentageWithAlerts}%` }}>{Math.round(100 * percentageWithAlerts)}%</div>
                <div className={`h-100 py-1 rounded${percentageWithAlerts > 0 && '-right'} border border-gray-600 bg-gray-700 float-right`} style= {{ width: `${100 * (1 - percentageWithAlerts)}%` }}></div>
            </div>

            <small className="font-weight-medium"># of Users receiving Text or App Alerts: <span className={`font-weight-bold text-${withAlertsClass}`}>{numberOfEmployeesWithAlerts}</span></small>
        </>
    );
}

export default RecipientSummary;
