import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { default as BootstrapForm } from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import { plans } from './../../../config/Stripe';

function Form(props) {
    const [ name, setName ] = useState(props.branch.name);
    const [ partnerId, setPartnerId ] = useState(props.branch.partnerId);
    const [ archiveNotificationsAfter, setArchiveNotificationsAfter ] = useState(props.branch.archiveNotificationsAfter);
    const subscription = props.branch.subscription;
    const [ stripePlanId, setStripePlanId ] = useState(subscription ? subscription.plan.id : null);
    const [ formState, setFormState ] = useState({ partners: null, message: '', error: '', loading: false });
    const negotiatedRate = props.branch.negotiatedRate;
    const history = useHistory();

    useEffect(() => {
        const getPartners = async () => {
            try {
                let partners = await API.get('AuthenticatedAPI', '/partners');
                setFormState({ partners, message: '', error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ partners: null, message: '', error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to edit this Branch. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setFormState({ partners: null, message: '', error: `We encountered an unexpected issue while retrieving Branches. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ partners: null, message: '', error: `We encountered an unexpected issue while retrieving Branches. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        setFormState({ partners: null, message: '', error: '', loading: true })
        getPartners();
    }, [ history ]);

    const formSubmit = evt => {
        evt.preventDefault();

        const putBranch = async () => {
            try {
                await API.put('AuthenticatedAPI', `/branches/${props.branch.id}`, { body: { name, partnerId, archiveNotificationsAfter, stripePlanId: stripePlanId || null }});

                setFormState({ ...formState, message: 'Successfully updated Branch.', error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ ...formState, message: '', error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to edit this Branch. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/admin/branches');
                            break;
                        default:
                            setFormState({ ...formState, message: '', error: `We encountered an unexpected issue while retrieving Branches. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ ...formState, message: '', error: `We encountered an unexpected issue while retrieving Branches. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        setFormState({ ...formState, message: '', error: '', loading: true })
        putBranch();
    };

    return (
        <BootstrapForm onSubmit={formSubmit}>
            <div className="bg-white rounded border border-gray-500 p-3 my-3">
                <Row>
                    <Col xs="12" sm="6" xl="4">
                        <fieldset>
                            <legend>Branch Details</legend>

                            <BootstrapForm.Group controlId="name">
                                <BootstrapForm.Label>Name</BootstrapForm.Label>
                                <BootstrapForm.Control value={name} required onChange={e => setName(e.target.value)} />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group controlId="archiveNotificationsAfter">
                                <BootstrapForm.Label>Archive Notifications After</BootstrapForm.Label>
                                <InputGroup>
                                    <BootstrapForm.Control type="number" min="1" max="255" value={archiveNotificationsAfter !== null ? archiveNotificationsAfter : ''} onChange={e => setArchiveNotificationsAfter(e.target.value)} />
                                    <InputGroup.Append>
                                        <InputGroup.Text className="small">Days</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                <BootstrapForm.Text muted>Leave the field blank if you don't want notifications to be archived.</BootstrapForm.Text>
                            </BootstrapForm.Group>

                            <BootstrapForm.Group controlId="partnerId">
                                <BootstrapForm.Label>Partner</BootstrapForm.Label>
                                <i className={`fas fa-spinner fa-fw fa-2x fa-spin ${formState.partners !== null ? 'd-block' : 'd-none'}`}></i>
                                {formState.partners !== null && formState.partners.map(partner =>
                                    <BootstrapForm.Check key={partner.id} type="radio" id={partner.id} value={partner.id} checked={partnerId === partner.id} label={partner.name} onChange={e => setPartnerId(partner.id)} />
                                )}
                            </BootstrapForm.Group>
                        </fieldset>
                    </Col>

                    <Col xs="12" sm="6" xl="4">
                        <fieldset>
                            <legend>Subscription</legend>

                            <BootstrapForm.Group controlId="plan">
                                <BootstrapForm.Label>Subscription Plan</BootstrapForm.Label>
                                <BootstrapForm.Control as="select" value={stripePlanId} onChange={e => setStripePlanId(e.target.value)}>
                                    {plans.map(plan =>
                                        <option key={plan.id} value={plan.id}>{plan.nickname} ${(plan.amount * negotiatedRate / (100 * 100)).toFixed(2)}</option>
                                    )}
                                    <option value="">Cancel Subscription</option>
                                </BootstrapForm.Control>
                            </BootstrapForm.Group>

                            {subscription !== null ? (
                                <>
                                    <BootstrapForm.Group>
                                        <BootstrapForm.Label>Created</BootstrapForm.Label>
                                        <BootstrapForm.Control value={new Date(subscription.created * 1000).toLocaleDateString()} disabled />
                                    </BootstrapForm.Group>

                                    {subscription.canceled_at !== null ? (
                                        <>
                                            <BootstrapForm.Group>
                                                <BootstrapForm.Label>Canceled</BootstrapForm.Label>
                                                <BootstrapForm.Control value={new Date(subscription.canceled_at * 1000).toLocaleDateString()} disabled />
                                            </BootstrapForm.Group>
                                            <BootstrapForm.Group>
                                                <BootstrapForm.Label>End of Service</BootstrapForm.Label>
                                                <BootstrapForm.Control value={new Date(subscription.current_period_end * 1000).toLocaleDateString()} disabled />
                                            </BootstrapForm.Group>
                                        </>
                                    ) : (
                                        <BootstrapForm.Group>
                                            <BootstrapForm.Label>Renews On</BootstrapForm.Label>
                                            <BootstrapForm.Control value={new Date(subscription.current_period_end * 1000).toLocaleDateString()} disabled />
                                        </BootstrapForm.Group>
                                    )}

                                    {subscription.trial_end !== null && (
                                        <BootstrapForm.Group>
                                            <BootstrapForm.Label>Trial Ends</BootstrapForm.Label>
                                            <BootstrapForm.Control value={new Date(subscription.trial_end * 1000).toLocaleDateString()} disabled />
                                        </BootstrapForm.Group>
                                    )}
                                </>
                            ) : (
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Status</BootstrapForm.Label>
                                    <BootstrapForm.Control value="Unsubscribed" disabled />
                                </BootstrapForm.Group>
                            )}
                        </fieldset>
                    </Col>
                </Row>
            </div>

            {formState.message !== '' && (
                <Alert variant="green-500" className="small">{formState.message}</Alert>
            )}

            {formState.error !== '' && (
                <Alert variant="orange-200" className="small">{formState.error}</Alert>
            )}

            <Button type="submit" variant="green-500" className="text-white" disabled={formState.loading}>Save</Button>
        </BootstrapForm>
    );
}

export default Form;
