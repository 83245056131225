import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Filters from './Filters';
import Table from './Table';
import Pagination from '../../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function List(props) {
    const { branch, unread } = props;

    const [ recipientState, setRecipientState ] = useState({ recipients: null, total: 0, loading: false, error: '' });
    const [ filters, setFilters ] = useState({ search: "", hasLoggedIn: null, disabled: false });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 25;
    const history = useHistory();

    useEffect(() => {
        const getRecipientsByBranch = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branch.id}/recipients`, { response: true, queryStringParameters: { ...filters, pageSize, pageNumber, expand: 'branch-recipient' } });

                setRecipientState({ recipients: response.data, total: response.headers['x-total-count'], loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Recipients.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setRecipientState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Recipients for this Branch. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setRecipientState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Recipients for this Branch. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setRecipientState({ recipients: null, total: 0, loading: true, error: '' });
        getRecipientsByBranch();
    }, [ branch.id, pageNumber, filters, history ]);

    const exportRecipients = async () => {
        try {
            let response = await API.get('AuthenticatedAPI', `/branches/${branch.id}/recipients`, { queryStringParameters: { ...filters, pageSize: recipientState.total, pageNumber: 0, expand: 'branch-recipient', accept: 'text/csv' } });

            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(response));
            element.setAttribute('download', 'employee-export.csv');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        history.push('/', {
                            message: 'Your session has expired. Please log back in to continue.',
                            redirect: history.location
                        });
                        break;
                    case 403:
                        history.push('/', {
                            message: 'You are not authorized to access Recipients.',
                            redirect: history.location
                        });
                        break;
                    default:
                        setRecipientState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while exporting Recipients for this Branch. The service returned error code ${error.response.status}` });
                        Sentry.captureException(error);
                        break;
                }
            } else {
                setRecipientState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while exporting Recipients for this Branch. The service returned error message ${error.message}` });
                Sentry.captureException(error);
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>Employee | Employee Contacts</title>
            </Helmet>

            <Filters onSearch={filters => { setFilters(filters); setPageNumber(0); } } showOfficeGroup={branch.officeGroup} showHasLoggedIn={branch.canManageEmployees} showDisabled={true} loading={recipientState.loading} />

            {recipientState.error !== '' && (
                <Alert variant="orange-200" className="small">{recipientState.error}</Alert>
            )}

            <div className="my-3 text-right">
                <Button variant="blue-200" size="sm" onClick={exportRecipients}><i className="fas fa-download fa-fw" /> Export</Button>
            </div>

            <Table branchId={branch.id} canManageEmployees={branch.canManageEmployees} canManageBranch={branch.canManageBranch} recipients={recipientState.recipients} unread={unread} disabled={filters.disabled} />

            {recipientState.total > 0 && (
                <Pagination total={recipientState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
            )}
        </>
    );
}

export default List;
