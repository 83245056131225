import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from './Form';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Details(props) {
    const { branch } = props;
    const recipientId = props.recipientId === 'new' ? null : props.recipientId;
    const [ profileState, setProfileState ] = useState({ recipient: null, error: '', loading: true });
    const [ showStatusDetails, setShowStatusDetails ] = useState(false);

    const history = useHistory();

    const setRecipient = (recipient) => {
        setProfileState({ recipient, error: '', loading: false });
        history.push(`/recipient/contacts/recipients/${recipient.id}`);
    };

    useEffect(() => {
        const getRecipient = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branch.id}/recipients/${recipientId}`, { queryStringParameters: { expand: 'branch-recipient,user-status' } });
                setProfileState({ recipient: response, error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setProfileState({ recipient: null, error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient/contacts/recipients');
                            break;
                        case 404:
                            history.push('/recipient/contacts/recipients');
                            break;
                        default:
                            setProfileState({ recipient: null, error: `We encountered an unexpected issue while retrieving this Recipient. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setProfileState({ recipient: null, error: `We encountered an unexpected issue while retrieving this Recipient. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        if (recipientId !== null) {
            getRecipient();
        } else {
            setProfileState({ recipient: null, error: '', loading: false });
        }
    }, [ branch.id, recipientId, history ]);

    const deactivateRecipient = () => {
        const deleteRecipientBranchRelationship = async () => {
            try {
                await API.del('AuthenticatedAPI', `/branches/${branch.id}/recipients/${recipientId}`);

                history.push('/recipient/contacts/recipients');
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setProfileState({ ...profileState, error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to edit this Recipient.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/recipient/contacts/recipients');
                            break;
                        default:
                            setProfileState({ ...profileState, error: `We encountered an unexpected issue while deactivating this Recipient. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setProfileState({ ...profileState, error: `We encountered an unexpected issue while deactivating this Recipient. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        if (window.confirm(`Are you sure you want to remove ${profileState.recipient.name} from ${branch.name}?`)) {
            setProfileState({ ...profileState, error: '', loading: true });
            deleteRecipientBranchRelationship();
        }
    };

    if (profileState.loading) {
        return (
            <h1 className="mt-3"><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>
        );
    }

    if (profileState.error) {
        return (
            <Alert variant="orange-200" className="small">{profileState.error}</Alert>
        );
    }

    let lastLogin = 'Never';

    if (profileState.recipient && profileState.recipient.lastLogin) {
        let lastLoginParts = profileState.recipient.lastLogin.split(/\D+/);
        let lastLoginDate = new Date(Date.UTC(lastLoginParts[0], --lastLoginParts[1], lastLoginParts[2], lastLoginParts[3], lastLoginParts[4], lastLoginParts[5], lastLoginParts[6]));
        lastLogin = `${lastLoginDate.toLocaleDateString()} ${lastLoginDate.toLocaleTimeString()}`;
    }

    return (
        <>
            <Helmet>
                <title>Employee | Manage Employee</title>
            </Helmet>

            <div className="bg-white p-4 mb-3 border border-top-0 border-gray-600 rounded-bottom">
                {profileState.recipient === null ? (
                    <Row className="align-items-center">
                        <Col xs="12" sm="3" lg="2" className="text-center">
                            <div className="bg-gray-300 rounded-circle d-inline-block p-3">
                                <i className="fas fa-3x fa-user-tie fa-fw text-gray-700"></i>
                            </div>

                            <hr className="d-sm-none" />
                        </Col>

                        <Col xs="12" sm="9" lg="10">
                            <h4>Fill out this form to invite employees to start using Notifyd.</h4>
                            <p className="small">If they are a new user, they will receive an email and an SMS text invite (if a phone number is provided) from {branch.name} with instructions on how to get started.</p>
                            <p className="small">If a user already exists for the email you provide, {branch.name} will be added to their existing account.</p>
                            <p className="small mb-0">Specify what permissions this employee should have based on their role and responsibilities.</p>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col xs="12" sm="6" lg="4">
                            <h4>{profileState.recipient.name}</h4>

                            {Boolean(branch.canManageEmployees) && (
                                <div>
                                    <a href={`mailto:${profileState.recipient.email}`} className="small"><i className="fas fa-envelope fa-fw"></i> {profileState.recipient.email}</a>

                                    {profileState.recipient.phone && (
                                        <>
                                            <br />
                                            <a href={`tel:${profileState.recipient.phone}`} className="small"><i className="fas fa-sms fa-fw"></i> {profileState.recipient.phone}</a>
                                        </>
                                    )}
                                </div>
                            )}
                        </Col>

                        <Col xs="12" sm="6" lg="5" xl="4">
                            <p className="mb-0 small">
                                <span className="font-weight-bold">Last Login: </span>
                                <span className={profileState.recipient.lastLogin ? 'text-gray-400 font-weight-regular' : 'text-orange-200 font-weight-medium'}>{lastLogin}</span>
                            </p>

                            <p className="mb-0 small">
                                <span className="font-weight-bold">App Alerts: </span>
                                <span className={profileState.recipient.allowedPushNotifications ? 'text-gray-400 font-weight-regular' : 'text-orange-200 font-weight-medium'}>{profileState.recipient.allowedPushNotifications ? 'On' : 'Off'}</span>
                            </p>

                            <p className="mb-0 small" onClick={() => setShowStatusDetails(!showStatusDetails)}>
                                <span className="font-weight-bold">User Status: </span>
                                <button className={`bg-transparent border-0 p-0 ${profileState.recipient.userStatus === 'UNCONFIRMED' ? 'text-orange-200 font-weight-medium' : 'text-green-500 font-weight-regular'}`}>{profileState.recipient.userStatus} <i className="fas fa-question-circle"></i></button>
                            </p>

                            {showStatusDetails && (
                                <>
                                    <span className="smaller form-text text-muted"><span className="font-weight-semibold">UNCONFIRMED</span>: registered, no confirmed email</span>
                                    <span className="smaller form-text text-muted"><span className="font-weight-semibold">CONFIRMED</span>: registered, confirmed email</span>
                                    <span className="smaller form-text text-muted"><span className="font-weight-semibold">RESET_REQUIRED</span>: requires new password</span>
                                    <span className="smaller form-text text-muted"><span className="font-weight-semibold">FORCE_CHANGE_PASSWORD</span>: temporary password</span>
                                </>
                            )}
                        </Col>

                        <Col xs="12" lg="3" xl="4">
                            <Button variant="orange-200" size="sm" block className="text-white mt-2" onClick={deactivateRecipient}><i className="fas fa-trash-alt"></i> Deactivate</Button>
                        </Col>
                    </Row>
                )}
            </div>

            <Form branch={branch} recipient={profileState.recipient || {}} onNewRecipient={setRecipient} />
        </>
    );
}

export default Details;
