import React, { useState, useEffect } from 'react'
import { Table, Dropdown } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { default as ChatButton } from '../Chat/Button'
import { default as CallButton } from '../Call/Button'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'

function Recipients(props) {
    const { notification } = props;
    const [ tableState, setTableState ] = useState({ recipients: props.recipients, error: '', loading: false });
    const history = useHistory()

    useEffect(() => {
        setTableState({ recipients: props.recipients, error: '', loading: false });
    }, [ props.recipients ]);

    const handleRecipientRemove = recipient => {
        const deleteRecipientNotificationRelationship = async () => {
            try {
                await API.del('AuthenticatedAPI', `/notifications/${notification.id}/recipients/${recipient.id}`)

                setTableState(tableState => {
                    return {
                        ...tableState,
                        recipients: tableState.recipients.filter(x => x.id !== recipient.id)
                    }
                })
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setTableState(tableState => { return { ...tableState, error: error.response.data.message } })
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to modify this Notification.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setTableState(tableState => { return { ...tableState, error: `We encountered an unexpected issue while responding to this Notifications. The service returned error code ${error.response.status}` } })
                            Sentry.captureException(error)
                            break
                    }
                } else {
                    setTableState(tableState => { return { ...tableState, error: `We encountered an unexpected issue while responding to this Notifications. The service returned error message ${error.message}` } })
                    Sentry.captureException(error);
                }
            }
        }

        deleteRecipientNotificationRelationship()
    }

    return (
        <>
            <Table responsive striped bordered hover className="bg-white">
                <thead className="bg-gray-200 text-white small font-weight-normal">
                    <tr>
                        <th>Employee</th>
                        <th>Responded</th>
                        <th>On</th>
                        <th width="161"></th>
                    </tr>
                </thead>
                <tbody>
                    {tableState.recipients === null ? (
                        <tr key="loading">
                            <td colSpan="4" className="text-center">
                                <i className="fas fa-spinner fa-fw fa-lg fa-spin" />
                            </td>
                        </tr>
                    ) : tableState.recipients.length === 0 ? (
                        <tr key="none">
                            <td colSpan="4" className="text-center">
                                No results found
                            </td>
                        </tr>
                    ) : tableState.recipients.map(recipient => {
                        let respondedOn;
                        let response = recipient['notification-recipient'].response;
                        let responseClass = 'text-gray-400 font-weight-light';

                        if (recipient['notification-recipient'].response !== null) {
                            let lastRespondedParts = recipient['notification-recipient'].lastUpdatedOn.split(/\D+/);
                            let lastRespondedDate = new Date(Date.UTC(lastRespondedParts[0], --lastRespondedParts[1], lastRespondedParts[2], lastRespondedParts[3], lastRespondedParts[4], lastRespondedParts[5], lastRespondedParts[6]));
                            respondedOn = `${lastRespondedDate.toLocaleDateString()} ${lastRespondedDate.toLocaleTimeString()}`;

                            if (notification) {
                                if (notification.responses.length === 0 || notification.responses[0].text === response) {
                                    responseClass = ' font-weight-medium text-blue-300';
                                } else if (notification.responses[1].text === response) {
                                    responseClass = 'font-weight-medium text-blue-400';
                                }
                            }
                        }

                        return (
                            <tr key={recipient.id}>
                                <td>{recipient.name}</td>
                                <td className={responseClass}>{response === null ? 'no response' : response || 'Viewed'}</td>
                                <td>{respondedOn}</td>
                                <td>
                                    <ChatButton recipient={recipient} />
                                    <CallButton recipient={recipient} />
                                    <Dropdown className="d-inline-block ml-1">
                                        <Dropdown.Toggle variant="gray-600" />
                                        <Dropdown.Menu className="p-1">
                                            <Dropdown.Item className="small p-1" onClick={() => handleRecipientRemove(recipient)}>
                                                <i className="fas fa-times fa-fw text-orange-200"></i> Revoke Notification
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        )}
                    )}
                </tbody>
            </Table>
        </>
    )
}

export default Recipients
