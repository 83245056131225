import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { default as BootstrapForm } from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";
const EventTypes = [ 'response.created' ];
const AuthTypes = [ 'basic', 'bearer' ];

function Form(props){
    const [ eventType, setEventType ] = useState(props.webhook ? props.webhook.eventType : EventTypes[0]);
    const [ authType, setAuthType ] = useState(props.webhook ? props.webhook.authType : AuthTypes[0]);
    const [ authUrl, setAuthUrl ] = useState(props.webhook ? props.webhook.authUrl : '');
    const [ authBody, setAuthBody ] = useState(props.webhook ? props.webhook.authBody : '');
    const [ username, setUsername ] = useState(props.webhook ? props.webhook.username : '');
    const [ password, setPassword ] = useState(props.webhook ? props.webhook.password : '');
    const [ url, setUrl ] = useState(props.webhook ? props.webhook.url : '');
    const [ formState, setFormState ] = useState({ message: '', error: '', loading: false });
    const history = useHistory();

    const formSubmit = evt => {
        evt.preventDefault();

        const postWebhook = async () => {
            try {
                await API.post('AuthenticatedAPI', `/webhooks`, { body: { eventType, authType, authUrl, authBody, username, password, url } });
                history.push(`/partner/web-hooks/${eventType}`);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ message: '', error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to edit this Webhook. Please log in as a partner to continue.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setFormState({ message: '', error: `We encountered an unexpected issue while creating the Webhook. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ message: '', error: `We encountered an unexpected issue while creating the Webhook. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }

        };

        const putWebhook = async () => {
            try {
                await API.put('AuthenticatedAPI', `/webhooks/${props.webhook.eventType}`, { body: { eventType, authType, authUrl, authBody, username, password, url } });

                setFormState({ message: 'Successfully updated Webhook.', error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ message: '', error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to edit this Webhook. Please log in as a partner to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/partner/web-hooks');
                            break;
                        default:
                            setFormState({ message: '', error: `We encountered an unexpected issue while updating the Webhook. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ message: '', error: `We encountered an unexpected issue while updating the Webhook. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        setFormState({ message: '', error: '', loading: true })

        if (props.webhook === null) {
            postWebhook();
        } else {
            putWebhook();
        }
    };

    return (
        <BootstrapForm onSubmit={formSubmit}>
            <div className="bg-white rounded border border-gray-500 p-3">
                <fieldset>
                    <legend>Webhook Details</legend>

                    <BootstrapForm.Group controlId="eventType">
                        <BootstrapForm.Label>Event Type</BootstrapForm.Label>
                        <BootstrapForm.Control as="select" value={eventType} required onChange={e => setEventType(e.target.value)} disabled={props.webhook !== null}>
                            {EventTypes.map(x =>
                                <option key={x}>{x}</option>
                            )}
                        </BootstrapForm.Control>
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="authType">
                        <BootstrapForm.Label>Auth Type</BootstrapForm.Label>
                        <BootstrapForm.Control as="select" value={authType} required onChange={e => setAuthType(e.target.value)}>
                            {AuthTypes.map(x =>
                                <option key={x}>{x}</option>
                            )}
                        </BootstrapForm.Control>
                        <BootstrapForm.Text className="text-muted">To learn more about different authentication/authorization techniques, <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication" target="_blank" rel="noopener noreferrer">click here</a>.</BootstrapForm.Text>
                    </BootstrapForm.Group>

                    {authType === 'bearer' && (
                        <>
                            <BootstrapForm.Group controlId="authUrl">
                                <BootstrapForm.Label>Auth URL</BootstrapForm.Label>
                                <BootstrapForm.Control type="url" placeholder="https://your-api.com/api/auth-endpoint" pattern="https://.*" value={authUrl} required onChange={e => setAuthUrl(e.target.value)} />
                                <BootstrapForm.Text className="text-muted">To learn more about different authentication/authorization techniques, <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication" target="_blank" rel="noopener noreferrer">click here</a>.</BootstrapForm.Text>
                            </BootstrapForm.Group>

                            <BootstrapForm.Group controlId="authBody">
                                <BootstrapForm.Label>Auth Body</BootstrapForm.Label>
                                <BootstrapForm.Control as="textarea" rows="4" value={authBody} onChange={e => setAuthBody(e.target.value)} />
                            </BootstrapForm.Group>
                        </>
                    )}

                    <BootstrapForm.Group controlId="username">
                        <BootstrapForm.Label>Username</BootstrapForm.Label>
                        <BootstrapForm.Control value={username} required={props.webhook === null} onChange={e => setUsername(e.target.value)} placeholder={props.webhook === null ? '' : '********'} />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="password">
                        <BootstrapForm.Label>Password</BootstrapForm.Label>
                        <BootstrapForm.Control type="password" value={password} required={props.webhook === null} onChange={e => setPassword(e.target.value)} placeholder={props.webhook === null ? '' : '********'} />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="url">
                        <BootstrapForm.Label>Webhook URL</BootstrapForm.Label>
                        <BootstrapForm.Control type="url" placeholder="https://your-api.com/api/endpoint" pattern="https://.*" value={url} required onChange={e => setUrl(e.target.value)} />
                        <BootstrapForm.Text className="text-muted">All webhooks must use the HTTPS protocol.</BootstrapForm.Text>
                    </BootstrapForm.Group>
                </fieldset>

                {formState.message !== '' && (
                    <Alert variant="green-500" className="small">{formState.message}</Alert>
                )}

                {formState.error !== '' && (
                    <Alert variant="orange-200" className="small">{formState.error}</Alert>
                )}

                <Button type="submit" variant="green-500" block className="text-white" disabled={formState.loading}>Save</Button>
            </div>
        </BootstrapForm>
    );
}

export default Form;
