import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import { Auth } from 'aws-amplify';
import { listGroupItem } from './App.module.scss';

function ListGroupItem(props) {
    const { icon, iconClass, title, titleClass, createdOn, senderId, senderName, message, unreadCount, highlight, doNotDisturb, onClick } = props;

    let dateString = null;

    if (createdOn) {
        const createdDate = new Date(createdOn);
        const timeDifferenceInSeconds = (new Date().getTime() - createdDate.getTime()) / 1000;

        dateString = timeDifferenceInSeconds < 86400 ? createdDate.toLocaleTimeString() : createdDate.toLocaleDateString();
    }

    const handleClick = () => {
        if (onClick) {
            if (!doNotDisturb || window.confirm('This employee is currently in "Do not disturb" mode, and may not see your message right away. If you don\'t get a response, consider reaching out to another coworker.')) {
                onClick()
            }
        }
    }

    return (
        <ListGroup.Item className={`${listGroupItem} ${highlight ? 'bg-orange-600' : ''}`}>
            <button onClick={handleClick} className="d-flex w-100 text-gray-100 p-0 bg-transparent border-0 text-left">
                <div className="position-relative">
                    <span className="fa-2x fa-layers fa-fw">
                        <i className={`fas fa-circle ${iconClass}`}></i>
                        <i className={`fa-inverse fas ${icon}`} data-fa-transform="shrink-8"></i>
                    </span>
                    {unreadCount > 0 && (
                        <Badge variant="orange-200" className="position-absolute text-gray-700" style={{ right: -5, top: -5 }}>{unreadCount}</Badge>
                    )}
                    {Boolean(doNotDisturb) && (
                        <Badge variant="gray-400" className="position-absolute text-gray-700" style={{ right: -5, bottom: -5 }}><i className="fa fa-bell-slash fa-fw fa-xs" /></Badge>
                    )}
                </div>

                <div className="flex-grow-1 ml-2">
                    <div className="d-lg-flex justify-content-between">
                        <div>
                            <span className={`small font-weight-semibold ${titleClass || 'text-blue-300'}`}>{title}</span>
                        </div>

                        {dateString !== null && (
                            <span className="d-lg-inline smaller font-weight-light text-gray-400">{dateString}</span>
                        )}
                    </div>

                    <p className="mb-0 small">
                        {senderName && (
                            <span className="font-weight-medium">
                                {senderId === Auth.user.username ? (
                                    <span className="text-green-500">Me</span>
                                ) : senderName} &raquo; </span>
                        )}
                        <span className="text-break">{message}</span>
                    </p>

                    {dateString && (
                        <span className="d-lg-none smaller font-weight-light text-gray-400">{dateString}</span>
                    )}
                </div>
            </button>
        </ListGroup.Item>
    );
}

export default ListGroupItem;
