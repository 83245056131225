import React from 'react';
import Button from 'react-bootstrap/Button';

function PageNotFound() {
    const goBack = () => {
        window.history.back();
    }

    return (
        <div className="text-center bg-gray-600 py-5">
            <h1 className="display-1">404</h1>
            <h2>Page not found</h2>

            <hr className="w-50" />

            <p className="lead">We're not able to find the page you requested.</p>
            <p>If you think you received this message in error, please let us know by emailing <a href="mailto:&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#110;&#111;&#116;&#105;&#102;&#121;&#100;&#046;&#099;&#111;&#109;">&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#110;&#111;&#116;&#105;&#102;&#121;&#100;&#046;&#099;&#111;&#109;</a>.</p>

            <Button variant="blue-200" className="text-gray-700 my-4" onClick={goBack}>Go Back</Button>
        </div>
    );
}

export default PageNotFound;
