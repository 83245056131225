import React from 'react';
import { Helmet } from 'react-helmet';

function App() {
    return (
        <>
            <Helmet>
                <title>Admin | Dashboard</title>
            </Helmet>

            <div className="p-4">

                <h1>Notifyd operators, we need your help!</h1>
                <p className="lead">Decide what the top 5 most important operations or metrics for the company are and we'll put them here.</p>
            </div>
        </>
    );
}

export default App;
