import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Papa from 'papaparse';
import { Alert, Badge, Form, Nav, Table, Modal, Button } from 'react-bootstrap';
import { default as RecipientFilters } from './Recipients/Filters';
import { default as PatientFilters } from './Patients/Filters';
import { default as TeamFilters } from './Teams/Filters';
import Pagination from '../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

const RECIPIENT_TAB = 0;
const PATIENT_TAB = 1;
const TEAM_TAB = 2;
const RECIPIENT_UPLOAD_TAB = 3;

const UPLOAD_BATCH_SIZE = 200;

export default function Picker(props) {
    const {
        branch,
        showRecipients, selectedRecipients, onSelectRecipient,
        showPatients, selectedPatients, onSelectPatient,
        showTeams, selectedTeams, onSelectTeam,
        showRecipientUpload,
        allowSelectPage
    } = props;

    const [ tab, setTab ] = useState(showRecipients ? RECIPIENT_TAB : showPatients ? PATIENT_TAB : showTeams ? TEAM_TAB : null);
    const [ recipientFilters, setRecipientFilters ] = useState({ search: '', hasLoggedIn: null, disabled: false });
    const [ patientFilters, setPatientFilters ] = useState({ search: '', assignedTo: null, disabled: false });
    const [ teamFilters, setTeamFilters ] = useState({ search: '', assignedTo: null, disabled: false });
    const [ contactState, setContactState ] = useState({ contacts: [], total: 0, error: '', loading: true });
    const [ pageNumber, setPageNumber ] = useState(0);
    const [ uploading, setUploading ] = useState(false);
    const [ uploadError, setUploadError ] =  useState(null);
    const [ selectChecked, setSelectChecked ] = useState(false)
    const [ showSelectPrompt, setShowSelectPrompt ] = useState(false)
    const pageSize = props.pageSize || 25;
    const history = useHistory();

    const getRecipientsByBranch = async (pageSize, pageNumber) => {
        return await API.get('AuthenticatedAPI', `/branches/${branch.id}/recipients`, { response: true, queryStringParameters: { ...recipientFilters, pageSize, pageNumber, expand: 'branch-recipient' } })
    }

    const getPatientsByBranch = async (pageSize, pageNumber) => {
        return await API.get('AuthenticatedAPI', `/branches/${branch.id}/patients`, { response: true, queryStringParameters: { ...patientFilters, pageSize, pageNumber, expand: 'patient-recipient' } })
    }

    const getTeamsByBranch = async (pageSize, pageNumber) => {
        return await API.get('AuthenticatedAPI', `/branches/${branch.id}/teams`, { response: true, queryStringParameters: { ...teamFilters, pageSize, pageNumber, expand: 'team-recipient' } })
    }

    useEffect(() => {
        setContactState({ contacts: [], total: 0, error: '', loading: true });

        let getEndpoint = null

        switch (tab) {
            case RECIPIENT_TAB:
                getEndpoint = getRecipientsByBranch
                break;
            case PATIENT_TAB:
                getEndpoint = getPatientsByBranch
                break;
            case TEAM_TAB:
                getEndpoint = getTeamsByBranch
                break;
            default:
                return
        }

        getEndpoint(pageSize, pageNumber).then(response => {
            setContactState({ contacts: response.data, total: response.headers['x-total-count'], loading: false, error: '' })
        }).catch(error => {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        history.push('/', {
                            message: 'Your session has expired. Please log back in to continue.',
                            redirect: history.location
                        });
                        break;
                    case 403:
                        history.push('/', {
                            message: 'You are not authorized to access Teams.',
                            redirect: history.location
                        });
                        break;
                    default:
                        setContactState({ contacts: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Teams for this Branch. The service returned error code ${error.response.status}` });
                        Sentry.captureException(error);
                        break;
                }
            } else {
                setContactState({ contacts: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Teams for this Branch. The service returned error message ${error.message}` });
                Sentry.captureException(error);
            }
        })
    }, [ branch.id, history, tab, recipientFilters, patientFilters, teamFilters, pageNumber, pageSize ])

    const uploadRecipients = (file) => {
        const lookupEmails = async (emails) => {
            let recipients = [];

            try {
                for (let i=0; i<emails.length/UPLOAD_BATCH_SIZE; i++) {
                    let emailSlice = emails.slice(i*UPLOAD_BATCH_SIZE, (i+1)*UPLOAD_BATCH_SIZE);
                    
                    recipients = recipients.concat(await API.get('AuthenticatedAPI', `/branches/${branch.id}/recipients`, { queryStringParameters : { emails: emailSlice.join(','), pageSize: emailSlice.length }}));
                }

                if (recipients.length < emails.length) {
                    alert(`Found ${recipients.length} out of ${emails.length} employees.`);
                }

                onSelectRecipient(recipients, true);
                setTab(RECIPIENT_TAB);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Recipients.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setUploadError(`We encountered an unexpected issue while retrieving Recipients for this Branch. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setUploadError(`We encountered an unexpected issue while retrieving Recipients for this Branch. The service returned error code ${error.response.status}`);
                    Sentry.captureException(error);
                }
            } finally {
                setUploading(false);
            }
        }

        setUploading(true);

        Papa.parse(file, {
            delimiter: ',',
            skipEmptyLines: true,
            dynamicTyping: true,
            header: true,
            transform: (value, index) => {
                if (value === 'null') {
                    return null;
                }

                return value;
            },
            transformHeader: (header, index) => {
              return header.replace('recipient.', '').toLowerCase();
            },
            complete: (results, file) => {
                let dedupeKey = employee => employee.email;
                let dedupedEmployees = [
                    ...new Map(
                        results.data
                            .filter(x => x.email)
                            .map(x => [dedupeKey(x), x])
                    ).values()
                ];
                
                // Check for email in file.
                if (dedupedEmployees.length > 0) {
                    lookupEmails(dedupedEmployees.map(x => x.email))
                } else  {
                    setUploadError("No emails found, please make sure your file has an email header");
                    setUploading(false);    
                } 
            }
        });
    };

    const handleSelectAll = checked => {
        switch (tab) {
            case RECIPIENT_TAB:
                getRecipientsByBranch(contactState.total, 0).then(results => {
                    const toggleRecipients = checked ?
                        results.data.filter(x => selectedRecipients.findIndex(y => y.id === x.id) === -1) :
                        results.data

                    onSelectRecipient(toggleRecipients, checked)
                })
                break
            case PATIENT_TAB:
                getPatientsByBranch(contactState.total, 0).then(results => {
                    const togglePatients = checked ?
                        results.data.filter(x => selectedPatients.findIndex(y => y.id === x.id) === -1) :
                        results.data

                    onSelectPatient(togglePatients, checked)
                })
                break
            case TEAM_TAB:
                getTeamsByBranch(contactState.total, 0).then(results => {
                    const toggleTeams = checked ?
                        results.data.filter(x => selectedTeams.findIndex(y => y.id === x.id) === -1) :
                        results.data

                    onSelectTeam(toggleTeams, checked)
                })
                break
            default:
                break
        }

        setShowSelectPrompt(false)
    }

    const handleSelectPage = checked => {
        switch (tab) {
            case RECIPIENT_TAB:
                const toggleRecipients = checked ?
                    contactState.contacts.filter(x => selectedRecipients.findIndex(y => y.id === x.id) === -1) :
                    contactState.contacts

                onSelectRecipient(toggleRecipients, checked);
                break;
            case PATIENT_TAB:
                const togglePatients = checked ?
                    contactState.contacts.filter(x => selectedPatients.findIndex(y => y.id === x.id) === -1) :
                    contactState.contacts

                onSelectPatient(togglePatients, checked);
                break;
            case TEAM_TAB:
                const toggleTeams = checked ?
                    contactState.contacts.filter(x => selectedTeams.findIndex(y => y.id === x.id) === -1) :
                    contactState.contacts
                
                onSelectTeam(toggleTeams, checked);
                break;
            default:
                break;
        }

        setShowSelectPrompt(false)
    };

    const selectContact = (contact, checked) => {
        switch (tab) {
            case RECIPIENT_TAB:
                onSelectRecipient(contact, checked);
                break;
            case PATIENT_TAB:
                onSelectPatient(contact, checked);
                break;
            case TEAM_TAB:
                onSelectTeam(contact, checked);
                break;
            default:
                break;
        }
    }

    let selectedContactsMap
    let showSelectPageChecked = false

    switch (tab) {
        case RECIPIENT_TAB:
            selectedContactsMap = selectedRecipients.reduce((obj, recipient) => Object.assign(obj, { [recipient.id]: true }), {})
            break
        case PATIENT_TAB:
            selectedContactsMap = selectedPatients.reduce((obj, patient) => Object.assign(obj, { [patient.id]: true }), {})
            break
        case TEAM_TAB:
            selectedContactsMap = selectedTeams.reduce((obj, team) => Object.assign(obj, { [team.id]: true }), {})
            break
        default:
            break
    }

    if (tab in [RECIPIENT_TAB, PATIENT_TAB, TEAM_TAB]) {
        showSelectPageChecked = contactState.contacts?.findIndex(contact => selectedContactsMap[contact.id] === undefined) === -1 ?? false
    }

    return (
        <>
            <Nav variant="tabs">
                {showRecipients && (
                    <Nav.Item>
                        <Nav.Link className={`small ${tab === RECIPIENT_TAB && 'active'}`} onClick={e => { setPageNumber(0); setTab(RECIPIENT_TAB); }}>
                            <i className="fas fa-user-tie fa-fw"></i> Employees <Badge variant="orange-200" className="text-gray-700">{selectedRecipients.length || ''}</Badge>
                        </Nav.Link>
                    </Nav.Item>
                )}

                {showPatients && (
                    <Nav.Item>
                        <Nav.Link className={`small ${tab === PATIENT_TAB && 'active'}`} onClick={e => { setPageNumber(0); setTab(PATIENT_TAB); }}>
                            <i className="fas fa-user-injured fa-fw"></i> Care Teams <Badge variant="orange-200" className="text-gray-700">{selectedPatients.length || ''}</Badge>
                        </Nav.Link>
                    </Nav.Item>
                )}

                {showTeams && (
                    <Nav.Item>
                        <Nav.Link className={`small ${tab === TEAM_TAB && 'active'}`} onClick={e => { setPageNumber(0); setTab(TEAM_TAB); }}>
                            <i className="fas fa-users fa-fw"></i> Teams <Badge variant="orange-200" className="text-gray-700">{selectedTeams.length || ''}</Badge>
                        </Nav.Link>
                    </Nav.Item>
                )}

                {showRecipientUpload && (
                    <Nav.Item>
                        <Nav.Link className={`small ${tab === RECIPIENT_UPLOAD_TAB && 'active'}`} onClick={e => setTab(RECIPIENT_UPLOAD_TAB)}>
                            <i className="fas fa-file-upload fa-fw"></i> Select from File
                        </Nav.Link>
                    </Nav.Item>
                )}
            </Nav>

            {showRecipients && tab === RECIPIENT_TAB && (
                <RecipientFilters onSearch={filters => { setPageNumber(0); setRecipientFilters(filters); }} showOfficeGroup={branch.officeGroup} showHasLoggedIn={branch.canManageEmployees} loading={contactState.loading} />
            )}

            {showPatients && tab === PATIENT_TAB && (
                <PatientFilters onSearch={filters => { setPageNumber(0); setPatientFilters(filters); }} showAssignedTo={true} loading={contactState.loading} />
            )}

            {showTeams && tab === TEAM_TAB && (
                <TeamFilters onSearch={filters => { setPageNumber(0); setTeamFilters(filters); }} showAssignedTo={true} loading={contactState.loading} />
            )}

            {showRecipientUpload && tab === RECIPIENT_UPLOAD_TAB ? (
                <div className="bg-white p-4 border border-top-0 gray-600">
                    {uploading ? (
                        <div key="uploading">
                            <i className="fas fa-spinner fa-fw fa-lg fa-spin" />
                        </div>
                    ) : (
                        <Form.Group controlId="fileUpload">
                            <Form.Label>Upload a CSV of Employee Emails</Form.Label>
                            <p className="small">Make sure your CSV file contains a column with "email" in the header.</p>
                            

                            <Form.File className="custom-file">
                                <Form.File.Input className="custom-file-input" onChange={e => uploadRecipients(e.target.files[0]) } />
                                <Form.File.Label className="custom-file-label">Choose File (.csv only)</Form.File.Label>
                            </Form.File>
                        </Form.Group>
                    )}

                    {Boolean(uploadError) && (
                        <Alert variant="orange-200">{uploadError}</Alert>
                    )}
                </div>
            ) : (
                <>
                    <Table striped bordered hover className="bg-white">
                        <thead className="bg-gray-200 text-white small font-weight-normal">
                            <tr>
                                <th width={allowSelectPage ? 122 : 1} className="text-right">
                                    {allowSelectPage && (
                                        <Form.Check type="checkbox" checked={showSelectPageChecked} onChange={e => {
                                            setSelectChecked(e.target.checked)
                                            setShowSelectPrompt(true)
                                        }} />
                                    )}
                                </th>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contactState.loading ? (
                                <tr key="loading">
                                    <td colSpan="2" className="text-center">
                                        <i className="fas fa-spinner fa-fw fa-lg fa-spin" />
                                    </td>
                                </tr>
                            ) : contactState.contacts.length === 0 ? (
                                <tr key="none">
                                    <td colSpan="2" className="text-center">
                                        No results
                                    </td>
                                </tr>
                            ) : contactState.contacts.map(x =>
                                <tr key={x.id} onClick={() => selectContact(x, !selectedContactsMap[x.id])}>
                                    <td className="text-right"><Form.Check type="checkbox" checked={selectedContactsMap[x.id] || false} readOnly /></td>
                                    <td className="d-flex justify-content-between">
                                        <div>{x.name}</div>
                                        {Boolean(x.doNotDisturb) && (
                                            <div><i className="fas fa-bell-slash fa-fw text-orange-200" /></div>
                                        )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    <Pagination total={contactState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page =>setPageNumber(page)} />

                    <Modal show={showSelectPrompt} onHide={() => {
                        setShowSelectPrompt(false)
                    }}>
                        <Modal.Header className="bg-blue-100 text-gray-700">
                            <Modal.Title className="p font-weight-medium">How would you like to proceed?</Modal.Title>

                            <button className="close text-gray-700" onClick={() => {
                                setShowSelectPrompt(false)
                            }}><i className="fas fa-times fa-sm" /></button>
                        </Modal.Header>

                        <Modal.Body>
                            <p className="small">Do you want to {selectChecked ? 'select' : 'deselect'} all pages that match this filter or are you only trying to {selectChecked ? 'select' : 'deselect'} the current page?</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="green-500" size="sm" className="font-weight-medium" onClick={() => handleSelectPage(selectChecked)}>{selectChecked ? 'Select' : 'Deselect'} Current Page</Button>
                            <Button variant="orange-200" size="sm" className="font-weight-medium" onClick={() => handleSelectAll(selectChecked)}>{selectChecked ? 'Select' : 'Deselect'} All Pages</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )}
        </>
    );
}