import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from './Card';
import Recipients from './Recipients';
import Pagination from '../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

const PAGE_SIZE = 25;

function Details(props) {
    const { notificationId } = props;

    const [ notificationState, setNotificationState ] = useState({ notification: null, error: '', loading: true });
    const [ recipientsState, setRecipientsState ] = useState({ recipients: null, total: 0, error: '', loading: false });
    const [ print, setPrint ] = useState(false);
    const [ printReady, setPrintReady ] = useState(false);
    const [ pageNumber, setPageNumber ] = useState(0);
    const [ pageSize, setPageSize ] = useState(PAGE_SIZE);

    const history = useHistory();

    useEffect(() => {
        const getNotification = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/notifications/${notificationId}`, { queryStringParameters: { expand: 'sending-user' } });

                setNotificationState({ notification: response, error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setNotificationState({ notification: null, loading: false, error: error.response.data.message });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Notification.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setNotificationState({ notification: null, loading: false, error: `We encountered an unexpected issue while retrieving Notification. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setNotificationState({ notification: null, loading: false, error: `We encountered an unexpected issue while retrieving Notification. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        getNotification();
    }, [ history, notificationId ]);

    useEffect(() => {
        const getRecipientsByNotification = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/notifications/${notificationId}/recipients`, { response: true, queryStringParameters: { expand: 'notification-recipient', pageSize, pageNumber } });

                setRecipientsState({ recipients: response.data, total: response.headers['x-total-count'], error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setNotificationState({ recipients: null, total: 0, loading: false, error: error.response.data.message });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Notification for this Branch.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setNotificationState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Recipients for this Notification. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setNotificationState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Recipients for this Notification. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setRecipientsState({ recipients: null, total: 0, loading: true, error: '' });
        getRecipientsByNotification().then(() => {
            if (print) {
                setPrintReady(true);
            }
        });
    }, [ history, notificationId, pageNumber, pageSize, print ]);

    useEffect(() => {
        if (printReady) {
            window.print();
            setPageSize(PAGE_SIZE);
            setPrint(false);
            setPrintReady(false);
        }
    }, [ printReady ]);

    return (
        <>
            <Helmet>
                <title>Employee | Notification Recipients</title>
            </Helmet>

            {notificationState.loading ? (
                <h1 className="mt-3"><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>
            ) : notificationState.error !== '' ? (
                <Alert variant="orange-200" className="small">{notificationState.error}</Alert>
            ) : (
                <Card className="mt-3" notification={notificationState.notification} />
            )}

            {notificationState.notification !== null && recipientsState.total > 0 && (
                <Row className="justify-content-end my-3">
                    <Col xs="auto">
                        <Button variant="blue-300" size="sm" className="text-gray-700" onClick={() => { setPageNumber(0); setPageSize(recipientsState.total); setPrint(true); }}><i className="fas fa-print fa-fw" /> Print</Button>
                    </Col>
                </Row>
            )}

            <Recipients notification={notificationState.notification} recipients={recipientsState.recipients} />

            {recipientsState.total > 0 && (
                <Pagination total={recipientsState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
            )}
        </>
    );
}

export default Details;
