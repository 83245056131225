import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Alert, Button } from 'react-bootstrap'
import { default as ChatButton } from '../Chat/Button'
import { default as CallButton } from '../Call/Button'
import { Auth, API } from 'aws-amplify'
import * as Sentry from '@sentry/react'

function Recipients(props) {
    const { branch, patientId, teamId } = props;

    const [ recipientsState, setRecipientsState ] = useState({ recipients: null, total: 0, loading: false, error: '' });

    const pageNumber = 0;
    const pageSize = 500;
    const history = useHistory();

    useEffect(() => {
        const getRecipientsByPatient = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/patients/${patientId}/recipients`, { response: true, queryStringParameters : { expand: 'branch-recipient', pageSize, pageNumber }});

                setRecipientsState({ recipients: response.data, total: response.headers['x-total-count'], loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient');
                            break;
                        default:
                            setRecipientsState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Recipients for this Patient. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setRecipientsState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Recipients for this Patient. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        const getRecipientsByTeam = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/teams/${teamId}/recipients`, { response: true, queryStringParameters : { expand: 'branch-recipient', pageSize, pageNumber }});

                setRecipientsState({ recipients: response.data, total: response.headers['x-total-count'], loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient');
                            break;
                        default:
                            setRecipientsState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Recipients for this Team. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setRecipientsState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Recipients for this Team. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setRecipientsState({ recipients: null, total: 0, loading: true, error: '' });

        if (patientId) {
            getRecipientsByPatient();
        } else if (teamId) {
            getRecipientsByTeam();
        }
    }, [ history, patientId, teamId, pageNumber ]);

    if (recipientsState.loading) {
        return <h1 className="text-center py-3"><i className="fas fa-spinner fa-fw fa-spin" /></h1>
    }

    if (recipientsState.error) {
        return <Alert variant="orange-200">{recipientsState.error}</Alert>
    }

    if (recipientsState.recipients === null) {
        return null;
    }

    const canEdit = (patientId && branch.canManagePatients) || (teamId && branch.canManageTeams);

    const renderRecipient = recipient => {
        if (recipient.id === Auth.user.username && !canEdit) {
            return <Button key={recipient.id} variant="link" block className="small text-left text-truncate rounded-0 bg-white border-gray-600 border-left-0 border-right-0 border-top-0" disabled={true}>{recipient.name}</Button>
        }

        return (
            <div key={recipient.id} className="d-flex justify-content-between align-items-center bg-white p-2 border-bottom border-gray-600" >
                <div className="small text-gray-100 text-truncate">{recipient.name}</div>

                {recipient.id !== Auth.user.username ? (
                    <>
                        {(branch.officeGroup || recipient.officeGroup || branch.canViewFieldEmployees) && (
                            <div>
                                <ChatButton recipient={recipient} size="sm" />
                                <CallButton recipient={recipient} size="sm" />
                            </div>
                        )}
                    </>
                ) : (
                    <Button size="sm" style={{ visibility: 'hidden' }}>&nbsp;</Button>
                )}
            </div>
        );
    };

    let officeRecipients = recipientsState.recipients.filter(recipient => recipient.officeGroup).map(recipient => renderRecipient(recipient));
    let fieldRecipients = recipientsState.recipients.filter(recipient => !recipient.officeGroup).map(recipient => renderRecipient(recipient));

    return (
        <>
            {officeRecipients.length > 0 && (
                <>
                    <div className="bg-blue-300 text-white text-center small font-weight-medium p-2">
                        <i className="fas fa-building fa-fw" /> Office Employees
                    </div>

                    {officeRecipients}
                </>
            )}

            {fieldRecipients.length > 0 && (
                <>
                    <div className="bg-blue-300 text-white text-center small font-weight-medium p-2">
                        <i className="fas fa-user-nurse fa-fw" /> Field Employees
                    </div>

                    {fieldRecipients}
                </>
            )}

            {canEdit && (
                <div className="w-100 p-2">
                    <Button as={Link} variant="green-500" size="sm" block className="text-gray-700" href={`/recipient/contacts/${patientId ? `patients/${patientId}` : `teams/${teamId}`}`} to={`/recipient/contacts/${patientId ? `patients/${patientId}` : `teams/${teamId}`}`}><i className="fas fa-edit fa-fw" /> Edit {patientId && 'Care '}Team</Button>
                </div>
            )}
        </>
    );
}

export default Recipients;
