import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { default as BootstrapTable } from 'react-bootstrap/Table';

function Table(props) {
    return (
        <>
            <Row className="smaller justify-content-end">
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-edit fa-fw"></i> Edit Partner
                </Col>
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-building fa-fw"></i> View Companies
                </Col>
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-code-branch fa-fw"></i> View Branches
                </Col>
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-project-diagram fa-fw"></i> View Webhooks
                </Col>
            </Row>

            <BootstrapTable responsive striped bordered hover className="mt-3 bg-white">
                <thead className="bg-gray-200 text-white small font-weight-normal">
                    <tr>
                        <th>Partner</th>
                        <th width="191"></th>
                    </tr>
                </thead>
                <tbody>
                    {props.partners === null ? (
                        <tr key="loading">
                            <td colSpan="3" className="text-center">
                                <i className="fas fa-spinner fa-fw fa-lg fa-spin" />
                            </td>
                        </tr>
                    ) : props.partners.length === 0 ? (
                        <tr key="none">
                            <td colSpan="3" className="text-center">
                                No results found
                            </td>
                        </tr>
                    ) : props.partners.map(partner =>
                        <tr key={partner.id}>
                            <td>
                                {partner.name}<br />
                                <small className="smaller text-muted">{partner.id}</small>
                            </td>
                            <td className="align-middle">
                                <Button as={Link} variant="link" size="sm" href={`/admin/partners/${partner.id}`} to={`/admin/partners/${partner.id}`} className="text-blue-300" title="Edit Partner"><i className="fas fa-edit fa-fw fa-lg"></i></Button>
                                <Button as={Link} variant="link" size="sm" href={`/admin/partners/${partner.id}/companies`} to={`/admin/partners/${partner.id}/companies`} className="text-blue-300" title="View Companies"><i className="fas fa-building fa-fw fa-lg"></i></Button>
                                <Button as={Link} variant="link" size="sm" href={`/admin/partners/${partner.id}/branches`} to={`/admin/partners/${partner.id}/branches`} className="text-blue-300" title="View Branches"><i className="fas fa-code-branch fa-fw fa-lg"></i></Button>
                                <Button as={Link} variant="link" size="sm" href={`/admin/partners/${partner.id}/webhooks`} to={`/admin/partners/${partner.id}/webhooks`} className="text-blue-300" title="View Webhooks"><i className="fas fa-project-diagram fa-fw fa-lg"></i></Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </BootstrapTable>
        </>
    );
}

export default Table;
