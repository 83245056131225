import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { default as BootstrapTable } from 'react-bootstrap/Table';

function Table(props) {
    
    return (
        <>
            <Row className="smaller justify-content-end">
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-edit fa-fw"></i> Edit Company
                </Col>
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-code-branch fa-fw"></i> View Branches
                </Col>
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fa fa-users fa-fw"></i> View Recipients
                </Col>
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-handshake fa-fw"></i> View Partners
                </Col>
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fab fa-cc-stripe fa-fw"></i> View Billing
                </Col>
            </Row>

            <BootstrapTable responsive striped bordered hover className="mt-3 bg-white">
                <thead className="bg-gray-200 text-white small font-weight-normal">
                    <tr>
                        <th>Company</th>
                        <th>Account Owner</th>
                        <th width="232"></th>
                    </tr>
                </thead>
                <tbody>
                    {props.companies === null ? (
                        <tr key="loading">
                            <td colSpan="3" className="text-center">
                                <i className="fas fa-spinner fa-fw fa-lg fa-spin" />
                            </td>
                        </tr>
                    ) : props.companies.length === 0 ? (
                        <tr key="none">
                            <td colSpan="3" className="text-center">
                                No results found
                            </td>
                        </tr>
                    ) : props.companies.map(company =>
                        <tr key={company.id}>
                            <td>
                                {company.name}<br />
                                <small className="smaller text-muted">{company.id}</small>
                            </td>
                            <td>
                                {company.contactName}<br />
                                <a href={`mailto:${company.contactEmail}`} className="small">{company.contactEmail}</a>
                                
                                {company.contactPhone && (
                                    <>
                                    <br />
                                <a href={`tel:${company.contactPhone}`} className="small">{company.contactPhone}</a>
                                </>
                                )}
                                
                            </td>
                            
                            <td className="align-middle">
                                <Button as={Link} variant="link" size="sm" href={`/admin/companies/${company.id}`} to={`/admin/companies/${company.id}`} className="text-blue-300" title="Edit Company"><i className="fas fa-edit fa-fw fa-lg"></i></Button>
                                <Button as={Link} variant="link" size="sm" href={`/admin/companies/${company.id}/branches`} to={`/admin/companies/${company.id}/branches`} className="text-blue-300" title="View Branches"><i className="fas fa-code-branch fa-fw fa-lg"></i></Button>
                                <Button as={Link} variant="link" size="sm" href={`/admin/companies/${company.id}/recipients`} to={`/admin/companies/${company.id}/recipients`} className="text-blue-300" title="View Recipients"><i className="fa fa-users fa-fw fa-lg"></i></Button>
                                <Button as={Link} variant="link" size="sm" href={`/admin/companies/${company.id}/partners`} to={`/admin/companies/${company.id}/partners`} className="text-blue-300" title="View Partners"><i className="fas fa-handshake fa-fw fa-lg"></i></Button>
                                <Button variant="link" size="sm" href={`https://dashboard.stripe.com/customers/${company.stripeCustomerId}`} className="text-blue-300" title="View Billing" target="_blank" rel="noopener noreferrer"><i className="fab fa-cc-stripe fa-fw fa-lg"></i></Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </BootstrapTable>
        </>
    );
}

export default Table;
