import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { default as BootstrapTable } from 'react-bootstrap/Table';

function Table(props) {
    return (
        <>
            <BootstrapTable responsive striped bordered hover className="mt-3 bg-white">
                <thead className="bg-gray-200 text-white small font-weight-normal">
                    <tr>
                        <th>Webhook</th>
                        <th>Auth</th>
                        <th width="1"></th>
                    </tr>
                </thead>
                <tbody>
                    {props.webhooks === null ? (
                        <tr key="loading">
                            <td colSpan="3" className="text-center">
                                <i className="fas fa-spinner fa-fw fa-lg fa-spin" />
                            </td>
                        </tr>
                    ) : props.webhooks.length === 0 ? (
                        <tr key="none">
                            <td colSpan="3" className="text-center">
                                No results found
                            </td>
                        </tr>
                    ) : props.webhooks.map(webhook =>
                        <tr key={webhook.eventType}>
                            <td>
                                {webhook.eventType}<br />
                                <small className="smaller text-muted">{webhook.url}</small>
                            </td>
                            <td>
                                {webhook.authType}
                            </td>
                            <td>
                                <Button as={Link} variant="link" size="sm" className="text-blue-300" to={`/partner/web-hooks/${webhook.eventType}`} href={`/partners/web-hooks/${webhook.eventType}`}>
                                    <i className="fas fa-edit fa-fw fa-lg"></i>
                                </Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </BootstrapTable>
        </>
    );
}

export default Table;
