const getFileIcon = fileExtension => {
    switch (fileExtension) {
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'png':
            return 'fa-file-image';
        case 'csv':
        case 'tsv':
        case 'xls':
        case 'xlsx':
        case 'xlsm':
        case 'xlt':
        case 'xltx':
        case 'xltm':
        case 'xlm':
            return 'fa-file-excel';
        case 'txt':
        case 'doc':
        case 'docx':
        case 'rtf':
            return 'fa-file-word';
        case 'pdf':
            return 'fa-file-pdf';
        default:
            return 'fa-file';
    }
};

module.exports = getFileIcon;
