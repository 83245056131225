import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Form from './Form';
import { API, Auth } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import { publishableKey } from './../../../config/Stripe';

const stripePromise = loadStripe(publishableKey);

function Details(props) {
    const [ branch, setBranch ] = useState(null);
    const [ error, setError ] = useState('');
    const branchId = props.branchId;

    const history = useHistory();
    const isCustomer = branch && branch.primaryUserId === Auth.user.username;

    useEffect(() => {
        const getBranch = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branchId}`, { queryStringParameters : { expand: 'subscription' }});

                setBranch(response);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setError(error.response.data.message);
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Branch.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/recipient');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving this Branch. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving this Branch. The service returned error message ${error.message}`);
                    Sentry.captureException(error);
                }
            }
        };
        
        setError('');
        getBranch();
    }, [ branchId, history ]);

    if (branch === null) {
        return (
            <div className="p-4">
                {error === '' ? (
                    <h1><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>
                ) : (
                    <Alert variant="orange-200" className="small">{error}</Alert>
                )}
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Account | Update Settings</title>
            </Helmet>

            <div className="p-4">
                <h1>Settings</h1>

                <div className="bg-white p-4 border border-gray-600 rounded">
                    <Row>
                        <Col xs="12" lg="4">
                            <h4>{branch.name} Settings</h4>
                        </Col>

                        <Col xs="12" sm="6" lg={true}>
                            <p className="small">
                                {branch.subscription ? (
                                    <>
                                        <strong>Created:</strong> {new Date(branch.subscription.created * 1000).toLocaleDateString()}
                                        <br/>

                                        {branch.subscription.canceled_at ? (
                                            <>
                                                <strong>Cancelled:</strong> {new Date(branch.subscription.canceled_at * 1000).toLocaleDateString()}
                                                <br/>
                                                <strong>End of Service:</strong> {new Date(branch.subscription.current_period_end * 1000).toLocaleDateString()}
                                            </>
                                        ) : (
                                            <>
                                                <strong>Renews On:</strong> {new Date(branch.subscription.current_period_end * 1000).toLocaleDateString()}
                                            </>
                                        )}

                                        {branch.subscription.trial_end && (
                                            <>
                                                <br />
                                                <strong>Trial Ends:</strong> {new Date(branch.subscription.trial_end * 1000).toLocaleDateString()}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <strong className="text-gray-400">Unsubscribed</strong>
                                )}
                            </p>
                        </Col>

                        {isCustomer && (
                            <Col xs="12" sm="6" lg="auto">
                                <Button as={Link} variant="green-500" size="sm" block className="text-gray-700" to="/recipient/settings/new" href="/recipient/settings/new"><i className="fas fa-cart-plus"></i> New Subscription</Button>
                            </Col>
                        )}
                    </Row>
                </div>

                <hr />

                <Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:300"' }] }}>
                    <Form branch={branch} />
                </Elements>
            </div>
        </>
    );
}

export default Details;
