import React from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import adminContract from './contracts/admin.yaml';
import partnerContract from './contracts/partner.yaml';
import individualContract from './contracts/individual.yaml';

function ApiDocs(props) {
    const history = useHistory();
    let contract;

    switch (props.group) {
        case 'admin':
            contract = adminContract;
            break;
        case 'partner':
            contract = partnerContract;
            break;
        case 'individual':
            contract = individualContract;
            break;
        default:
            history.push('/', {
                redirect: history.location
            });
            break;
    }

    return (
        <>
            <Helmet>
                <title>API Docs</title>
                <script src="https://cdn.jsdelivr.net/npm/redoc@next/bundles/redoc.standalone.js"></script>
            </Helmet>

            <redoc spec-url={contract}></redoc>
        </>
    );
}

export default ApiDocs;
