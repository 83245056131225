import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Recent from './Recent';
import Room from './Room';
import Recipients from './Recipients';
import './App.scss';
import { chatNavigation, chatMenu } from './App.module.scss';

function App(props) {
    const { branch, unread, recipient, patient, team } = props;

    const [ showRecipients, setShowRecipients ] = useState(false);

    if (!recipient && !patient && !team) {
        return <Redirect to="/recipient" />
    }

    let targetId = recipient ? recipient.id : patient ? `patient_${patient.id}` : team ? `team_${team.id}` : null;
    let roomName = recipient ? recipient.name : patient ? `Care team for ${patient.name}` : team ? team.name : null;

    return (
        <>
            <Helmet>
                <title>Employee | Chat with {roomName}</title>
            </Helmet>

            <Row noGutters={true}>
                <Col xs="auto" className={`${chatNavigation} d-none d-lg-block mh-100v min-vh-100 overflow-auto border-right border-gray-600`}>
                    <Recent branchId={branch.id} unread={unread} currentTargetId={targetId} />
                </Col>

                <div className="col">
                    <Room key={`${recipient && recipient.id}-${patient && patient.id}-${team && team.id}`} branchId={branch.id} roomName={roomName} recipientId={recipient && recipient.id} patientId={patient && patient.id} teamId={team && team.id} onShowRecipients={(patient || team) && (() => setShowRecipients(!showRecipients))} archived={(recipient && recipient.disabled) || (patient && (!patient.chatEnabled || patient.disabled)) || (team && (!team.chatEnabled || team.disabled))} />
                </div>

                {(patient || team) && (
                    <Col xs="auto" className={`${chatMenu} responsive-chat-panel mh-100v overflow-auto text-break bg-gray-700 border-left border-gray-600 ${!showRecipients && 'd-none'}`}>
                        <Recipients branch={branch} patientId={patient && patient.id} teamId={team && team.id} />
                    </Col>
                )}
            </Row>
        </>
    );
}

export default App;
