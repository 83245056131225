import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { default as BootstrapForm } from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Form(props) {
    const [ name, setName ] = useState(props.company.name);
    const [ negotiatedRate, setNegotiatedRate ] = useState(props.company.negotiatedRate);
    const [ primaryUserId, setPrimaryUserId ] = useState(props.company.primaryUserId);
    const [ doNotTextCredentials, setDoNotTextCredentials ] = useState(props.company.doNotTextCredentials);
    const [ formState, setFormState ] = useState({ message: '', error: '', loading: false })
    const { contactName } = props.company;;
    const history = useHistory();

    const formSubmit = evt => {
        evt.preventDefault();

        const putCompany = async () => {
            try {
                await API.put('AuthenticatedAPI', `/companies/${props.company.id}`, { body: { name, negotiatedRate, primaryUserId, doNotTextCredentials }});

                setFormState({ message: 'Successfully updated Company.', error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ message: '', error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to edit this Company. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/admin/companies');
                            break;
                        default:
                            setFormState({ message: '', error: `We encountered an unexpected issue while retrieving Companies. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ message: '', error: `We encountered an unexpected issue while retrieving Companies. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        setFormState({ message: '', error: '', loading: true })
        putCompany();
    };

    return (
        <BootstrapForm onSubmit={formSubmit}>
            <div className="bg-white rounded border border-gray-500 p-3 my-3">
                <Row>
                    <Col xs="12" sm="6" xl="4">
                        <fieldset>
                            <legend>Company Details</legend>

                            <BootstrapForm.Group controlId="name">
                                <BootstrapForm.Label>Name</BootstrapForm.Label>
                                <BootstrapForm.Control value={name} required onChange={e => setName(e.target.value)} />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group controlId="negotiatedRate">
                                <BootstrapForm.Label>Negotiated Rate</BootstrapForm.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text className="font-weight-medium smaller">Will be charged</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <BootstrapForm.Control type="number" min="0" max="100" step=".01" required className="text-right" value={negotiatedRate || 100} onChange={e => setNegotiatedRate(e.target.value)} />
                                    <InputGroup.Append>
                                        <InputGroup.Text className="font-weight-medium smaller">%</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </BootstrapForm.Group>
                        </fieldset>
                    </Col>

                    <Col xs="12" sm="6" xl="4">
                        <fieldset>
                            <legend>Account Owner</legend>

                            <BootstrapForm.Group controlId="primaryUserId">
                                <BootstrapForm.Label>Primary User ID</BootstrapForm.Label>
                                <Link href={`/admin/recipients/${primaryUserId}`} to={`/admin/recipients/${primaryUserId}`} className="small float-right"><i className="fas fa-user fa-fw" />{contactName}</Link>
                                <BootstrapForm.Control value={primaryUserId} onChange={e => setPrimaryUserId(e.target.value)} />
                            </BootstrapForm.Group>
                        </fieldset>
                    </Col>

                    <Col xs="12" xl="4">
                        <fieldset>
                            <legend>Preferences</legend>

                            <BootstrapForm.Group controlId="doNotTextCredentials">
                                <BootstrapForm.Label>Should text invites include credentials?</BootstrapForm.Label>
                                <BootstrapForm.Check type="switch" label="Yes" checked={!doNotTextCredentials} onChange={e => setDoNotTextCredentials(!e.target.checked)} />
                            </BootstrapForm.Group>
                        </fieldset>
                    </Col>
                </Row>
            </div>

            {formState.message !== '' && (
                <Alert variant="green-500" className="small">{formState.message}</Alert>
            )}

            {formState.error !== '' && (
                <Alert variant="orange-200" className="small">{formState.error}</Alert>
            )}

            <Button type="submit" variant="green-500" className="text-white" disabled={formState.loading}>Save</Button>
        </BootstrapForm>
    );
}

export default Form;
