import React, { useState } from 'react';
import Linkify from 'react-linkify';
import { ButtonGroup, Button, Toast } from 'react-bootstrap'
import { days, months } from '../TemporalAbbreviations';
import getFileIcon from '../FileIcons';
import { Auth } from 'aws-amplify';
import { chatMessage } from './Message.module.scss';

function Message(props) {
    const { message, previousMessage, showName, alwaysShowTimestamp, hourlyFormat, onDeleteMessage, getFile } = props;
    const [hover, setHover] = useState(false)
    const [showCopyToast, setShowCopyToast] = useState(false)

    let date, time;
    let showDate = true;
    let switchedUsers = false;
    let createdOnDate = new Date(message.createdOn);
    let fileExt;

    if (previousMessage) {
        let prevCreatedOnDate = new Date(previousMessage.createdOn);

        showDate = Math.abs(createdOnDate - prevCreatedOnDate) > 1000 * 60 * 60;
        switchedUsers = message.sendingRecipientId !== previousMessage.sendingRecipientId;
    }

    if (alwaysShowTimestamp) {
        showDate = true
    }

    if (showDate) {
        let currentDate = new Date();
        date = (
            currentDate.getFullYear() === createdOnDate.getFullYear() &&
            currentDate.getMonth() === createdOnDate.getMonth() &&
            currentDate.getDate() === createdOnDate.getDate()
            ? 'Today'
            : `${days[createdOnDate.getDay()]}, ${months[createdOnDate.getMonth()]} ${createdOnDate.getDate()}`
        );

        time = `, ${createdOnDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: !hourlyFormat })}`
    }

    if (message.fileId) {
        fileExt = message.fileId.split('.').pop();
    }

    const handleCopy = message => {
        navigator.clipboard.writeText(message.message)
        setShowCopyToast(true)
    }

    const handleDelete = message => {
        if (window.confirm('Are you sure you want to delete this message?')) {
            onDeleteMessage(message)
        }
    }

    return (
        <>
            {showDate && (
                <div className="smaller text-gray-400 text-center font-weight-light mt-2 py-1 border-gray-700 border-top">{date}{time}</div>
            )}

            {showName && (switchedUsers || showDate) && message.sendingRecipientId !== Auth.user.username && (
                <div className="small font-weight-semibold text-gray-400" style={{ paddingRight: 5 }}>{message.sendingRecipientName}</div>
            )}

            <div
                className={`d-flex flex-column position-relative ${hover && 'bg-gray-700'}`}
                style={{ marginTop: (switchedUsers && !showDate) ? 5 : 1 }}
                onMouseEnter={e => setHover(true)}
                onMouseLeave={e => setHover(false)}
            >
                <div className={`${chatMessage} p-2 rounded text-break small font-weight-medium ${message.sendingRecipientId === Auth.user.username ? `align-self-end text-right text-gray-700 ${message.localMessageId ? 'bg-blue-600' : 'bg-blue-300'}` : 'align-self-start bg-gray-600'}`} style={{ whiteSpace: 'pre-line' }}>
                    {message.fileId ? (
                        <button className="bg-transparent border-0 text-reset p-0" onClick={() => getFile(message.fileId)}>
                            <u><i className={`fas fa-fw ${getFileIcon(fileExt)}`} />{message.message}</u>
                        </button>
                    ) : (
                        <Linkify componentDecorator={(href, text, key) => (
                            <a href={href} key={key} target="_blank" rel="noopener noreferrer" className="text-reset"><u>{text}</u></a>
                        )}>{message.message}</Linkify>
                    )}
                </div>

                {hover && (
                    <ButtonGroup size="sm" className="text-gray-400 position-absolute" style={{ top: -20, right: 5 }}>
                        <Button variant="gray-600" onClick={() => handleCopy(message)} title="Copy message">
                            <i className="fas fa-paste fa-fw" />
                        </Button>
                        {Auth.user.username === message.sendingRecipientId && (
                            <Button variant="orange-200" title="Delete message" onClick={() => handleDelete(message)}>
                                <i className="fas fa-trash fa-fw" />
                            </Button>
                        )}
                    </ButtonGroup>
                )}
            </div>

            <Toast onClose={() => setShowCopyToast(false)} show={showCopyToast} delay={3000} autohide className="position-absolute border bg-gray-700 border-green-500" style={{ top: 5, right: 5 }}>
                <Toast.Body className="small"><i className="fas fa-info-circle fa-fw" /> Message copied to clipboard</Toast.Body>
            </Toast>
        </>
    );
}

export default Message;
