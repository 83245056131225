import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import ApiDocs from './ApiDocs';
import { default as AdminApp } from './Admin/App';
import { default as PartnerApp } from './Partner/App';
import { default as RecipientApp } from './Recipient/App';
import './App.scss';

function App() {
    const [ state, setState ] = useState({ user: null, loading: true });
    const history = useHistory();

    useEffect(() => {
        const getUser = async () => {
            try {
                let data = await Auth.currentAuthenticatedUser();

                setState({ user: data, loading: false });
            } catch (error) {
                history.push('/', {
                    redirect: history.location
                });
            }
        };

        getUser();
    }, [ history ]);

    const logout = async (evt) => {
        await Auth.signOut();

        history.push('/');
    };

    if (state.loading) {
        return (
            <div key="loading" className="p-4">
                <i className="fas fa-spinner fa-5x fa-spin" />
            </div>
        );
    }

    const group = state.user.signInUserSession.idToken.payload['cognito:groups'][0];

    return (
        <Switch>
            <Route path="/api-docs">
                <ApiDocs group={group} />
            </Route>
            <Route path="/admin">
                <AdminApp group={group} onLogout={logout} />
            </Route>
            <Route path="/partner">
                <PartnerApp group={group} onLogout={logout} />
            </Route>
            <Route path="/recipient">
                <RecipientApp group={group} onLogout={logout} />
            </Route>
        </Switch>
    );
}

export default App;
