import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { default as UnauthenticatedApp } from './Unauthenticated/App';
import { default as AuthenticatedApp } from './Authenticated/App';
import ScrollToTop from './ScrollToTop';
import './App.scss';

function App() {
    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route path={['/api-docs', '/admin', '/partner', '/recipient']} component={AuthenticatedApp} />
                <Route component={UnauthenticatedApp} />
            </Switch>
        </Router>
    );
}

export default App;
