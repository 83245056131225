import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import RecipientSummary from './../Branches/RecipientSummary';
import NotificationSummary from './../Branches/NotificationSummary';
import ChatSummary from './../Branches/ChatSummary';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Reports(props) {
    const [ recipientSummary, setRecipientSummary ] = useState({ summary: null, loading: true, error: '' });
    const [ notificationSummary, setNotificationSummary ] = useState({ summary: null, loading: true, error: '' });
    const [ chatSummary, setChatSummary ] = useState({ summary: null, loading: true, error: '' });
    const [ downloading, setDownloading ] = useState(false);

    const history = useHistory();
    const branchId = props.branch.id;

    useEffect(() => {
        const getRecipientSummary = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branchId}/recipient-summary`);

                setRecipientSummary({ summary: response, loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setRecipientSummary({ summary: null, loading: false, error: error.response.data.message });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Recipient Summary.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setRecipientSummary({ summary: null, loading: false, error: `We encountered an unexpected issue while retrieving this Recipient Summary. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setRecipientSummary({ summary: null, loading: false, error: `We encountered an unexpected issue while retrieving this Recipient Summary. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        const getNotificationSummary = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branchId}/notification-summary`);

                setNotificationSummary({ summary: response, loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setNotificationSummary({ summary: null, loading: false, error: error.response.data.message });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Notification Summary.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setNotificationSummary({ summary: null, loading: false, error: `We encountered an unexpected issue while retrieving this Notification Summary. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setNotificationSummary({ summary: null, loading: false, error: `We encountered an unexpected issue while retrieving this Notification Summary. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        const getChatSummary = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branchId}/chat-summary`);

                setChatSummary({ summary: response, loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setChatSummary({ summary: null, loading: false, error: error.response.data.message });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Chat Summary.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setChatSummary({ summary: null, loading: false, error: `We encountered an unexpected issue while retrieving this Chat Summary. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setChatSummary({ summary: null, loading: false, error: `We encountered an unexpected issue while retrieving this Chat Summary. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        if (props.branch.canManageBranch || props.branch.canManageEmployees) {
            getRecipientSummary();
        }

        if (props.branch.canManageBranch || props.branch.canViewAllBranchNotifications) {
            getNotificationSummary();
        }

        if (props.branch.canManageBranch || props.branch.canViewAllChats) {
            getChatSummary();
        }
    }, [ history, branchId, props.branch.canManageBranch, props.branch.canManageEmployees, props.branch.canViewAllBranchNotifications, props.branch.canViewAllChats ]);

    const downloadNotLoggedIn = evt => {
        const getNotLoggedIn = async () => {
            try {
                let data = await API.get('AuthenticatedAPI', `/branches/${props.branch.id}/recipients`, { headers: { 'Accept': 'text/csv' }, queryStringParameters: { hasLoggedIn: false, pageSize: (recipientSummary.summary.numberOfEmployees - recipientSummary.summary.numberOfEmployeesLoggedIn) }});

                let element = document.createElement('a');
                element.setAttribute('href', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(data));
                element.setAttribute('download', 'download.csv');
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);

                setDownloading(false);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setDownloading(false);
                            alert(error.response.data.message);
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access reports for this Branch.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setDownloading(false);
                            Sentry.captureException(error);
                            alert(`We encountered an unexpected issue while retrieving employees who have not logged in. The service returned error code ${error.response.status}`);
                            break;
                    }
                } else {
                    setDownloading(false);
                    Sentry.captureException(error);
                    alert(`We encountered an unexpected issue while retrieving employees who have not logged in. The service returned error message ${error.message}`);
                }
            }
        };

        setDownloading(true);
        getNotLoggedIn();
    };

    const downloadEmployeeStats = evt => {
        const getEmployeeNotificationSummary = async () => {
            try {
                let data = await API.get('AuthenticatedAPI', `/branches/${props.branch.id}/recipient-notification-summary`, { headers: { 'Accept': 'text/csv' } });

                let element = document.createElement('a');
                element.setAttribute('href', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(data));
                element.setAttribute('download', 'download.csv');
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);

                setDownloading(false);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setDownloading(false);
                            alert(error.response.data.message);
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access reports for this Branch.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setDownloading(false);
                            Sentry.captureException(error);
                            alert(`We encountered an unexpected issue while retrieving employees-notification statistics. The service returned error code ${error.response.status}`);
                            break;
                    }
                } else {
                    setDownloading(false);
                    Sentry.captureException(error);
                    alert(`We encountered an unexpected issue while retrieving employees-notification statistics. The service returned error message ${error.message}`);
                }
            }
        };

        setDownloading(true);
        getEmployeeNotificationSummary();
    };

    return (
        <>
            <Helmet>
                <title>Employee | Reports and Metrics</title>
            </Helmet>

            <div className="p-4">
                <h1>Reports</h1>

                <hr />

                <Row>
                    {(Boolean(props.branch.canManageBranch) || Boolean(props.branch.canManageEmployees)) && (
                        <Col xs="12" sm="6" xl="4" className="mb-3">
                            <Card className="h-100">
                                <Card.Body>
                                    <Card.Title>Employee Onboarding</Card.Title>
                                    <Card.Subtitle className="text-muted font-weight-regular">How many of your employees have installed and started using Notifyd?</Card.Subtitle>

                                    {recipientSummary.loading ? (
                                        <div className="text-center py-5">
                                            <i className="fas fa-spinner fa-fw fa-spin fa-3x" />
                                        </div>
                                    ) : recipientSummary.error ? (
                                        <Alert variant="orange-200" className="small">{recipientSummary.error}</Alert>
                                    ) : (
                                        <RecipientSummary {...recipientSummary.summary} />
                                    )}
                                </Card.Body>

                                <Card.Footer>
                                    <Button variant="blue-300" size="sm" block className="text-gray-700" onClick={downloadNotLoggedIn} disabled={recipientSummary.loading || downloading}>
                                        <i className="fas fa-file-csv fa-fw"></i> Not Logged In
                                    </Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    )}

                    {(Boolean(props.branch.canManageBranch) || Boolean(props.branch.canViewAllBranchNotifications)) && (
                        <Col xs="12" sm="6" xl="4" className="mb-3">
                            <Card className="h-100">
                                <Card.Body>
                                    <Card.Title>Notifications Usage</Card.Title>
                                    <Card.Subtitle className="text-muted font-weight-regular">Are you using Notifications to reduce redundant communication?</Card.Subtitle>

                                    {notificationSummary.loading ? (
                                        <div className="text-center py-5">
                                            <i className="fas fa-spinner fa-fw fa-spin fa-3x" />
                                        </div>
                                    ) : notificationSummary.error ? (
                                        <Alert variant="orange-200" className="small">{notificationSummary.error}</Alert>
                                    ) : (
                                        <NotificationSummary {...notificationSummary.summary} />
                                    )}
                                </Card.Body>

                                {(Boolean(props.branch.canManageBranch) || (Boolean(props.branch.canManageEmployees) && Boolean(props.branch.canViewAllBranchNotifications))) && (
                                    <Card.Footer>
                                        <Button variant="blue-300" size="sm" block className="text-gray-700" onClick={downloadEmployeeStats} disabled={notificationSummary.loading || downloading}><i className="fas fa-file-csv fa-fw"></i> Employee Stats</Button>
                                    </Card.Footer>
                                )}
                            </Card>
                        </Col>
                    )}

                    {(Boolean(props.branch.canManageBranch) || Boolean(props.branch.canViewAllChats)) && (
                        <Col xs="12" sm="6" xl="4" className="mb-3">
                            <Card className="h-100">
                                <Card.Body>
                                    <Card.Title>Chat Usage</Card.Title>
                                    <Card.Subtitle className="text-muted font-weight-regular">Are you using Chat to cutdown on miscommunications and missed calls?</Card.Subtitle>

                                    {chatSummary.loading ? (
                                        <div className="text-center py-5">
                                            <i className="fas fa-spinner fa-fw fa-spin fa-3x" />
                                        </div>
                                    ) : chatSummary.error ? (
                                        <Alert variant="orange-200" className="small">{chatSummary.error}</Alert>
                                    ) : (
                                        <ChatSummary {...chatSummary.summary} />
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>
            </div>
        </>
    );
}

export default Reports;
