import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Auth } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Confirmation(props) {
    const [ code, setCode ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ formState, setFormState ] = useState({ error: '', loading: false });

    let history = useHistory();

    if (!props.location.state || !props.location.state.email) {
        return <Redirect to="/reset-password" />
    }

    const { email, destination } = props.location.state;

    const formSubmit = async (evt) => {
        evt.preventDefault();
        setFormState({ error: '', loading: true });

        try {
            await Auth.forgotPasswordSubmit(email, code, password);

            history.push('/', {
                message: 'You have successfully reset your password and can now login to your account.'
            });
        } catch (error) {
            let formError;

            switch (error.code) {
                case 'CodeMismatchException':
                    formError = 'Invalid confirmation code provided, please try again.';
                    break;
                case 'ExpiredCodeException':
                    formError = 'The confirmation code you submitted has expired. Please try to reset your password again.';
                    break;
                case 'LimitExceededException':
                    formError = 'You have exceeded the number of confirmation attempts allowed. Please wait some time and try to reset your password again.';
                    break;
                default:
                    formError = `We encountered an unexpected issue while confirming your password reset. The service returned error code ${error.code}`;
                    Sentry.captureException(error);
                    break;
            }

            setFormState({ error: formError, loading: false });
        }
    }

    return (
        <>
            <Helmet>
                <title>Confirm Password Reset</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <div className="bg-gray-700 py-5">
                <Container>
                    <div className="text-center">
                        <h1 className="display-4">Confirm Password Reset</h1>
                        <p className="lead">Your password is almost reset!</p>
                    </div>

                    <hr />

                    <Row className="justify-content-center pt-5">
                        <Col xs="12" sm="6" lg="5" xl="4">
                            <h3>Next Steps</h3>
                            <p>You should have received a confirmation code at {destination ? destination : 'your account email or phone number'}. Please provide this code along with your new password to complete this process.</p>.
                        </Col>

                        <Col xs="12" sm="6" md="5" lg="4" xl="3">
                            <Form onSubmit={formSubmit}>
                                <fieldset>
                                    <Form.Group controlId="code">
                                        <Form.Label>Confirmation Code</Form.Label>
                                        <Form.Control type="text" required minLength="6" value={code} onChange={e => setCode(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group controlId="password">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control type="password" required="required" minLength="8" pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" value={password} onChange={e => setPassword(e.target.value)} />
                                        <Form.Text muted>Must be at least 8 characters long and contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.</Form.Text>
                                    </Form.Group>
                                </fieldset>

                                {formState.error !== '' && (
                                    <Alert variant="orange-200" className="small">{formState.error}</Alert>
                                )}

                                <Button type="submit" variant="orange-300" className="text-white" disabled={formState.loading}>Confirm</Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Confirmation;
