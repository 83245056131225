import React from 'react';
import { Helmet } from 'react-helmet';

function Guide() {
    return (
        <>
            <Helmet>
                <title>Partner | Web Hooks - Implementation Guide</title>
            </Helmet>

            <div className="p-4">
                <h1>Webhook Implementation Guide</h1>

                <hr />

                <h3>Request Body</h3>
                <p>When we notify you of an event, you should expect to receive the <code>NOUN</code> that triggered the event as the request body. So, for example, if you are dealing with the <code>response</code>.<code>created</code> webhook, the structure of the request body will match the structure of the <code>response</code> resource as described in the <a href="/api-docs" target="_blank">API Docs</a>.</p>

                <br />

                <h3>Response Status Code Considerations</h3>
                <p>We determine whether or not a notification was successfully received by the <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Status" target="_blank" rel="noopener noreferrer">status code</a> returned by the webhook endpoint. If the notification we send is successfully received on your end, you should return an HTTP status code in the 2XX family. Upon receiving a 2XX response, we will no longer send the associated event notification.</p>
                <p>Notifications will not follow 3XX redirects.</p>
                <p>Any non-2XX status code will be treated as a failure. For more information on failures, see the next section.</p>

                <br />

                <h3>Token-Based Authentication</h3>
                <p>If you are using the <code>bearer</code> authentication type, you must provide an authentication URL for us to hit so we can retrieve an access token to make subsequent requests with. The response body for this authentication request must return a JSON body with the property <code>access_token</code> populated. If you use an off-the-shelf API Gateway that does not support this type of response, please contact us at <a href="mailto:tech-support@notifyd.com">tech-support@notifyd.com</a> and we will work with you to find an alternative solution.</p>

                <br />

                <h3>Failed Notification Considerations</h3>
                <p>When a webhook endpoint responds with a non-2XX status code, it is treated as a failure. We will retry the notification 5 times with a 10 minute delay between attempts. If the notification continues to fail, it will be moved to our <a href="https://en.wikipedia.org/wiki/Dead_letter_queue" target="_blank" rel="noopener noreferrer">deadletter queue</a> where it will be stored for 14 days. Once in the deadletter queue, we will send you an email every 12 hours letting you know there is an issue with your endpoint. Once you have addressed the issue with your endpoint, you will need to contact us at <a href="mailto:tech-support@notifyd.com">tech-support@notifyd.com</a> so we requeue all events in the deadletter queue. If the issue with your endpoint is not addressed after 14 days, the notification will be discarded.</p>
                <p>We are aware that emailing us to requeue deadletter messages is less than ideal, and we have plans to allow you to requeue deadletter events without having to email us. Sorry for the temporary inconvenience.</p>

                <br />

                <h3>Performance Considerations</h3>
                <p>You will also want to design your endpoints so that they return a response in less than 3 seconds, otherwise we will treat it as a failure (see Failed Notification Considerations above). If your system is extremely resource intensive and requires more than 3 seconds to process the event, you may want to store the event in a <a href="https://en.wikipedia.org/wiki/Message_queue" target="_blank" rel="noopener noreferrer">message queue</a> which you can process offline - message queues can also help if you need to reprocess an event on your end. Which message queue you choose will depend on your technology stack and hosting environment.</p>

                <br />

                <h3>Distributed Processing Considerations</h3>
                <p>Our applications run in a distributed environment, and as a result you may occasionally receive a notification for the same event more than once. When developing your webhook endpoints, make sure they are <a href="https://developer.mozilla.org/en-US/docs/Glossary/Idempotent" target="_blank" rel="noopener noreferrer">idempotent</a> to avoid any unintended consequences.</p>

                <br />

                <h3>Having Issues?</h3>
                <p>If at anytime you're having difficulties getting your webhooks setup, please do not hesitate to reach out to us at <a href="mailto:tech-support@notifyd.com">tech-support@notifyd.com</a> so we can assist you.</p>
            </div>
        </>
    );
}

export default Guide;
