import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function App() {
    return (
        <>
            <Helmet>
                <title>Partner | Dashboard</title>
            </Helmet>

            <div className="p-4">
                <h1>Welcome to Notifyd</h1>

                <hr />

                <div className="py-4">
                    <h5>Keep up with the latest API changes</h5>
                    <p>We are constantly making improvements to our API and looking for ways to give our partners more access to the full breadth of services performed by Notifyd. Check out the latest changes to the API to see if there are new ways to leverage our services as part of your workflow.</p>

                    <Row>
                        <Col xs="12" lg="auto">
                            <Button variant="blue-300" size="sm" block className="text-white" href="/api-docs" target="_blank"><i className="fas fa-book fa-fw"></i> API Docs</Button>
                        </Col>
                    </Row>
                </div>

                <hr />

                <div className="py-4">
                    <h5>Create deeper integrations with webhooks</h5>
                    <p>Webhooks allow us to effectively inform partners when events occur in our system. For example, you may want to build alerts into your solution that go off every time a recipient responds to a notification. Webhooks allow us to send you these events in near real-time, and eliminates the need to poll our API for new data to arrive.</p>
                    <p>Also check out our implementation guide which provides tips for creating reliable webhook integrations back into your systems.</p>

                    <Row>
                        <Col xs="12" lg="auto">
                            <Button as={Link} variant="blue-300" size="sm" block className="text-white" href="/partner/web-hooks" to="/partner/web-hooks"><i className="fas fa-project-diagram fa-fw"></i> Webhooks</Button>
                        </Col>

                        <Col xs="12" lg="auto">
                            <Button as={Link} variant="blue-400" size="sm" block className="text-white" href="/partner/web-hooks/implementation-guide" to="/partner/web-hooks/implementation-guide"><i className="fas fa-question-circle fa-fw"></i> Implementation Guide</Button>
                        </Col>
                    </Row>
                </div>

                <hr />

                <div className="py-4">
                    <h5>Have a request for us or found an issue?</h5>
                    <p>We want to serve our partners just as well as we serve our customers. Any time you run into an issue or find a limitation with our services, please <a href="mailto:tech-support@notifyd.com">reach out</a> and we will find a way to help!</p>
                </div>
            </div>
        </>
    );
}

export default App;
