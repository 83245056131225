import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { default as BootstrapForm } from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import { plans } from './../../../config/Stripe';

function Form(props) {
    const [ name, setName ] = useState(props.branch.name || '');
    const [ partnerId, setPartnerId ] = useState(props.branch.partnerId || null);
    const [ archiveNotificationsAfter, setArchiveNotificationsAfter ] = useState(props.branch.archiveNotificationsAfter || '');
    const [ stripePlanId, setStripePlanId ] = useState(props.branch.subscription ? props.branch.subscription.plan.id : plans[0].id);
    const [ newCard, setNewCard ] = useState(props.newCard);

    const negotiatedRate = props.branch.negotiatedRate || 1;
    const [ formState, setFormState ] = useState({ partners: null, message: '', error: '', loading: true, saving: false });

    const history = useHistory();
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        const getPartners = async () => {
            try {
                let partners = await API.get('AuthenticatedAPI', '/partners');
                setFormState({ partners, message: '', error: '', loading: false, saving: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ partners: null, message: '', error: error.response.data.message, loading: false, saving: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to retrieve Partners.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setFormState({ partners: null, message: '', error: `We encountered an unexpected issue while retrieving Branches. The service returned error code ${error.response.status}`, loading: false, saving: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ partners: null, message: '', error: `We encountered an unexpected issue while retrieving Branches. The service returned error message ${error.message}`, loading: false, saving: false });
                    Sentry.captureException(error);
                }
            }
        };

        getPartners();
    }, [ history ]);

    const formSubmit = evt => {
        evt.preventDefault();

        const putBranch = async (stripeTokenId) => {
            try {
                await API.put('AuthenticatedAPI', `/branches/${props.branch.id}`, { body: { name, partnerId, archiveNotificationsAfter, stripePlanId: stripePlanId || null, stripeTokenId }});

                setFormState({ ...formState, message: 'Successfully updated Branch.', error: '', loading: false, saving: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ ...formState, message: '', error: error.response.data.message, loading: false, saving: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to edit this Branch.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/recipient');
                            break;
                        default:
                            setFormState({ ...formState, message: '', error: `We encountered an unexpected issue while updating this Branch. The service returned error code ${error.response.status}`, loading: false, saving: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ ...formState, message: '', error: `We encountered an unexpected issue while updating this Branch. The service returned error message ${error.message}`, loading: false, saving: false });
                    Sentry.captureException(error);
                }
            }
        };

        const postBranch = async (stripeTokenId) => {
            try {
                let branch = await API.post('AuthenticatedAPI', '/branches', { body: { name, partnerId, archiveNotificationsAfter, stripePlanId, stripeTokenId }});

                props.onNewBranch(branch);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ ...formState, message: '', error: error.response.data.message, loading: false, saving: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to create Branches.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setFormState({ ...formState, message: '', error: `We encountered an unexpected issue while creating a Branch. The service returned error code ${error.response.status}`, loading: false, saving: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ ...formState, message: '', error: `We encountered an unexpected issue while creating a Branch. The service returned error message ${error.message}`, loading: false, saving: false });
                    Sentry.captureException(error);
                }
            }
        };

        const cardElement = elements.getElement(CardElement);
        setFormState({ ...formState, message: '', error: '', saving: true })

        if (cardElement !== null) {
            stripe.createToken(cardElement).then(response => {
                let { error, token } = response;

                if (error) {
                    setFormState({ ...formState, error: error.message, saving: false });
                } else {
                    if (props.branch.id) {
                        putBranch(token.id);
                    } else {
                        postBranch(token.id);
                    }
                }
            }).catch(error => {
                setFormState({ ...formState, error: error.message, saving: false });
            });
        } else {
            if (props.branch.id) {
                putBranch();
            } else {
                postBranch();
            }
        }
    };

    const selectedStripePlan = plans.find(plan => plan.id === stripePlanId);

    return (
        <BootstrapForm onSubmit={formSubmit}>
            <Row className="mb-3">
                <Col xs="12" lg="5" xl="4">
                    <div className="bg-white rounded border border-gray-500 p-3">
                        <fieldset>
                            <legend>Billing</legend>

                            <BootstrapForm.Group controlId="name">
                                <BootstrapForm.Label>Location Name</BootstrapForm.Label>
                                <BootstrapForm.Control value={name} required onChange={e => setName(e.target.value)} />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group controlId="plan">
                                <BootstrapForm.Label>Subscription Plan</BootstrapForm.Label>
                                <BootstrapForm.Control as="select" value={stripePlanId} onChange={e => setStripePlanId(e.target.value)}>
                                    {plans.map(plan =>
                                        <option key={plan.id} value={plan.id}>{plan.nickname} ${(plan.amount * negotiatedRate / (100 * 100)).toFixed(2)}</option>
                                    )}
                                    {props.branch.id && (
                                        <option value="">Cancel Subscription</option>
                                    )}
                                </BootstrapForm.Control>
                                <BootstrapForm.Text className="small text-green-500 font-weight-medium">{selectedStripePlan && selectedStripePlan.metadata.message}</BootstrapForm.Text>
                                <BootstrapForm.Text className="small text-muted font-weight-light">Sales tax not included</BootstrapForm.Text>
                            </BootstrapForm.Group>

                            {!newCard ? (
                                <BootstrapForm.Group>
                                    <BootstrapForm.Label>Debit / Credit Card on file</BootstrapForm.Label>
                                    <InputGroup>
                                        <BootstrapForm.Control value="Use card on file" readOnly />
                                        <InputGroup.Append>
                                            <Button variant="orange-200" size="sm" className="text-white" onClick={e => setNewCard(true)}>Update</Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </BootstrapForm.Group>
                            ) : (
                                <BootstrapForm.Group controlId="card">
                                    <BootstrapForm.Label>Debit / Credit Card</BootstrapForm.Label>
                                    <CardElement className="form-control pt-2" options={{ style: { base: { fontSize: '16px', fontFamily: '"Montserrat", sans-serif' } }  }} />

                                    {!props.branch.id && (
                                        <BootstrapForm.Text className="text-orange-200 small font-weight-medium">Your card will not be charged until your free trial ends in 7 days. You can cancel at anytime.</BootstrapForm.Text>
                                    )}
                                </BootstrapForm.Group>
                            )}
                        </fieldset>
                    </div>
                </Col>

                <Col xs="12" lg={true}>
                    <div className="bg-white rounded border border-gray-500 p-3">
                        <fieldset>
                            <legend>Integrations and Settings</legend>

                            <BootstrapForm.Group controlId="partnerId">
                                <BootstrapForm.Label>Integration for Location</BootstrapForm.Label>
                                {formState.loading ? (
                                    <div><i className="fas fa-spinner fa-fw fa-2x fa-spin" /></div>
                                ) : formState.partners.map(partner =>
                                    <BootstrapForm.Check key={partner.id} type="radio" id={partner.id} name="partnerId" value={partner.id} checked={partnerId === partner.id} label={partner.name} onChange={e => setPartnerId(partner.id)} required />
                                )}
                                <BootstrapForm.Text className="small text-muted">The integration you choose will be given permission to create new Notifyd users, send notifications, and view how users respond to notifications.</BootstrapForm.Text>
                            </BootstrapForm.Group>

                            <BootstrapForm.Group controlId="archiveNotificationsAfter">
                                <BootstrapForm.Label>Archive Notifications After</BootstrapForm.Label>
                                <InputGroup>
                                    <BootstrapForm.Control type="number" min="1" max="255" value={archiveNotificationsAfter} onChange={e => setArchiveNotificationsAfter(e.target.value)} />
                                    <InputGroup.Append>
                                        <InputGroup.Text className="small">Days</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                <BootstrapForm.Text className="small text-muted">Archiving your notifications automatically hides old notifications and responses from mobile app users that may no longer be relevant. It can also help to reduce the amount of sensitive data exposed to your clinicians over long periods of time.</BootstrapForm.Text>
                                <BootstrapForm.Text className="small text-muted">Leave the field blank if you don't want notifications to be archived.</BootstrapForm.Text>
                            </BootstrapForm.Group>
                        </fieldset>
                    </div>
                </Col>
            </Row>

            {formState.message !== '' && (
                <Alert variant="green-500" className="small">{formState.message}</Alert>
            )}

            {formState.error !== '' && (
                <Alert variant="orange-200" className="small">{formState.error}</Alert>
            )}

            <Button type="submit" variant="green-500" block className="text-white" disabled={formState.loading || formState.saving}>{props.branch.id ? 'Update Account' : 'Create Account'}</Button>
        </BootstrapForm>
    );
}

export default Form;
