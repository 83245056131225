import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Linkify from 'react-linkify';
import { Alert, Button, ListGroup } from 'react-bootstrap'
import { default as ChatButton } from '../../Chat/Button'
import { default as CallButton } from '../../Call/Button'
import { Auth, API } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import getFileIcon from '../../FileIcons';

function Details(props) {
    const { notification, unsetNotification, removeNotification } = props;

    const [ recipients, setRecipients ] = useState(null);
    const [ total, setTotal ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState();

    const [ downloading, setDownloading ] = useState(false);
    const [ responding, setResponding ] = useState(false);

    const pageNumber = 0;
    const pageSize = 25;
    const history = useHistory();

    const canManageNotification = Boolean(notification.sendingUserId === Auth.user.username || notification.assigned)

    useEffect(() => {
        const getRecipientsByNotification = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/notifications/${notification.id}/recipients`, { response: true, queryStringParameters: { sort: 'lastResponded', expand: 'notification-recipient', pageNumber, pageSize } });

                setRecipients(response.data);
                setLoading(false);
                setTotal(response.headers['x-total-count'])
            } catch (error) {
                if (error.response) {
                    setError(error.response.data.message);
                } else {
                    setError(error.message);
                }

                Sentry.captureException(error);
            }
        };

        if (canManageNotification) {
            setLoading(true);
            getRecipientsByNotification();
        }
    }, [ notification.id, notification.sendingUserId, notification.assigned ]);

    const getFile = async () => {
        setDownloading(true);

        try {
            let file = await API.get('AuthenticatedAPI', `/branches/${notification.branchId}/notifications/${notification.id}/files/${notification.fileId}`);
            setDownloading(false);
            window.open(file.uri, '_blank');
        } catch (error) {
            if (error.response) {
                setError(error.response.data.message);
            } else {
                setError(error.message);
            }

            Sentry.captureException(error);
        }
    };

    const respondToNotification = response => {
        const postRecipientResponse = async () => {
            try {
                await API.post('AuthenticatedAPI', `/notifications/${notification.id}/responses`, { body: { response: response ? response.text : '' } });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setError(error.response.data.message);
                            setResponding(false);
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to respond to this Notification.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setError(`We encountered an unexpected issue while responding to this Notifications. The service returned error code ${error.response.status}`);
                            setResponding(false);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while responding to this Notifications. The service returned error message ${error.message}`);
                    setResponding(false);
                    Sentry.captureException(error);
                }
            }
        };
    
        setResponding(true);
        postRecipientResponse().then(() => {
            removeNotification(notification.id);
            unsetNotification();

            if (response && response.hyperlink) {
                window.open(response.hyperlink, '_blank');
            }
        });
    };

    let date, dateString;
    let sendingUser = notification['sending-user'];

    if (canManageNotification) {
        date = new Date(notification.lastRespondedOn || notification.createdOn);
    } else {
        date = new Date(notification.createdOn);
    }

    let timeDifferenceInSeconds = (new Date().getTime() - date.getTime()) / 1000;
    dateString = timeDifferenceInSeconds < 86400 ? date.toLocaleTimeString() : date.toLocaleDateString();

    if (error) {
        return <Alert variant="orange-200" className="m-2">{error}</Alert>
    }

    return (
        <>
            <div className="bg-blue-300 text-white text-center small font-weight-medium py-2">
                <button className="bg-transparent border-0 p-0 position-absolute text-gray-700" style={{ left: 15, top: 8 }} onClick={unsetNotification}><i className="fas fa-chevron-left fa-lg" /></button>

                {canManageNotification ? (
                    <>
                        <i className="fas fa-envelope-open fa-fw" /> Notification Responses
                    </>
                ) : (
                    <>
                        <i className="fas fa-envelope fa-fw" /> New Notification
                    </>
                )}
            </div>

            <div className="bg-white p-3 border-bottom border-gray-600">
                <div className="d-lg-flex justify-content-between">
                    <span className="small font-weight-semibold text-blue-300">
                        {Boolean(notification.critical) && (
                            <i className="fas fa-exclamation-triangle fa-fw text-orange-200 mr-1" />
                        )}
                        {notification.title}
                    </span>
                    <span className="d-none d-lg-inline smaller font-weight-light text-gray-400">{dateString}</span>
                </div>

                <p className="mb-0 small text-break" style={{ whiteSpace: 'pre-line' }}>
                    <Linkify componentDecorator={(href, text, key) => (
                        <a href={href} key={key} target="_blank" rel="noopener noreferrer">{text}</a>
                    )}>{notification.message}</Linkify>
                </p>

                <div className="d-lg-none smaller font-weight-light text-gray-400">{dateString}</div>

                {notification.fileId && (
                    <Button variant="link" size="sm" className="p-0 pt-1" onClick={getFile} disabled={downloading}><i className={`fas ${getFileIcon(notification.fileId.split('.')[1])} fa-fw`} /> View Attachment</Button>
                )}

                <hr />

                {sendingUser && (
                    <div className="d-flex justify-content-between align-items-center pb-2 text-gray-300">
                        <div className="smaller font-weight-medium">Sent by <span className="text-orange-300">{sendingUser.name}</span></div>
                        <div>
                            <ChatButton recipient={sendingUser} size="sm" />
                            <CallButton recipient={sendingUser} size="sm" />
                        </div>
                    </div>
                )}

                {!canManageNotification && (
                    <>
                        {notification.responses.length > 0 ? notification.responses.map((response, index) =>
                            <Button key={index} variant={index === 0 ? 'blue-300' : 'blue-400'} size="sm" block className="text-gray-700 font-weight-medium" onClick={() => respondToNotification(response)} disabled={responding}>
                                {response.hyperlink && (
                                    <i className="fas fa-link fa-fw" />
                                )}

                                {response.text}
                            </Button>
                        ) : (
                            <Button variant="blue-300" size="sm" block className="text-gray-700 font-weight-medium" onClick={() => respondToNotification(null)} disabled={responding}>Viewed</Button>
                        )}
                    </>
                )}
            </div>

            {canManageNotification && (
                <>
                    <ListGroup variant="flush">
                        {loading ? (
                            <ListGroup.Item key="loading" className="text-center">
                                <i className="fas fa-spinner fa-fw fa-spin fa-2x text-blue-400" />
                            </ListGroup.Item>
                        ) : recipients !== null && (
                            <>
                                {recipients.length === 0 ? (
                                    <ListGroup.Item className="text-center">
                                        No recipients
                                    </ListGroup.Item>
                                ) : recipients.map(recipient => {
                                    let response = recipient['notification-recipient'].response;
                                    let responseClass = response === null ? 'text-gray-400 font-weight-medium' : (notification.responses.length === 0 || notification.responses[0].text === response) ? 'text-blue-300 font-weight-semibold' : 'text-blue-400 font-weight-semibold';
                                    let respondedOn = new Date(recipient['notification-recipient'].lastUpdatedOn);

                                    return (
                                        <ListGroup.Item key={recipient.id} className={`d-flex justify-content-between align-items-center ${response === null ? 'bg-gray-700' : ''}`}>
                                            <div className="small">
                                                <div className="font-weight-medium">{recipient.name}</div>
                                                <div className={responseClass}>
                                                    {response === null ? 'No response' : response === '' ? 'Viewed' : response}
                                                </div>
                                                {response !== null && (
                                                    <div className="smaller">{respondedOn.toLocaleString()}</div>
                                                )}
                                            </div>

                                            <div>
                                                <ChatButton recipient={recipient} size="sm" />
                                                <CallButton recipient={recipient} size="sm" />
                                            </div>
                                        </ListGroup.Item>
                                    );
                                })}
                            </>
                        )}
                    </ListGroup>

                    <div className="text-center py-2 border-top border-gray-600">
                        <Button as={Link} href={`/recipient/notifications/responses/${notification.id}`} to={`/recipient/notifications/responses/${notification.id}`} variant="blue-300" size="sm" className="text-gray-700">
                            <i className="fas fa-pencil fa-fw" /> Manage {total} Response(s)
                        </Button>
                    </div>
                </>
            )}
        </>
    );
}

export default Details;
