import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Autosuggest from '../Contacts/Recipients/Autosuggest';

function Filters(props) {
    const { branchId, onSearch, showSentBy, showSentTo } = props;

    const [ sendingUserId, setSendingUserId ] = useState(null);
    const [ recipientId, setRecipientId ] = useState(null);
    const [ createdAfter, setCreatedAfter ] = useState('');
    const [ createdBefore, setCreatedBefore ] = useState('');

    const formSubmit = e => {
        e.preventDefault();

        onSearch({ sendingUserId, recipientId, createdAfter, createdBefore });
    };

    return (
        <Form onSubmit={formSubmit} className="bg-white p-4 border border-top-0 border-gray-600 rounded-bottom">
            <Form.Row>
                {Boolean(showSentBy) && (
                    <Col xs="12" sm="4" lg="auto">
                        <Form.Group controlId="sendingUserName">
                            <Form.Label>Sent By</Form.Label>
                            <Autosuggest branchId={branchId} size="sm" onSelect={selection => setSendingUserId(selection?.id ?? null)} officeGroup={true} />
                            <Form.Text className="text-muted smaller">Search by name, email, phone number, job title, or ZIP code.</Form.Text>
                        </Form.Group>
                    </Col>
                )}

                {Boolean(showSentTo) && (
                    <Col xs="12" sm="4" lg="auto">
                        <Form.Group controlId="recipientName">
                            <Form.Label>Sent To</Form.Label>
                            <Autosuggest branchId={branchId} size="sm" onSelect={selection => setRecipientId(selection?.id ?? null)} />
                            <Form.Text className="text-muted smaller">Search by name, email, phone number, job title, or ZIP code.</Form.Text>
                        </Form.Group>
                    </Col>
                )}

                <Col xs="6" sm="4" xl="auto">
                    <Form.Group controlId="createdAfter">
                        <Form.Label>Sent After</Form.Label>
                        <Form.Control type="date" size="sm" max={createdBefore} value={createdAfter} onChange={e => setCreatedAfter(e.target.value)} />
                    </Form.Group>
                </Col>

                <Col xs="6" sm="4" xl="auto">
                    <Form.Group controlId="createdBefore">
                        <Form.Label>Sent Before</Form.Label>
                        <Form.Control type="date" size="sm" min={createdAfter} value={createdBefore} onChange={e => setCreatedBefore(e.target.value)} />
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col xs="12" sm="auto">
                    <Button type="submit" variant="green-500" size="sm" block className="text-gray-700">Search</Button>
                </Col>
            </Form.Row>
        </Form>
    );
}

export default Filters;
