import React from 'react'
import { Badge, Button as BootstrapButton } from 'react-bootstrap';

function Button(props) {
  const { recipient, size } = props

  const handleClick = () => {
    if (!recipient.doNotDisturb || window.confirm('This employee is currently in "Do not disturb" mode, and may not see your message right away. If you don\'t get a response, consider reaching out to another coworker.')) {
      window.location = `tel:${recipient.contactPhone}`
    }
  }

  return (
    <div className="d-inline-block position-relative">
      <BootstrapButton
        variant={recipient.contactPhone ? 'green-500' : 'gray-600'}
        size={size}
        className="ml-1 text-gray-700"
        onClick={handleClick}
        disabled={!recipient.contactPhone}
      >
        <i className="fas fa-phone fa-fw" />
      </BootstrapButton>

      {Boolean(recipient.doNotDisturb) && (
        <Badge variant="gray-400" className="position-absolute text-gray-700 p-1" style={{ right: -5, bottom: -5, zIndex: 5 }}><i className="fa fa-bell-slash fa-fw fa-xs" /></Badge>
      )}
    </div>
  )
}

export default Button