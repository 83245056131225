import React, {} from 'react';
import { Button, Modal } from 'react-bootstrap'

function Help(props) {
    const { branch, hideHelp } = props

    return (
        <Modal show={true} onHide={hideHelp}>
            <Modal.Header className="bg-blue-100 text-gray-700">
                <Modal.Title className="p font-weight-medium">Helpful Guides, Resources and Links</Modal.Title>

                <button className="close text-gray-700" onClick={hideHelp}><i className="fas fa-times fa-sm" /></button>
            </Modal.Header>

            <Modal.Body>
                <p className="small">Here is some content to help you with using Notifyd or if you need further assistance!</p>

                <ul className="small">
                    <li><a href="https://drive.google.com/file/d/16n_Aj7fpqX54tdGKcucVQ6vHvrMZYfpB/view?usp=sharing" target="_blank" rel="noopener noreferrer">Web App User Guide</a></li>
                    <li><a href="https://drive.google.com/uc?export=download&id=16t84RiFiSIvUBGorV1pVL5ogNNp3qPHA" target="_blank" rel="noopener noreferrer">Onboarding Approach Guide</a></li>
                    <li><a href="https://drive.google.com/uc?export=download&id=15IWLI_azpDK5uoP7HPnF43883P05B1KL" target="_blank" rel="noopener noreferrer">App Flyer for Employees</a></li>
                    <li><a href="https://drive.google.com/uc?export=download&id=16O3_7deRmka389Q17BjpdvIM1L2_gVzr" target="_blank" rel="noopener noreferrer">App Flyer for Patient</a></li>
                    <li><a href="https://drive.google.com/uc?export=download&id=17StIn2osGOSjiLtjuY4fLv2QnWcJjozB" target="_blank" rel="noopener noreferrer">Getting Started Emails</a></li>
                </ul>
        
                <p className="small">For additional how-to videos, device setting help or app tips, visit<a href="https://notifyd.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer"> our FAQ page</a>.</p>
                <p className="small">If you are not able to find the information you need or have an issue with our app or service, send us an email.</p>

                <div className="d-flex justify-content-between">
                    {Boolean(branch.canManageBranch) && (
                        <Button href="https://meetings.hubspot.com/josh-guthrie1" size="sm" variant="blue-300">
                            <i className="fas fa-fw fa-graduation-cap" /> Book 1 hour of training
                        </Button>
                    )}

                    <Button href="https://app.notifyd.com/support" target="_blank" rel="noopener noreferrer" size="sm" variant="blue-400">
                        <i className="fas fa-fw fa-envelope fa-fw"></i> Contact Support
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default Help;
