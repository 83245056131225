import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Table from '../Branches/Table';
import Pagination from '../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Branches(props) {
    const [ branchState, setBranchState ] = useState({ branches: null, total: 0, error: '' });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 25;
    const history = useHistory();
    const id = props.partner.id;

    useEffect(() => {
        const getBranchesByPartner = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/partners/${id}/branches`, { response: true, queryStringParameters: { pageSize, pageNumber } });
                setBranchState({ branches: response.data, total: response.headers['x-total-count'], error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setBranchState({ branches: null, total: 0, error: error.response.data.message });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Branches for this Partner. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/admin/partners');
                            break;
                        default:
                            setBranchState({ branches: null, total: 0, error: `We encountered an unexpected issue while retrieving Branches for this Partner. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setBranchState({ branches: null, total: 0, error: `We encountered an unexpected issue while retrieving Branches for this Partner. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setBranchState({ branches: null, total: 0, error: '' });
        getBranchesByPartner();
    }, [ id, pageNumber, history ]);

    return (
        <>
            <hr/>

            {branchState.error !== '' && (
                <Alert variant="orange-200" className="small">{branchState.error}</Alert>
            )}

            <Table branches={branchState.branches} />

            {branchState.total > 0 && (
                <Pagination total={branchState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
            )}
        </>
    );
}

export default Branches;
