import React from 'react';

function NotificationSummary(props) {
    const { numberOfNotifications, numberOfNotificationRecipients, numberOfNotificationsWithResponses } = props;

    const bronze = 10;
    const silver = 20;
    const gold = 30;

    const maximum = numberOfNotifications < bronze ? bronze : numberOfNotifications < silver ? silver : numberOfNotifications < gold ? gold : null;
    const remaining = maximum ? maximum - numberOfNotifications : null;
    const progressPercentage = maximum ? numberOfNotifications / maximum : 1;
    const progressClass = numberOfNotifications < bronze ? 'bronze' : numberOfNotifications < silver ? 'silver' : numberOfNotifications < gold ? 'gold' : 'green-500';

    const notificationsClass = numberOfNotifications < bronze ? 'gray-600' : numberOfNotifications < silver ? 'bronze' : numberOfNotifications < gold ? 'silver' : 'gold';
    const percentageHasResponse = numberOfNotifications > 0 ? numberOfNotificationsWithResponses / numberOfNotifications : 0;
    const hasResponseClass = percentageHasResponse < 0.4 ? 'bronze' : percentageHasResponse < 0.6 ? 'silver' : percentageHasResponse < 0.8 ? 'gold' : 'green-500';

    return (
        <>
            <h4 className="text-center py-3">
                <i className={`fas fa-trophy-alt fa-fw fa-3x mb-2 text-${notificationsClass}`}></i><br />
                {numberOfNotifications} Notifications Sent<br />
                <small className="text-muted">to {numberOfNotificationRecipients} recipients</small>
            </h4>

            <div className="text-center text-truncate small font-weight-bold" style={{ height: 30 }}>
                <div className={`h-100 py-1 rounded${progressPercentage < 1 && '-left'} bg-striped-${progressClass} float-left`} style={{ width: `${100 * progressPercentage }%` }}>{Math.round(100 * progressPercentage)}%</div>
                <div className={`h-100 py-1 rounded${progressPercentage > 0 && '-right'} border border-gray-600 bg-gray-700 float-right`} style={{ width: `${100 * (1 - progressPercentage) }%` }}></div>
            </div>
            {remaining !== null && (
                <small className="font-weight-medium"><i className={`fas fa-trophy-alt fa-fw text-${progressClass}`}></i> Send {remaining} more Notifications</small>
            )}

            <hr />

            <div className="text-center text-truncate small font-weight-bold" style={{ height: 30 }}>
                <div className={`h-100 py-1 rounded${percentageHasResponse < 1 && '-left'} bg-striped-${hasResponseClass} float-left`} style={{ width: `${100 * percentageHasResponse}%` }}>{Math.round(100 * percentageHasResponse)}%</div>
                <div className={`h-100 py-1 rounded${percentageHasResponse > 0 && '-right'} border border-gray-600 bg-gray-700 float-right`} style={{ width: `${100 * (1 - percentageHasResponse)}%` }}></div>
            </div>

            <small className="font-weight-medium"># of Notifications Acknowledged: <span className={`font-weight-bold text-${hasResponseClass}`}>{numberOfNotificationsWithResponses}</span></small>

            <hr />

            <div className="smaller text-muted text-right">in the last 30 days</div>
        </>
    );
}

export default NotificationSummary;
