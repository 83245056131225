import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from './Form';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Details(props) {
    const { branch } = props;
    const teamId = props.teamId === 'new' ? null : props.teamId;
    const [ profileState, setProfileState ] = useState({ team: null, error: '', loading: true });

    const history = useHistory();

    const setTeam = (team) => {
        setProfileState({ team, error: '', loading: false });
        history.push(`/recipient/contacts/teams/${team.id}`);
    };

    useEffect(() => {
        const getTeam = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/teams/${teamId}`, { queryStringParameters: { expand: 'team-recipient' } });
                setProfileState({ team: response, error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setProfileState({ team: null, error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient/contacts/teams');
                            break;
                        case 404:
                            history.push('/recipient/contacts/teams');
                            break;
                        default:
                            setProfileState({ team: null, error: `We encountered an unexpected issue while retrieving this Team. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setProfileState({ team: null, error: `We encountered an unexpected issue while retrieving this Team. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        if (teamId !== null) {
            getTeam();
        } else {
            setProfileState({ team: null, error: '', loading: false });
        }
    }, [ branch.id, teamId, history ]);

    const deleteTeam = () => {
        const deleteTeamBranchRelationship = async () => {
            try {
                await API.del('AuthenticatedAPI', `/teams/${teamId}`);

                history.push('/recipient/contacts/teams');
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setProfileState({ ...profileState, error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient/contacts/teams');
                            break;
                        case 404:
                            history.push('/recipient/contacts/teams');
                            break;
                        default:
                            setProfileState({ ...profileState, error: `We encountered an unexpected issue while deleting this Team. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setProfileState({ ...profileState, error: `We encountered an unexpected issue while deleting this Team. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        if (window.confirm(`Are you sure you want to remove ${profileState.team.name} from ${branch.name}?`)) {
            setProfileState({ ...profileState, error: '', loading: true });
            deleteTeamBranchRelationship();
        }
    };

    if (profileState.loading) {
        return (
            <h1 className="mt-3"><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>
        );
    }

    if (profileState.error) {
        return (
            <Alert variant="orange-200" className="small">{profileState.error}</Alert>
        );
    }

    return (
        <>
            <Helmet>
                <title>Employee | Manage Team</title>
            </Helmet>

            <div className="bg-white p-4 mb-3 border border-top-0 border-gray-600 rounded-bottom">
                {profileState.team === null ? (
                    <Row className="align-items-center">
                        <Col xs="12" sm="3" lg="2" className="text-center">
                            <div className="bg-gray-300 rounded-circle d-inline-block p-3">
                                <i className="fas fa-3x fa-users fa-fw text-gray-700"></i>
                            </div>

                            <hr className="d-sm-none" />
                        </Col>

                        <Col xs="12" sm="9" lg="10">
                            <h4>Fill out this form to create a team of employees that share a job function.</h4>
                            <p className="small mb-0">Teams allow employees to be part of organizational groups where they can share information with others who have similar skills, responsibilities, and/or managers.</p>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col xs="12" sm="6" lg={true}>
                            <h4>{profileState.team.name}</h4>
                        </Col>

                        <Col xs="12" sm="6" lg={"auto"}>
                            <Button variant="orange-200" size="sm" block className="text-white mt-2 mt-sm-0" onClick={deleteTeam}><i className="fas fa-trash-alt"></i> Deactivate</Button>
                        </Col>
                    </Row>
                )}
            </div>

            <Form branch={branch} team={profileState.team || {}} onNewTeam={setTeam} />
        </>
    );
}

export default Details;
