import React, { useState, useEffect } from 'react';
import { Switch, Route, Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from './Form';
import Companies from './Companies';
import Branches from './Branches';
import Webhooks from './Webhooks';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Details(props) {
    const [ partner, setPartner ] = useState(null);
    const [ error, setError ] = useState('');

    const history = useHistory();
    const id = props.match.params.id;

    useEffect(() => {
        const getPartner = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/partners/${id}`);
                setPartner(response);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setError(error.response.data.message);
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Partner. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/admin/partners');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving this Partner. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving this Partner. The service returned error message ${error.message}`);
                    Sentry.captureException(error);
                }
            }
        };

        setError('');
        getPartner();
    }, [ id, history ]);

    if (partner === null) {
        return (
            <div className="p-4">
                <h1><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>

                {error !== '' && (
                    <Alert variant="orange-200" className="small">{error}</Alert>
                )}
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Admin | Manage Partner</title>
            </Helmet>

            <div className="p-4">
                <h1>{partner.name}</h1>
                <span>{partner.id}</span>

                {error !== '' && (
                    <Alert variant="orange-200" className="small">{error}</Alert>
                )}

                <hr />

                <Row>
                    <Col xs="12" sm="6" lg="auto">
                        <Button as={Link} variant="blue-300" size="sm" block to={props.match.url} href={props.match.url} className="text-white mb-2 mb-lg-0">
                            <i className="fas fa-pencil fa-fw"></i> Edit Partner
                        </Button>
                    </Col>
                    <Col xs="12" sm="6" lg="auto">
                        <Button as={Link} variant="blue-300" size="sm" block to={`${props.match.url}/companies`} href={`${props.match.url}/companies`} className="text-white mb-2 mb-lg-0">
                            <i className="fas fa-building fa-fw"></i> View Companies
                        </Button>
                    </Col>
                    <Col xs="12" sm="6" lg="auto">
                        <Button as={Link} variant="blue-300" size="sm" block to={`${props.match.url}/branches`} href={`${props.match.url}/branches`} className="text-white mb-2 mb-lg-0">
                            <i className="fas fa-code-branch fa-fw"></i> View Branches
                        </Button>
                    </Col>
                    <Col xs="12" sm="6" lg="auto">
                        <Button as={Link} variant="blue-300" size="sm" block to={`${props.match.url}/webhooks`} href={`${props.match.url}/webhooks`} className="text-white mb-2 mb-lg-0">
                            <i className="fas fa-project-diagram fa-fw"></i> View Webhooks
                        </Button>
                    </Col>
                </Row>

                <Switch>
                    <Route path={`${props.match.url}`} exact>
                        <Form partner={partner} />
                    </Route>
                    <Route path={`${props.match.url}/companies`} exact>
                        <Companies partner={partner} />
                    </Route>
                    <Route path={`${props.match.url}/branches`} exact>
                        <Branches partner={partner} />
                    </Route>
                    <Route path={`${props.match.url}/webhooks`} exact>
                        <Webhooks partner={partner} />
                    </Route>
                </Switch>
            </div>
        </>
    );
}

export default Details;
