import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { default as ReactAutosuggest } from 'react-autosuggest';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import styles from './Autosuggest.module.scss';

const debounce = (fn, time) => {
    let timeoutId
    return wrapper
    function wrapper (...args) {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
              timeoutId = null
              fn(...args)
        }, time)
    }
}

function Autosuggest(props) {
    const { branchId, size, onSelect } = props;
    const officeGroup = props.officeGroup || null;
    const [ value, setValue ] = useState('');
    const [ suggestionsState, setSuggestionsState ] = useState({ suggestions: [], error: '', loading: false });

    const pageSize = 10;
    const history = useHistory();
    const delayedQuery = useCallback(debounce(value => {
        lookupRecipients(value);
    }, 500), []);

    const lookupRecipients = (value) => {
        const getRecipientsByBranch = async () => {
            try {
                let queryStringParameters = { search: value.trim(), pageSize };

                if (officeGroup !== null) {
                    queryStringParameters.officeGroup = officeGroup;
                }

                let suggestions = await API.get('AuthenticatedAPI', `/branches/${branchId}/recipients`, { queryStringParameters });

                setSuggestionsState({ suggestions, error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Recipients.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setSuggestionsState({ suggestions: [], error: `We encountered an unexpected issue while retrieving Recipients for this Branch. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setSuggestionsState({ suggestions: [], error: `We encountered an unexpected issue while retrieving Recipients for this Branch. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        setSuggestionsState({ suggestions: [], error: '', loading: true });
        getRecipientsByBranch();
    };

    const inputProps = {
        value,
        onChange: (e, { newValue }) => {
            if (newValue === '') {
                onSelect(null)
            }

            setValue(newValue);
        }
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        delayedQuery(value);
    };

    return (
        <ReactAutosuggest
            theme={styles}
            suggestions={suggestionsState.suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={() => setSuggestionsState({ suggestions: [], error: '', loading: false })}
            getSuggestionValue={(suggestion) => { onSelect(suggestion); return suggestion.name; }}
            renderSuggestion={suggestion => suggestion.name}
            inputProps={inputProps}
            renderInputComponent={(inputProps) => (
                <>
                    <InputGroup size={size}>
                        <Form.Control size={size} {...inputProps} />
                        <InputGroup.Append>
                            <InputGroup.Text>
                                {suggestionsState.loading ? (
                                    <div key="loading">
                                        <i className="fas fa-spinner fa-fw fa-spin" />
                                    </div>
                                ) : (
                                    <div key="search">
                                        <i className="fas fa-search fa-fw" />
                                    </div>
                                )}
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>

                    {suggestionsState.error && (
                        <Form.Text className="text-orange-200 smaller">{suggestionsState.error}</Form.Text>
                    )}
                </>
            )}
        />
    );
}

export default Autosuggest;
