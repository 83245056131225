import React from 'react';

function ChatSummary(props) {
    const { numberOfChats, numberOfFiles } = props;

    const bronze = 50;
    const silver = 100;
    const gold = 150;

    const maximum = numberOfChats < bronze ? bronze : numberOfChats < silver ? silver : numberOfChats < gold ? gold : null;
    const remaining = maximum ? maximum - numberOfChats : null;
    const progressPercentage = maximum ? numberOfChats / maximum : 1;
    const progressClass = numberOfChats < bronze ? 'bronze' : numberOfChats < silver ? 'silver' : numberOfChats < gold ? 'gold' : 'green-500';

    const chatsClass = numberOfChats < bronze ? 'gray-600' : numberOfChats < silver ? 'bronze' : numberOfChats < gold ? 'silver' : 'gold';

    return (
        <>
            <h4 className="text-center py-3">
                <i className={`fas fa-trophy-alt fa-fw fa-3x mb-2 text-${chatsClass}`}></i><br />
                {numberOfChats} Chats Sent<br />
                <small className="text-muted">{numberOfFiles} files uploaded</small>
            </h4>

            <div className="text-center text-truncate small font-weight-bold" style={{ height: 30 }}>
                <div className={`h-100 py-1 rounded${progressPercentage < 1 && '-left'} bg-striped-${progressClass} float-left`} style={{ width: `${100 * progressPercentage}%` }}>{Math.round(100 * progressPercentage)}%</div>
                <div className={`h-100 py-1 rounded${progressPercentage > 0 && '-right'} border border-gray-600 bg-gray-700 float-right`} style={{ width: `${100 * (1 - progressPercentage)}%` }}></div>
            </div>
            {remaining !== null && (
                <small className="font-weight-medium"><i className={`fas fa-trophy-alt fa-fw text-${progressClass}`}></i> Send {remaining} more Chats</small>
            )}

            <hr />

            <div className="smaller text-muted text-right">in the last 30 days</div>
        </>
    );
}

export default ChatSummary;
