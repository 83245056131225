import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Alert from 'react-bootstrap/Alert';
import { default as BootstrapForm } from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Auth, API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

const CustomPhoneInput = React.forwardRef((props, ref) => <BootstrapForm.Control ref={ref} {...props} />)

function Form(props) {
    const { branch, onNewRecipient } = props;

    const contactPhoneParts = (props.recipient.contactPhone || '').split(',')

    const [ formState, setFormState ] = useState({ message: '', error: '', loading: false });
    const [ name, setName ] = useState(props.recipient.name || '');
    const [ email, setEmail ] = useState(props.recipient.email || '');
    const [ phone, setPhone ] = useState(props.recipient.phone || '');
    const [ contactPhone, setContactPhone ] = useState(contactPhoneParts[0]);
    const [ contactPhoneExt, setContactPhoneExt ] = useState(contactPhoneParts[1])
    const [ jobTitle, setJobTitle ] = useState(props.recipient.jobTitle || '');
    const [ zipCode, setZipCode ] = useState(props.recipient.zipCode || '');
    const [ permissions, setPermissions ] = useState({
        canManageBranch: props.recipient.canManageBranch || false,
        officeGroup: props.recipient.officeGroup || false,
        canViewFieldEmployees: props.recipient.canViewFieldEmployees || false,
        canViewAllBranchNotifications: props.recipient.canViewAllBranchNotifications || false,
        canViewAllChats: props.recipient.canViewAllChats || false,
        canManageEmployees: props.recipient.canManageEmployees || false,
        canManagePatients: props.recipient.canManagePatients || false,
        canManageTeams: props.recipient.canManageTeams || false
    });
    const [ resetPassword, setResetPassword ] = useState(false);

    const history = useHistory();
    const id = props.recipient.id || null;

    if (id === Auth.user.username) {
        history.push('/recipient/contacts/recipients');
    }

    useEffect(() => {
        setName(props.recipient.name || '');
        setEmail(props.recipient.email || '');
        setPhone(props.recipient.phone || '');
        setContactPhone(contactPhoneParts[0] || '');
        setContactPhoneExt(contactPhoneParts[1] || '');
        setJobTitle(props.recipient.jobTitle || '');
        setZipCode(props.recipient.zipCode || '');
        setPermissions({
            canManageBranch: props.recipient.canManageBranch || false,
            officeGroup: props.recipient.officeGroup || false,
            canViewFieldEmployees: props.recipient.canViewFieldEmployees || false,
            canViewAllBranchNotifications: props.recipient.canViewAllBranchNotifications || false,
            canViewAllChats: props.recipient.canViewAllChats || false,
            canManageEmployees: props.recipient.canManageEmployees || false,
            canManagePatients: props.recipient.canManagePatients || false,
            canManageTeams: props.recipient.canManageTeams || false,
        });
        setResetPassword(false);
    }, [
        id,
        props.recipient.name,
        props.recipient.email,
        props.recipient.phone,
        props.recipient.contactPhone,
        props.recipient.contactPhoneExt,
        props.recipient.jobTitle,
        props.recipient.zipCode,
        props.recipient.canManageBranch,
        props.recipient.officeGroup,
        props.recipient.canViewFieldEmployees,
        props.recipient.canViewAllBranchNotifications,
        props.recipient.canViewAllChats,
        props.recipient.canManageEmployees,
        props.recipient.canManagePatients,
        props.recipient.canManageTeams
    ]);

    const togglePermissions = (permissions) => {
        if (permissions.canManageBranch) {
            permissions.officeGroup = true;
            permissions.canManageEmployees = true;
        }

        if (permissions.officeGroup) {
            permissions.canViewFieldEmployees = true;
        } else {
            permissions.canViewAllBranchNotifications = false;
            permissions.canViewAllChats = false;
            permissions.canManageEmployees = false;
            permissions.canManagePatients = false;
            permissions.canManageTeams = false;
        }

        setPermissions(permissions);
    }

    const formSubmit = evt => {
        evt.preventDefault();

        const fullContactPhone = contactPhone + (contactPhoneExt.length > 0 ? ',' : '') + contactPhoneExt

        const postRecipient = async () => {
            try {
                return await API.post('AuthenticatedAPI', `/companies/${branch.companyId}/recipients`, { body: { name, email, phone, contactPhone : fullContactPhone, jobTitle, zipCode } });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ message: '', error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient/contacts/recipients');
                            break;
                        case 409:
                            setFormState({ message: '', error: 'Email is already in use by another account. Please provide a unique email address not already associated with a Notifyd Recipient.', loading: false });
                            break;
                        case 429:
                            setFormState({ message: '', error: error.response.data.message, loading: false });
                            break;
                        default:
                            setFormState({ message: '', error: `We encountered an unexpected issue while creating a Recipient. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ message: '', error: `We encountered an unexpected issue while creating a Recipient. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        const putRecipient = async () => {
            try {
                await API.put('AuthenticatedAPI', `/recipients/${props.recipient.id}`, { body: { name, email, phone, contactPhone : fullContactPhone, jobTitle, zipCode, resetPassword } });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ message: '', error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to edit this Recipient.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/recipient/contacts/recipients');
                            break;
                        case 409:
                            setFormState({ message: '', error: 'Email is already in use by another account. Please provide a unique email address not already associated with a Notifyd Recipient.', loading: false });
                            break;
                        case 429:
                            setFormState({ message: '', error: 'This user has had their password reset too many times. Please try again in an hour.', loading: false });
                            break;
                        default:
                            setFormState({ message: '', error: `We encountered an unexpected issue while updating this Recipient. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ message: '', error: `We encountered an unexpected issue while updating this Recipient. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        const putRecipientBranchRelationship = async (recipientId) => {
            try {
                let { canManageBranch, officeGroup, canViewFieldEmployees, canViewAllBranchNotifications, canViewAllChats, canManageEmployees, canManagePatients, canManageTeams } = permissions;

                await API.put('AuthenticatedAPI', `/branches/${branch.id}/recipients/${recipientId}`, { body: {
                    canManageBranch,
                    officeGroup,
                    canViewFieldEmployees,
                    canViewAllBranchNotifications,
                    canViewAllChats,
                    canManageEmployees,
                    canManagePatients,
                    canManageTeams
                } });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ message: '', error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient/contacts/recipients', {
                                message: 'You are not authorized to edit this Recipient.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/recipient/contacts/recipients');
                            break;
                        default:
                            setFormState({ message: '', error: `We encountered an unexpected issue while assigning permissions for this Recipient. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ message: '', error: `We encountered an unexpected issue while assigning permissions for this Recipient. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        setFormState({ message: '', error: '', loading: true })

        if (id === null) {
            postRecipient().then((recipient) => {
                putRecipientBranchRelationship(recipient.id).then(() => {
                    setFormState({ message: 'Successfully created Employee.', error: '', loading: false });
                    onNewRecipient(recipient);
                });
            });
        } else {
            putRecipient().then(() => {
                putRecipientBranchRelationship(id).then(() => {
                    let message = 'Successfully updated Employee.';

                    if (resetPassword) {
                        message += ` Password reset instructions have been sent to ${phone ? phone : email}`;
                    }

                    setResetPassword(false);
                    setFormState({ message, error: '', loading: false });
                });
            });
        }
    };

    return (
        <BootstrapForm onSubmit={formSubmit}>
            <Row>
                <Col xs="12" sm="6" xl="4">
                    <fieldset>
                        <legend>Contact Details</legend>

                        <BootstrapForm.Group controlId="name">
                            <BootstrapForm.Label>Name</BootstrapForm.Label>
                            <BootstrapForm.Control value={name} required maxLength="64" onChange={e => setName(e.target.value)} />
                            <BootstrapForm.Text className="text-muted">How the employee will appear to others.</BootstrapForm.Text>
                        </BootstrapForm.Group>

                        <BootstrapForm.Group controlId="email">
                            <BootstrapForm.Label>Email</BootstrapForm.Label>
                            <BootstrapForm.Control type="email" maxLength="128" value={email} required onChange={e => setEmail(e.target.value)} />
                            <BootstrapForm.Text className="text-muted">If an account already exists with this email, it will pull their existing information and update any missing fields.</BootstrapForm.Text>
                        </BootstrapForm.Group>

                        <BootstrapForm.Group controlId="phone">
                            <BootstrapForm.Label>Mobile Phone #</BootstrapForm.Label>
                            <PhoneInput defaultCountry="US" limitMaxLength={true} placeholder="Enter phone number" value={phone || ''} onChange={setPhone} inputComponent={CustomPhoneInput} />
                            <BootstrapForm.Text className="text-muted">Used for password resets and text message notices.</BootstrapForm.Text>
                        </BootstrapForm.Group>

                        <BootstrapForm.Group>
                            <Row>
                                <Col xs={12} lg={8}>
                                    <BootstrapForm.Label htmlFor="contactPhone">Contact Phone #</BootstrapForm.Label>
                                    <PhoneInput id="contactPhone" defaultCountry="US" limitMaxLength={true} placeholder="Enter phone number" value={contactPhone || ''} onChange={val => setContactPhone(val === undefined ? '' : val)} inputComponent={CustomPhoneInput} />
                                </Col>
                                <Col xs={5} lg={4}>
                                    <BootstrapForm.Label htmlFor="contactPhoneExt">Ext.</BootstrapForm.Label>
                                    <BootstrapForm.Control id="contactPhoneExt" pattern="[0-9]+" maxLength={5} value={contactPhoneExt || ''} onChange={e => setContactPhoneExt(e.target.value)} />
                                </Col>
                            </Row>
                            <BootstrapForm.Text className="text-muted">Displayed to authorized users so they can call directly.</BootstrapForm.Text>
                        </BootstrapForm.Group>
                    </fieldset>
                </Col>

                <Col xs="12" sm="6" xl="4">
                    <fieldset>
                        <legend>Employment Details</legend>

                        <BootstrapForm.Group controlId="jobTitle">
                            <BootstrapForm.Label>Job Title</BootstrapForm.Label>
                            <BootstrapForm.Control maxLength="32" value={jobTitle || ''} onChange={e => setJobTitle(e.target.value)} />
                        </BootstrapForm.Group>

                        <BootstrapForm.Group controlId="zipCode">
                            <BootstrapForm.Label>ZIP Code</BootstrapForm.Label>
                            <BootstrapForm.Control pattern="^[0-9]{5}$" value={zipCode} onChange={e => setZipCode(e.target.value)} />
                            <BootstrapForm.Text className="text-muted">Used to send shift offers and emergency preparedness alerts in the area.</BootstrapForm.Text>
                            <BootstrapForm.Text className="text-muted font-weight-semibold">Format: 75013</BootstrapForm.Text>
                        </BootstrapForm.Group>
                    </fieldset>
                </Col>

                <Col xs="12" sm={true}>
                    <fieldset>
                        <legend>Groups &amp; Permissions</legend>
                        <small className="form-text text-muted">Groups and Permissions control what an Employee can do and see with Notifyd.</small>

                        {Boolean(branch.canManageBranch) && (
                            <BootstrapForm.Check type="switch" id="canManageBranch" className="my-2">
                                <BootstrapForm.Check.Input checked={permissions.canManageBranch} onChange={e => togglePermissions({ ...permissions, canManageBranch: e.target.checked })} />
                                <BootstrapForm.Check.Label className="font-weight-medium">
                                    Manage Account
                                    <BootstrapForm.Text className="text-muted smaller">Update account, billing, and subscription detail</BootstrapForm.Text>
                                </BootstrapForm.Check.Label>
                            </BootstrapForm.Check>
                        )}

                        <BootstrapForm.Check type="switch" id="officeGroup" className="my-2">
                            <BootstrapForm.Check.Input checked={permissions.officeGroup} onChange={e => togglePermissions({ ...permissions, officeGroup: e.target.checked })} disabled={permissions.canManageBranch } />
                            <BootstrapForm.Check.Label className="font-weight-medium">
                                Office Employee
                                <BootstrapForm.Text className="text-muted smaller">Visible to and can view all employees, can send notifications. If not set, can only view office employees.</BootstrapForm.Text>
                            </BootstrapForm.Check.Label>
                        </BootstrapForm.Check>

                        <BootstrapForm.Check type="switch" id="canViewFieldEmployees" className="my-2">
                            <BootstrapForm.Check.Input checked={permissions.canViewFieldEmployees} onChange={e => togglePermissions({ ...permissions, canViewFieldEmployees: e.target.checked })} disabled={permissions.officeGroup} />
                            <BootstrapForm.Check.Label className="font-weight-medium">
                                View Field Employees
                                <BootstrapForm.Text className="text-muted smaller">Allows field (non-office) employees to view other field employees.</BootstrapForm.Text>
                            </BootstrapForm.Check.Label>
                        </BootstrapForm.Check>

                        <BootstrapForm.Check type="switch" id="canViewAllBranchNotifications" className="my-2">
                            <BootstrapForm.Check.Input checked={permissions.canViewAllBranchNotifications} onChange={e => togglePermissions({ ...permissions, canViewAllBranchNotifications: e.target.checked })} disabled={!permissions.officeGroup} />
                            <BootstrapForm.Check.Label className="font-weight-medium">
                                View Everyone's Sent Notifications
                                <BootstrapForm.Text className="text-muted smaller">See notifications others have sent.</BootstrapForm.Text>
                            </BootstrapForm.Check.Label>
                        </BootstrapForm.Check>

                        <BootstrapForm.Check type="switch" id="canViewAllChats" className="my-2">
                            <BootstrapForm.Check.Input checked={permissions.canViewAllChats} onChange={e => togglePermissions({ ...permissions, canViewAllChats: e.target.checked })} disabled={!permissions.officeGroup} />
                            <BootstrapForm.Check.Label className="font-weight-medium">
                                View Everyone's Chats
                                <BootstrapForm.Text className="text-muted smaller">See chats others have sent.</BootstrapForm.Text>
                            </BootstrapForm.Check.Label>
                        </BootstrapForm.Check>

                        <BootstrapForm.Check type="switch" id="canManageEmployees" className="my-2">
                            <BootstrapForm.Check.Input checked={permissions.canManageEmployees} onChange={e => togglePermissions({ ...permissions, canManageEmployees: e.target.checked })} disabled={permissions.canManageBranch || !permissions.officeGroup} />
                            <BootstrapForm.Check.Label className="font-weight-medium">
                                Manage Employees
                                <BootstrapForm.Text className="text-muted smaller">Create and update employee accounts.</BootstrapForm.Text>
                            </BootstrapForm.Check.Label>
                        </BootstrapForm.Check>

                        <BootstrapForm.Check type="switch" id="canManagePatients" className="my-2">
                            <BootstrapForm.Check.Input checked={permissions.canManagePatients} onChange={e => togglePermissions({ ...permissions, canManagePatients: e.target.checked })} disabled={!permissions.officeGroup} />
                            <BootstrapForm.Check.Label className="font-weight-medium">
                                Manage Patients
                                <BootstrapForm.Text className="text-muted smaller">Create and update care teams.</BootstrapForm.Text>
                            </BootstrapForm.Check.Label>
                        </BootstrapForm.Check>

                        <BootstrapForm.Check type="switch" id="canManageTeams" className="my-2">
                            <BootstrapForm.Check.Input checked={permissions.canManageTeams} onChange={e => togglePermissions({ ...permissions, canManageTeams: e.target.checked })} disabled={!permissions.officeGroup} />
                            <BootstrapForm.Check.Label className="font-weight-medium">
                                Manage Teams
                                <BootstrapForm.Text className="text-muted smaller">Create and update org teams.</BootstrapForm.Text>
                            </BootstrapForm.Check.Label>
                        </BootstrapForm.Check>
                    </fieldset>

                    {id && (
                        <fieldset>
                            <legend>Manage Account</legend>

                            <BootstrapForm.Group>
                                <BootstrapForm.Label>Does this user need their password reset?</BootstrapForm.Label>
                                <BootstrapForm.Check type="switch" id="resetPassword" label="Toggle on to reset" checked={resetPassword} onChange={e => setResetPassword(e.target.checked)} />
                            </BootstrapForm.Group>
                        </fieldset>
                    )}
                </Col>
            </Row>

            {formState.message !== '' && (
                <Alert variant="green-500" className="small">{formState.message}</Alert>
            )}

            {formState.error !== '' && (
                <Alert variant="orange-200" className="small">{formState.error}</Alert>
            )}

            <Button type="submit" variant="green-500" block className="text-white" disabled={formState.loading}>Save</Button>
        </BootstrapForm>
    );
}

export default Form;
