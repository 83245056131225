import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Navigation from './Navigation';
import Register from './Register';
import Verification from './Verification';
import Login from './Login';
import ResetPassword from './ResetPassword';
import Confirmation from './Confirmation';
import Support from './Support';
import Footer from './Footer';
import PageNotFound from './../Exceptions/PageNotFound';

export default function App() {
    return (
        <main style={{ marginTop: 57 }}>
            <Navigation />

            <Switch>
                {/* Session Management Pages */}
                <Route exact path="/" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/verification" component={Verification} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <Route exact path="/confirmation" component={Confirmation} />
                <Route exact path="/support" component={Support} />

                {/* Exceptions */}
                <Route component={PageNotFound} />
            </Switch>

            <Footer />
        </main>
    );
}