import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Container, Row, Col, Form, Button, Alert, ListGroup } from 'react-bootstrap'
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

const TOPICS = {
    ACCOUNT_ACCESS: 'I need help with my account',
    BUG: 'I found a bug',
    TRAINING: 'I need training on how to use Notifyd',
    FEATURE_REQUEST: 'I want Notifyd to add a feature',
    OTHER: 'Other'
}

export default function Support(props) {
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ topic, setTopic ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ loading, setLoading] = useState(false)
    const [ done, setDone ] = useState(false)
    const [ error, setError] = useState('')

    const formSubmit = async (evt) => {
        evt.preventDefault();
   
        setLoading(true)
        setError('')

        try {
            await API.post('UnauthenticatedAPI', '/zendesk-tickets', {
                body: {
                    firstName,
                    lastName,
                    email,
                    topic,
                    message
                }
            });

            setDone(true)
        } catch (error) {
            let formError;

            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        formError = error.response.data.message;
                        break;
                    case 500:
                    default:
                        formError = `We encountered an unexpected issue while creating your account. The service returned error code ${error.response.status}`;
                        Sentry.captureException(error);
                        break;
                }
            } else {
                formError = `We encountered an unexpected issue while creating your account. The service returned error message ${error.message}`;
                Sentry.captureException(error);
            }

            setError(formError)
        } finally {
            setLoading(false)
        }
    };

    return (
        <>
            <Helmet>
                <title>Contact Notifyd Support</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <div className="bg-gray-700 py-5">
                <Container>
                    <div className="text-center mb-5">
                        <h1 className="display-4 font-weight-semibold">Contact Support</h1>
                        <p className="lead">Are you running into issues with your account or one of our apps?</p>
                    </div>

                    <Row>
                        <Col xs="12" lg="8">
                            <div className="bg-white p-3 rounded border border-gray-600">
                                <p className="h4">
                                    <i className="fas fa-pencil fa-fw" /> Please fill out this form and we will get back to you promptly!
                                </p>

                                {done ? (
                                    <p>We have received your support request and will get back to you promptly.</p>
                                ) : (
                                    <Form onSubmit={formSubmit}>
                                        <Row>
                                            <Col sm="6">
                                                <Form.Group controlId="firstName">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="firstName" placeholder="Enter you first name" required value={firstName} onChange={e => setFirstName(e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group controlId="lastName">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="lastName" placeholder="Enter you last name" required value={lastName} onChange={e => setLastName(e.target.value)} />
                                                </Form.Group>    
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                <Form.Group controlId="email">
                                                    <Form.Label>Email Address</Form.Label>
                                                    <Form.Control type="email" placeholder="name@domain.com" required value={email} onChange={e => setEmail(e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group controlId="topicOptions.SelectTopic">
                                                    <Form.Label>Topic</Form.Label>
                                                    <Form.Control as="select" onChange={e => setTopic(e.target.value)}>
                                                        <option value="">Choose...</option>
                                                        {Object.values(TOPICS).map((topic, index) =>
                                                            <option key={index}>{topic}</option>
                                                        )}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {topic !== '' && (
                                            <div className="small">
                                                <strong>Instructions:</strong>
                                                {topic === TOPICS.ACCOUNT_ACCESS ? (
                                                    <ul>
                                                        <li>If your email differs from the email tied to your account, please provide your account email below as well.</li>
                                                        <li>If you're not sure if you have an account, please provide any email address your employer might have on file for you.</li>
                                                    </ul>
                                                ) : topic === TOPICS.BUG ? (
                                                    <ul>
                                                        <li>Please provide the location and/or steps you took when this bug occurred.</li>
                                                        <li>Let us know if this is for our web app, mobile app, or both.</li>
                                                    </ul>
                                                ) : topic === TOPICS.TRAINING ? (
                                                    <ul>
                                                        <li><a href="https://www.youtube.com/channel/UC7hp7aJIlUtyxGP6t5RVtoA?feature=embeds_subscribe_title" target="_blank" rel="noopener noreferrer">Check out these great tutorial videos!</a></li>
                                                    </ul>
                                                ) : topic === TOPICS.FEATURE_REQUEST ? (
                                                    <ul>
                                                        <li>Tell us your ideas as well as which problem you are trying to solve.</li>
                                                        <li>How does not having this feature negatively effect you today?</li>
                                                    </ul>
                                                ) : topic === TOPICS.OTHER ? (
                                                    <ul>
                                                        <li>Please provide any important details you can think of so we can help you as quickly and efficiently as possible.</li>
                                                    </ul>
                                                ) : null}
                                            </div>
                                        )}

                                        <Form.Group controlId="message">
                                            <Form.Label>How can we help?</Form.Label>
                                            <Form.Control as="textarea" placeholder="Provide details on how we can assist" required value={message} onChange={e => setMessage(e.target.value)} rows="4" />
                                        </Form.Group>

                                        {error !== '' && (
                                            <Alert variant="orange-200" className="small">{error}</Alert>
                                        )}

                                        <Button type="submit" variant="green-500" className="text-white" disabled={loading}>Submit</Button>
                                    </Form>
                                )}
                            </div>
                        </Col>
                        <Col xs="12" lg="4">
                            <div className="mt-3">
                                <p className="h4 font-weight-medium">Common Issues</p>

                                <ListGroup className="small">
                                    <ListGroup.Item><a href="https://notifyd.zendesk.com/hc/en-us/articles/360058090631-I-don-t-know-what-my-login-credentials-are" target="_blank" rel="noopener noreferrer">I don't know my login credentials.</a></ListGroup.Item>
                                    <ListGroup.Item><a href="https://notifyd.zendesk.com/hc/en-us/articles/360057613012-I-don-t-see-the-Notifyd-email-in-my-inbox-what-do-I-do-" target="_blank" rel="noopener noreferrer">I don't see the Notifyd email in my inbox; what do I do?</a></ListGroup.Item>
                                    <ListGroup.Item><a href="https://notifyd.zendesk.com/hc/en-us/articles/360058093371-I-received-a-message-saying-my-email-or-password-is-incorrect-what-is-wrong-" target="_blank" rel="noopener noreferrer">I received a message saying my email or password is incorrect; what is wrong?</a></ListGroup.Item>
                                </ListGroup>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
