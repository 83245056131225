import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Table from './Table';
import Pagination from '../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import Filters from "./Filters"

function List() {
    const [ companyState, setCompanyState ] = useState({ companies: null, total: 0, error: '' });
    const [ filters, setFilters ] = useState({ search: "", hasBranch: null });
    const [ pageNumber, setPageNumber ] = useState(0);

    const pageSize = 25;
    const history = useHistory();

    useEffect(() => {
        const getCompanies = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', '/companies', { response: true, queryStringParameters: { pageSize, pageNumber, ...filters } });
                setCompanyState({ companies: response.data, total: response.headers['x-total-count'], error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Companies. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setCompanyState({ companies: null, total: 0, error: `We encountered an unexpected issue while retrieving Companies. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setCompanyState({ companies: null, total: 0, error: `We encountered an unexpected issue while retrieving Companies. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setCompanyState({ companies: null, total: 0, error: '' });
        getCompanies();
    }, [ pageNumber, history, filters ]);

    return (
        <>
            <Helmet>
                <title>Admin | Companies</title>
            </Helmet>

            <div className="p-4">
                <h1>Companies</h1>

                {companyState.error !== '' && (
                    <Alert variant="orange-200" className="small">{companyState.error}</Alert>
                )}

                <hr />
                
                <Filters onSearch={filters => { setFilters(filters); setPageNumber(0); } } loading={companyState.loading} />

                <hr />

                <Table companies={companyState.companies} />

                {companyState.total > 0 && (
                    <Pagination total={companyState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
                )}
            </div>
        </>
    );
}

export default List;
