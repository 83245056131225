import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { default as BootstrapCard } from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Filters from './Filters';
import Card from './Card';
import Pagination from '../../Pagination';
import { Auth, API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function New(props) {
    const { branchId } = props;

    const [ notificationsState, setNotificationsState ] = useState({ notifications: null, total: 0, error: '', loading: false, responding: false });
    const [ filters, setFilters ] = useState({ sendingUserId: null, createdAfter: '', createdBefore: '' });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 30;
    const history = useHistory();

    useEffect(() => {
        const getNotificationsByBranch = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branchId}/notifications`, { response: true, queryStringParameters: { ...filters, recipientId: Auth.user.username, hasResponse: false, pageSize, pageNumber, expand: 'sending-user' } });

                setNotificationsState({ notifications: response.data, total: parseInt(response.headers['x-total-count']), error: '', loading: false, responding: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setNotificationsState({ notifications: null, total: 0, loading: false, error: error.response.data.message, responding: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Notifications for this Branch.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setNotificationsState({ notifications: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Notifications for this Branch. The service returned error code ${error.response.status}`, responding: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setNotificationsState({ notifications: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Notifications for this Branch. The service returned error message ${error.message}`, responding: false });
                    Sentry.captureException(error);
                }
            }
        };

        setNotificationsState({ notifications: null, total: 0, loading: true, error: '', responding: false });
        getNotificationsByBranch();
    }, [ history, branchId, filters, pageNumber ]);

    const respondToNotification = (notification, response) => {
        const postRecipientResponse = async () => {
            try {
                await API.post('AuthenticatedAPI', `/notifications/${notification.id}/responses`, { body: { response: response ? response.text : '' } });

                setNotificationsState({ ...notificationsState, notifications: notificationsState.notifications.filter(x => x.id !== notification.id) });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setNotificationsState({ notifications: null, total: 0, loading: false, error: error.response.data.message, responding: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to respond to this Notification.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setNotificationsState({ notifications: null, total: 0, loading: false, error: `We encountered an unexpected issue while responding to this Notifications. The service returned error code ${error.response.status}`, responding: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setNotificationsState({ notifications: null, total: 0, loading: false, error: `We encountered an unexpected issue while responding to this Notifications. The service returned error message ${error.message}`, responding: false });
                    Sentry.captureException(error);
                }
            }
        };

        setNotificationsState({ ...notificationsState, responding: true });
        postRecipientResponse().then(() => {
            if (response && response.hyperlink) {
                window.open(response.hyperlink, '_blank');
            }
        });
    };

    return (
        <>
            <Helmet>
                <title>Employee | New Notifications</title>
            </Helmet>

            <Filters branchId={branchId} onSearch={setFilters} showSentBy={true} />

            {notificationsState.loading ? (
                <h1 className="mt-3"><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>
            ) : notificationsState.error !== '' ? (
                <Alert variant="orange-200" className="small">{notificationsState.error}</Alert>
            ) : notificationsState.total === 0 ? (
                <h3 className="text-center my-5">No results.</h3>
            ) : (
                <div className="bg-gray-400 mt-2 px-1 rounded">
                    <Form.Row>
                        {notificationsState.notifications.map(notification => 
                            <Col key={notification.id} xs="12" sm="6" md="12" lg="6" xl="4" className="my-1">
                                <Card notification={notification} footer={
                                    <BootstrapCard.Footer>
                                        {notification.responses.length > 0 ? notification.responses.map((response, index) =>
                                            <Button key={index} variant={index === 0 ? 'blue-300' : 'blue-400'} size="sm" block className="text-gray-700 font-weight-medium" onClick={() => respondToNotification(notification, response)} disabled={notificationsState.responding}>
                                                {response.hyperlink && (
                                                    <i className="fas fa-link fa-fw" />
                                                )}

                                                {response.text}
                                            </Button>
                                        ) : (
                                            <Button variant="blue-300" size="sm" block className="text-gray-700 font-weight-medium" onClick={() => respondToNotification(notification, null)} disabled={notificationsState.responding}>Viewed</Button>
                                        )}
                                    </BootstrapCard.Footer>
                                } />
                            </Col>
                        )}
                    </Form.Row>
                </div>
            )}

            <hr />

            {notificationsState.total > 0 && (
                <Pagination total={notificationsState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
            )}
        </>
    );
}

export default New;
