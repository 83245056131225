import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


const Filters = React.memo(props => {
    const [ search, setSearch ] = useState('');
    const [ hasBranch, setHasBranch ] = useState(null);

    const formSubmit = evt => {
        evt.preventDefault();

        props.onSearch({ search, hasBranch });
    }

    return (
        <Form onSubmit={formSubmit}>
            <Form.Group as={Row} controlId="search">
                <Form.Label column xs="4" sm="3" md="4" lg="3" xl="2">Search</Form.Label>
                <Col sm="6" lg="4" xl="3">
                    <Form.Control size="sm" value={search} placeholder="Company Name" autoComplete="off" onChange={e => setSearch(e.target.value)} />
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column xs="4" sm="3" md="4" lg="3" xl="2">Subscription</Form.Label>
                <Col>
                    <Form.Check type="radio" id="all" name="hasBranch" label="All" inline defaultChecked={hasBranch === null} onChange={e => setHasBranch(null)} />
                    <Form.Check type="radio" id="active" name="hasBranch" label="with a Branch" inline defaultChecked={hasBranch} onChange={e => setHasBranch(true)} />
                    <Form.Check type="radio" id="no" name="hasBranch" label="No Branch" inline defaultChecked={hasBranch === false} onChange={e => setHasBranch(false)} />
                </Col>
            </Form.Group>

            <Row>
                <Col xs={{ offset: 4 }} sm={{ offset: 3 }} md={{ offset: 4 }} lg={{ offset: 3 }} xl={{ offset: 2 }}>
                    <Button type="submit" variant="green-500" size="sm" className="text-white" disabled={props.loading}>Search</Button>
                </Col>
            </Row>
        </Form>
    );
});

export default Filters;
