import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Verification(props) {
    const [ code, setCode ] = useState('');
    const [ formState, setFormState ] = useState({ message: '', error: '', loading: false });

    let history = useHistory();

    if (!props.location.state || !props.location.state.email) {
        return <Redirect to="/" />
    }

    const { email } = props.location.state;

    const verifyFormSubmit = async (evt) => {
        evt.preventDefault();
        setFormState({ message: '', error: '', loading: true });

        try {
            await API.post('UnauthenticatedAPI', '/account-confirmation', {
                body: {
                    email,
                    confirmationCode: code
                }
            });

            history.push('/', { message: 'Your account has been successfully verified. You can now login to your account and setup your subscription!' });
        } catch (error) {
            let formError;

            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        formError = error.response.data.message;
                        break;
                    case 403:
                        formError = 'Your verification code does not match what we have on file. Please check your email and try again.';
                        formError = error.response.data.message;
                        break;
                    case 429:
                        formError = 'You have exceeded the number of verification attempts allowed. Please wait some time and try again.';
                        break;
                    case 500:
                    default:
                        formError = `We encountered an unexpected issue while verifying your account. The service returned error code ${error.code}`;
                        Sentry.captureException(error);
                        break;
                }
            } else {
                formError = `We encountered an unexpected issue while verifying your account. The service returned error message ${error.message}`;
                Sentry.captureException(error);
            }

            setFormState({ message: '', error: formError, loading: false });
        }
    }

    const resendFormSubmit = async (evt) => {
        evt.preventDefault();
        setFormState({ message: '', error: '', loading: true });

        try {
            await API.get('UnauthenticatedAPI', '/account-confirmation', {
                queryStringParameters: { email }
            });

            setFormState({ message: 'Another verification code has been sent.', error: '', loading: false });
        } catch (error) {
            let formError;

            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        formError = error.response.data.message;
                        break;
                    case 429:
                        formError = 'You have exceeded the number of confirmation attempts allowed. Please wait some time and try again.';
                        break;
                    case 500:
                    default:
                        formError = `We encountered an unexpected issue while verifying your account. The service returned error code ${error.code}`;
                        Sentry.captureException(error);
                        break;
                }
            } else {
                formError = `We encountered an unexpected issue while verifying your account. The service returned error message ${error.message}`;
                Sentry.captureException(error);
            }

            setFormState({ message: '', error: formError, loading: false });
        }
    }

    return (
        <>
            <Helmet>
                <title>Verify Contact Information</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <div className="bg-gray-700 py-5">
                <Container>
                    <div className="text-center">
                        <h1 className="display-4">Verify your contact information</h1>
                        <p className="lead">Your account is almost complete!</p>
                    </div>

                    <hr />

                    <Row className="justify-content-center pt-5">
                        <Col xs="12" sm="6" lg="5" xl="4">
                            <h3>Next Steps</h3>
                            <p>An email has been sent to {email} to verify your email address. Insert the verification code to complete the registration process.</p>

                            <Form onSubmit={resendFormSubmit}>
                                <p>Can't find your confirmation code?</p>
                                <Button type="submit" variant="orange-300" size="sm" className="text-white" disabled={formState.loading}>Send another verification code</Button>
                            </Form>
                        </Col>

                        <Col xs="12" sm="6" md="5" lg="4" xl="3">
                            <Form onSubmit={verifyFormSubmit}>
                                <Form.Group controlId="code">
                                    <Form.Label>Verification Code</Form.Label>
                                    <Form.Control type="text" required minLength="6" value={code} onChange={e => setCode(e.target.value)} />
                                </Form.Group>

                                {formState.message !== '' && (
                                    <Alert variant="green-500" className="small">{formState.message}</Alert>
                                )}

                                {formState.error !== '' && (
                                    <Alert variant="orange-200" className="small">{formState.error}</Alert>
                                )}

                                <Button type="submit" variant="green-500" className="text-white" disabled={formState.loading}>Verify</Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Verification;
