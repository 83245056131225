import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { default as BootstrapCard } from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Filters from './Filters';
import Card from './Card';
import Pagination from '../../Pagination';
import { Auth, API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Sent(props) {
    const { branchId, canViewAllBranchNotifications, unread } = props;

    const [ notificationsState, setNotificationsState ] = useState({ notifications: null, total: 0, error: '', loading: false, responding: false });
    const [ filters, setFilters ] = useState({ sendingUserId: null, createdAfter: '', createdBefore: '' });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 30;
    const history = useHistory();

    const indexedUnread = {};

    if (unread) {
        unread.forEach(element => {
            indexedUnread[element.id.replace('notification_', '')] = element.count;
        });
    }

    useEffect(() => {
        const getNotificationsByBranch = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branchId}/notifications`, { response: true, queryStringParameters: { ...filters, pageSize, pageNumber, expand: 'response-summary,sending-user' } });

                setNotificationsState({ notifications: response.data, total: parseInt(response.headers['x-total-count']), error: '', loading: false, responding: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setNotificationsState({ notifications: null, total: 0, loading: false, error: error.response.data.message, responding: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to view sent or assigned Notifications for this Branch.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setNotificationsState({ notifications: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Notifications for this Branch. The service returned error code ${error.response.status}`, responding: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setNotificationsState({ notifications: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Notifications for this Branch. The service returned error message ${error.message}`, responding: false });
                    Sentry.captureException(error);
                }
            }
        };

        const getNotificationsByBranchRecipient = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branchId}/recipients/${Auth.user.username}/notifications`, { response: true, queryStringParameters: { ...filters, received: false, sent: true, assigned: true, pageSize, pageNumber, expand: 'response-summary,sending-user' } });

                setNotificationsState({ notifications: response.data, total: response.headers['x-total-count'], error: '', loading: false, responding: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setNotificationsState({ notifications: null, total: 0, loading: false, error: error.response.data.message, responding: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to view sent or assigned Notifications for this Branch.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setNotificationsState({ notifications: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Notifications for this Branch. The service returned error code ${error.response.status}`, responding: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setNotificationsState({ notifications: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Notifications for this Branch. The service returned error message ${error.message}`, responding: false });
                    Sentry.captureException(error);
                }
            }
        };

        setNotificationsState({ notifications: null, total: 0, loading: true, error: '', responding: false });

        if (canViewAllBranchNotifications) {
            getNotificationsByBranch();
        } else {
            getNotificationsByBranchRecipient();
        }
    }, [ history, branchId, canViewAllBranchNotifications, filters, pageNumber ]);

    return (
        <>
            <Helmet>
                <title>Employee | Sent Notifications</title>
            </Helmet>

            <Filters branchId={branchId} onSearch={setFilters} showSentBy={canViewAllBranchNotifications} showSentTo={canViewAllBranchNotifications} />

            {notificationsState.loading ? (
                <h1 className="mt-3"><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>
            ) : notificationsState.error !== '' ? (
                <Alert variant="orange-200" className="small">{notificationsState.error}</Alert>
            ) : notificationsState.total === 0 ? (
                <h3 className="text-center my-5">No results.</h3>
            ) : (
                <div className="bg-gray-400 mt-2 px-1 rounded">
                    <Form.Row>
                        {notificationsState.notifications.map(notification => 
                            <Col key={notification.id} xs="12" sm="6" md="12" lg="6" xl="4" className="my-1">
                                <Card notification={notification} footer={
                                    <BootstrapCard.Footer>
                                        {notification.recipientCount ? (
                                            <Button as={Link} variant="blue-300" size="sm" block className="position-relative text-gray-700" href={`/recipient/notifications/responses/${notification.id}`} to={`/recipient/notifications/responses/${notification.id}`}>
                                                Responses ({notification.responseCount}/{notification.recipientCount})
                                                <Badge variant="orange-200" className="position-absolute text-gray-700" style={{ right: -5, top: -5 }}>{indexedUnread[notification.id]}</Badge>
                                            </Button>
                                        ) : (
                                            <div className="text-muted text-center small">Not sent to anybody</div>
                                        )}
                                    </BootstrapCard.Footer>
                                } />
                            </Col>
                        )}
                    </Form.Row>
                </div>
            )}

            <hr />

            {notificationsState.total > 0 && (
                <Pagination total={notificationsState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
            )}
        </>
    );
}

export default Sent;
