import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from './Table';
import Pagination from '../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Webhooks(props) {
    const [ webhookState, setWebhookState ] = useState({ webhooks: null, total: 0, error: '' });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 25;
    const history = useHistory();

    useEffect(() => {
        const getWebhooks = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/webhooks`, { response: true, queryStringParameters: { pageSize, pageNumber } });
                setWebhookState({ webhooks: response.data, total: response.headers['x-total-count'], error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Webhooks.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setWebhookState({ webhooks: null, total: 0, error: `We encountered an unexpected issue while retrieving Webhooks. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setWebhookState({ webhooks: null, total: 0, error: `We encountered an unexpected issue while retrieving Webhooks. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setWebhookState({ webhooks: null, total: 0, error: '' });
        getWebhooks();
    }, [ pageNumber, history ]);

    return (
        <div className="p-4">
            <h1>Web Hooks</h1>

            <hr/>

            {webhookState.error !== '' && (
                <Alert variant="orange-200" className="small">{webhookState.error}</Alert>
            )}

            <Row>
                <Col xs="12" sm="auto">
                    <Button as={Link} variant="blue-300" size="sm" block className="text-white" to="/partner/web-hooks/new" href="/partner/web-hooks/new">
                        <i className="fas fa-project-diagram fa-fw"></i> Add Webhook
                    </Button>
                </Col>

                <Col xs="12" sm="auto">
                    <Button as={Link} variant="blue-400" size="sm" block className="text-white" to="/partner/web-hooks/implementation-guide" href="/partner/web-hooks/implementation-guide">
                        <i className="fas fa-question-circle fa-fw"></i> Implementation Guide
                    </Button>
                </Col>
            </Row>

            <Table webhooks={webhookState.webhooks} />

            {webhookState.total > 0 && (
                <Pagination total={webhookState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
            )}
        </div>
    );
}

export default Webhooks;
