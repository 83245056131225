import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const Filters = React.memo(props => {
    const { showAssignedTo, showDisabled, loading, onSearch } = props;
    const [ search, setSearch ] = useState('');
    const [ assignedTo, setAssignedTo ] = useState(false);
    const [ disabled, setDisabled ] = useState(false);

    const formSubmit = evt => {
        evt.preventDefault();

        onSearch({
            search,
            assignedTo: assignedTo === '' ? null : assignedTo,
            disabled
        });
    }

    return (
        <Form onSubmit={formSubmit} className="bg-white p-4 border border-top-0 gray-600">
            <Row>
                <Col xs="12" md="4">
                    <Form.Group controlId="search">
                        <Form.Label>Search</Form.Label>
                        <Form.Control size="sm" placeholder="Name" value={search} onChange={e => setSearch(e.target.value)} />
                    </Form.Group>
                </Col>

                {Boolean(showAssignedTo) && (
                    <Col xs="6"  md="4">
                        <Form.Group controlId="assignedTo">
                            <Form.Label>Viewing</Form.Label>
                            <Form.Control as="select" size="sm" value={assignedTo} onChange={e => setAssignedTo(e.target.value)}>
                                <option value="">All</option>
                                <option value={true}>Teams I am assigned to</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                )}

                {Boolean(showDisabled) && (
                    <Col xs="12" md="4">
                        <Form.Group controlId="disabled">
                            <Form.Label>Status</Form.Label>
                            <Form.Check type="checkbox" value={true} checked={disabled} label="Deactivated" onChange={e => setDisabled(e.target.checked)} />
                        </Form.Group>
                    </Col>
                )}
            </Row>

            <Button type="submit" variant="green-500" size="sm" className="text-white mb-1" disabled={loading}>Search</Button>
        </Form>
    );
});

export default Filters;
