import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Alert from 'react-bootstrap/Alert';
import { default as BootstrapForm } from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

const CustomPhoneInput = React.forwardRef((props, ref) => <BootstrapForm.Control ref={ref} {...props} />)

function Form(props) {
    const contactPhoneParts = (props.recipient.contactPhone || '').split(',')

    const [ formState, setFormState ] = useState({ message: '', error: '', loading: false });
    const [ name, setName ] = useState(props.recipient.name);
    const [ email, setEmail ] = useState(props.recipient.email);
    const [ phone, setPhone ] = useState(props.recipient.phone);
    const [ contactPhone, setContactPhone ] = useState(contactPhoneParts[0] || '');
    const [ contactPhoneExt, setContactPhoneExt ] = useState(contactPhoneParts[1] || '')
    const [ jobTitle, setJobTitle ] = useState(props.recipient.jobTitle);
    const [ zipCode, setZipCode ] = useState(props.recipient.zipCode);
    const [ resetPassword, setResetPassword ] = useState(false);
    const [ branchesState, setBranchesState ] = useState({ branches: [], error: '', loading: true });
    const { lastLogin, deviceToken, smsOptOut, doNotDisturb, userStatus } = props.recipient;
    const history = useHistory();
    const id = props.recipient.id;

    useEffect(() => {
        const getBranchesByRecipient = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/recipients/${id}/branches`);
                setBranchesState({ branches: response, error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setBranchesState({ branches: [], error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Branches for this Recipient. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/admin/recipients');
                            break;
                        default:
                            setBranchesState({ branches: [], error: `We encountered an unexpected issue while retrieving Branches for this Recipient. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setBranchesState({ branches: [], error: `We encountered an unexpected issue while retrieving Branches for this Recipient. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        getBranchesByRecipient();
    }, [ id, history ]);

    const formSubmit = evt => {
        evt.preventDefault();

        const putRecipient = async () => {
            try {
                const fullContactPhone = contactPhone + (contactPhoneExt.length > 0 ? ',' : '') + contactPhoneExt

                await API.put('AuthenticatedAPI', `/recipients/${props.recipient.id}`, { body: { name, email, phone, contactPhone : fullContactPhone, jobTitle, zipCode, resetPassword } });

                let message = 'Successfully updated Recipient.';

                if (resetPassword) {
                    message += ` Password reset instructions have been sent to ${phone ? phone : email}`;
                }

                setResetPassword(false);
                setFormState({ message, error: '', loading: false });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ message: '', error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to edit this Recipient. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/admin/recipients');
                            break;
                        case 409:
                            setFormState({ message: '', error: 'Email is already in use by another account. Please provide a unique email address not already associated with a Notifyd Recipient.', loading: false });
                            break;
                        case 429:
                            setFormState({ message: '', error: 'This user has had their password reset too many times. Please try again in an hour.', loading: false });
                            break;
                        default:
                            setFormState({ message: '', error: `We encountered an unexpected issue while updating Recipients. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ message: '', error: `We encountered an unexpected issue while updating Recipients. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        setFormState({ message: '', error: '', loading: true })
        putRecipient();
    };

    return (
        <BootstrapForm onSubmit={formSubmit}>
            <div className="bg-white rounded border border-gray-500 p-3 my-3">
                <Row>
                    <Col xs="12" sm="6" xl="4">
                        <fieldset>
                            <legend>Contact Details</legend>

                            <BootstrapForm.Group controlId="name">
                                <BootstrapForm.Label>Name</BootstrapForm.Label>
                                <BootstrapForm.Control value={name || ''} required onChange={e => setName(e.target.value)} />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group controlId="email">
                                <BootstrapForm.Label>Email</BootstrapForm.Label>
                                <BootstrapForm.Control type="email" value={email} required onChange={e => setEmail(e.target.value)} />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group controlId="phone">
                                <BootstrapForm.Label>Mobile Phone #</BootstrapForm.Label>
                                <PhoneInput defaultCountry="US" limitMaxLength={true} placeholder="Enter phone number" value={phone || ''} onChange={setPhone} inputComponent={CustomPhoneInput} />
                                <BootstrapForm.Text className="text-muted">Used for password resets and text message notices.</BootstrapForm.Text>
                            </BootstrapForm.Group>

                            <BootstrapForm.Group>
                                <Row>
                                    <Col xs={12} lg={8}>
                                        <BootstrapForm.Label htmlFor="contactPhone">Contact Phone #</BootstrapForm.Label>
                                        <PhoneInput id="contactPhone" defaultCountry="US" limitMaxLength={true} placeholder="Enter phone number" value={contactPhone || ''} onChange={val => setContactPhone(val === undefined ? null : val)} inputComponent={CustomPhoneInput} />
                                    </Col>
                                    <Col xs={5} lg={4}>
                                        <BootstrapForm.Label htmlFor="contactPhoneExt">Ext.</BootstrapForm.Label>
                                        <BootstrapForm.Control id="contactPhoneExt" pattern="[0-9]+" maxLength={5} value={contactPhoneExt || ''} onChange={e => setContactPhoneExt(e.target.value)} />
                                    </Col>
                                </Row>
                                <BootstrapForm.Text className="text-muted">Displayed to authorized users so they can call directly.</BootstrapForm.Text>
                            </BootstrapForm.Group>
                        </fieldset>
                    </Col>

                    <Col xs="12" sm="6" xl="4">
                        <fieldset>
                            <legend>Employment Details</legend>

                            <BootstrapForm.Group controlId="jobTitle">
                                <BootstrapForm.Label>Job Title</BootstrapForm.Label>
                                <BootstrapForm.Control value={jobTitle || ''} onChange={e => setJobTitle(e.target.value)} />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group controlId="zipCode">
                                <BootstrapForm.Label>ZIP Code</BootstrapForm.Label>
                                <BootstrapForm.Control pattern="^[0-9]{5}$" value={zipCode || ''} onChange={e => setZipCode(e.target.value)} />
                                <BootstrapForm.Text className="text-muted">Used to send shift offers and emergency preparedness alerts in the area.</BootstrapForm.Text>
                                <BootstrapForm.Text className="text-muted font-weight-semibold">Format: 75013</BootstrapForm.Text>
                            </BootstrapForm.Group>

                            <BootstrapForm.Group>
                                <BootstrapForm.Label>Employed At (Branches)</BootstrapForm.Label>
                                <ul>
                                    {branchesState.loading ? (
                                        <li key="loading" className="small text-muted"><i className="fas fa-spinner fa-fw fa-spin" /></li>
                                    ) : branchesState.branches.length > 0 ? branchesState.branches.map(branch =>
                                        <li key={branch.id}>
                                            <Link href={`/admin/branches/${branch.id}`} to={`/admin/branches/${branch.id}`} className="small">{branch.name}</Link>
                                        </li>
                                    ) : (
                                        <li key="empty" className="small text-muted">No Companies</li>
                                    )}
                                </ul>
                            </BootstrapForm.Group>
                        </fieldset>
                    </Col>

                    <Col xs="12" sm="6" xl="4">
                        <fieldset>
                            <legend>Support</legend>

                            <BootstrapForm.Group>
                                <BootstrapForm.Label>Does this user need their password reset?</BootstrapForm.Label>
                                <BootstrapForm.Check type="switch" id="resetPassword" label="Yes" onChange={e => setResetPassword(e.target.checked)} checked={resetPassword} />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group>
                                <BootstrapForm.Label>Is this user opted in to receive SMS messages?</BootstrapForm.Label>
                                <BootstrapForm.Check type="switch" id="smsOptOut" label="Yes" disabled={true} checked={!smsOptOut} />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group>
                                <BootstrapForm.Label>Is this user in do not disturb mode?</BootstrapForm.Label>
                                <BootstrapForm.Check type="switch" id="doNotDisturb" label="Yes" disabled={true} checked={doNotDisturb} />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group>
                                <BootstrapForm.Label>Last logged in</BootstrapForm.Label>
                                <BootstrapForm.Control value={lastLogin ? new Date(lastLogin).toLocaleString() : 'Never'} disabled={true} />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group>
                                <BootstrapForm.Label>Device Token</BootstrapForm.Label>
                                <BootstrapForm.Control value={deviceToken || ''} disabled={true} />
                            </BootstrapForm.Group>

                            <BootstrapForm.Group>
                                <BootstrapForm.Label>User Status</BootstrapForm.Label>
                                <BootstrapForm.Control value={userStatus} disabled={true} />
                                <BootstrapForm.Text className="text-muted smaller">
                                    <span className="font-weight-semibold">UNCONFIRMED</span>: registered, no confirmed email
                                </BootstrapForm.Text>
                                <BootstrapForm.Text className="text-muted smaller">
                                    <span className="font-weight-semibold">CONFIRMED</span>: registered, confirmed email
                                </BootstrapForm.Text>
                                <BootstrapForm.Text className="text-muted smaller">
                                    <span className="font-weight-semibold">RESET_REQUIRED</span>: requires new password
                                </BootstrapForm.Text>
                                <BootstrapForm.Text className="text-muted smaller">
                                    <span className="font-weight-semibold">FORCE_CHANGE_PASSWORD</span>: temporary password
                                </BootstrapForm.Text>
                            </BootstrapForm.Group>
                        </fieldset>
                    </Col>
                </Row>
            </div>

            {formState.message !== '' && (
                <Alert variant="green-500" className="small">{formState.message}</Alert>
            )}

            {formState.error !== '' && (
                <Alert variant="orange-200" className="small">{formState.error}</Alert>
            )}

            <Button type="submit" variant="green-500" className="text-white" disabled={formState.loading}>Save</Button>
        </BootstrapForm>
    );
}

export default Form;
