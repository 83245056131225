import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from './Form';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Details(props) {
    const [ webhook, setWebhook ] = useState(null);
    const [ error, setError ] = useState('');
    const [ deleteState, setDeleteState ] = useState({ error: '', loading: false });

    const history = useHistory();
    const id = props.match.params.id;
    
    useEffect(() => {
        const getWebhook = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/webhooks/${id}`);
                setWebhook(response);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setError(error.response.data.message);
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Webhook. Please log in as a partner to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/partner/web-hooks');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving this Webhook. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving this Webhook. The service returned error message ${error.message}`);
                    Sentry.captureException(error);
                }
            }
        };

        if (id !== 'new') {
            setError('');
            getWebhook();
        }
    }, [ id, history ]);

    const deleteButtonClick = evt => {
        const deleteWebhook = async () => {
            try {
                await API.del('AuthenticatedAPI', `/webhooks/${webhook.eventType}`);

                history.push('/partner/web-hooks');
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setDeleteState({ error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to delete this Webhook. Please log in as a partner to continue.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setDeleteState({ error: `We encountered an unexpected issue while deleting the Webhook. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setDeleteState({ error: `We encountered an unexpected issue while deleting the Webhook. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        setDeleteState({ error: '', loading: true });
        deleteWebhook();
    };

    if (id !== 'new' && webhook === null) {
        return (
            <div className="p-4">
                <h1><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>

                {error !== '' && (
                    <Alert variant="orange-200" className="small">{error}</Alert>
                )}
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Partner | Manage Web Hook</title>
            </Helmet>

            <div className="p-4">
                {id === 'new' ? (
                    <h1>Create New Webhook</h1>
                ) : (
                    <h1>{id}</h1>
                )}

                <hr />

                {id !== 'new' && (
                    <>
                        {deleteState.error !== '' && (
                            <Alert variant="orange-200" className="small">{deleteState.error}</Alert>
                        )}

                        <Row>
                            <Col xs="12" sm="auto">
                                <Button variant="blue-300" size="sm" block className="text-white" onClick={deleteButtonClick} disabled={deleteState.loading}>
                                    <i className="fas fa-trash fa-fw"></i> Delete Webhook
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}

                <Row className="mt-3">
                    <Col xs="12" lg={{ span: true, order: 2 }}>
                        <h4>What does this do?</h4>
                        <p>Webhooks allow us to notify you of events that occur within Notifyd in near real-time, and removes the need for you to poll our API to see what's happening.</p>
                        <p>Event Types follow the nomenclature of <code>NOUN.VERB</code>. For example, whenever a Recipient responds to a Notification, it will trigger the <code>response</code>.<code>created</code> event.</p>
                    </Col>

                    <Col xs="12" lg="6" xl="5">
                        <Form webhook={webhook} />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Details;
