import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { default as BootstrapForm } from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Picker from '../Contacts/Picker';
import Autosuggest from '../Contacts/Recipients/Autosuggest';
import { Auth, API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

let recipient = null

function Form(props) {
    const { branch } = props;

    const [ title, setTitle ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ file, setFile ] = useState(null);
    const [ response1, setResponse1 ] = useState({ text: '', hyperlink: '' });
    const [ response2, setResponse2 ] = useState({ text: '', hyperlink: '' });
    const [ responseAlert, setResponseAlert ] = useState(false);
    const [ doesNotContainPHI, setDoesNotContainPHI ] = useState(false);
    const [ critical, setCritical ] = useState(false);
    const [ recipients, setRecipients ] = useState([]);
    const [ patients, setPatients ] = useState([]);
    const [ teams, setTeams ] = useState([]);
    const [ assignedRecipients, setAssignedRecipients ] = useState([  ]);
    const [ formState, setFormState ] = useState({ message: '', error: '', saving: false });
    const [ uploadProgress, setUploadProgress ] = useState(null);

    const history = useHistory();

    useEffect(() => {
        const getRecipient = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/recipients/${Auth.user.username}`);

                recipient = response
                selectAssignedRecipient(response)
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Recipient profile. Please login to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/', {
                                message: 'We could not find your account. Please contact support for assistance.',
                                redirect: history.location
                            });
                            break;
                        default:
                            // Do nothing for now.
                            // TODO - Add proper error handling.
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    // Do nothing for now.
                    // TODO - Add proper error handling.
                    Sentry.captureException(error);
                }
            }
        }

        getRecipient()
    }, [])

    const handleDoesNotContainPHI = checked => {
        if (checked) {
            if (!window.confirm('This notification will be shown in a less secure manner. Make sure the title and body both do not contain PHI before sending.')) {
                return;
            }
        }

        setDoesNotContainPHI(checked);
    };

    const handleCritical = checked => {
        if (checked) {
            if (!window.confirm('This notification will alert recipients whether their phone is muted or in Do Not Disturb mode, and should only be used in emergency or critical situations. Are you sure you want to do this?')) {
                return;
            }
        }

        setCritical(checked);
    };

    const selectRecipient = (recipient, checked) => {
        if (checked) {
            setRecipients(recipients.concat(recipient));
        } else {
            if (Array.isArray(recipient)) {
                setRecipients(recipients.filter(x => !recipient.some(y => x.id === y.id)));
            } else {
                setRecipients(recipients.filter(x => x.id !== recipient.id));
            }
        }
    };

    const selectPatient = (patient, checked) => {
        if (checked) {
            setPatients(patients.concat(patient));
        } else {
            if (Array.isArray(patient)) {
                setPatients(patients.filter(x => !patient.some(y => x.id === y.id)));
            } else {
                setPatients(patients.filter(x => x.id !== patient.id));
            }
        }
    };

    const selectTeam = (team, checked) => {
        if (checked) {
            setTeams(teams.concat(team));
        } else {
            if (Array.isArray(team)) {
                setTeams(teams.filter(x => !team.some(y => x.id === y.id)));
            } else {
                setTeams(teams.filter(x => x.id !== team.id));
            }
        }
    };

    const selectAssignedRecipient = recipient => {
        if (recipient !== null) {
            setAssignedRecipients(assignedRecipients.concat(recipient));
        }
    };

    const deselectAssignedRecipient = recipient => {
        setAssignedRecipients(assignedRecipients.filter(x => x.id !== recipient.id));
    };

    const formSubmit = evt => {
        evt.preventDefault();

        const postNotification = async (fileId) => {
            try {
                await API.post('AuthenticatedAPI', `/branches/${branch.id}/notifications`, { body: { title, message, fileId, responses: [ response1, response2 ], responseAlert, doesNotContainPHI, critical, assignedRecipientIds: assignedRecipients.map(x => x.id), recipientIds: recipients.map(x => x.id), patientIds: patients.map(x => x.id), teamIds: teams.map(x => x.id) }});
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            throw new Error(error.response.data.message);
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 402:
                            throw new Error('Your branch has been disabled for non-payment.');
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to edit this Recipient.',
                                redirect: history.location
                            });
                            break;
                        default:
                            Sentry.captureException(error);
                            throw new Error(`We encountered an unexpected issue while sending this Notification. The service returned error code ${error.response.status}`);
                    }
                } else {
                    Sentry.captureException(error);
                    throw new Error(`We encountered an unexpected issue while sending this Notification. The service returned error message ${error.message}`);
                }
            }
        };

        const postNotificationFileByBranch = async (fileExt) => {
            try {
                return await API.post('AuthenticatedAPI', `/branches/${branch.id}/notification-files`, { body: { fileExt } });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setFormState({ message: '', error: error.response.data.message, saving: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to edit this Recipient.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setFormState({ message: '', error: `We encountered an unexpected issue while securing a file upload location. The service returned error code ${error.response.status}`, saving: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setFormState({ message: '', error: `We encountered an unexpected issue while securing a file upload location. The service returned error message ${error.message}`, saving: false });
                    Sentry.captureException(error);
                }
            }
        };

        setFormState({ ...formState, message: '', error: '', saving: true });

        const clearState = () => {
            setTitle('');
            setMessage('');
            setFile(null);
            setResponse1({ text: '', hyperlink: '' });
            setResponse2({ text: '', hyperlink: '' });
            setResponseAlert(false);
            setDoesNotContainPHI(false);
            setCritical(false);
            setRecipients([]);
            setPatients([]);
            setTeams([]);
            setAssignedRecipients([ recipient ]);
            setFormState({ message: 'Successfully sent Notification.', error: '', saving: false });
            setUploadProgress(null);
        };

        const handleError = error => {
            setFormState({ message: '', error: error.message, saving: false });
        };

        if (file) {
            let fileExt = file.name.split('.').pop();

            postNotificationFileByBranch(fileExt).then(secureFileUri => {
                const { fileId, uri } = secureFileUri;

                let xhr = new XMLHttpRequest();
                xhr.open('put', uri);

                xhr.onload = () => {
                    postNotification(fileId).then(clearState).catch(handleError);
                };

                xhr.onerror = error => {
                    setFormState({ message: '', error: error.message, saving: false });
                    Sentry.captureException(error);
                };

                if (xhr.upload) {
                    xhr.upload.onprogress = (progressEvent) => {
                        let progress = Math.round(100 * progressEvent.loaded / progressEvent.total);
                        setUploadProgress(progress);
                    };
                }

                xhr.send(file);
            });
        } else {
            postNotification().then(clearState).catch(handleError);
        }
    };

    return (
        <>
            <div className="bg-white p-4 border border-top-0 border-gray-600 rounded-bottom">
                <Row className="align-items-center">
                    <Col xs="12" sm="3" lg="2" className="text-center">
                        <div className="bg-gray-300 rounded-circle d-inline-block p-3">
                            <i className="fas fa-3x fa-envelope fa-fw text-gray-700"></i>
                        </div>

                        <hr className="d-sm-none" />
                    </Col>

                    <Col xs="12" sm="9" lg="10">
                        <h4>Send a notification to your employees and optionally gather responses.</h4>
                        <p className="small">This is great for letting your employees know about early office closings, inclement weather, expiring licenses, license renewal classes, and seeing who is available for shift call outs.</p>
                        <p className="small">Employees will be able to see who sent the notification, and can follow up directly with you through Notifyd if they have any questions.</p>
                        <p className="small mb-0">If the employee has not logged in before, they will receive a reminder text to download the app so they can view the notification.</p>
                    </Col>
                </Row>
            </div>

            <Row className="mt-3">
                <Col xs="12" lg="8">
                    <fieldset>
                        <legend>What do you want to say?</legend>

                        <BootstrapForm.Group controlId="title">
                            <BootstrapForm.Label>Title</BootstrapForm.Label>
                            <BootstrapForm.Control value={title} onChange={e => setTitle(e.target.value)} required />
                        </BootstrapForm.Group>

                        <BootstrapForm.Group controlId="message">
                            <BootstrapForm.Label>Body</BootstrapForm.Label>
                            <BootstrapForm.Control as="textarea" value={message} onChange={e => setMessage(e.target.value)} required className="small" rows="9" />
                        </BootstrapForm.Group>

                        <BootstrapForm.Group>
                            <BootstrapForm.Label>Options</BootstrapForm.Label>
                            <BootstrapForm.Check type="checkbox" id="responseAlert" label="Send a mobile alert when someone responds" checked={responseAlert} onChange={e => setResponseAlert(e.target.checked)} />
                            <BootstrapForm.Check type="checkbox" id="doesNotContainPHI" label="This message does not contain PHI" checked={doesNotContainPHI} onChange={e => handleDoesNotContainPHI(e.target.checked)} />
                            <BootstrapForm.Check type="checkbox" id="critical" label="This is an emergency" checked={critical} onChange={e => handleCritical(e.target.checked)} />
                        </BootstrapForm.Group>

                        <BootstrapForm.Group controlId="fileUpload">
                            <BootstrapForm.Label>File Attachment</BootstrapForm.Label>
                            <BootstrapForm.File className="custom-file">
                                <BootstrapForm.File.Input className="custom-file-input" onChange={e => setFile(e.target.files[0]) } />
                                <BootstrapForm.File.Label className="custom-file-label">{file ? file.name : 'Choose File'}</BootstrapForm.File.Label>
                            </BootstrapForm.File>
                        </BootstrapForm.Group>
                    </fieldset>
                </Col>

                <Col xs="12" lg="4">
                    <fieldset>
                        <legend>Responses</legend>

                        <Row>
                            <Col xs="6" lg="12">
                                <BootstrapForm.Group controlId="response1Text">
                                    <BootstrapForm.Label>Response 1 Text</BootstrapForm.Label>
                                    <BootstrapForm.Control maxLength={32} value={response1.text} onChange={e => setResponse1({ ...response1, text: e.target.value })} />
                                </BootstrapForm.Group>

                                <BootstrapForm.Group controlId="response1Hyperlink" className="mb-0">
                                    <BootstrapForm.Label>Response 1 Website URL</BootstrapForm.Label>
                                    <BootstrapForm.Control type="url" value={response1.hyperlink} onChange={e => setResponse1({ ...response1, hyperlink: e.target.value })} />
                                </BootstrapForm.Group>
                            </Col>

                            <Col xs="6" lg={{ span: 12, order: 1 }}>
                                <BootstrapForm.Group controlId="response2Text">
                                    <BootstrapForm.Label>Response 2 Text</BootstrapForm.Label>
                                    <BootstrapForm.Control maxLength={32} value={response2.text} onChange={e => setResponse2({ ...response2, text: e.target.value })} />
                                </BootstrapForm.Group>

                                <BootstrapForm.Group controlId="response2Hyperlink">
                                    <BootstrapForm.Label>Response 2 Website URL</BootstrapForm.Label>
                                    <BootstrapForm.Control type="url" value={response2.hyperlink} onChange={e => setResponse2({ ...response2, hyperlink: e.target.value })} />
                                </BootstrapForm.Group>
                            </Col>

                            <Col xs="12" className="mb-3">
                                <small className="text-muted"><i className="fas fa-info-circle" /> Provide a website URL to open when they click this response.</small>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>

            <hr />

            <Row className="mb-3">
                <Col>
                    <fieldset>
                        <legend>Who will receive this?</legend>

                        <Picker branch={branch} showRecipients={true} selectedRecipients={recipients} onSelectRecipient={selectRecipient} showPatients={true} selectedPatients={patients} onSelectPatient={selectPatient} showTeams={true} selectedTeams={teams} onSelectTeam={selectTeam} showRecipientUpload={true} allowSelectPage={true} />
                    </fieldset>
                </Col>

                <Col xs="12" lg="4">
                    <fieldset>
                        <legend>Who needs to see responses?</legend>

                        <BootstrapForm.Group controlId="assignedTo">
                            <BootstrapForm.Label>Assigned To</BootstrapForm.Label>
                            <Autosuggest branchId={branch.id} officeGroup={true} onSelect={selectAssignedRecipient} />
                            <BootstrapForm.Text className="text-muted smaller">Search by name, email, phone number, job title, or ZIP code</BootstrapForm.Text>
                        </BootstrapForm.Group>

                        <div>
                            {assignedRecipients.map(recipient =>
                                <ListGroup.Item key={recipient.id}>
                                    <BootstrapForm.Check id={recipient.id} label={recipient.name} checked={true} onChange={e => deselectAssignedRecipient(recipient)} />
                                </ListGroup.Item>
                            )}
                        </div>
                    </fieldset>
                </Col>
            </Row>

            {formState.message !== '' && (
                <Alert variant="green-500" className="small">{formState.message}</Alert>
            )}

            {formState.error !== '' && (
                <Alert variant="orange-200" className="small">{formState.error}</Alert>
            )}

            {uploadProgress !== null && (
                <Alert variant="green-500" className="small"><i className="fas fa-spinner fa-fw fa-spin" /> Uploading... {uploadProgress}%</Alert>
            )}

            <Button variant="green-500" block className="text-white" onClick={formSubmit} disabled={formState.saving}>Send</Button>
        </>
    );
}

export default Form;
