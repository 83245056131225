import React from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

export default function Footer() {
    return (
        <div className="bg-gray-100 py-4">
            <Container className="text-gray-600">
                <Row>
                    <Col xs="6">
                        <p className="mb-1">Resources</p>
                        <hr className="border-yellow-200" />

                        <Row>
                            <Col xs="12" md="6" xl="5">
                                <Button variant="link" href="https://notifyd.com/terms/customer" className="text-gray-700 text-left small">Customer Terms of Service</Button>
                                <Button variant="link" href="https://notifyd.com/terms/user" className="text-gray-700 text-left small">User Terms of Service</Button>
                                <Button variant="link" href="https://notifyd.com/terms/partner" className="text-gray-700 text-left small">Partner Terms of Service</Button>
                                <Button variant="link" href="https://notifyd.com/terms/privacy" className="text-gray-700 text-left small">Privacy Policy</Button>
                            </Col>

                            <Col xs="12" md="6" xl="5">
                                <Button variant="link" href="https://notifyd.com/brand" className="text-gray-700 text-left small">Brand Resources</Button>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs="6">
                        <p className="mb-1">Contact Us</p>
                        <hr className="border-yellow-200" />

                        <Button href="https://notifyd.com/contact" variant="link" className="text-left small text-gray-700">Sales &amp; Inquiries</Button><br/>
                        <Button as={Link} href="/support" to="/support" variant="link" className="text-left small text-gray-700">Existing Customer &amp; User Support</Button>
                    </Col>
                </Row>

                <hr className="border-yellow-200" />

                <Row className="justify-content-between">
                    <Col xs="6"><small>&copy; {new Date().getFullYear()} Notifyd, LLC. All Rights Reserved.</small></Col>
                    <Col xs="6">
                        <Button variant="link" size="sm" className="text-blue-400" href="https://www.linkedin.com/company/notifyd-llc/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin fa-2x fa-fw"></i></Button>
                        <Button variant="link" size="sm" className="text-blue-400" href="https://www.youtube.com/channel/UC7hp7aJIlUtyxGP6t5RVtoA?feature=embeds_subscribe_title" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube fa-2x fa-fw"></i></Button>
                        <Button variant="link" size="sm" className="text-blue-400" href="https://www.facebook.com/thenotifydapp/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook fa-2x fa-fw"></i></Button>
                        <Button variant="link" size="sm" className="text-blue-400" href="https://twitter.com/thenotifydapp?lang=en" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter fa-2x fa-fw"></i></Button>
                    </Col>
                </Row>
                
            </Container>
        </div>
    )
}