import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Table from '../Companies/Table';
import Pagination from '../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Companies(props) {
    const [ companyState, setCompanyState ] = useState({ companies: null, total: 0, error: '' });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 25;
    const history = useHistory();
    const id = props.partner.id;

    useEffect(() => {
        const getCompaniesByPartner = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/partners/${id}/companies`, { response: true, queryStringParameters: { pageSize, pageNumber } });
                setCompanyState({ companies: response.data, total: response.headers['x-total-count'], error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setCompanyState({ companies: null, total: 0, error: error.response.data.message });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Companies for this Partner. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/admin/partners');
                            break;
                        default:
                            setCompanyState({ companies: null, total: 0, error: `We encountered an unexpected issue while retrieving Companies for this Partner. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setCompanyState({ companies: null, total: 0, error: `We encountered an unexpected issue while retrieving Companies for this Partner. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setCompanyState({ companies: null, total: 0, error: '' });
        getCompaniesByPartner();
    }, [ id, pageNumber, history ]);

    return (
        <>
            <hr/>

            {companyState.error !== '' && (
                <Alert variant="orange-200" className="small">{companyState.error}</Alert>
            )}

            <Table companies={companyState.companies} />

            {companyState.total > 0 && (
                <Pagination total={companyState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
            )}
        </>
    );
}

export default Companies;
