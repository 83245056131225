import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Table from './Table';
import Pagination from '../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function List() {
    const [ partnerState, setPartnerState ] = useState({ partners: null, total: 0, error: '' });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 25;
    const history = useHistory();

    useEffect(() => {
        const getPartners = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', '/partners', { response: true, queryStringParameters: { pageSize, pageNumber } });
                setPartnerState({ partners: response.data, total: response.headers['x-total-count'], error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Partners. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setPartnerState({ partners: null, total: 0, error: `We encountered an unexpected issue while retrieving Partners. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setPartnerState({ partners: null, total: 0, error: `We encountered an unexpected issue while retrieving Partners. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setPartnerState({ partners: null, total: 0, error: '' });
        getPartners();
    }, [ pageNumber, history ]);

    return (
        <>
            <Helmet>
                <title>Admin | Partners</title>
            </Helmet>

            <div className="p-4">
                <h1>Partners</h1>

                {partnerState.error !== '' && (
                    <Alert variant="orange-200" className="small">{partnerState.error}</Alert>
                )}

                <hr />

                <Table partners={partnerState.partners} />

                {partnerState.total > 0 && (
                    <Pagination total={partnerState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
                )}
            </div>
        </>
    );
}

export default List;
