import React, { useState, useEffect, useRef } from 'react';
import { Switch, Route, Redirect, Link, useLocation, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { default as Dashboard } from './Dashboard/App';
import { default as Notifications } from './Notifications/App';
import { default as Contacts } from './Contacts/App';
import { default as Chat } from './Chat/App';
import Reports from './Reports';
import Profile from './Profile/Details';
import Settings from './Settings/Details';
import Subscribe from './Settings/Subscribe';
import Help from './Help'
import { Auth, API } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import Favicon from 'react-favicon';    
import logo from '../../images/logo-full.png';

const BRANCH_COOKIE_KEY = 'branch';
const UNREAD_INTERVAL = 15000;
let FAVICON_SHOW_COUNT = true;
const FAVICON_ANIMATION_SPEED = 1500;

function App(props) {
    const [ unreadState, setUnreadState ] = useState({ unread: null, error: '' });
    const [ branchesState, setBranchesState ] = useState({ branches: null, currentBranch: null, error: '', loading: true });
    const [ doNotDisturbState, setDoNotDisturbState ] = useState({ doNotDisturb: null, loading: true, error: ''})
    const [ showHelp, setViewHelp ] = useState(false)

    const history = useHistory();
    const location = useLocation();

    const previousUnreadRef = useRef();
    useEffect(() => { previousUnreadRef.current = unreadState.unread; });

    useEffect(() => {
        const getDoNotDisturb = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/recipients/${Auth.user.username}`);

                setDoNotDisturbState(doNotDisturbState => { return { ...doNotDisturbState, doNotDisturb: response.doNotDisturb, loading: false }});
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Recipient profile. Please login to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            setDoNotDisturbState(doNotDisturbState => { return { ...doNotDisturbState, error: 'We could not find your account. Please contact support for assistance.' }});
                            break;
                        default:
                            setDoNotDisturbState(doNotDisturbState => { return { ...doNotDisturbState, error: `We encountered an unexpected issue while retrieving Recipient profile. The service returned error code ${error.response.status}` }});
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setDoNotDisturbState(doNotDisturbState => { return { ...doNotDisturbState, error: `We encountered an unexpected issue while retrieving Recipient profile. The service returned error message ${error.message}` }});
                    Sentry.captureException(error);
                }
            }
        };

        const getUnread = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', '/unread-summary');

                setUnreadState({ unread: response, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access unread messages. Please log in as an employee to continue.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            setUnreadState({ unread: { recipientId: Auth.user.username, count: 0, branches: [] }, error: '' });
                            break;
                        default:
                            setUnreadState({ unread: null, error: `We encountered an unexpected issue while retrieving Branches. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setUnreadState({ unread: null, error: `We encountered an unexpected issue while retrieving Branches. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        const getBranches = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', '/branches', { queryStringParameters: { expand: 'branch-recipient' }});

                let v = document.cookie.match(`(^|;) ?${BRANCH_COOKIE_KEY}=([^;]*)(;|$)`);
                let branchId = (v && v[2] ? v[2] : null);
                let branch = response.find(x => x.id === parseInt(branchId));
                let currentBranch = branch ? branch : response.length > 0 ? response[0] : null;

                if (currentBranch !== null) {
                    let d = new Date();
                    d.setTime(d.getTime() + 24*60*60*1000*30); // 30 days
                    document.cookie = `${BRANCH_COOKIE_KEY}=${currentBranch.id};path=/;expires=${d.toGMTString()}`;
                }

                setBranchesState({ branches: response.length > 0 ? response : null, currentBranch, error: '', loading: false });

            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Branches. Please log in as an employee to continue.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setBranchesState({ branches: null, currentBranch: null, error: `We encountered an unexpected issue while retrieving Branches. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setBranchesState({ branches: null, currentBranch: null, error: `We encountered an unexpected issue while retrieving Branches. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        getDoNotDisturb();  
        getUnread();
        getBranches();

        const interval = setInterval(() => {
            getUnread();
        }, UNREAD_INTERVAL);

        return () => clearInterval(interval);
    }, [ history ]);

    if (props.group !== 'individual') {
        return (
            <Redirect to={{ pathname: '/', state: { message: 'You must be logged in as an employee to access this area.' }}} />
        );
    }

    const setBranch = id => {
        let branch = branchesState.branches.find(x => x.id === parseInt(id));

        if (branch) {
            let d = new Date();
            d.setTime(d.getTime() + 24*60*60*1000*30); // 30 days
            document.cookie = `${BRANCH_COOKIE_KEY}=${branch.id};path=/;expires=${d.toGMTString()}`;
            history.push('/recipient');

            setBranchesState({ ...branchesState, currentBranch: branch });
        }
    };

    const newBranch = branch => {
        let branches = (branchesState.branches || []).concat(branch);

        setBranchesState({ ...branchesState, branches, currentBranch: branch });
        history.push('/recipient');
    };

    const handleDoNotDisturb = async doNotDisturb => {
        API.patch('AuthenticatedAPI', `/recipients/${Auth.user.username}`, { body: { doNotDisturb } }).then(data => {
            setDoNotDisturbState({  ...doNotDisturbState, doNotDisturb });
        }).catch(error => {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setDoNotDisturbState({  ...doNotDisturbState, error: error.response.data.message });
                        break;
                    case 401:
                        history.push('/', {
                            message: 'Your session has expired. Please log back in to continue.',
                            redirect: history.location
                        });
                        break;
                    case 403:
                        history.push('/', {
                            message: 'You are not authorized to update this Recipient.',
                            redirect: history.location
                        });
                        break;
                    case 404:
                        setDoNotDisturbState({  ...doNotDisturbState, error: 'We could not find your account. Please contact support for assistance.' });
                        break;
                    default:
                        setDoNotDisturbState({  ...doNotDisturbState, error: `We encountered an unexpected issue while updating this Recipient. The service returned error code ${error.response.status}` });
                        Sentry.captureException(error);
                        break;
                }
            } else {
                setDoNotDisturbState({  ...doNotDisturbState, error: `We encountered an unexpected issue while updating this Recipient. The service returned error message ${error.message}` });
                Sentry.captureException(error);
            }
        });
    };

    const showNotification = (title) => {
        const options = {
            badge: 'https://notifyd.com/images/logo-md.png',
            icon: 'https://notifyd.com/images/logo-md.png'
        };
        const onclick = function(event) { window.focus(); this.close(); };

        if (("Notification" in window)) {
            if (!doNotDisturbState.doNotDisturb) {
                if (Notification.permission === "granted") {
                    new Notification(title, options).onclick = onclick;
                } else if (Notification.permission !== "denied") {
                    Notification.requestPermission().then(function (permission) {
                        // If the user accepts, let's create a notification
                        if (permission === "granted") {
                            new Notification(title, options).onclick = onclick;
                        }
                    });
                }

                const audio = new Audio('/audio/notification.wav');

                try {
                    audio.play()
                } catch (error) {
                    // Ignore Autoplay NotAllowedErrors as they will go away once the user interacts with the page.
                }
            }
        }
    };

    let unreadCurrentBranch;
    let unreadOtherBranches = 0;
    let unreadNotifications = 0;
    let unreadContacts = 0;
    let unreadBranchIndex = {};

    if (unreadState.unread !== null && branchesState.currentBranch !== null) {
        unreadState.unread.branches.forEach(branch => {
            let total = branch.notificationCount + branch.chatCount;
            unreadBranchIndex[branch.id] = total;

            if (parseInt(branch.id) !== branchesState.currentBranch.id) {
                unreadOtherBranches += total;
            }
        });

        unreadCurrentBranch = unreadState.unread.branches.find(x => parseInt(x.id) === branchesState.currentBranch.id);

        if (unreadCurrentBranch) {
            unreadNotifications = unreadState.unread.branches.find(x => parseInt(x.id) === branchesState.currentBranch.id).notificationCount;
            unreadContacts = unreadState.unread.branches.find(x => parseInt(x.id) === branchesState.currentBranch.id).chatCount;
        }

        if (previousUnreadRef.current !== null) {
            let newChats = 0;
            let newNotifications = 0;

            unreadState.unread.branches.forEach(branch => {
                const previousBranch = previousUnreadRef.current.branches.find(x => x.id === branch.id);

                branch.recipients.forEach(recipient => {
                    const previousRecipient = previousBranch ? previousBranch.recipients.find(x => x.id === recipient.id) : null;

                    newChats += previousRecipient ? Math.max(0, recipient.count - previousRecipient.count) : recipient.count;
                });

                branch.notifications.forEach(notification => {
                    const previousNotification = previousBranch ? previousBranch.notifications.find(x => x.id === notification.id) : null;

                    newNotifications += previousNotification ? Math.max(0, notification.count - previousNotification.count) : notification.count;
                });
            });

            if (newChats > 0) {
                showNotification(`${newChats} New Message${newChats > 1 ? 's' : ''}`);
            }

            if (newNotifications > 0) {
                showNotification(`${newNotifications} New Notification Response${newNotifications > 1 ? 's' : ''}`);
            }
        }
    }

    const unreadTotal = unreadNotifications + unreadContacts;
    
    return (
        <>
            <Favicon
                url={['/favicon.ico']}
                animated={true}
                animationDelay={FAVICON_ANIMATION_SPEED}
                renderOverlay={unreadTotal > 0 ? (canvas, context) => {
                    FAVICON_SHOW_COUNT = !FAVICON_SHOW_COUNT;

                    if (!FAVICON_SHOW_COUNT) {
                        return null;
                    }

                    const top = 0
                    const left = canvas.width/3
                    const bottom = 2*canvas.height/3
                    const right = canvas.width
                    const radius = 4

                    context.fillStyle = '#E05C47'
                    context.strokeStyle = '#E05C47'
                    context.lineWidth = 1

                    context.beginPath()
                    context.moveTo(left + radius, top)
                    context.quadraticCurveTo(left, top, left, top + radius)
                    context.lineTo(left, bottom - radius)
                    context.quadraticCurveTo(left, bottom, left + radius, bottom)
                    context.lineTo(right - radius, bottom)
                    context.quadraticCurveTo(right, bottom, right, bottom - radius)
                    context.lineTo(right, top + radius)
                    context.quadraticCurveTo(right, top, right - radius, top)
                    context.closePath()
                    context.fill()

                    context.font = 'normal 10px arial'
                    context.fillStyle = '#FFF'
                    context.textAlign = 'center'
                    context.textBaseline = 'top'
                    context.fillText(unreadTotal > 9 ? '!' : unreadTotal, 2*canvas.width/3, 1)
                } : null}
            />

            <Row noGutters className="bg-gray-700">
                <Col as="aside" xs="12" md="3" xl="2" className="bg-blue-100 sticky-top vh-md-100 mh-100v overflow-x-hidden">
                    <Navbar variant="dark" expand="md" className="align-items-md-start flex-md-column px-3" collapseOnSelect={true}>
                        <Navbar.Brand>
                            <Image src={logo} alt="Notifyd logo" width="100" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="primary-navigation">
                            <i className="fas fa-bars text-white"></i>
                        </Navbar.Toggle>

                        <Navbar.Collapse id="primary-navigation" className="mt-2 w-100">
                            <Nav className="flex-column w-100" activeKey={location.pathname}>
                                {branchesState.loading ? (
                                    <Nav.Item key="loading-branches">
                                        <i className="fas fa-spinner fa-fw fa-lg fa-spin text-yellow-200" />
                                    </Nav.Item>
                                ) : branchesState.branches !== null && (
                                    <Nav.Item>
                                        <Form.Group controlId="branches" className="position-relative">
                                            <Form.Label className="text-yellow-200 font-weight-medium">Show me data for</Form.Label>
                                            {branchesState.branches.length > 1 ? (
                                                <>
                                                    <NavDropdown title={branchesState.currentBranch.name} className="w-100 bg-blue-400 rounded text-gray-700 small p-0">
                                                        {branchesState.branches.map(x =>
                                                            <NavDropdown.Item key={x.id} className="text-truncate w-100 px-3 smaller" onClick={() => setBranch(x.id)}>
                                                                {x.name} {unreadBranchIndex[x.id] && (
                                                                    <Badge variant="orange-200" className="text-gray-700">{unreadBranchIndex[x.id]}</Badge>
                                                                )}
                                                            </NavDropdown.Item>
                                                        )}
                                                    </NavDropdown>
                                                    <Badge variant="orange-200" className="position-absolute text-gray-700" style={{ right: -10, top: 20 }}>
                                                        {unreadOtherBranches > 0 && unreadOtherBranches}
                                                    </Badge>
                                                </>
                                            ) : (
                                                <div className="text-gray-700 font-weight-medium small">{branchesState.branches[0].name}</div>
                                            )}
                                        </Form.Group>
                                    </Nav.Item>
                                )}

                                {doNotDisturbState.loading ? (
                                    <div key="loading-donotdisturb" className="mt-2"><i className="fas fa-spinner fa-fw fa-lg fa-spin text-yellow-200" /></div>
                                ) : (
                                    <Form.Check type="switch" id="doNotDisturb" label="Do not disturb" className="text-gray-700" checked={doNotDisturbState.doNotDisturb} onChange={e => handleDoNotDisturb(!doNotDisturbState.doNotDisturb)} disabled={doNotDisturbState.loading} />
                                )}

                                {branchesState.currentBranch !== null && (
                                    <>
                                        <Nav.Item><hr className="border-blue-300 my-3 py-0" /></Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link as={Link} href="/recipient" to="/recipient" className="small">
                                                <i className="fas fa-tachometer-alt fa-fw"></i> Dashboard
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link as={Link} href="/recipient/notifications/new" to="/recipient/notifications/new" className="small">
                                                <i className="fas fa-envelope fa-fw" /> Notifications <Badge variant="orange-200" className="text-gray-700">{unreadNotifications > 0 && unreadNotifications}</Badge>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link as={Link} href="/recipient/contacts/recipients" to="/recipient/contacts/recipients" className="small">
                                                <i className="fas fa-users fa-fw" /> Contacts <Badge variant="orange-200" className="text-gray-700">{unreadContacts > 0 && unreadContacts}</Badge>
                                            </Nav.Link>
                                        </Nav.Item>
                                        {Boolean(branchesState.currentBranch.canManageBranch || branchesState.currentBranch.canManageEmployees || branchesState.currentBranch.canViewAllBranchNotifications) && (
                                            <Nav.Item>
                                                <Nav.Link as={Link} href="/recipient/reports" to="/recipient/reports" className="small">
                                                    <i className="fas fa-chart-line fa-fw" /> Reports
                                                </Nav.Link>
                                            </Nav.Item>
                                        )}

                                        <Nav.Item><hr className="border-blue-300 my-3 py-0" /></Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link as={Link} href="/recipient/profile" to="/recipient/profile" className="small">
                                                <i className="fas fa-user-cog fa-fw" /> My Profile
                                            </Nav.Link>
                                        </Nav.Item>
                                        {Boolean(branchesState.currentBranch.canManageBranch) && (
                                            <Nav.Item>
                                                <Nav.Link as={Link} href="/recipient/settings" to="/recipient/settings" className="small">
                                                    <i className="fas fa-cogs fa-fw" /> Account Settings
                                                </Nav.Link>
                                            </Nav.Item>
                                        )}
                                    </>
                                )}

                                <Nav.Item><hr className="border-blue-300 my-3 py-0" /></Nav.Item>

                                <Nav.Item>
                                    <Nav.Link href="#" onClick={() => setViewHelp(true)} className="small text-blue-400">
                                        <i className="fas fa-question-circle fa-fw" /> Help
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/api-docs" target="_blank" className="small text-blue-400">
                                        <i className="fas fa-book fa-fw"></i> API
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="small text-blue-400" onClick={() => props.onLogout()}>
                                        <i className="fas fa-sign-out-alt fa-fw"></i> Logout
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>

                <Col as="main" xs="12" md="9" xl="10">
                    {branchesState.loading ? (
                        <div className="p-4">
                            <h1><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>
                        </div>
                    ) : branchesState.currentBranch ? (
                        <Switch>
                            <Route path="/recipient" exact>
                                <Dashboard key={branchesState.currentBranch.id} branch={branchesState.currentBranch} unread={unreadCurrentBranch} />
                            </Route>
                            <Route path="/recipient/notifications">
                                <Notifications branch={branchesState.currentBranch} unread={unreadCurrentBranch && unreadCurrentBranch.notifications} />
                            </Route>
                            <Route path="/recipient/contacts">
                                <Contacts branch={branchesState.currentBranch} unread={unreadCurrentBranch && unreadCurrentBranch.recipients} />
                            </Route>
                            <Route path="/recipient/chat" render={routeProps =>
                                <Chat branch={branchesState.currentBranch} unread={unreadCurrentBranch && unreadCurrentBranch.recipients} {...routeProps.location.state} />
                            } />
                            <Route path="/recipient/reports" exact>
                                <Reports branch={branchesState.currentBranch} />
                            </Route>
                            <Route path="/recipient/profile" exact component={Profile} />
                            <Route path="/recipient/settings" exact>
                                <Settings branchId={branchesState.currentBranch.id} />
                            </Route>
                            <Route path="/recipient/settings/new" exact>
                                <Subscribe onNewBranch={newBranch} />
                            </Route>
                        </Switch>
                    ) : (
                        <Subscribe onNewBranch={newBranch} newCard={true} />
                    )}
                </Col>
            </Row>

            {showHelp && (
                <Help branch={branchesState.currentBranch} hideHelp={() => setViewHelp(false)}/>
            )}
        </>
    );
}

export default App;
