import React, { useEffect, useState } from 'react';
import { Link, Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { Alert, Badge, Nav, NavDropdown } from 'react-bootstrap';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import { default as RecipientList } from './Recipients/List';
import { default as RecipientDetails } from './Recipients/Details';
import { default as RecipientUpload } from './Recipients/Upload';
import { default as UploadKantime } from './Recipients/UploadKantime'
import { default as PatientList } from './Patients/List';
import { default as PatientDetails } from './Patients/Details';
import { default as TeamList } from './Teams/List';
import { default as TeamDetails } from './Teams/Details';

function App(props) {
    const { branch, unread } = props;
    const [ partner, setPartner ] = useState(null)
    const [ error, setError ] = useState(null)

    const location = useLocation();
    const history = useHistory();

    const employeeUnread = unread && unread.reduce((accumulator, item) => accumulator + (!item.id.startsWith("patient") && !item.id.startsWith("team") ? item.count : 0), 0);
    const patientUnread = unread && unread.reduce((accumulator, item) => accumulator + (item.id.startsWith("patient") ? item.count : 0), 0);
    const teamUnread = unread && unread.reduce((accumulator, item) => accumulator + (item.id.startsWith("team") ? item.count : 0), 0);
    const totalUnread = employeeUnread + patientUnread + teamUnread || 0;

    useEffect(() => {
        const getPartner = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/partners/${branch.partnerId}`);
                setPartner(response);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setError(error.response.data.message);
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Partner.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            setError('We were unable to retrieve integrated partner information for your location. If you are receiving this error, please contact Notifyd Support through your Help menu.')
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving this Recipient. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving this Recipient. The service returned error message ${error.message}`);
                    Sentry.captureException(error);
                }
            }
        }

        if (branch.partnerId) {
            getPartner()
        }
    }, [ history, branch.partnerId ]);

    return (
        <>
            <div className="p-4">
                <h1>Contacts</h1>

                {error !== null && (
                    <Alert variant="orange-200" className="small">{error}</Alert>
                )}

                <Nav variant="tabs small" activeKey={location.pathname}>
                    <Nav.Item className="d-none d-sm-inline">
                        <Nav.Link as={Link} to="/recipient/contacts/recipients" href="/recipient/contacts/recipients">
                            <i className="fas fa-user-tie fa-fw" /> Employees
                            {employeeUnread !== 0 && (
                                <Badge variant="orange-200" className="ml-1">{employeeUnread}</Badge>
                            )}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="d-none d-sm-inline">
                        <Nav.Link as={Link} to="/recipient/contacts/patients" href="/recipient/contacts/patients">
                            <i className="fas fa-user-injured fa-fw" /> Care Teams
                            {patientUnread !== 0 && (
                                <Badge variant="orange-200" className="ml-1">{patientUnread}</Badge>
                            )}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="d-none d-sm-inline mr-auto">
                        <Nav.Link as={Link} to="/recipient/contacts/teams" href="/recipient/contacts/teams">
                            <i className="fas fa-users fa-fw" /> Teams
                            {teamUnread !== 0 && (
                                <Badge variant="orange-200" className="ml-1">{teamUnread}</Badge>
                            )}
                        </Nav.Link>
                    </Nav.Item>

                    {Boolean(branch.canManageEmployees) && (
                        <NavDropdown title={<><i className="fas fa-plus fa-fw" /> Employee</>} className="d-none d-xl-inline">
                            <NavDropdown.Item as={Link} to="/recipient/contacts/recipients/new" href="/recipient/contacts/recipients/new" className="action small">
                                <i className="fas fa-user-tie fa-fw" /> Create One
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/recipient/contacts/recipients/upload" href="/recipient/contacts/recipients/upload" className="action small">
                                <i className="fas fa-upload fa-fw" /> Upload Multiple
                            </NavDropdown.Item>
                            {partner !== null && partner.name === 'Kantime' && (
                                <NavDropdown.Item as={Link} to="/recipient/contacts/recipients/upload-kantime" href="/recipient/contacts/recipients/upload-kantime" className="action small">
                                    <i className="fas fa-upload fa-fw" /> Kantime Import
                                </NavDropdown.Item>
                            )}
                        </NavDropdown>
                    )}
                    {Boolean(branch.canManagePatients) && (
                        <Nav.Item className="d-none d-xl-inline">
                            <Nav.Link as={Link} to="/recipient/contacts/patients/new" href="/recipient/contacts/patients/new" className="action">
                                <i className="fas fa-plus fa-fw" /> Care Team
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    {Boolean(branch.canManageTeams) && (
                        <Nav.Item className="d-none d-xl-inline">
                            <Nav.Link as={Link} to="/recipient/contacts/teams/new" href="/recipient/contacts/teams/new" className="action">
                                <i className="fas fa-plus fa-fw" /> Team
                            </Nav.Link>
                        </Nav.Item>
                    )}

                    <NavDropdown title={<>Viewing {totalUnread !== 0 && (<Badge variant="orange-200">{totalUnread}</Badge>)}</>} className="d-sm-none mr-auto">
                        <NavDropdown.Item as={Link} to="/recipient/contacts/recipients" href="/recipient/contact/recipients" className="small">
                            <i className="fas fa-user-tie fa-fw" /> Employees
                            {employeeUnread !== 0 && (
                               <Badge variant="orange-200" className="ml-1">{employeeUnread}</Badge>
                            )}
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/recipient/contacts/patients" href="/recipient/contact/patients" className="small">
                            <i className="fas fa-user-injured fa-fw" /> Care Teams
                            {patientUnread !== 0 && (
                               <Badge variant="orange-200" className="ml-1">{patientUnread}</Badge>
                            )}
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/recipient/contacts/teams" href="/recipient/contact/teams" className="small">
                            <i className="fas fa-users fa-fw" /> Teams
                            {teamUnread !== 0 && (
                               <Badge variant="orange-200" className="ml-1">{teamUnread}</Badge>
                            )}
                        </NavDropdown.Item>
                    </NavDropdown>

                    {Boolean(branch.canManageEmployees || branch.canManagePatients || branch.canManageTeams) && (
                        <NavDropdown title="Create" className="d-xl-none">
                            {Boolean(branch.canManageEmployees) && (
                                <NavDropdown.Item as={Link} to="/recipient/contacts/recipients/new" href="/recipient/contacts/recipients/new" className="action small">
                                    <i className="fas fa-plus fa-fw" /> Employee
                                </NavDropdown.Item>
                            )}
                            {Boolean(branch.canManagePatients) && (
                                <NavDropdown.Item as={Link} to="/recipient/contacts/patients/new" href="/recipient/contacts/patients/new" className="action small">
                                    <i className="fas fa-plus fa-fw" /> Care Team
                                </NavDropdown.Item>
                            )}
                            {Boolean(branch.canManageTeams) && (
                                <NavDropdown.Item as={Link} to="/recipient/contacts/teams/new" href="/recipient/contacts/teams/new" className="action small">
                                    <i className="fas fa-plus fa-fw" /> Team
                                </NavDropdown.Item>
                            )}
                            {Boolean(branch.canManageEmployees) && (
                                <>
                                    <hr/>

                                    <NavDropdown.Item as={Link} to="/recipient/contacts/recipients/upload" href="/recipient/contacts/recipients/upload" className="action small">
                                        <i className="fas fa-upload fa-fw" /> Upload Employees
                                    </NavDropdown.Item>
                                </>
                            )}
                        </NavDropdown>
                    )}
                </Nav>

                <Switch>
                    <Route path="/recipient/contacts/recipients" exact>
                        <RecipientList branch={branch} unread={unread} />
                    </Route>
                    <Route path="/recipient/contacts/recipients/upload" exact render={props =>
                        <RecipientUpload branch={branch} />
                    } />
                    <Route path="/recipient/contacts/recipients/upload-kantime" exact render={props =>
                        <UploadKantime branch={branch} />
                    } />
                    <Route path="/recipient/contacts/recipients/:id" exact render={(props) =>
                        <RecipientDetails branch={branch} recipientId={props.match.params.id} />
                    } />
                    <Route path="/recipient/contacts/patients" exact>
                        <PatientList branch={branch} unread={unread} />
                    </Route>
                    <Route path="/recipient/contacts/patients/:id" exact render={(props) =>
                        <PatientDetails branch={branch} patientId={props.match.params.id} />
                    } />
                    <Route path="/recipient/contacts/teams" exact>
                        <TeamList branch={branch} unread={unread} />
                    </Route>
                    <Route path="/recipient/contacts/teams/:id" exact render={(props) =>
                        <TeamDetails branch={branch} teamId={props.match.params.id} />
                    } />
                </Switch>
            </div>
        </>
    );
}

export default App;
