import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import List from './List';
import Details from './Details';
import { Auth, API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

const REFRESH_INTERVAL = 15000;
const PAGE_NUMBER = 0;
const PAGE_SIZE = 30;

function App(props) {
    const { branchId, unread } = props;
    const [ notifications, setNotifications ] = useState(null);
    const [ error, setError ] = useState(null);
    const [ notification, setNotification ] = useState(null);
    const [ filters, setFilters ] = useState(null)

    const history = useHistory();

    useEffect(() => {
        const getNotificationsByBranchRecipient = async () => {
            try {
                let response;
                
                if(filters.hasOwnProperty('ids') && filters.ids === '') {
                    response = [];
                } else {
                    response = await API.get('AuthenticatedAPI', `/branches/${branchId}/recipients/${Auth.user.username}/notifications`, { queryStringParameters : { received: true, sent: true, assigned: true, hasResponse: false, ...filters, expand: 'sending-user,response-summary', sort: 'lastTouched', pageNumber: PAGE_NUMBER, pageSize: PAGE_SIZE } });
                }

                setNotifications(response);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to retrieve Notifications. For assistance, please contact Notifyd Support through your Help menu.'
                            });
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving recently sent messages. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving recently sent messages. The service returned error message ${error.message}`);
                    Sentry.captureException(error);
                }
            }
        };

        if (filters !== null) {
            getNotificationsByBranchRecipient();

            const interval = setInterval(() => {
                getNotificationsByBranchRecipient();
            }, REFRESH_INTERVAL);

            return () => clearInterval(interval);
        }
    }, [ branchId, filters, history ])

    const removeNotification = notificationId => {
        setNotifications(notifications.filter(x => x.id !== notificationId));
    };

    if (error) {
        return <ListGroup.Item className="text-center text-orange-200">{error}</ListGroup.Item>
    }

    return (
        <>
            <div className={notification !== null ? 'd-none' : undefined}>
                <List notifications={notifications} unread={unread} setFilters={setFilters} setNotification={setNotification} />
            </div>

            {notification !== null && (
                <Details notification={notification} unsetNotification={() => setNotification(null)} removeNotification={removeNotification} />
            )}
        </>
    );
}

export default App;
