import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Col, Form, Row, Table as BootstrapTable } from 'react-bootstrap';
import { default as ChatButton } from '../../Chat/Button'
import { default as CallButton } from '../../Call/Button'
import { Auth, API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Table(props) {
    const { branchId, canManageEmployees, canManageBranch, disabled } = props;
    const unread = props.unread || [];
    const [ tableState, setTableState ] = useState({ recipients: props.recipients, error: '', loading: false });

    const userId = Auth.user.username;
    const history = useHistory();

    useEffect(() => {
        setTableState({ recipients: props.recipients, error: '', loading: false });
    }, [ props.recipients ]);

    const putRecipientBranchRelationship = async (recipient) => {
        try {
            let { canManageBranch, officeGroup, canViewFieldEmployees, canViewAllBranchNotifications, canViewAllChats, canManageEmployees, canManagePatients, canManageTeams } = recipient;

            await API.put('AuthenticatedAPI', `/branches/${branchId}/recipients/${recipient.id}`, { body: {
                canManageBranch,
                officeGroup,
                canViewFieldEmployees,
                canViewAllBranchNotifications,
                canViewAllChats,
                canManageEmployees,
                canManagePatients,
                canManageTeams
            } });

            let updatedRecipients = tableState.recipients.map(x => x.id === recipient.id ? recipient : x);
            setTableState({ recipients: updatedRecipients, error: '', loading: false });
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setTableState({ ...tableState, error: error.response.data.message, loading: false });
                        break;
                    case 401:
                        history.push('/', {
                            message: 'Your session has expired. Please log back in to continue.',
                            redirect: history.location
                        });
                        break;
                    case 403:
                        history.push('/', {
                            message: 'You are not authorized to edit this Recipient.',
                            redirect: history.location
                        });
                        break;
                    case 404:
                        history.push('/recipient/contacts/recipients');
                        break;
                    default:
                        setTableState({ ...tableState, error: `We encountered an unexpected issue while retrieving Recipients. The service returned error code ${error.response.status}`, loading: false });
                        Sentry.captureException(error);
                        break;
                }
            } else {
                setTableState({ ...tableState, error: `We encountered an unexpected issue while retrieving Recipients. The service returned error message ${error.message}`, loading: false });
                Sentry.captureException(error);
            }
        }
    };

    const updateRecipient = (recipient) => {
        if (recipient.canManageBranch) {
            recipient.officeGroup = true;
            recipient.canManageEmployees = true;
        }

        if (recipient.officeGroup) {
            recipient.canViewFieldEmployees = true;
        } else {
            recipient.canViewAllBranchNotifications = false;
            recipient.canViewAllChats = false;
            recipient.canManageEmployees = false;
            recipient.canManagePatients = false;
            recipient.canManageTeams = false;
        }

        setTableState({ ...tableState, error: '', loading: true });
        putRecipientBranchRelationship(recipient);
    }

    const activateRecipient = (recipient) => {
        setTableState({ ...tableState, error: '', loading: true });
        putRecipientBranchRelationship(recipient).then(() => {
            let updatedRecipients = tableState.recipients.filter(x => x.id !== recipient.id);
            setTableState({ recipients: updatedRecipients, error: '', loading: false });
        });
    };

    return (
        <>
            <Row className="smaller justify-content-end my-3">
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-comment fa-fw"></i> Chat Employee
                </Col>
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-phone fa-fw"></i> Call Employee
                </Col>
                {Boolean(canManageEmployees) && (
                    <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                        <i className="fas fa-edit fa-fw"></i> Edit Employee
                    </Col>
                )}
            </Row>

            <BootstrapTable responsive striped bordered hover className="bg-white">
                <thead className="bg-gray-200 text-white small font-weight-normal">
                    <tr>
                        <th>Employee</th>
                        {canManageEmployees ? (
                            <>
                                <th>Groups / Permissions</th>
                                <th>Onboard Status</th>
                            </>
                        ) : (
                            <th>Group</th>
                        )}
                        <th width={canManageEmployees ? 172 : 122}></th>
                    </tr>
                </thead>
                <tbody>
                    {tableState.recipients === null ? (
                        <tr key="loading">
                            <td colSpan={canManageEmployees ? 4 : 3} className="text-center">
                                <i className="fas fa-spinner fa-fw fa-lg fa-spin" />
                            </td>
                        </tr>
                    ) : tableState.recipients.length === 0 ? (
                        <tr key="none">
                            <td colSpan={canManageEmployees ? 4 : 3} className="text-center">
                                No results found
                            </td>
                        </tr>
                    ) : tableState.recipients.map(recipient => {
                        let lastLogin = 'Never';

                        if (recipient.lastLogin) {
                            let lastLoginParts = recipient.lastLogin.split(/\D+/);
                            let lastLoginDate = new Date(Date.UTC(lastLoginParts[0], --lastLoginParts[1], lastLoginParts[2], lastLoginParts[3], lastLoginParts[4], lastLoginParts[5], lastLoginParts[6]));
                            lastLogin = `${lastLoginDate.toLocaleDateString()} ${lastLoginDate.toLocaleTimeString()}`;
                        }

                        let unreadRecipient = unread.find(x => x.id === recipient.id);
                        let unreadCount = unreadRecipient ? unreadRecipient.count : null;

                        // For passing through to Chat
                        if (disabled) {
                            recipient.disabled = true
                        }

                        return (
                            <tr key={recipient.id}>  
                                <td>
                                    {recipient.name}
                                    {recipient.jobTitle && (
                                        <div className="small text-muted font-weight-medium">{recipient.jobTitle}</div>
                                    )}
                                    {Boolean(canManageEmployees) && (
                                        <div>
                                            <a href={`mailto:${recipient.email}`} className="small"><i className="fas fa-envelope fa-fw"></i> {recipient.email}</a>

                                            {recipient.phone && (
                                                <>
                                                    <br />
                                                    <a href={`tel:${recipient.phone}`} className="small"><i className="fas fa-sms fa-fw"></i> {recipient.phone}</a>
                                                </>
                                            )}

                                            {recipient.zipCode && (
                                                <p className="mb-0 text-gray-400 small">
                                                    <strong>ZIP:</strong> {recipient.zipCode}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </td>
                                {canManageEmployees ? (
                                    <>
                                        <td>
                                            {Boolean(canManageBranch) && (
                                                <Form.Check type="switch" id={`canManageBranch_${recipient.id}`}>
                                                    <Form.Check.Input checked={recipient.canManageBranch} onChange={e => updateRecipient({ ...recipient, canManageBranch: e.target.checked })} disabled={tableState.loading || disabled || recipient.id === userId} />
                                                    <Form.Check.Label className="font-weight-medium">Manage Account</Form.Check.Label>
                                                </Form.Check>
                                            )}

                                            <Form.Check type="switch" id={`officeGroup_${recipient.id}`}>
                                                <Form.Check.Input checked={recipient.officeGroup} onChange={e => updateRecipient({ ...recipient, officeGroup: e.target.checked })} disabled={tableState.loading || disabled || recipient.canManageBranch || recipient.id === userId} />
                                                <Form.Check.Label className="font-weight-medium">Office Employee</Form.Check.Label>
                                            </Form.Check>

                                            <Form.Check type="switch" id={`canViewFieldEmployees_${recipient.id}`}>
                                                <Form.Check.Input checked={recipient.canViewFieldEmployees} onChange={e => updateRecipient({ ...recipient, canViewFieldEmployees: e.target.checked })} disabled={tableState.loading || disabled || recipient.officeGroup || recipient.id === userId} />
                                                <Form.Check.Label className="font-weight-medium">View Field Employees</Form.Check.Label>
                                            </Form.Check>

                                            <Form.Check type="switch" id={`canViewAllBranchNotifications_${recipient.id}`}>
                                                <Form.Check.Input checked={recipient.canViewAllBranchNotifications} onChange={e => updateRecipient({ ...recipient, canViewAllBranchNotifications: e.target.checked })} disabled={tableState.loading || disabled || !recipient.officeGroup || recipient.id === userId} />
                                                <Form.Check.Label className="font-weight-medium">View Everyone's Sent Notifications</Form.Check.Label>
                                            </Form.Check>

                                            <Form.Check type="switch" id={`canViewAllChats_${recipient.id}`}>
                                                <Form.Check.Input checked={recipient.canViewAllChats} onChange={e => updateRecipient({ ...recipient, canViewAllChats: e.target.checked })} disabled={tableState.loading || disabled || !recipient.officeGroup || recipient.id === userId} />
                                                <Form.Check.Label className="font-weight-medium">View Everyone's Chats</Form.Check.Label>
                                            </Form.Check>

                                            <Form.Check type="switch" id={`canManageEmployees_${recipient.id}`}>
                                                <Form.Check.Input checked={recipient.canManageEmployees} onChange={e => updateRecipient({ ...recipient, canManageEmployees: e.target.checked })} disabled={tableState.loading || disabled || recipient.canManageBranch || !recipient.officeGroup || recipient.id === userId} />
                                                <Form.Check.Label className="font-weight-medium">Manage Employees</Form.Check.Label>
                                            </Form.Check>

                                            <Form.Check type="switch" id={`canManagePatients_${recipient.id}`}>
                                                <Form.Check.Input checked={recipient.canManagePatients} onChange={e => updateRecipient({ ...recipient, canManagePatients: e.target.checked })} disabled={tableState.loading || disabled || !recipient.officeGroup || recipient.id === userId} />
                                                <Form.Check.Label className="font-weight-medium">Manage Patients</Form.Check.Label>
                                            </Form.Check>

                                            <Form.Check type="switch" id={`canManageTeams_${recipient.id}`}>
                                                <Form.Check.Input checked={recipient.canManageTeams} onChange={e => updateRecipient({ ...recipient, canManageTeams: e.target.checked })} disabled={tableState.loading || disabled || !recipient.officeGroup || recipient.id === userId} />
                                                <Form.Check.Label className="font-weight-medium">Manage Teams</Form.Check.Label>
                                            </Form.Check>
                                        </td>
                                        <td>
                                            <p className="small">
                                                <strong>Last Login</strong><br />
                                                <span className={recipient.lastLogin ? 'text-gray-400 font-weight-light' : 'text-orange-200 font-weight-medium'}>{lastLogin}</span>
                                            </p>
                                            <p className="small">
                                                <strong>App Alerts</strong><br />
                                                <span className={recipient.allowedPushNotifications ? 'text-gray-400 font-weight-light' : 'text-orange-200 font-weight-medium'}>{recipient.allowedPushNotifications ? 'On' : 'Off'}</span>
                                            </p>

                                            {Boolean(canManageEmployees) && disabled && (
                                                <Form.Check type="switch" id={`enable_${recipient.id}`}>
                                                    <Form.Check.Input onChange={e => activateRecipient(recipient)} disabled={tableState.loading} />
                                                    <Form.Check.Label className="font-weight-medium">Activate</Form.Check.Label>
                                                </Form.Check>
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <td>
                                        {recipient.officeGroup ? 'Office' : 'Field'} Employee
                                    </td>
                                )}
                                <td>
                                     {recipient.id !== userId && (
                                         <>
                                            <ChatButton recipient={recipient} unread={unreadCount} />
                                            <CallButton recipient={recipient} />

                                            {Boolean(canManageEmployees) && !disabled && (
                                                <Button as={Link} variant="blue-300" className="text-gray-700 ml-1" href={`/recipient/contacts/recipients/${recipient.id}`} to={`/recipient/contacts/recipients/${recipient.id}`} title="Edit Employee"><i className="fas fa-edit fa-fw"></i></Button>
                                            )}
                                         </>
                                    )}
                                </td>
                            </tr>
                        )}
                    )}
                </tbody>
            </BootstrapTable>
        </>
    );
}

export default Table;
