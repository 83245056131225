import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

const CustomPhoneInput = React.forwardRef((props, ref) => <Form.Control ref={ref} {...props} />)

function Register() {
    const [ name, setName ] = useState('');
    const [ contactEmail, setContactEmail ] = useState('');
    const [ contactName, setContactName ] = useState('');
    const [ contactPhone, setContactPhone ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ agreement, setAgreement ] = useState(false);
    const [ formState, setFormState ] = useState({ error: '', loading: false });

    let history = useHistory();

    const formSubmit = async (evt) => {
        evt.preventDefault();
        setFormState({ error: '', loading: true });

        try {
            await API.post('UnauthenticatedAPI', '/companies', {
                body: {
                    name,
                    contactName,
                    contactEmail,
                    contactPhone,
                    password
                }
            });

            history.push('/verification', {
                email: contactEmail
            });
        } catch (error) {
            let formError;

            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        formError = error.response.data.message;
                        break;
                    case 409:
                        formError = <>This email is already in use. Click <Link href="/reset-password" to="/reset-password">here</Link> to reset your password.</>
                        break;
                    case 500:
                    default:
                        formError = `We encountered an unexpected issue while creating your account. The service returned error code ${error.response.status}`;
                        Sentry.captureException(error);
                        break;
                }
            } else {
                formError = `We encountered an unexpected issue while creating your account. The service returned error message ${error.message}`;
                Sentry.captureException(error);
            }

            setFormState({ error: formError, loading: false });
        }
    }

    return (
        <>
            <Helmet>
                <title>Sign Up</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <div className="bg-gray-700 py-5">
                <Container>
                    <div className="text-center">
                        <h1 className="display-4">Setup your company</h1>
                        <p className="lead">Fill out the form below to start a new company account for your employees.</p>
                    </div>

                    <hr />

                    <Form onSubmit={formSubmit}>
                        <fieldset>
                            <legend><i className="fas fa-fw fa-user"></i> Personal Information</legend>

                            <Row>
                                <Col xs="12" sm="6" lg="4" xl="3">
                                    <Form.Group controlId="name">
                                        <Form.Label>Your Company Name</Form.Label>
                                        <Form.Control required value={name} onChange={e => setName(e.target.value)} />
                                    </Form.Group>
                                </Col>

                                <Col xs="12" sm="6" lg="4" xl="3">
                                    <Form.Group controlId="contactName">
                                        <Form.Label>Your Name</Form.Label>
                                        <Form.Control required value={contactName} onChange={e => setContactName(e.target.value)} />
                                    </Form.Group>
                                </Col>

                                <Col xs="12" sm="6" lg="4" xl="3">
                                    <Form.Group controlId="contactEmail">
                                        <Form.Label>Your Email</Form.Label>
                                        <Form.Control type="email" required onChange={e => setContactEmail(e.target.value)} value={contactEmail} />
                                    </Form.Group>
                                </Col>

                                <Col xs="12" sm="6" lg="4" xl="3">
                                    <Form.Group controlId="contactPhone">
                                        <Form.Label>Your Phone Number</Form.Label>
                                        <PhoneInput defaultCountry="US" limitMaxLength={true} placeholder="Enter phone number" value={contactPhone} onChange={setContactPhone} inputComponent={CustomPhoneInput} />
                                    </Form.Group>
                                </Col>

                                <Col xs="12" md="8" lg="6">
                                    <Form.Group controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" required="required" minLength="8" pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" onChange={e => setPassword(e.target.value)} value={password} />
                                        <Form.Text muted>Must be at least 8 characters long and contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.</Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </fieldset>

                        <hr />

                        <Form.Group>
                            <Form.Check type="checkbox" id="agreement">
                                <FormCheck.Input required onChange={e => setAgreement(e.target.checked)} checked={agreement} />
                                <FormCheck.Label>By signing up you agree to the <a href="https://notifyd.com/terms/customer#terms" rel="noopener noreferrer" target="_blank">Customer Terms of Service</a>.</FormCheck.Label>
                            </Form.Check>
                        </Form.Group>

                        {formState.error !== '' && (
                            <Alert variant="orange-200" className="small">{formState.error}</Alert>
                        )}

                        <Button type="submit" variant="orange-300" className="text-white" disabled={formState.loading}>Register my company</Button>
                    </Form>

                    <p className="text-center mt-4">If you already have an account, click <Link href="/" to="/">here</Link> to login.</p>
                </Container>
            </div>
        </>
    );
}

export default Register;
