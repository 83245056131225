import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Auth } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function ResetPassword() {
    const [ email, setEmail ] = useState('');
    const [ formState, setFormState ] = useState({ error: '', loading: false });

    let history = useHistory();

    const resetPasswordFormSubmit = async (evt) => {
        evt.preventDefault();
        setFormState({ error: '', loading: true });

        try {
            let data = await Auth.forgotPassword(email.trim());

            history.push('/confirmation', {
                email: email.trim(),
                destination: data.CodeDeliveryDetails.Destination
            });
        } catch (error) {
            let formError;

            switch (error.code) {
                case 'InvalidParameterException':
                    formError = 'Invalid email. Please check that your username is formatted correctly.';
                    break;
                case 'UserNotFoundException':
                    formError = 'Could not find an account with this email address. Double check your email as credentials are case sensitive.';
                    break;
                case 'NotAuthorizedException':
                    if (error.message === 'User password cannot be reset in the current state.') {
                        formError = <>Please contact your employer or <a href="https://app.notifyd.com/support" target="_blank" rel="noopener noreferrer">contact support</a> to have your password reset.</>;
                        break;
                    }
                    // fall through
                default:
                    formError = `We encountered an unexpected issue while resetting your password. The service returned error code ${error.code}.`;
                    Sentry.captureException(error);
                    break;
            }

            setFormState({ error: formError, loading: false });
        }
    }

    return (
        <>
            <Helmet>
                <title>Reset Password</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <div className="bg-gray-700 py-5">
                <Container>
                    <div className="text-center">
                        <h1 className="display-4">Forgot Your Password?</h1>
                        <p className="lead">Share your email address and we'll help you reset your password.</p>
                    </div>

                    <hr />

                    <Form onSubmit={resetPasswordFormSubmit}>
                        <Row className="justify-content-center">
                            <Col xs="12" md="6" lg="5" xl="4">
                                <fieldset>
                                    <Form.Group controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" required value={email} onChange={e => setEmail(e.target.value)} />
                                    </Form.Group>
                                </fieldset>

                                {formState.error !== '' && (
                                    <Alert variant="orange-200" className="small">{formState.error}</Alert>
                                )}

                                <Button type="submit" variant="orange-300" className="text-white" disabled={formState.loading}>Reset Password</Button>
                            </Col>
                        </Row>
                    </Form>

                    <hr />

                    <p className="text-center">If you remember your password, click <Link to="/" href="/">here</Link> to login.</p>
                </Container>
            </div>
        </>
    );
}

export default ResetPassword;
