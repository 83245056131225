import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Form from './Form';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import { publishableKey } from './../../../config/Stripe';
import logo from '../../../images/logo-md.png';

const stripePromise = loadStripe(publishableKey);

function Subscribe(props) {
    const [ companyState, setCompanyState ] = useState({ company: null, error: '', loading: true });

    const history = useHistory();

    useEffect(() => {
        const getCompanies = async () => {
            try {
                let companies = await API.get('AuthenticatedAPI', `/companies`);

                if (companies.length === 0) {
                    setCompanyState({ company: null, error: '', loading: false });
                } else {
                    setCompanyState({ company: companies[0], error: '', loading: false });
                }
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setCompanyState({ company: null, error: error.response.data.message, loading: false });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Companies.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setCompanyState({ company: null, error: `We encountered an unexpected issue while retrieving Companies. The service returned error code ${error.response.status}`, loading: false });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setCompanyState({ company: null, error: `We encountered an unexpected issue while retrieving Companies. The service returned error message ${error.message}`, loading: false });
                    Sentry.captureException(error);
                }
            }
        };

        getCompanies();
    }, [ history ]);

    if (companyState.loading) {
        return (
            <div className="p-4">
                {companyState.error === '' ? (
                    <h1><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>
                ) : (
                    <Alert variant="orange-200" className="small">{companyState.error}</Alert>
                )}
            </div>
        );
    }
    
    if (companyState.company === null) {
        return (
            <div key="no-company" className="text-center p-4">
                <h1 className="display-1 font-weight-medium">Oops!</h1>
                <p className="h2">You're not associated with any companies!</p>
                <i className="fas fa-heart-broken fa-fw fa-8x text-orange-200 my-3"></i>
                <p className="lead">If you believe you are getting this message in error, please contact your employer and ask to have your Notifyd account enabled for their location(s).</p>
            </div>
        );
    }

    const branch = { negotiatedRate: companyState.company.negotiatedRate };

    return (
        <>
            <Helmet>
                <title>Account | New Subscription</title>
            </Helmet>

            <div className="p-4">
                <h1>New Subscription</h1>

                <div className="bg-white p-4 border border-gray-600 rounded">
                    <Row className="align-items-center">
                        <Col xs="12" sm="3" lg="2" className="text-center">
                            <Image fluid src={logo} />

                            <hr className="d-sm-none" />
                        </Col>

                        <Col xs="12" sm="9" lg="10">
                            <h4>Fill out this form to create a new Notifyd subscription.</h4>
                            <p className="small">If you operate out of New York City, NY as well as Sacramento, CA, you will want to create a separate subscription for each of these locations.</p>
                            <p className="small">You may also want to set up separate subscriptions if you operate multiple service lines (ie. PDN, Therapy, Attendant Care).</p>
                            <p className="small mb-0">Having multiple subscriptions allows you to reduce the amount of data your separate business lines have to work with to perform their roles and duties.</p>
                        </Col>
                    </Row>
                </div>

                <hr />

                <Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:300"' }] }}>
                    <Form branch={branch} onNewBranch={props.onNewBranch} newCard={props.newCard} />
                </Elements>
            </div>
        </>
    );
}

export default Subscribe;
