import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Filters from './Filters';
import Table from './Table';
import Pagination from '../../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function List(props) {
    const { branch, unread } = props;

    const [ teamState, setTeamState ] = useState({ teams: null, total: 0, loading: false, error: '' });
    const [ filters, setFilters ] = useState({ search: "", assignedTo: null, disabled: false });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 25;
    const history = useHistory();

    useEffect(() => {
        const getTeamsByBranch = async () => {
            try {
                let queryStringParameters = { ...filters, pageSize, pageNumber, expand: 'team-recipient' };

                if (!branch.canManageTeams) {
                    queryStringParameters.assignedTo = true;
                    queryStringParameters.chatEnabled = true;
                }

                let response = await API.get('AuthenticatedAPI', `/branches/${branch.id}/teams`, { response: true, queryStringParameters });

                setTeamState({ teams: response.data, total: response.headers['x-total-count'], loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Teams.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setTeamState({ teams: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Teams for this Branch. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setTeamState({ teams: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Teams for this Branch. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setTeamState({ teams: null, total: 0, loading: true, error: '' });
        getTeamsByBranch();
    }, [ branch.id, branch.canManageTeams, pageNumber, filters, history ]);

    const removeTeam = team => {
        setTeamState(teamState => { return { teams: teamState.teams.filter(x => x.id !== team.id), total: teamState.total - 1, loading: false, error: '' }});
    };

    return (
        <>
            <Helmet>
                <title>Employee | Org Teams</title>
            </Helmet>

            <Filters onSearch={filters => { setFilters(filters); setPageNumber(0); } } showAssignedTo={branch.canManageTeams} showDisabled={branch.canManageTeams} loading={teamState.loading} />

            {teamState.error !== '' && (
                <Alert variant="orange-200" className="small">{teamState.error}</Alert>
            )}

            <Table canManageTeams={branch.canManageTeams} teams={teamState.teams} unread={unread} disabled={filters.disabled} removeTeam={removeTeam} />

            {teamState.total > 0 && (
                <Pagination total={teamState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
            )}
        </>
    );
}

export default List;
