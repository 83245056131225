import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Badge from 'react-bootstrap/Badge'
import Message from './Message'
import { Auth, API } from 'aws-amplify'
import * as Sentry from "@sentry/react"
import { v4 as uuidv4 } from 'uuid'
import websocketConfig from '../../../config/WebsocketAPI'
import { chatHistory } from './Room.module.scss'

const HEARTBEAT_UPPER_BOUND = 2
const HEARTBEAT_PERIOD = 15000

const ENTER_PREFERENCE_COOKIE_KEY = 'chatEnterPreferences'
const TIMESTAMP_PREFERENCE_COOKIE_KEY = 'chatTimestampPreference'
const HOURLY_FORMAT_PREFERENCE_COOKIE_KEY = 'chatHourlyFormatPreference'

export default function Room(props) {
    const { branchId, roomName, recipientId, patientId, teamId, archived, onShowRecipients } = props

    const enterV = document.cookie.match(`(^|;) ?${ENTER_PREFERENCE_COOKIE_KEY}=([^;]*)(;|$)`)
    const timestampV = document.cookie.match(`(^|;) ?${TIMESTAMP_PREFERENCE_COOKIE_KEY}=([^;]*)(;|$)`)
    const hourlyFormatV = document.cookie.match(`(^|;) ?${HOURLY_FORMAT_PREFERENCE_COOKIE_KEY}=([^;]*)(;|$)`)
    const defaultEnterPreference = (enterV && enterV[2] ? enterV[2] : null) === 'true'
    const defaultTimestampPreference = (timestampV && timestampV[2] ? timestampV[2] : null) === 'true'
    const defaultHourlyFormatPreference = (hourlyFormatV && hourlyFormatV[2] ? hourlyFormatV[2] : null) === 'true'

    const [ connection, setConnection ] = useState(null)
    const [ heartbeatCount, setHeartbeatCount ] = useState(0)
    const [ heartbeatToggle, setHeartbeatToggle ] = useState(false)
    const [ messages, setMessages ] = useState(null)
    const [ lastEvaluatedKey, setLastEvaluatedKey ] = useState(null)
    const [ nextKey, setNextKey ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ readReceipts, setReadReceipts ] = useState(null)
    const [ error, setError ] = useState('')
    const [ message, setMessage ] = useState('')
    const [ scrollToBottom, setScrollToBottom ] = useState(false)
    const [ fileState, setFileState ] = useState({ uploading: false, uploadProgress: null })
    const [ showChatPreferences, setShowChatPreferences ] = useState(false)
    const [ enterPreference, setEnterPreference ] = useState(defaultEnterPreference)
    const [ timestampPreference, setTimestampPreference ] = useState(defaultTimestampPreference)
    const [ hourlyFormatPreference, setHourlyFormatPreference ] = useState(defaultHourlyFormatPreference)

    const trimmedMessage = message.trim()
    const history = useHistory()

    const observer = useRef()
    const oldestChatElementRef = useCallback(node => {
        if (loading || nextKey === null) {
            return;
        }

        if (observer.current) {
            observer.current.disconnect();
        }

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setLastEvaluatedKey(nextKey);
            }
        });

        if (node) {
            observer.current.observe(node);
        }
    }, [ loading, nextKey ])

    useEffect(() => {
        const getReadReceiptsByBranchAndRecipient = async () => {
            try {
                return await API.get('AuthenticatedAPI', `/branches/${branchId}/recipients/${recipientId}/read-receipts`);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            alert('You are not authorized to view chat history for this channel. If you continue to experience this problem, please contact Notifyd Support through the Help menu.');
                            history.push('/recipient');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving Chat History for this Recipient. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving Chat History for this Recipient. The service returned error message ${error.message}`);
                    setLoading(false);
                    Sentry.captureException(error);
                }
            }
        }

        const getReadReceiptsByBranchAndPatient = async () => {
            try {
                return await API.get('AuthenticatedAPI', `/branches/${branchId}/patients/${patientId}/read-receipts`);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            alert('You are not authorized to view chat history for this channel. If you continue to experience this problem, please contact Notifyd Support through the Help menu.');
                            history.push('/recipient');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving Chat History for this Recipient. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving Chat History for this Recipient. The service returned error message ${error.message}`);
                    setLoading(false);
                    Sentry.captureException(error);
                }
            }
        }

        const getReadReceiptsByBranchAndTeam = async () => {
            try {
                return await API.get('AuthenticatedAPI', `/branches/${branchId}/teams/${teamId}/read-receipts`);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            alert('You are not authorized to view chat history for this channel. If you continue to experience this problem, please contact Notifyd Support through the Help menu.');
                            history.push('/recipient');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving Chat History for this Recipient. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving Chat History for this Recipient. The service returned error message ${error.message}`);
                    setLoading(false);
                    Sentry.captureException(error);
                }
            }
        }

        let getReadReceipts;

        if (recipientId) {
            getReadReceipts = getReadReceiptsByBranchAndRecipient
        } else if (patientId) {
            getReadReceipts = getReadReceiptsByBranchAndPatient
        } else if (teamId) {
            getReadReceipts = getReadReceiptsByBranchAndTeam
        }

        setLoading(true)
        setError('')

        const callback = data => {
            setReadReceipts(data)
            setScrollToBottom(new Date().getTime())
        };

        getReadReceipts().then(callback)

        let wssUrl = new URL(websocketConfig.server);
        wssUrl.searchParams.append('token', Auth.user.signInUserSession.accessToken.jwtToken);
        wssUrl.searchParams.append('branchId', branchId);

        if (recipientId) {
            wssUrl.searchParams.append('recipientId', recipientId);
        } else if (patientId ){
            wssUrl.searchParams.append('patientId', patientId);
        } else if (teamId) {
            wssUrl.searchParams.append('teamId', teamId);
        }

        let client = new WebSocket(wssUrl);

        client.onopen = function(event) {
            client.send(JSON.stringify({ action: "readMessages" }));

            if (archived) {
                client.close();
            }
        };

        client.onmessage = event => {
            if (event.data === 'pong') {
                setHeartbeatCount(0);
                return;
            }

            const currentTime = new Date().getTime()
            const response = JSON.parse(event.data);
            let error = false;

            switch (response.action) {
                case 'postMessage':
                    switch (response.statusCode) {
                        case 201:
                            setMessages(messages => messages.map(x => {
                                if (x.localMessageId === response.localMessageId) {
                                    x.createdOn = response.createdOn
                                    delete x.localMessageId;
                                }

                                return x;
                            }));
                            setReadReceipts(readReceipts => readReceipts.map(readReceipt => {
                                if (response.connectedRecipientIds.includes(readReceipt.recipientId)) {
                                    readReceipt.readOn = currentTime
                                }

                                return readReceipt
                            }))
                            break
                        default:
                            break
                    }
                    break
                case 'receivedMessage':
                    switch (response.statusCode) {
                        case 200:
                            let chatHistoryContainer = document.getElementById('chat-history');
                            let { scrollHeight, offsetHeight, scrollTop } = chatHistoryContainer;

                            setMessages(messages => messages.concat(response.payload));

                            if (scrollHeight - offsetHeight === scrollTop) {
                                setScrollToBottom(new Date().getTime());
                            }
                            break
                        default:
                            break
                    }
                    break
                case 'deleteMessage':
                    switch (response.statusCode) {
                        case 204:
                            const { createdOn } = response.payload

                            setMessages(messages => messages.filter(message => message.createdOn !== createdOn))
                            break
                        default:
                            break
                    }
                    break
                case 'readMessages':
                    // Messages marked as read successfully.
                    break
                default:
                    break
            }

            // TODO - Move these up into the action-based switch-case.
            switch (response.statusCode) {
                case 400:
                    alert('There was an issue with your request.', response.message);
                    error = true;
                    break;
                case 402:
                    alert('Chat is currently not available for this location.', 'Please contact your office and ask to have chat enabled.');
                    error = true;
                    break;
                case 403:
                    switch(response.message) {
                        case 'You are not authorized to access this recipient.':
                            window.history.back();
                            break;
                        default:
                            window.location.reload();
                            break;
                    }
                    break;
                case 500:
                    Sentry.captureException(response);
                    alert('Uhoh! We encountered an error.', response.message);
                    error = true;
                    break;
                default:
                    break
            }

            if (error) {
                setMessages(messages => messages.filter(x => x.localMessageId !== response.localMessageId));
            }
        };

        client.onerror = function(event) {
            Sentry.captureEvent(event);
            window.location.reload();
        };

        setConnection(client);

        return () => {
            client.close();
        };
    }, [ branchId, recipientId, patientId, teamId, archived, heartbeatToggle ]);

    useEffect(() => {
        let heartbeatTimer;

        if (connection !== null && !archived) {
            heartbeatTimer = setInterval(function() {
                // If we have skipped too many heartbeats, re-establish the websocket connection.
                if (heartbeatCount > HEARTBEAT_UPPER_BOUND) {
                    setHeartbeatCount(0);
                    clearInterval(heartbeatTimer);
                    heartbeatTimer = null;
                    connection.close();
                    setConnection(null);
                    setHeartbeatToggle(new Date().getTime());
                } else {
                    connection.send('ping');
                    setHeartbeatCount(heartbeatCount + 1);
                }
            }, HEARTBEAT_PERIOD)
        }

        return () => {
            clearInterval(heartbeatTimer);
        };
    }, [ connection, archived, heartbeatCount ]);

    useEffect(() => {
        const getMessagesByBranchRecipient = async (lastEvaluatedKey) => {
            try {
                return await API.get('AuthenticatedAPI', `/branches/${branchId}/recipients/${recipientId}/messages`, { response: true, queryStringParameters : { lastEvaluatedKey }});
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            alert('You are not authorized to view chat history for this channel. If you continue to experience this problem, please contact Notifyd Support through the Help menu.');
                            history.push('/recipient');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving Chat History for this Recipient. The service returned error code ${error.response.status}`);
                            setLoading(false);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving Chat History for this Recipient. The service returned error message ${error.message}`);
                    setLoading(false);
                    Sentry.captureException(error);
                }
            }
        };

        const getMessagesByPatient = async (lastEvaluatedKey) => {
            try {
                return await API.get('AuthenticatedAPI', `/branches/${branchId}/patients/${patientId}/messages`, { response: true, queryStringParameters : { lastEvaluatedKey, expand: 'sending-user' }});
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            alert('You are not authorized to view chat history for this channel. If you continue to experience this problem, please contact Notifyd Support through the Help menu.');
                            history.push('/recipient');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving Chat History for this Patient. The service returned error code ${error.response.status}`);
                            setLoading(false);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving Chat History for this Patient. The service returned error message ${error.message}`);
                    setLoading(false);
                    Sentry.captureException(error);
                }
            }
        };

        const getMessagesByTeam = async (lastEvaluatedKey) => {
            try {
                return await API.get('AuthenticatedAPI', `/branches/${branchId}/teams/${teamId}/messages`, { response: true, queryStringParameters : { lastEvaluatedKey, expand: 'sending-user' }});
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            alert('You are not authorized to view chat history for this channel. If you continue to experience this problem, please contact Notifyd Support through the Help menu.');
                            history.push('/recipient');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving Chat History for this Team. The service returned error code ${error.response.status}`);
                            setLoading(false);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving Chat History for this Team. The service returned error message ${error.message}`);
                    setLoading(false);
                    Sentry.captureException(error);
                }
            }
        };

        let getMessages;

        if (recipientId) {
            getMessages = getMessagesByBranchRecipient;
        } else if (patientId) {
            getMessages = getMessagesByPatient;
        } else if (teamId) {
            getMessages = getMessagesByTeam;
        }

        setLoading(true);
        setError('');

        const callback = response => {
            let chatHistoryContainer = document.getElementById('chat-history');
            let oldHeight = chatHistoryContainer.scrollHeight;

            let newMessages = response.data;
            let nextKey = response.headers['x-last-evaluated-key'] || null;
            newMessages.reverse();

            setMessages(messages => lastEvaluatedKey ? newMessages.concat(messages) : newMessages);
            setNextKey(nextKey);
            setLoading(false);

            let newHeight = chatHistoryContainer.scrollHeight;
            chatHistoryContainer.scrollTop = newHeight - oldHeight;
        };

        getMessages(lastEvaluatedKey).then(callback);
    }, [ branchId, history, recipientId, patientId, teamId, lastEvaluatedKey ]);

    useEffect(() => {
        let chatHistoryContainer = document.getElementById('chat-history');
        chatHistoryContainer.scrollTop = chatHistoryContainer.scrollHeight - chatHistoryContainer.offsetHeight;
    }, [ scrollToBottom ]);

    const handleEnterPreferenceChange = selected => {
        let d = new Date()
        d.setTime(d.getTime() + 24*60*60*1000*365)
        document.cookie = `${ENTER_PREFERENCE_COOKIE_KEY}=${selected};path=/;expires=${d.toGMTString()}`

        setEnterPreference(selected)
    }

    const handleTimestampPreferenceChange = selected => {
        let d = new Date()
        d.setTime(d.getTime() + 24*60*60*1000*365)
        document.cookie = `${TIMESTAMP_PREFERENCE_COOKIE_KEY}=${selected};path=/;expires=${d.toGMTString()}`

        setTimestampPreference(selected)
    }

    const handleHourlyFormatPreferenceChange = selected => {
        let d = new Date()
        d.setTime(d.getTime() + 24*60*60*1000*365)
        document.cookie = `${HOURLY_FORMAT_PREFERENCE_COOKIE_KEY}=${selected};path=/;expires=${d.toGMTString()}`

        setHourlyFormatPreference(selected)
    }

    const handleReturnKey = e => {
        if (e.which !== 13) {
            return
        }

        if (!e.shiftKey && enterPreference) {
            sendMessage(message)
            e.preventDefault()
        }
    }

    const sendMessage = (message, fileId = null) => {
        const trimmedMessage = message.trim()

        if (trimmedMessage.length === 0) {
            return
        }

        let payload = {
            message: trimmedMessage,
            fileId,
            sendingRecipientId: Auth.user.username,
            createdOn: new Date().getTime(),
            localMessageId: uuidv4()
        };

        setMessages(messages.concat(payload));
        setMessage('');
        setScrollToBottom(new Date().getTime());

        connection.send(JSON.stringify({
            action: 'postMessage',
            data: payload
        }));
    };

    const deleteMessage = (message) => {
        const { createdOn } = message

        setMessages(messages => messages.map(x => {
            if (x.createdOn === createdOn) {
                x.localMessageId = 'temp delete'
            }

            return x;
        }));

        connection.send(JSON.stringify({
            action: 'deleteMessage',
            data: { createdOn }
        }));
    };

    const sendFile = file => {
        if (!file) {
            return;
        }

        const postFileByBranchAndRecipient = async (fileExt) => {
            try {
                return await API.post('AuthenticatedAPI', `/branches/${branchId}/recipients/${recipientId}/files`, { body: { fileExt } });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setError(error.response.data.message);
                            setFileState({ uploading: false, uploadProgress: null });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while securing a file upload location. The service returned error code ${error.response.status}`);
                            setFileState({ uploading: false, uploadProgress: null });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while securing a file upload location. The service returned error message ${error.message}`);
                    setFileState({ uploading: false, uploadProgress: null });
                    Sentry.captureException(error);
                }
            }
        };

        const postFileByBranchAndPatient = async (fileExt) => {
            try {
                return await API.post('AuthenticatedAPI', `/branches/${branchId}/patients/${patientId}/files`, { body: { fileExt } });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setError(error.response.data.message);
                            setFileState({ uploading: false, uploadProgress: null });
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while securing a file upload location. The service returned error code ${error.response.status}`);
                            setFileState({ uploading: false, uploadProgress: null });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while securing a file upload location. The service returned error message ${error.message}`);
                    setFileState({ uploading: false, uploadProgress: null });
                    Sentry.captureException(error);
                }
            }
        };

        const postFileByBranchAndTeam = async (fileExt) => {
            try {
                return await API.post('AuthenticatedAPI', `/branches/${branchId}/teams/${teamId}/files`, { body: { fileExt } });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setError(error.response.data.message);
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while securing a file upload location. The service returned error code ${error.response.status}`);
                            setFileState({ uploading: false, uploadProgress: null });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while securing a file upload location. The service returned error message ${error.message}`);
                    setFileState({ uploading: false, uploadProgress: null });
                    Sentry.captureException(error);
                }
            }
        };

        let fileExt = file.name.split('.').pop();
        let postFileEndpoint;

        if (recipientId) {
            postFileEndpoint = postFileByBranchAndRecipient;
        } else if (patientId) {
            postFileEndpoint = postFileByBranchAndPatient;
        } else if (teamId) {
            postFileEndpoint = postFileByBranchAndTeam;
        }

        postFileEndpoint(fileExt).then(response => {
            let { fileId, uri } = response;

            let xhr = new XMLHttpRequest();
            xhr.open('put', uri);

            xhr.onload = () => {
                setFileState({ uploading: false, uploadProgress: null });
                sendMessage(file.name, fileId);
            };

            xhr.onerror = error => {
                setError(error);
                setFileState({ uploading: false, uploadProgress: null });
                Sentry.captureException(error);
            };

            if (xhr.upload) {
                xhr.upload.onprogress = (progressEvent) => {
                    let progress = Math.round(100 * progressEvent.loaded / progressEvent.total);
                    setFileState({ uploading: true, uploadProgress: progress });
                };
            }

            xhr.send(file);
        });

        setFileState({ uploading: true, uploadProgress: 0 });
    }

    const getFile = fileId => {
        const getFileByBranchAndRecipient = async () => {
            try {
                return await API.get('AuthenticatedAPI', `/branches/${branchId}/recipients/${recipientId}/files/${fileId}`)
            } catch (error) {
                throw error
            }
        };

        const getFileByBranchAndPatient = async () => {
            try {
                return await API.get('AuthenticatedAPI', `/branches/${branchId}/patients/${patientId}/files/${fileId}`)
            } catch (error) {
                throw error
            }
        };

        const getFileByBranchAndTeam = async () => {
            try {
                return await API.get('AuthenticatedAPI', `/branches/${branchId}/teams/${teamId}/files/${fileId}`)
            } catch (error) {
                throw error
            }
        };

        let getFileEndpoint

        if (recipientId) {
            getFileEndpoint = getFileByBranchAndRecipient;
        } else if (patientId) {
            getFileEndpoint = getFileByBranchAndPatient;
        } else if (teamId) {
            getFileEndpoint = getFileByBranchAndTeam;
        }

        const callback = response => {
            window.open(response.uri);
        };

        getFileEndpoint().then(callback).catch(error => {
            let errorTarget = ''

            if (recipientId) {
                errorTarget = 'Recipient'
            } else if (patientId) {
                errorTarget = 'Patient'
            } else if (teamId) {
                errorTarget = 'Team'
            }

            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setError(error.response.data.message)
                        break
                    case 401:
                        history.push('/', {
                            message: 'Your session has expired. Please log back in to continue.',
                            redirect: history.location
                        })
                        break
                    case 403:
                        history.push('/recipient')
                        break
                    case 404:
                        setError('This file was not successfully uploaded. Please have the sender re-upload this file.')
                        break
                    default:
                        setError(`We encountered an unexpected issue while retrieving File for this ${errorTarget}. The service returned error code ${error.response.status}`)
                        Sentry.captureException(error)
                        break
                }
            } else {
                setError(`We encountered an unexpected issue while retrieving File for this ${errorTarget}. The service returned error message ${error.message}`)
                Sentry.captureException(error)
            }
        });
    };

    return (
        <>
            <div id="chat-history" className={`${chatHistory} responsive-chat-panel bg-white overflow-auto p-2 text-break`}>
                {loading ? (
                    <div id="loading" key="loading" className="text-center"><i className="fas fa-spinner fa-fw fa-spin" /></div>
                )  : nextKey === null && (
                    <h4 className="text-orange-200 mb-3">Beginning of conversation</h4>
                )}

                {messages !== null && messages.map((message, index) => {
                    const previousMessage = index > 0 ? messages[index - 1] : null
                    const nextMessage = index < messages.length - 1 ? messages[index + 1] : null
                    let readMarkers = []

                    if (readReceipts) {
                        readMarkers = readReceipts.filter(({ recipientId, readOn }) => {
                            return recipientId !== Auth.user.username && readOn >= message.createdOn && (nextMessage === null || readOn < nextMessage.createdOn)
                        })
                    }

                    return (
                        <div ref={index === 0 ? oldestChatElementRef : null} key={index}>
                            <Message message={message} previousMessage={previousMessage} showName={(patientId || teamId)} alwaysShowTimestamp={timestampPreference} hourlyFormat={hourlyFormatPreference} onDeleteMessage={deleteMessage} getFile={getFile} />

                            {readMarkers.length > 0 && (
                                <div className="text-right smaller text-gray-400">
                                    {recipientId ? (
                                        <Badge variant="gray-600" className="p-1 mt-1 ml-1">Read {new Date(readMarkers[0].readOn).toLocaleTimeString()}</Badge>
                                    ) : readMarkers.map(readMarker => {
                                        return (
                                            <Badge title={`${readMarker.recipientName}: Read ${new Date(readMarker.readOn).toLocaleTimeString()}`} key={readMarker.recipientId} variant="gray-600" className="p-1 mt-1 ml-1">{readMarker.recipientName.split(' ').map(nameFragment => nameFragment[0]).join('')}</Badge>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>

            <div className="bg-gray-700 border-gray-600 border-top">
                {archived ? (
                    <div className="text-center px-1 py-2">
                        <p className="text-orange-200 font-weight-medium mb-0">This conversation has been archived by an administrator.</p>
                        <p className="mb-0">No new messages can be sent.</p>
                    </div>
                ) : (
                    <>
                        <Form.Control as="textarea" rows="2" className="bg-white border-0 rounded-0" placeholder={`Message ${roomName}`} value={message} onKeyPress={e => handleReturnKey(e)} onChange={e => setMessage(e.target.value)} autoFocus style={{ resize: 'none' }} />

                        <div className="d-flex flex-row justify-content-between px-2 py-2">
                            <div className="position-relative">
                                <Form.File id="file-upload" className="d-none" onChange={e => sendFile(e.target.files[0])} />
                                <Button variant="green-500" size="sm" className="small text-gray-700 font-weight-medium" onClick={() => { document.getElementById('file-upload').click(); }} disabled={fileState.uploading}><i className="fas fa-lg fa-file-upload fa-fw" /> {fileState.uploading ? `${fileState.uploadProgress}%` : 'Share File'}</Button>

                                {onShowRecipients && (
                                    <Button variant="blue-300" size="sm" className="small text-gray-700 font-weight-medium ml-1" onClick={e => onShowRecipients()}><i className="fas fa-users" /> Employees</Button>
                                )}
                            </div>

                            <div>
                                <Button variant="link" size="sm" className="mr-1" onClick={() => setShowChatPreferences(true)}><i className="fas fa-cog fa-fw text-gray-400" /></Button>
                                <Button variant={trimmedMessage === '' ? 'gray-400' : 'green-500'} size="sm" className="border-0 text-gray-700 font-weight-medium" onClick={() => sendMessage(message)} onChange={e => setMessage(e.target.value)} disabled={trimmedMessage === ''}>Send</Button>
                            </div>
                        </div>
                    </>
                )}
            </div>

            {error && (
                <Modal show={true} onHide={() => setError('')}>
                    <Modal.Header className="bg-orange-200 text-gray-700">
                        <Modal.Title className="p font-weight-medium">Chat Error</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {error}
                    </Modal.Body>
                </Modal>
            )}

            <Modal show={showChatPreferences} onHide={() => setShowChatPreferences(false)}>
                <Modal.Header className="bg-blue-100 text-gray-700">
                    <Modal.Title className="p font-weight-medium">Chat Preferences</Modal.Title>
                    <button className="close text-gray-700" onClick={() => setShowChatPreferences(false)}><i className="fas fa-times fa-sm" /></button>
                </Modal.Header>

                <Modal.Body>
                    <h4>When I press <code>Enter</code>, I want to...</h4>

                    <div className="bg-gray-700 border border-gray-600 rounded p-2">
                        <Form.Check>
                            <Form.Check.Input type="radio" id="returnSends" checked={enterPreference} onChange={e => handleEnterPreferenceChange(e.target.checked)} />
                            <Form.Check.Label htmlFor="returnSends">
                                <p className="mb-0 small">Send my message</p>
                                <p className="mb-2 small text-gray-400">Use <code>Shift</code> + <code>Enter</code> to start a new line</p>
                            </Form.Check.Label>
                        </Form.Check>

                        <Form.Check type="radio" id="returnNewLine" label="Start a new line" checked={!enterPreference} onChange={e => handleEnterPreferenceChange(!e.target.checked)} />
                    </div>

                    <h4 className="mt-4">I want to show timestamps...</h4>

                    <div className="bg-gray-700 border border-gray-600 rounded p-2">
                        <Form.Check>
                            <Form.Check.Input type="radio" id="timestampConversations" checked={!timestampPreference} onChange={e => handleTimestampPreferenceChange(!e.target.checked)} />
                            <Form.Check.Label htmlFor="timestampConversations">
                                <p className="mb-0 small">For every conversation</p>
                                <p className="mb-2 small text-gray-400">All messages that are sent less than one hour apart are considered part of the same conversation.</p>
                            </Form.Check.Label>
                        </Form.Check>

                        <Form.Check type="radio" id="timestampMessages" label="For every message" checked={timestampPreference} onChange={e => handleTimestampPreferenceChange(e.target.checked)} />

                        <hr className="my-2" />

                        <Form.Check type="radio" id="12hourFormat" label="In 12-hour format" checked={!hourlyFormatPreference} onChange={e => handleHourlyFormatPreferenceChange(!e.target.checked)} />
                        <Form.Check type="radio" id="24hourFormat" label="In 24-hour format" checked={hourlyFormatPreference} onChange={e => handleHourlyFormatPreferenceChange(e.target.checked)} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
