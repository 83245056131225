import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Filters from '../Recipients/Filters';
import Table from '../Recipients/Table';
import Pagination from '../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Recipients(props) {
    const [ recipientState, setRecipientState ] = useState({ recipients: null, total: 0, loading: false, error: '' });
    const [ filters, setFilters ] = useState({ search: "", hasLoggedIn: null });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 25;
    const history = useHistory();
    const id = props.branch.id;

    useEffect(() => {
        const getRecipients = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${id}/recipients`, { response: true, queryStringParameters: { ...filters, expand: 'branch-recipient', pageSize, pageNumber } });

                setRecipientState({ recipients: response.data, total: response.headers['x-total-count'], loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Recipients for this Branch. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setRecipientState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Recipients for this Branch. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setRecipientState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Recipients for this Branch. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setRecipientState({ recipients: null, total: 0, loading: true, error: '' });
        getRecipients();
    }, [ id, pageNumber, filters, history ]);

    return (
        <>
            <hr />

            <Filters onSearch={filters => { setFilters(filters); setPageNumber(0); } } loading={recipientState.loading} />

            <hr />

            {recipientState.error !== '' && (
                <Alert variant="orange-200" className="small">{recipientState.error}</Alert>
            )}

            <Table recipients={recipientState.recipients} showBranchDetails={true} />

            {recipientState.total > 0 && (
                <Pagination total={recipientState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
            )}
        </>
    );
}

export default Recipients;
