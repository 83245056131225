import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const Filters = React.memo(props => {
    const { showOfficeGroup, showHasLoggedIn, showDisabled, loading, onSearch } = props;
    const [ search, setSearch ] = useState('');
    const [ officeGroup, setOfficeGroup ] = useState("");
    const [ hasLoggedIn, setHasLoggedIn ] = useState("");
    const [ disabled, setDisabled ] = useState(false);

    const smColWidth = showDisabled ? 4 : 6;
    const lgColWidth = showDisabled ? 3 : 4;

    const setFilters = evt => {
        evt.preventDefault();

        onSearch({
            search,
            officeGroup: officeGroup === '' ? null : officeGroup,
            hasLoggedIn: hasLoggedIn === '' ? null : hasLoggedIn,
            disabled
        });
    }

    return (
        <Form className="bg-white p-4 border border-top-0 gray-600" onSubmit={setFilters}>
            <Row>
                <Col xs="12" sm={smColWidth} lg={lgColWidth}>
                    <Form.Group controlId="search">
                        <Form.Label>Search</Form.Label>
                        <Form.Control size="sm" placeholder="Name" value={search} onChange={e => setSearch(e.target.value)} disabled={disabled} />
                        <Form.Text className="text-muted smaller">Search by name, email, phone number, job title, or ZIP code.</Form.Text>
                    </Form.Group>
                </Col>

                {Boolean(showOfficeGroup) && (
                    <Col xs="6" sm={smColWidth} lg={lgColWidth}>
                        <Form.Group controlId="officeGroup">
                            <Form.Label>Employee Type</Form.Label>
                            <Form.Control as="select" size="sm" disabled={disabled} value={officeGroup} onChange={e => setOfficeGroup(e.target.value)}>
                                <option value="">All</option>
                                <option value={true}>Office</option>
                                <option value={false}>Field</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                )}

                {Boolean(showHasLoggedIn) && (
                    <>
                        <Col xs="6" sm={smColWidth} lg={lgColWidth}>
                            <Form.Group controlId="hasLoggedIn">
                                <Form.Label>Has Logged In</Form.Label>
                                <Form.Control as="select" size="sm" disabled={disabled} value={hasLoggedIn} onChange={e => setHasLoggedIn(e.target.value)}>
                                    <option value={""}>All</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>

                        {Boolean(showDisabled) && (
                            <Col xs={12} sm={smColWidth} lg={lgColWidth}>
                                <Form.Group controlId="disabled">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Check type="checkbox" value={true} checked={disabled} label="Deactivated" onChange={e => setDisabled(e.target.checked)} />
                                </Form.Group>
                            </Col>
                        )}
                    </>
                )}
            </Row>

            <Button type="submit" variant="green-500" size="sm" className="text-white mb-1" disabled={loading}>Search</Button>
        </Form>
    );
});

export default Filters;
