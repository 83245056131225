import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { default as BootstrapTable } from 'react-bootstrap/Table';

function Table(props) {
    return (
        <>
            <Row className="smaller justify-content-end">
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-edit fa-fw"></i> Edit Recipient
                </Col>
            </Row>

            <BootstrapTable responsive striped bordered hover className="mt-3 bg-white">
                <thead className="bg-gray-200 text-white small font-weight-normal">
                    <tr>
                        <th>Recipient</th>
                        <th>Contact Info</th>
                        {props.showBranchDetails && (
                            <th>Groups / Permissions</th>
                        )}
                        <th>Last Login</th>
                        <th>Device Token</th>
                        <th width="1"></th>
                    </tr>
                </thead>
                <tbody>
                    {props.recipients === null ? (
                        <tr key="loading">
                            <td colSpan={props.showBranchDetails ? 6 : 5} className="text-center">
                                <i className="fas fa-spinner fa-fw fa-lg fa-spin" />
                            </td>
                        </tr>
                    ) : props.recipients.length === 0 ? (
                        <tr key="none">
                            <td colSpan={props.showBranchDetails ? 6 : 5} className="text-center">
                                No results found
                            </td>
                        </tr>
                    ) : props.recipients.map(recipient => {
                        let lastLogin = 'Never';

                        if (recipient.lastLogin) {
                            let lastLoginParts = recipient.lastLogin.split(/\D+/);
                            let lastLoginDate = new Date(Date.UTC(lastLoginParts[0], --lastLoginParts[1], lastLoginParts[2], lastLoginParts[3], lastLoginParts[4], lastLoginParts[5], lastLoginParts[6]));
                            lastLogin = `${lastLoginDate.toLocaleDateString()} ${lastLoginDate.toLocaleTimeString()}`;
                        }

                        return (
                            <tr key={recipient.id}>
                                <td>
                                    {recipient.name}<br />
                                    {recipient.jobTitle && (
                                        <small className="d-block text-muted font-weight-medium">{recipient.jobTitle}</small>
                                    )}
                                    <small className="smaller text-muted">{recipient.id}</small>
                                </td>
                                <td className="small text-blue-300">
                                    <i className="fas fa-envelope fa-fw"></i> <a href={`mailto:${recipient.email}`}>{recipient.email}</a>

                                    {recipient.phone && (
                                        <>
                                            <br />
                                            <i className="fas fa-sms fa-fw"></i> <a href={`tel:${recipient.phone}`}>{recipient.phone}</a>
                                        </>
                                    )}

                                    {recipient.contactPhone && (
                                        <>
                                            <br />
                                            <i className="fas fa-phone fa-fw"></i> <a href={`tel:${recipient.contactPhone}`}>{recipient.contactPhone}</a>
                                        </>
                                    )}

                                    {recipient.zipCode && (
                                        <>
                                            <br />
                                            <i className="fas fa-map-marker-alt fa-fw"></i> {recipient.zipCode}
                                        </>
                                    )}
                                </td>
                                {props.showBranchDetails && (
                                    <td>
                                        <div className={`text-${recipient.canManageBranch ? 'green-500' : 'orange-200' } font-weight-medium smaller`}>
                                            <i className={`fas ${recipient.canManageBranch ? 'fa-check-square' : 'fa-window-close'} fa-fw`}></i> Manage Account
                                        </div>

                                        <div className={`text-${recipient.officeGroup ? 'green-500' : 'orange-200'} font-weight-medium smaller`}>
                                            <i className={`fas ${recipient.officeGroup ? 'fa-check-square' : 'fa-window-close'} fa-fw`}></i> Office Employee
                                        </div>

                                        <div className={`text-${recipient.canViewFieldEmployees ? 'green-500' : 'orange-200'} font-weight-medium smaller`}>
                                            <i className={`fas ${recipient.canViewFieldEmployees ? 'fa-check-square' : 'fa-window-close'} fa-fw`}></i> View Field Employees
                                        </div>

                                        <div className={`text-${recipient.canViewAllBranchNotifications ? 'green-500' : 'orange-200'} font-weight-medium smaller`}>
                                            <i className={`fas ${recipient.canViewAllBranchNotifications ? 'fa-check-square' : 'fa-window-close'} fa-fw`}></i> View Everyone's Sent Notifications
                                        </div>

                                        <div className={`text-${recipient.canViewAllChats ? 'green-500' : 'orange-200'} font-weight-medium smaller`}>
                                            <i className={`fas ${recipient.canViewAllChats ? 'fa-check-square' : 'fa-window-close'} fa-fw`}></i> View Everyone's Chats
                                        </div>

                                        <div className={`text-${recipient.canManageEmployees ? 'green-500' : 'orange-200'} font-weight-medium smaller`}>
                                            <i className={`fas ${recipient.canManageEmployees ? 'fa-check-square' : 'fa-window-close'} fa-fw`}></i> Manage Employees
                                        </div>

                                        <div className={`text-${recipient.canManagePatients ? 'green-500' : 'orange-200'} font-weight-medium smaller`}>
                                            <i className={`fas ${recipient.canManagePatients ? 'fa-check-square' : 'fa-window-close'} fa-fw`}></i> Manage Care Teams
                                        </div>

                                        <div className={`text-${recipient.canManageTeams ? 'green-500' : 'orange-200'} font-weight-medium smaller`}>
                                            <i className={`fas ${recipient.canManageTeams ? 'fa-check-square' : 'fa-window-close'} fa-fw`}></i> Manage Teams
                                        </div>
                                    </td>
                                )}
                                <td className="small">{lastLogin}</td>
                                <td className="small">{recipient.deviceToken}</td>
                                <td className="align-middle">
                                    <Button as={Link} variant="link" size="sm" href={`/admin/recipients/${recipient.id}`} to={`/admin/recipients/${recipient.id}`} className="text-blue-300" title="Edit Recipient"><i className="fas fa-edit fa-fw fa-lg"></i></Button>
                                </td>
                            </tr>
                        )}
                    )}
                </tbody>
            </BootstrapTable>
        </>
    );
}

export default Table;
