import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';
import ListGroupItem from '../Chat/ListGroupItem';
import Message from '../Chat/Message';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Monitor(props) {
    const { branchId, recipient, messages, loading, error, onClose } = props;
    const [ target, setTarget ] = useState(null);
    const [ channelLoading, setChannelLoading ] = useState(false);
    const [ channelError, setChannelError ] = useState();
    const [ channelMessages, setChannelMessages ] = useState(null);

    const targetId = target ? target.id : null;
    const history = useHistory();

    useEffect(() => {
        const getMessagesByChannel = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/branches/${branchId}/channels/${targetId}/messages`, { queryStringParameters: { expand: 'sending-user' } });
                response.reverse();

                setChannelLoading(false);
                setChannelMessages(response);

                let chatHistoryContainer = document.getElementById(`chat-history_${targetId}`);
                chatHistoryContainer.scrollTop = chatHistoryContainer.scrollHeight - chatHistoryContainer.clientHeight;
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            setChannelLoading(false);
                            setChannelError(error.response.data.message);
                            break;
                        default:
                            setChannelLoading(false);
                            setChannelError(`We encountered an unexpected issue while retrieving Chat History for this Channel. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setChannelLoading(false);
                    setChannelError(`We encountered an unexpected issue while retrieving Chat History for this Channel. The service returned error message ${error.message}`);
                    Sentry.captureException(error);
                }
            }
        }

        if (targetId) {
            setChannelLoading(true);
            setChannelError(null);
            getMessagesByChannel();
        }
    }, [ history, branchId, targetId ]);

    const getFile = async (fileId) => {
        try {
            let response = await API.get('AuthenticatedAPI', `/branches/${branchId}/channels/${targetId}/files/${fileId}`);

            window.open(response.uri);
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setChannelError(error.response.data.message);
                        break;
                    case 401:
                        history.push('/', {
                            message: 'Your session has expired. Please log back in to continue.',
                            redirect: history.location
                        });
                        break;
                    case 403:
                        history.push('/recipient');
                        break;
                    default:
                        setChannelError(`We encountered an unexpected issue while retrieving File for this Channel. The service returned error code ${error.response.status}`);
                        Sentry.captureException(error);
                        break;
                }
            } else {
                setChannelError(`We encountered an unexpected issue while retrieving File for this Channel. The service returned error message ${error.message}`);
                Sentry.captureException(error);
            }
        }
    };

    return (
        <>
            <div className="bg-blue-400 text-white text-center small font-weight-medium py-2">
                {target !== null && (
                    <button className="position-absolute bg-transparent border-0 p-0 text-gray-700" style={{ left: 15, top: 8 }} onClick={() => { setChannelMessages(null); setTarget(null); }}><i className="fas fa-chevron-left fa-lg" /></button>
                )}

                <div className="w-75 mx-auto text-truncate">
                    <i className="fas fa-comment fa-fw" /> {recipient.name}
                    {target !== null && (
                        <> &raquo; {target.name}</>
                    )}
                </div>

                <button className="position-absolute bg-transparent border-0 p-0 text-gray-700" style={{ right: 15, top: 8 }} onClick={onClose}><i className="fas fa-times fa-lg" /></button>
            </div>

            {target === null ? (
                <ListGroup variant="flush">
                    {error && (
                        <ListGroup.Item className="text-orange-200">{error}</ListGroup.Item>
                    )}
                    {messages !== null && (
                        <>
                            {messages.length === 0 ? (
                                <ListGroupItem icon="fa-empty-set" iconClass="text-orange-200" title="No recent chat history." message="Send them a message!" onClick={() => history.push({ pathname: '/recipient/chat', state: { recipient } })} />
                            ) : messages.map((message, index) => {
                                let icon;
                                let onClick;

                                if (message.targetId.startsWith('patient_')) {
                                    icon = 'fa-user-injured';
                                    onClick = () => {
                                        setTarget({ id: message.targetId, name: message.targetName });
                                    };
                                } else if (message.targetId.startsWith('team_')) {
                                    icon = 'fa-users';
                                    onClick = () => {
                                        setTarget({ id: message.targetId, name: message.targetName });
                                    };
                                } else {
                                    icon = 'fa-user-tie';
                                    onClick = () => {
                                        setTarget({
                                            id: message.targetId < recipient.id ? `${message.targetId}_${recipient.id}` : `${recipient.id}_${message.targetId}`,
                                            name: message.targetName
                                        });
                                    };
                                }
                                
                                return (
                                    <ListGroupItem key={message.targetId} icon={icon} iconClass="text-blue-300" title={message.targetName} createdOn={message.createdOn} senderId={message.sendingRecipientId} senderName={message.sendingRecipientName} message={message.message} onClick={onClick} />
                                );
                            })}
                        </>
                    )}
                    {loading && (
                        <ListGroup.Item className="text-blue-400 text-center">
                            <i className="fas fa-spinner fa-fw fa-spin fa-2x" />
                        </ListGroup.Item>
                    )}
                </ListGroup>
            ) : (
                <div id={`chat-history_${targetId}`} className="p-1 bg-white overflow-auto" style={{ maxHeight: 'calc(100vh - 53px)' }}>
                    {channelLoading ? (
                        <div id="loading" key="loading" className="text-center"><i className="fas fa-spinner fa-fw fa-spin" /></div>
                    ) : channelError ? (
                        <Alert key="error" variant="orange-200">{error}</Alert>
                    ) : channelMessages !== null && channelMessages.map((message, index) =>
                            <Message key={index} message={message} previousMessage={channelMessages.length > 0 ? channelMessages[index - 1] : null} showName={true} getFile={getFile} />
                    )}
                </div>
            )}
        </>
    );
}

export default Monitor;
