import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Filters from './Filters';
import Table from './Table';
import Pagination from '../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function List() {
    const [ recipientState, setRecipientState ] = useState({ recipients: null, total: 0, loading: false, error: '' });
    const [ filters, setFilters ] = useState({ search: "", hasLoggedIn: null });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 25;
    const history = useHistory();

    useEffect(() => {
        const getRecipients = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', '/recipients', { response: true, queryStringParameters: { ...filters, pageSize, pageNumber } });

                setRecipientState({ recipients: response.data, total: response.headers['x-total-count'], loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Recipients. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setRecipientState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Recipients. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setRecipientState({ recipients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Recipients. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setRecipientState({ recipients: null, total: 0, loading: true, error: '' });
        getRecipients();
    }, [ pageNumber, filters, history ]);

    return (
        <>
            <Helmet>
                <title>Admin | Recipients</title>
            </Helmet>

            <div className="p-4">
                <h1>Recipients</h1>

                {recipientState.error !== '' && (
                    <Alert variant="orange-200" className="small">{recipientState.error}</Alert>
                )}

                <hr />

                <Filters onSearch={filters => { setFilters(filters); setPageNumber(0); } } loading={recipientState.loading} />

                <hr />

                <Table recipients={recipientState.recipients} />

                {recipientState.total > 0 && (
                    <Pagination total={recipientState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
                )}
            </div>
        </>
    );
}

export default List;
