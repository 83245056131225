import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import ListGroupItem from './ListGroupItem';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import { listGroupItem } from './Recent.module.scss';
import { Dropdown } from 'react-bootstrap';

const RECENT_INTERVAL = 15000;
const VIEW_COOKIE_KEY = 'chatView';

function Recent(props) {
    const { branchId, unread, currentTargetId } = props;
    const history = useHistory();
    const v = document.cookie.match(`(^|;) ?${VIEW_COOKIE_KEY}=([^;]*)(;|$)`);
    const initialViewIndex = (v && v[2] ? parseInt(v[2]) : 0);
    
    const VIEW_OPTIONS = [{
        text: 'My Recent Chats',
        filters: {}
    }, {
        text: 'My Unread Chats',
        filters: {
            targetIds: unread ? unread.filter(x => x.count > 0).map(x => x.id).join(',') : ''
        }
    }];

    const [ messagesState, setMessagesState ] = useState({ messages: null, loading: false, error: '' });
    const [ viewIndex, setViewIndex ] = useState(initialViewIndex)
    const view = VIEW_OPTIONS[viewIndex]

    const handleViewChange = (viewOption, index) => {
        if (viewOption.text !== view.text) {
            let d = new Date();
            d.setTime(d.getTime() + 24*60*60*1000*30); // 30 days
            document.cookie = `${VIEW_COOKIE_KEY}=${index};path=/;expires=${d.toGMTString()}`;

            setViewIndex(index)
        }
    }

    useEffect(() => {
        const getRecentMessageSummaryByBranch = async () => {
            try {
                let messages;
                
                if(view.filters.targetIds !== undefined && view.filters.targetIds === '') {
                    messages = [];
                } else {
                    messages = await API.get('AuthenticatedAPI', `/branches/${branchId}/recent-message-summary`, { queryStringParameters : { targetIds: view.filters.targetIds, expand: 'target' } });
                }

                setMessagesState({ messages, loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient');
                            break;
                        default:
                            setMessagesState({ messages: null, loading: false, error: `We encountered an unexpected issue while retrieving recently sent messages. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setMessagesState({ messages: null, loading: false, error: `We encountered an unexpected issue while retrieving recently sent messages. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        getRecentMessageSummaryByBranch();

        let interval = setInterval(() => {
            getRecentMessageSummaryByBranch();
        }, RECENT_INTERVAL);

        return () => clearInterval(interval);
    }, [ history, branchId, view.filters.targetIds ]);

    const getRecipientByBranch = async (recipientId) => {
        try {
            return await API.get('AuthenticatedAPI', `/branches/${branchId}/recipients/${recipientId}`);
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        history.push('/', {
                            message: 'Your session has expired. Please log back in to continue.',
                            redirect: history.location
                        });
                        break;
                    case 403:
                    case 404:
                        return {
                            id: recipientId,
                            disabled: true
                        };
                    default:
                        Sentry.captureException(error);
                        history.push('/recipient');
                        break;
                }
            } else {
                Sentry.captureException(error);
                history.push('/recipient');
            }
        }
    };

    const getPatient = async (patientId) => {
        try {
            return await API.get('AuthenticatedAPI', `/patients/${patientId}`);
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        history.push('/', {
                            message: 'Your session has expired. Please log back in to continue.',
                            redirect: history.location
                        });
                        break;
                    case 403:
                    case 404:
                        return {
                            id: patientId,
                            deleted: true
                        };
                    default:
                        Sentry.captureException(error);
                        history.push('/recipient');
                        break;
                }
            } else {
                Sentry.captureException(error);
                history.push('/recipient');
            }
        }
    };

    const getTeam = async (teamId) => {
        try {
            return await API.get('AuthenticatedAPI', `/teams/${teamId}`);
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        history.push('/', {
                            message: 'Your session has expired. Please log back in to continue.',
                            redirect: history.location
                        });
                        break;
                    case 403:
                    case 404:
                        return {
                            id: teamId,
                            deleted: true
                        };
                    default:
                        Sentry.captureException(error);
                        history.push('/recipient');
                        break;
                }
            } else {
                Sentry.captureException(error);
                history.push('/recipient');
            }
        }
    };

    let unreadCount = unread ? unread.reduce((accum, item) => { return accum + item.count; }, 0) : 0;

    return (   
        <>
            <div className="bg-blue-300 text-white text-center small font-weight-medium py-2">
                <i className="fas fa-comment fa-fw"/>
            
                <Dropdown className="d-inline">
                    <Dropdown.Toggle variant="link" className="text-white px-1 py-0 border-0 small">{view.text}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {VIEW_OPTIONS.map((viewOption, index) =>
                            <Dropdown.Item key={index} className="small" onClick={() => handleViewChange(viewOption, index)}>{viewOption.text}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>

                <Badge variant="orange-200" className="text-gray-700">{unreadCount > 0 && unreadCount}</Badge>
            </div>

            <ListGroup variant="flush" className="border-bottom">
                {messagesState.loading ? (
                    <ListGroup.Item className={`${listGroupItem} text-blue-400 text-center`}>
                        <i className="fas fa-spinner fa-fw fa-spin fa-2x" />
                    </ListGroup.Item>
                ) : messagesState.error ? (
                    <ListGroupItem icon="fa-times" iconClass="text-orange-200" title="Error" titleClass="text-orange-200" message={messagesState.error} />
                ) : messagesState.messages === null ? (
                    <ListGroupItem onClick={() => history.push('/recipient/contacts/recipients')} icon="fa-comment" iconClass="text-blue-400" title="Let's get started!" message="Click here to start a conversation!" />
                ) : (

                    <>
                        <ListGroupItem onClick={() => history.push('/recipient/contacts/recipients')} icon="fa-comment" iconClass="text-blue-400" title="Let's get started!" message="Click here to start a conversation!" />

                        {messagesState.messages.map(message => {
                            let icon;
                            let unreadCount;
                            let onClick;

                            if (message.targetId.startsWith('patient_')) {
                                icon = 'fa-user-injured';

                                onClick = async () => {
                                    let patient = await getPatient(message.targetId.replace('patient_', ''));

                                    if (patient.deleted) {
                                        alert('This channel was deleted in a previous version of Notifyd and is no longer accessible. If you need access to chat history for auditing purposes, please contact Notifyd Support through your Help menu');
                                    } else {
                                        history.push('/recipient/chat', { patient });
                                    }
                                };

                                let patientUnread = unread && unread.find(x => x.id === message.targetId);
                                unreadCount = patientUnread ? patientUnread.count : 0;
                            } else if (message.targetId.startsWith('team_')) {
                                icon = 'fa-users';

                                onClick = async () => {
                                    let team = await getTeam(message.targetId.replace('team_', ''));

                                    if (team.deleted) {
                                        alert('This channel was deleted in a previous version of Notifyd and is no longer accessible. If you need access to chat history for auditing purposes, please contact Notifyd Support, through your Help menu');
                                    } else {
                                        history.push('/recipient/chat', { team });
                                    }
                                };

                                let teamUnread = unread && unread.find(x => x.id === message.targetId);
                                unreadCount = teamUnread ? teamUnread.count : 0;
                            } else {
                                icon = 'fa-user-tie';

                                onClick = async () => {
                                    let recipient = await getRecipientByBranch(message.targetId);

                                    if (recipient.disabled) {
                                        recipient.name = message.targetName;
                                    }

                                    history.push('/recipient/chat', { recipient });
                                }

                                let recipientUnread = unread && unread.find(x => x.id === message.targetId);
                                unreadCount = recipientUnread ? recipientUnread.count : 0;
                            }

                            return (
                                <ListGroupItem key={message.targetId} icon={icon} iconClass="text-blue-300" title={message.targetName} createdOn={message.createdOn} senderId={message.sendingRecipientId} senderName={message.sendingRecipientName} message={message.message} unreadCount={unreadCount} highlight={currentTargetId === message.targetId} doNotDisturb={message.doNotDisturb} onClick={currentTargetId !== message.targetId ? onClick : undefined} />
                            );
                        })}
                    </>
                )}
            </ListGroup>
        </>
    );
}

export default Recent;
