import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Filters from './Filters';
import Table from './Table';
import Pagination from '../../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function List(props) {
    const { branch, unread } = props;

    const [ patientState, setPatientState ] = useState({ patients: null, total: 0, loading: false, error: '' });
    const [ filters, setFilters ] = useState({ search: "", assignedTo: null, disabled: false });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 25;
    const history = useHistory();

    useEffect(() => {
        const getPatientsByBranch = async () => {
            try {
                let queryStringParameters = { ...filters, pageSize, pageNumber, expand: 'patient-recipient' };

                if (!branch.canManagePatients) {
                    queryStringParameters.assignedTo = true;
                    queryStringParameters.chatEnabled = true;
                }

                let response = await API.get('AuthenticatedAPI', `/branches/${branch.id}/patients`, { response: true, queryStringParameters });

                setPatientState({ patients: response.data, total: response.headers['x-total-count'], loading: false, error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Patients.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setPatientState({ patients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Patients for this Branch. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setPatientState({ patients: null, total: 0, loading: false, error: `We encountered an unexpected issue while retrieving Patients for this Branch. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setPatientState({ patients: null, total: 0, loading: true, error: '' });
        getPatientsByBranch();
    }, [ branch.id, branch.canManagePatients, pageNumber, filters, history ]);

    const removePatient = patient => {
        setPatientState(patientState => { return { patients: patientState.patients.filter(x => x.id !== patient.id), total: patientState.total - 1, loading: false, error: '' }});
    };

    return (
        <>
            <Helmet>
                <title>Employee | Care Teams</title>
            </Helmet>

            <Filters onSearch={filters => { setFilters(filters); setPageNumber(0); } } showAssignedTo={branch.canManagePatients} showDisabled={branch.canManagePatients} loading={patientState.loading} />

            {patientState.error !== '' && (
                <Alert variant="orange-200" className="small">{patientState.error}</Alert>
            )}

            <Table canManagePatients={branch.canManagePatients} patients={patientState.patients} unread={unread} disabled={filters.disabled} removePatient={removePatient} />

            {patientState.total > 0 && (
                <Pagination total={patientState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
            )}
        </>
    );
}

export default List;
