import { Auth } from 'aws-amplify';

const imports = {
    API: {
        endpoints: [{
            name: "UnauthenticatedAPI",
            endpoint: process.env.REACT_APP_NOTIFYD_API_URL
        }, {
            name: "AuthenticatedAPI",
            endpoint: process.env.REACT_APP_NOTIFYD_API_URL,
            custom_header: async () => {
                try {
                    return {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    };
                } catch (error) {
                    if (error === 'No current user') {
                        let apiError = new Error();
                        apiError.response = { status: 401 };
                        throw apiError;
                    } else {
                        throw error;
                    }
                }
            }
        }, {
            name: "AuthenticatedAccessAPI",
            endpoint: process.env.REACT_APP_NOTIFYD_API_URL,
            custom_header: async () => {
                try {
                    const currentSession = await Auth.currentSession()
                    
                    return {
                        Authorization: `Bearer ${currentSession.getIdToken().getJwtToken()}`,
                        'Access-Token': currentSession.getAccessToken().getJwtToken()
                    };
                } catch (error) {
                    if (error === 'No current user') {
                        let apiError = new Error();
                        apiError.response = { status: 401 };
                        throw apiError;
                    } else {
                        throw error;
                    }
                }
            }
        }]
    },
    Auth: {
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
    }

};

export default imports;
