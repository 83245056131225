import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import Papa from 'papaparse';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

const TABLE_HEADERS = [
    'First Name',
    'Last Name',
    'City',
    'State',
    'Zip',
    'Cell',
    'Phone',
    'Email',
    'Gender',
    'Discipline',
    'Status',
    'Language'
];

function Upload(props) {
    const { branch } = props;

    const [ file, setFile ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ errors, setErrors ] = useState();
    const [ employees, setEmployees ] = useState();
    const [ processing, setProcessing ] = useState(false);
    const [ successfulRecords, setSuccessfulRecords ] = useState({});
    const [ erroredRecords, setErroredRecords ] = useState({});
    const [ complete, setComplete ] = useState(false);

    const history = useHistory();

    const formSubmit = e => {
        e.preventDefault();

        setLoading(true);
        setEmployees(undefined);
        setProcessing(false);
        setSuccessfulRecords({});
        setErroredRecords({});
        setComplete(false);

        Papa.parse(file, {
            delimiter: ',',
            skipEmptyLines: true,
            dynamicTyping: true,
            header: true,
            transformHeader: (header, index) => {
                return header.replace('recipient.', '');
            },
            transform: (value, index) => {
                if (value === 'null') {
                    return null;
                }

                return value;
            },
            complete: (results, file) => {
                setLoading(false);
                setErrors(results.errors);

                let dedupeKey = employee => employee.Email;
                let dedupedEmployees = [ ...new Map(results.data.map(x => [dedupeKey(x), x])).values() ];

                setEmployees(dedupedEmployees);
            }
        });
    };

    const processRecords = async () => {
        const createEmployeeAndBranchRelationship = async (employee, index) => {
            let recipientId;

            try {
                let name = `${employee['First Name']} ${employee['Last Name']}`;
                let { Email: email, Zip: zipCode } = employee;
                let phone = (employee['Phone'] || employee['Cell']).toString().replace(/\D/g, '');
                let contactPhone = (employee['Cell'] || employee['Phone']).toString().replace(/\D/g, '');
                let jobTitle = employee['Discipline'];

                const formatPhoneNumber = (number) => {
                    if (number.length < 9) {
                        return null;
                    }

                    if (number.length === 10) {
                        return `+1${number}`;
                    }

                    if (number.substring(0,1) !== '+') {
                        return `+${number}`;
                    }
                }

                phone = formatPhoneNumber(phone);
                contactPhone = formatPhoneNumber(contactPhone);

                let recipient = await API.post('AuthenticatedAPI', `/companies/${branch.companyId}/recipients`, { body: {
                    name, email, phone, contactPhone, jobTitle, zipCode: zipCode ? ('00000' + zipCode).slice(-5) : null
                } });

                recipientId = recipient.id;
            } catch (error) {
                let errorMessage;

                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            errorMessage = error.response.data.message;
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/recipient/contacts/recipients');
                            break;
                        case 409:
                            errorMessage = 'Email is already in use by another account. Please provide a unique email address not already associated with a Notifyd Recipient.';
                            break;
                        case 429:
                            errorMessage = error.response.data.message;
                            break;
                        default:
                            errorMessage = `We encountered an unexpected issue while creating a Recipient. The service returned error code ${error.response.status}`;
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    errorMessage = error.message;
                    Sentry.captureException(error);
                }

                setErroredRecords(erroredRecords => { return { ...erroredRecords, [index]: errorMessage } });
            }

            if (recipientId) {
                try {
                    let [ canManageBranch, officeGroup, canViewFieldEmployees, canViewAllBranchNotifications, canViewAllChats, canManageEmployees, canManagePatients, canManageTeams ] = [ false, false, false, false, false, false, false, false ];

                    await API.put('AuthenticatedAPI', `/branches/${branch.id}/recipients/${recipientId}`, { body: {
                        canManageBranch, officeGroup, canViewFieldEmployees, canViewAllBranchNotifications, canViewAllChats, canManageEmployees, canManagePatients, canManageTeams
                    } });

                    setSuccessfulRecords(successfulRecords => { return { ...successfulRecords, [index]: true } });
                } catch (error) {
                    let errorMessage;

                    if (error.response) {
                        switch (error.response.status) {
                            case 400:
                                errorMessage = error.response.data.message;
                                break;
                            case 401:
                                history.push('/', {
                                    message: 'Your session has expired. Please log back in to continue.',
                                    redirect: history.location
                                });
                                break;
                            case 403:
                                history.push('/recipient/contacts/recipients', {
                                    message: 'You are not authorized to edit this Recipient.',
                                    redirect: history.location
                                });
                                break;
                            case 404:
                                history.push('/recipient/contacts/recipients');
                                break;
                            default:
                                errorMessage = `We encountered an unexpected issue while assigning permissions for this Recipient. The service returned error code ${error.response.status}`;
                                Sentry.captureException(error);
                                break;
                        }
                    } else {
                        errorMessage = `We encountered an unexpected issue while assigning permissions for this Recipient. The service returned error message ${error.message}`;
                        Sentry.captureException(error);
                    }

                    setErroredRecords(erroredRecords => { return { ...erroredRecords, [index]: errorMessage } });
                }
            }
        };

        setProcessing(true);
        setFile(undefined);

        for (let i=0; i<employees.length; i++) {
            try {
                await createEmployeeAndBranchRelationship(employees[i], i);
            } catch (error) {
                console.log(error);
            }
        }

        setComplete(true);
    };

    return (
        <>
            <Helmet>
                <title>Employee | Upload from a Kantime Export</title>
            </Helmet>

            <div className="bg-white p-4 mb-3 border border-top-0 border-gray-600 rounded-bottom">
                <Row className="align-items-center">
                    <Col xs="12" sm="3" lg="2" className="text-center">
                        <div className="bg-gray-300 rounded-circle d-inline-block p-3">
                            <i className="fas fa-3x fa-upload fa-fw text-gray-700"></i>
                        </div>

                        <hr className="d-sm-none" />
                    </Col>

                    <Col xs="12" sm="9" lg="10">
                        <h3>Follow these steps to upload multiple employees from a Kantime CSV export.</h3>
                        <ol className="small">
                            <li>Export your staff roster from Kantime.</li>
                            <li>Upload your exported file and review the data looks correct.</li>
                            <li>Start the upload.</li>
                        </ol>
                    </Col>
                </Row>
            </div>

            <Alert variant="blue-400" className="small">
                <strong>Tips:</strong>
                <ul>
                    <li>After your upload is complete, you can bulk-update your Notifyd permissions by <Alert.Link as={Link} href="/recipient/contacts/recipients" to="/recipient/contacts/recipients">exporting your current Employees</Alert.Link>, modifying them in excel or another spreadsheet editor, and re-uploading them.</li>
                </ul>
            </Alert>

            <Form onSubmit={formSubmit}>
                <fieldset>
                    <legend>Upload Kantime Export</legend>

                    <Row>
                        <Col xs="12" sm="6" lg="5" xl="4" className="overflow-hidden">
                            <Form.Group controlId="file">
                                <Form.File className="custom-file">
                                    <Form.File.Input className="custom-file-input" onChange={e => setFile(e.target.files[0])} accept={'.csv'} />
                                    <Form.File.Label className="custom-file-label">{file ? file.name : 'Choose File (.csv only)'}</Form.File.Label>
                                </Form.File>
                            </Form.Group>
                        </Col>

                        <Col xs="auto">
                            <Button type="submit" variant="blue-200" size="sm" disabled={file === undefined || loading}>
                                {loading ? (
                                    <span key="loading"><i className="fas fa-spinner fa-fw fa-spin" /></span>
                                ) : (
                                    <>Upload</>
                                )}
                            </Button>
                        </Col>
                    </Row>
                </fieldset>
            </Form>

            {errors && errors.length > 0 && (
                <Alert variant="orange-200" className="small">
                    {errors.map(error =>
                        <div><strong>Row {1 + error.row}:</strong> {error.message}</div>
                    )}
                </Alert>
            )}

            {employees && employees.length > 0 && (
                <>
                    <hr/>

                    <Row className="justify-content-end my-3">
                        <Col xs="12" sm="auto">
                            {complete ? (
                                <strong className="small">Finished ({Object.keys(erroredRecords).length} errors)</strong>
                            ) : processing ? (
                                <strong className="small">{(100 * (Object.keys(erroredRecords).length + Object.keys(successfulRecords).length) / employees.length).toFixed(2)}%</strong>
                            ) : (
                                <>
                                    <Button variant="green-500" size="sm" className="text-gray-700" onClick={processRecords} disabled={processing}><i className="fas fa-upload fa-fw" /> Process {employees.length} records</Button>
                                </>
                            )}
                        </Col>
                    </Row>

                    <Table size="sm" responsive striped bordered hover className="bg-white smaller">
                        <thead className="bg-gray-200 text-white">
                            <tr>
                                {TABLE_HEADERS.map((header, index) =>
                                    <th key={index}>{header}</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {employees.length > 0 ? employees.map((employee, index) => {
                                const className = successfulRecords[index] ? 'bg-green-700' : erroredRecords[index] ? 'bg-orange-200 text-white' : processing ? 'bg-yellow-500' : null;

                                return (
                                    <React.Fragment key={index}>
                                        <tr className={className}>
                                            {TABLE_HEADERS.map((header, index2) =>
                                                <td key={index2}>{employee[header]}</td>
                                            )}
                                        </tr>
                                        {(index in erroredRecords) && (
                                            <tr className={className}><td colSpan={TABLE_HEADERS.length}>{erroredRecords[index]}</td></tr>
                                        )}
                                    </React.Fragment>
                                );
                            }) : (
                                <tr><td colSpan={TABLE_HEADERS.length}>No rows</td></tr>
                            )}
                        </tbody>
                    </Table>
                </>
            )}
        </>
    );
}

export default Upload;
