import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Form from './Form';
import { Auth, API } from 'aws-amplify';
import * as Sentry from "@sentry/react";

function Details() {
    const [ recipient, setRecipient ] = useState(null);
    const [ error, setError ] = useState('');
    const recipientId = Auth.user.username;

    const history = useHistory();

    useEffect(() => {
        const getRecipient = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', `/recipients/${recipientId}`);

                setRecipient(response);
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setError(error.response.data.message);
                            break;
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access this Recipient.',
                                redirect: history.location
                            });
                            break;
                        case 404:
                            history.push('/recipient');
                            break;
                        default:
                            setError(`We encountered an unexpected issue while retrieving this Recipient. The service returned error code ${error.response.status}`);
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setError(`We encountered an unexpected issue while retrieving this Recipient. The service returned error message ${error.message}`);
                    Sentry.captureException(error);
                }
            }
        };
        
        getRecipient();
    }, [ recipientId, history ]);

    return (
        <>
            <Helmet>
                <title>Account | Update Profile</title>
            </Helmet>

            <div className="p-4">
                <h1>My Profile</h1>

                <hr />


                {recipient === null ?
                    error === '' ? (
                        <h1><i className="fas fa-spinner fa-fw fa-lg fa-spin" /></h1>
                    ) : (
                        <Alert variant="orange-200" className="small">{error}</Alert>
                    )
                : (
                    <Form recipient={recipient} />
                )}
            </div>
        </>
    );
}

export default Details;
