import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { default as BootstrapTable } from 'react-bootstrap/Table';

function Table(props) {
    return (
        <>
            <Row className="smaller justify-content-end">
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-edit fa-fw"></i> Edit Branch
                </Col>
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-building fa-fw"></i> View Company
                </Col>
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fas fa-handshake fa-fw"></i> View Partner
                </Col>
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fa fa-users fa-fw"></i> View Recipients
                </Col>
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fa fa-chart-line fa-fw"></i> View Reports
                </Col>
                <Col xs="6" lg="auto" className="text-gray-400 font-weight-light">
                    <i className="fab fa-cc-stripe fa-fw"></i> View Billing
                </Col>
            </Row>

            <BootstrapTable responsive striped bordered hover className="mt-3 bg-white">
                <thead className="bg-gray-200 text-white small font-weight-normal">
                    <tr>
                        <th>Branch</th>
                        <th width="273"></th>
                    </tr>
                </thead>
                <tbody>
                    {props.branches === null ? (
                        <tr key="loading">
                            <td colSpan="3" className="text-center">
                                <i className="fas fa-spinner fa-fw fa-lg fa-spin" />
                            </td>
                        </tr>
                    ) : props.branches.length === 0 ? (
                        <tr key="none">
                            <td colSpan="3" className="text-center">
                                No results found
                            </td>
                        </tr>
                    ) : props.branches.map(branch =>
                        <tr key={branch.id}>
                            <td>
                                {branch.name}<br />
                                <small className="smaller text-muted">{branch.id}</small>
                            </td>
                            <td className="align-middle">
                                <Button as={Link} variant="link" size="sm" href={`/admin/branches/${branch.id}`} to={`/admin/branches/${branch.id}`} className="text-blue-300" title="Edit Branch"><i className="fas fa-edit fa-fw fa-lg"></i></Button>
                                <Button as={Link} variant="link" size="sm" href={`/admin/companies/${branch.companyId}`} to={`/admin/companies/${branch.companyId}`} className="text-blue-300" title="View Company"><i className="fas fa-building fa-fw fa-lg"></i></Button>
                                <Button as={Link} variant="link" size="sm" href={`/admin/partners/${branch.partnerId}`} to={`/admin/partners/${branch.partnerId}`} className="text-blue-300" title="View Partner"><i className="fas fa-handshake fa-fw fa-lg"></i></Button>
                                <Button as={Link} variant="link" size="sm" href={`/admin/branches/${branch.id}/recipients`} to={`/admin/branches/${branch.id}/recipients`} className="text-blue-300" title="View Recipients"><i className="fa fa-users fa-fw fa-lg"></i></Button>
                                <Button as={Link} variant="link" size="sm" href={`/admin/branches/${branch.id}/reports`} to={`/admin/branches/${branch.id}/reports`} className="text-blue-300" title="View Reports"><i className="fa fa-chart-line fa-fw fa-lg"></i></Button>
                                <Button variant="link" disabled={branch.stripeSubscriptionId === null} size="sm" href={`https://dashboard.stripe.com/subscriptions/${branch.stripeSubscriptionId}`} className={branch.stripeSubscriptionId === null ? 'text-gray-600' : 'text-blue-300'} title="View Billing" target="_blank" rel="noopener noreferrer"><i className="fab fa-cc-stripe fa-fw fa-lg"></i></Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </BootstrapTable>
        </>
    );
}

export default Table;
