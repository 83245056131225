import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Alert from 'react-bootstrap/Alert';
import Table from './Table';
import Pagination from '../../Pagination';
import { API } from 'aws-amplify';
import * as Sentry from "@sentry/react";
import Filters from "./Filters"

function List() {
    const [ branchState, setBranchState ] = useState({ branches: null, total: 0, error: '' });
    const [ pageNumber, setPageNumber ] = useState(0);
    const pageSize = 25;
    const history = useHistory();
    const [ filters, setFilters ] = useState({ search: "", enabled: null });

    useEffect(() => {  
        const getBranches = async () => {
            try {
                let response = await API.get('AuthenticatedAPI', '/branches', { response: true, queryStringParameters: { pageSize, pageNumber, ...filters } });
                setBranchState({ branches: response.data, total: response.headers['x-total-count'], error: '' });
            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            history.push('/', {
                                message: 'Your session has expired. Please log back in to continue.',
                                redirect: history.location
                            });
                            break;
                        case 403:
                            history.push('/', {
                                message: 'You are not authorized to access Branches. Please log in as an administrator to continue.',
                                redirect: history.location
                            });
                            break;
                        default:
                            setBranchState({ branches: null, total: 0, error: `We encountered an unexpected issue while retrieving Branches. The service returned error code ${error.response.status}` });
                            Sentry.captureException(error);
                            break;
                    }
                } else {
                    setBranchState({ branches: null, total: 0, error: `We encountered an unexpected issue while retrieving Branches. The service returned error message ${error.message}` });
                    Sentry.captureException(error);
                }
            }
        };

        setBranchState({ branches: null, total: 0, error: '' });
        getBranches();
    }, [ pageNumber, history, filters ]);

    return (
        <>
            <Helmet>
                <title>Admin | Branches</title>
            </Helmet>

            <div className="p-4">
                <h1>Branches</h1>

                {branchState.error !== '' && (
                    <Alert variant="orange-200" className="small">{branchState.error}</Alert>
                )}
                
                <hr />
                
                <Filters onSearch={filters => { setFilters(filters); setPageNumber(0); } } loading={branchState.loading} />

                <hr />

                <Table branches={branchState.branches} />

                {branchState.total > 0 && (
                    <Pagination total={branchState.total} pageSize={pageSize} pageNumber={pageNumber} onChange={page => setPageNumber(page)} />
                )}
            </div>
        </>
    );
}

export default List;
