import React from 'react';
import { Switch, Route, Redirect, Link, useLocation } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Dashboard from './Dashboard';
import Guide from './Webhooks/Guide';
import { default as WebhookList } from './Webhooks/List';
import { default as WebhookDetails } from './Webhooks/Details';
import logo from '../../images/logo-full.png';

function App(props) {
    const location = useLocation();

    if (props.group !== 'partner') {
        return (
            <Redirect to={{ pathname: '/', state: { message: 'You must be logged in as a partner to access this area.' }}} />
        );
    }

    return (
        <Row noGutters className="bg-gray-700">
            <Col as="aside" xs="12" md="3" xl="2" className="bg-blue-100 sticky-top vh-md-100 mh-100v overflow-x-hidden">
                <Navbar variant="dark" expand="md" className="align-items-md-start flex-md-column px-3" collapseOnSelect={true}>
                    <Navbar.Brand>
                        <Image src={logo} alt="Notifyd logo" width="100" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="primary-navigation">
                        <i className="fas fa-bars text-white"></i>
                    </Navbar.Toggle>

                    <Navbar.Collapse id="primary-navigation" className="mt-2 w-100">
                        <Nav className="flex-column w-100" activeKey={location.pathname}>
                            <Nav.Item>
                                <Nav.Link as={Link} href="/partner" to="/partner" className="small">
                                    <i className="fas fa-tachometer-alt fa-fw"></i> Dashboard
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} href="/partner/web-hooks" to="/partner/web-hooks" className="small">
                                    <i className="fas fa-project-diagram fa-fw"></i> Web Hooks
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item><hr className="border-blue-300 my-3 py-0" /></Nav.Item>

                            <Nav.Item>
                                <Nav.Link href="mailto:tech-support@notifyd.com" className="small text-blue-400">
                                    <i className="fas fa-question-circle fa-fw"></i> Help
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/api-docs" target="_blank" className="small text-blue-400">
                                    <i className="fas fa-book fa-fw"></i> API
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="small text-blue-400" onClick={() => props.onLogout()}>
                                    <i className="fas fa-sign-out-alt fa-fw"></i> Logout
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Col>

            <Col as="main" xs="12" md="9" xl="10">
                <Switch>
                    <Route path="/partner" exact component={Dashboard} />

                    {/* Webhook Pages */}
                    <Route path="/partner/web-hooks" exact component={WebhookList} />
                    <Route path="/partner/web-hooks/implementation-guide" exact component={Guide} />
                    <Route path="/partner/web-hooks/:id" component={WebhookDetails} />
                </Switch>
            </Col>
        </Row>
    );
}

export default App;
