import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import Amplify from "aws-amplify";
import App from './App';
import awsExports from "./aws-exports";
import awsImports from "./aws-imports";
import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_SENTRY_ENABLED === '1') {
    Sentry.init({ dsn: "https://0e188b88497745bb97f50ddaae8cfc09@o310783.ingest.sentry.io/5435207" });
}

Amplify.configure(awsExports);
Amplify.configure(awsImports);

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
