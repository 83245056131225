import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import logo from '../images/logo-full.png';

export default function Navigation() {
    const location = useLocation();

    return (
        <div className="bg-blue-100 position-fixed w-100" style={{ zIndex: 99, top: 0 }}>
            <Container>
                <Navbar variant="dark" expand="lg" collapseOnSelect={true}>
                    <Navbar.Brand href="https://notifyd.com" className="pr-3"><img src={logo} alt="Notifyd logo" width="100" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="primary-navigation-collapsed">
                        <i className="fas fa-bars text-white"></i>
                    </Navbar.Toggle>

                    <Navbar.Collapse id="primary-navigation-collapsed">
                        <Nav activeKey={location.pathname}>
                            <Nav.Link href="https://notifyd.com/infrastructure">The Problem</Nav.Link>
                            <Nav.Link href="https://notifyd.com/customers">The Solution</Nav.Link>
                            <Nav.Link href="https://notifyd.com/hipaa">HIPAA Compliance</Nav.Link>
                            <Nav.Link href="https://notifyd.com/pricing">Pricing</Nav.Link>
                            <Nav.Link href="https://notifyd.zendesk.com/hc/en-us" target="_blank">FAQ</Nav.Link>
                            <Nav.Link href="https://notifyd.com/contact">Contact</Nav.Link>
                            <Nav.Link href="https://meetings.hubspot.com/josh-guthrie1" target="_blank" rel="noopener noreferrer" className="d-lg-none">Get a Demo</Nav.Link>
                            <Nav.Link as={Link} to="/" href="/" className="d-lg-none">Login</Nav.Link>
                        </Nav>

                        <Button variant="orange-300" size="sm" href="https://meetings.hubspot.com/josh-guthrie1" target="_blank" rel="noopener noreferrer" className="ml-auto mr-3 d-none d-lg-block">Get a Demo</Button>
                        <Button variant="blue-400" size="sm" as={Link} to="/" className="d-none d-lg-block">Login</Button>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </div>
    );
}